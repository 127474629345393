import { throwError } from "rxjs";
import {
  SimpleChange,
  EventEmitter,
  Component,
  OnChanges,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Libraries
import Player from "@vimeo/player";

@Component({
  selector: "app-vimeo-player",
  templateUrl: "./vimeo-player.component.html",
  styleUrls: ["./vimeo-player.component.scss"],
})
export class VimeoPlayerComponent implements OnInit, OnChanges {
  @Input() gameData;
  @Input() isLoggedIn;
  playerInstance: any;
  isMutted: boolean = true;
  isPaused: boolean = true;
  isVideoEnded: boolean = false;
  isLoading: boolean = false;
  @Output() gameLaunch: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["gameData"]) {
      this.gameData = changes["gameData"].currentValue;
      setTimeout(() => {
        this.initiateVimeoPlayer(this.gameData["externalAsset"]);
      });
    }
    if (changes["isLoggedIn"]) {
      this.isLoggedIn = changes["isLoggedIn"].currentValue;
    }
  }

  initiateVimeoPlayer(vimeoId) {
    /**
     * @property id/url
     *  Idof vedio or url of vedio need to pass here to create a vedio iframe
     *  ex: 'https://player.vimeo.in/video/76979871'(url)
     *  ex:  76979871(ID)
     *
     * @property speed:true/false
     * This flag is need when ur planning to increase play back spped of video
     * by using player.setPlaybackRate(value)
     * Here value can be b/w 0.5 to 2...
     *
     * @property loop:true/false
     * when this flag is set ture video autoplay again after it ends.
     *
     * @property speed: true/false
     * Set speed flag true to programatically handle speed of the vedio..
     *
     * @property autopause:true/false
     * Pause this video automatically when another one plays.
     *
     */

    const options = {
      id: vimeoId,
      autopause: true,
      width: "100%",
      muted: true,
      loop: false,
      responsive: true,
      speed: true,
      controls: false,
    };

    this.playerInstance = new Player("vimeoId" + vimeoId, options);
    if (this.playerInstance) {
      this.playerInstance.on("play", () => {
        //Do something here if requested...after play btn clicked
      });
    }

    this.playerInstance.on("ended", (data) => {
      this.isVideoEnded = true;
    });

    this.playerInstance.on("pause", (data) => {
      this.isPaused = true;
    });
  }

  setVolume() {
    this.isMutted = !this.isMutted;
    if (this.isMutted) {
      this.playerInstance.setVolume(0);
    } else {
      this.playerInstance.setVolume(1);
    }
  }
  playandPausedHandler() {
    if (this.isVideoEnded) {
      this.isVideoEnded = false;
    }
    if (this.isPaused) {
      this.isLoading = true;
    }
    this.playerInstance.getPaused().then((paused) => {
      if (paused) {
        this.playerInstance
          .play()
          .then((paused) => {
            this.isPaused = false;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            return throwError(error);
          });
      } else {
        this.playerInstance
          .pause()
          .then((paused) => {
            this.isPaused = false;
          })
          .catch((error) => {
            return throwError(error);
          });
      }
    });
  }

  gameLauncHanlder(name, hasDemo, callingFrom) {
    this.gameLaunch.emit({
      name: name,
      hasDemo: hasDemo,
      callingFrom: callingFrom,
    });
  }
}
