import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Output,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-free-spin-reward-card",
  templateUrl: "./free-spin-reward-card.component.html",
  styleUrls: ["./free-spin-reward-card.component.scss"],
})
export class FreeSpinRewardCardComponent implements OnChanges, OnDestroy {
  @Input() bonusDetails;
  @Input() callingFrom: string;
  @Output() callOpenRewardTCPopUp: EventEmitter<any> = new EventEmitter();
  gamesUrlPath: string = environment.gamesUrlPath;
  currencyCode: any;
  currencySysbol: any;
  currencyCodeSub: Subscription;
  isClaimBonusInprogress: boolean;
  langCodeSub: Subscription;
  langCode: string;
  imgixParams: string;

  constructor(
    private router: Router,
    private utility: UtilityService,
    private gamePlayService: GamePlayService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService
  ) {
    this.currencyCodeSub = this.userDetailsService.currencyCodeSb$.subscribe(
      (curreny) => {
        this.currencyCode = curreny;
      }
    );
    this.langCodeSub = this.translationService.langCodeSb$.subscribe(
      (langcode) => {
        this.langCode = langcode;
      }
    );
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["bonusDetails"]) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  launchGame(gameName) {
    gameName = this.utility.convertGameNameToUrl(gameName);
    let currentGameSlug = this.utility.getDecodedCurrentPath().split("/");
    if (currentGameSlug[currentGameSlug.length - 1] != gameName) {
      this.gamePlayService.clearGameWindowData();
    }
    this.gamePlayService.setGameCalledfrom("realgame");
    let currentPath = this.utility.getDecodedCurrentPath();
    setTimeout(() => {
      if (
        currentPath.includes(
          this.translationService.instant("url.game") + "/" + gameName
        )
      ) {
        this.gamePlayService.broadCastRelanuchGame(true);
      } else {
        this.router.navigate([
          this.langCode +
            "/" +
            this.translationService.instant("url.game") +
            "/" +
            gameName,
        ]);
      }
      this.utility.closeAccountComponent();
    });
  }

  ngOnDestroy() {
    this.currencyCodeSub.unsubscribe();
    this.currencyCodeSub.unsubscribe();
  }

  openRewardTCPopUp(event) {
    this.callOpenRewardTCPopUp.emit(event);
  }
}
