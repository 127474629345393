import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  SimpleChange,
  EventEmitter,
  Component,
  OnDestroy,
  Output,
  Input,
} from "@angular/core";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-game-lobby",
  templateUrl: "./game-lobby.component.html",
  styleUrls: ["./game-lobby.component.scss"],
})
export class GameLobbyComponent implements OnDestroy {
  @Input() callingFrom;
  @Output() callOnSelectedLobby: EventEmitter<any> = new EventEmitter();
  lobbyConfig: any;
  lobbyLinkConfig: any;
  activeLobby: string = "casino";
  routerSub: any;
  isLaoding: boolean;
  gameGroupLobbies: any;
  isLoggedIn;
  updateActiveLobbySub: Subscription;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  isLastPlayedLiveGamesExist: boolean = false;
  lastedPlayedLiveGamesSub: Subscription;
  showSearchResult: boolean = false;
  isSearchEnabled: boolean = false;
  constructor(
    private utility: UtilityService,
    private router: Router,
    private gameGroupsService: GameGroupsService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute
  ) {
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.commonService.getActiveLobby()) {
          this.activeLobby = this.commonService.getActiveLobby();
          this.navigateToLobby(this.activeLobby);
        } else {
          this.navigateToLobby(this.activeLobby);
        }
      }
    });
    this.updateActiveLobbySub = this.commonService.updateActiveLobbyData$.subscribe(
      (data) => {
        if (data && data["lobby"]) {
          this.activeLobby = data["lobby"];
          this.navigateToLobby(this.activeLobby);
        }
      }
    );
    this.lastedPlayedLiveGamesSub = this.gameGroupsService.isLastPlayedLiveGamesExistSb$.subscribe(
      (isExist) => {
        this.isLastPlayedLiveGamesExist = isExist;
      }
    );
    this.commonService.searchEnabledStatus$.subscribe((status) => {
      this.isSearchEnabled = status;
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["callingFrom"]) {
      let lobby;
      this.callingFrom = changes["callingFrom"].currentValue;
      if (
        this.utility.getQueryParams() &&
        this.utility.getQueryParams()["lobby"] &&
        this.isLoggedIn
      ) {
        lobby = this.utility.getQueryParams()["lobby"];
      } else if (this.commonService.getActiveLobby()) {
        lobby = this.commonService.getActiveLobby();
      } else {
        lobby = this.callingFrom === "casinoLobby" ? "casino" : "all-live";
      }
      this.lobbyConfig = this.getLobbyconfigData(this.callingFrom);
      this.navigateToLobby(lobby);
    }
  }

  getLobbyconfigData(callingFrom) {
    if (this.callingFrom === "casinoLobby") {
      return this.utility.getBrandBasedConfig("casinoLobbyConfig");
    } else if (this.callingFrom === "liveCasinoLobby") {
      return this.utility.getBrandBasedConfig("liveCasinolobbyConfig");
    }
  }

  getGameGroupLobby() {
    this.isLaoding = true;
    this.gameGroupsService.getLobbyListWithGameGroups().subscribe((data) => {
      this.isLaoding = false;
      if (data && data.length > 0) {
        this.gameGroupLobbies = data;
      }
    });
  }

  navigateToLobby(lobbyName, callingFrom?) {
    if (
      callingFrom === "list" &&
      Object.keys(this.activatedRoute.snapshot.queryParams).length > 0
    ) {
      this.clearQueryParams();
    }
    this.activeLobby = this.assignProperLobby(lobbyName);
    if (this.activeLobby) {
      this.commonService.setActiveLobby(this.activeLobby);
      this.callOnSelectedLobby.emit(this.activeLobby);
    }
  }

  assignProperLobby(name) {
    const lobbies = this.utility.getBrandBasedConfig("lobbyByPageName");
    if (
      this.router.url.includes("/casino") &&
      lobbies &&
      lobbies["casinolobbies"] &&
      lobbies["casinolobbies"].indexOf(name) > -1
    ) {
      return name;
    } else if (
      this.router.url.includes("/live-casino") &&
      lobbies &&
      lobbies["livecasinolobbies"] &&
      lobbies["livecasinolobbies"].indexOf(name) > -1
    ) {
      return name;
    } else if (this.router.url.includes("/casino")) {
      return "casino";
    } else if (this.router.url.includes("/live-casino")) {
      return "all-live";
    }
  }

  clearQueryParams() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
    });
  }

  openSearchResultsAtLobby() {
    this.isSearchEnabled = true;
    this.commonService.setCasinoPageSearchEnabledStatus(this.isSearchEnabled);
    this.utility.addClassToAppBody("overflow-hidden");
  }
  closeSearchResultsAtLobby() {
    this.isSearchEnabled = false;
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  ngOnDestroy() {
    this.updateActiveLobbySub.unsubscribe();
    if (this.isSearchEnabled) {
      this.commonService.setCasinoPageSearchEnabledStatus(
        !this.isSearchEnabled
      );
    }
  }
}
