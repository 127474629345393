import { BehaviorSubject, Observable, Subject } from "rxjs/";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";

// Configurations
import { cmsToLocalLanguageConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

@Injectable()
export class TranslationService {
  currentLanguage = environment.defaultLang;
  currentStaticPageUrl = undefined;

  constructor(private translateService: TranslateService) {}

  /**
   * we are using BehaviorSubject as we have initial
   * value & to set user language code
   */

  public langCodeSb = new BehaviorSubject<string>(
    localStorage.getItem("langCode")
      ? localStorage.getItem("langCode")
      : environment.defaultLang
  );
  public langCodeSb$: Observable<string> = this.langCodeSb.asObservable();

  public langCode = new Subject<string>();
  public langCode$ = this.langCode.asObservable();

  broadCastLangCode(lang) {
    this.currentLanguage = lang;
    this.langCodeSb.next(lang);
    this.langCode.next(lang);
  }

  instant(key, objectData?): any {
    if (objectData) {
      return this.translateService.instant(key, objectData);
    } else {
      return this.translateService.instant(key);
    }
  }

  getCurrentLang(): string {
    return this.currentLanguage;
  }

  setDefaultLang(language): void {
    this.currentLanguage = language;
    this.translateService.setDefaultLang(this.currentLanguage);
  }

  getCurrentStaticPageUrl(): string {
    return this.currentStaticPageUrl;
  }

  setCurrentStaticPageUrl(url): void {
    this.currentStaticPageUrl = url;
  }

  getDefaultLang(): string {
    return this.translateService.getDefaultLang();
  }

  getLangs() {
    return this.translateService.getLangs();
  }

  addLangs(data) {
    this.translateService.addLangs(data);
  }

  /**To change the lang currently used
   * we have pass lang code to this function.
   */
  use(language): void {
    this.currentLanguage = language;
    this.translateService.use(language).subscribe((data) => {
      this.broadCastLangCode(cmsToLocalLanguageConfigurations[language]);
    });
  }

  getBrowserLang(): string | undefined {
    return this.translateService.getBrowserLang();
  }
}
