import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Services
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { RewardsService } from "src/app/pages/rewards/service/reward.service";

@Component({
  selector: "app-unclaimed-free-spin-reward-card",
  templateUrl: "./unclaimed-free-spin-reward-card.component.html",
  styleUrls: ["./unclaimed-free-spin-reward-card.component.scss"],
})
export class UnclaimedFreeSpinRewardCardComponent implements OnChanges {
  @Input() levelId;
  @Output() callOpenRewardTCPopUp: EventEmitter<any> = new EventEmitter();
  @Output() callOnLevelupClaimed: EventEmitter<any> = new EventEmitter();
  isLevelUpClaimFailed: boolean = false;
  isRequestInProcess: boolean = false;
  levelupBonusSub: any;
  callingFrom: string;
  constructor(
    private utility: UtilityService,
    private rewardService: RewardsService,
    public liveChatService: LiveChatService
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["levelId"]) {
      this.levelId = changes["levelId"].currentValue;
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  claimLevelupBonus(levelId: number) {
    this.isRequestInProcess = true;
    this.isLevelUpClaimFailed = false;
    this.levelupBonusSub = this.rewardService
      .claimLevelupBonus({ levelId: levelId })
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS") {
          this.callOnLevelupClaimed.emit();
        } else {
          this.isLevelUpClaimFailed = true;
        }
        this.isRequestInProcess = false;
      });
  }

  openRewardTCPopUp(event) {
    this.callOpenRewardTCPopUp.emit(event);
  }

  initChat() {
    this.liveChatService.initChat();
    this.utility.closeAccountComponent();
  }
}
