// Models
import { EnvironmentsDetails } from "src/app/models/environments/environments.model";

const isNew: boolean = false;

const MEDIA_URL = isNew
  ? "https://cdn.kanuuna.com"
  : "https://hpyhr-ps.imgix.net";

export const environment: EnvironmentsDetails = {
  production: false,
  // Site URL
  siteUrl: "https://stage-kanuuna.uatsecure.com",
  apiUrl: "https://stage-kanuuna.uatsecure.com",
  // Media URL
  mediaUrl: `${MEDIA_URL}`,
  mediaUrlPath: `${MEDIA_URL}/uploads/media/CAF`,
  // Game URL
  gamesUrlPath: `${MEDIA_URL}/uploads/games/CAF`,
  // Vimeo
  vimeoUrl: "https://player.vimeo.com/video/",
  // Website Code
  websiteCode: "KAN",
  websiteName: "kanuuna",
  // Languages
  langpath: "/languages/KAN/",
  langCode: ["fi", "et"],
  defaultLang: "fi-fi",
  // Payments
  paymentIqUrl: "https://test-api.paymentiq.io/paymentiq",
  paymentIqMID: "100264999",
  paymentIqUID: "casinofriday",
  // Micro Gaming
  microGamingJackpotsUrl:
    "https://api32.gameassists.co.uk/casino/progressive/public/v1",
  // Live Gaming
  liveGameUrl: "prelive-dga.pragmaticplaylive.net",
  liveGameCasioID: "ppcdk00000004555",
  // Netent
  netentBrand: "oneupent",
  netentGameLiveServerUrl: "https://oneupent-livegame-test.casinomodule.com",
  netentGameStaticUrl: "https://oneupent-static-test.casinomodule.com",
  // Default Currency
  defaultCurrencySymbol: "€",
  defaultCurrencyCode: "EUR",
  // Website
  website: "Kanuuna.com",
  // Livespins Configurations
  livespins: {
    configurations: {
      language: "en",
      tenant: "KAN",
      serverConfig: {
        api: "https://happyhour-apigateway-stage.a.hpyhr.cloud/stream",
        ui: "https://stream-livespins-stage.a.hpyhr.cloud",
      },
    },
    shareURL: "/livespins/:sessionId/play",
  },
};
