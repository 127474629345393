import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnDestroy,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { CashbackPromoService } from "src/app/pages/rewards/service/cashback-promo.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-cashback-promo-reward",
  templateUrl: "./cashback-promo-reward.component.html",
  styleUrls: ["./cashback-promo-reward.component.scss"],
})
export class CashbackPromoRewardComponent implements OnInit, OnDestroy {
  promoRaceId;
  promoParentId;
  bonusType;
  promoConfigInfo;
  optinValidityDuration;
  @Input() bonusDetails;
  @Input() callingFrom;
  @Output() callOpenRewardTCPopUp: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  userSlabInfo;
  currencyCodeSub: Subscription;
  currencyCode: string;
  currencySymbolSubscription: Subscription;
  currencySymbol: string;
  isAbsolute;
  mediaUrlPath: string = environment.mediaUrlPath;
  imgixParams: string;
  isCashBackPopup: boolean = false;
  isDepositCashbackPromo: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  successResponse: boolean = false;

  constructor(
    private cashBackPromoService: CashbackPromoService,
    private userDetailsService: UserDetailsService,
    private utility: UtilityService,
    private translationService: TranslationService
  ) {
    this.userDetailsService.currencyCodeSb$
      .pipe(takeUntil(this.destroy$))
      .subscribe((curreny) => {
        this.currencyCode = curreny;
      });
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnInit(): void {}

  getPromoConfigDetails() {
    this.isLoading = true;
    let requestData = {
      promoRaceId: this.promoRaceId,
      promoParentId: this.promoParentId,
    };
    this.cashBackPromoService
      .getPromoConfigDetails(requestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((promoConfigInfo) => {
        if (promoConfigInfo && promoConfigInfo["status"] === "SUCCESS") {
          this.promoConfigInfo = promoConfigInfo["promoConfig"];
          let slabGroupInfo = this.promoConfigInfo["slabGroupInfo"][
            Object.keys(this.promoConfigInfo["slabGroupInfo"])[0]
          ];
          this.userSlabInfo = _.filter(slabGroupInfo["slabInfo"], {
            slabCurrency: this.currencyCode,
          })[0];
          this.isAbsolute = this.userSlabInfo["slabAward"][0][
            "slabAwardValue"
          ][0]["absolute"];
        }
        this.isLoading = false;
      });
  }

  navigateToNextStep() {
    /*Here we checking whether depositRequired for user or not
    based on that we are navigating to next step*/
    this.isLoading = true;
    let requestData = {
      promoId: this.promoParentId,
      promoRaceId: this.promoRaceId,
    };
    this.cashBackPromoService
      .getOptIn_url(requestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((promoConfigInfo) => {
        this.isLoading = false;
        if (promoConfigInfo && promoConfigInfo["status"] === "SUCCESS") {
          this.isCashBackPopup = true;
          this.successResponse = true;
          this.optinValidityDuration = this.utility.getTimeFormat(
            this.promoConfigInfo["campaignEndDate"]
          );
          this.cashBackPromoService.broadCastPromoCounter();
        } else {
          this.isCashBackPopup = true;
          this.successResponse = false;
        }
      });
  }

  closeConfirmDialogue() {
    this.isCashBackPopup = false;
    this.utility.closeAccountComponent();
  }

  openRewardTCPopUp(event) {
    this.callOpenRewardTCPopUp.emit(event);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["bonusDetails"]) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
      this.promoRaceId = this.bonusDetails["promoRaceId"];
      this.promoParentId = this.bonusDetails["promoParentId"];
      if (
        this.promoRaceId &&
        this.promoParentId &&
        !this.bonusDetails["depositRequired"]
      ) {
        this.getPromoConfigDetails();
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
