import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// Components
import { LivecasinoGroupDisplayComponent } from "src/app/modules/game-groups/components/livecasino-group-display/livecasino-group-display.component";
import { LiveCasinoPlayagainComponent } from "src/app/modules/game-groups/components/live-casino-playagain/live-casino-playagain.component";
import { DefaultLiveCasinoComponent } from "src/app/modules/game-groups/components/default-live-casino/default-live-casino.component";
import { GameGoupsDisplayComponent } from "src/app/modules/game-groups/components/game-groups-display/game-groups-display.component";
import { PosterLiveCasinoComponent } from "src/app/modules/game-groups/components/poster-live-casino/poster-live-casino.component";
import { ProviderLobbyComponent } from "src/app/modules/game-groups/components/provider-lobby/provider-lobby.component";
import { DefaultCasinoComponent } from "src/app/modules/game-groups/components/default-casino/default-casino.component";
import { PosterJockpotComponent } from "src/app/modules/game-groups/components/poster-jockpot/poster-jockpot.component";
import { ViewAllGamesComponent } from "src/app/modules/game-groups/components/view-all-games/view-all-games.component";
import { GameProviderComponent } from "src/app/modules/game-groups/components/game-provider/game-provider.component";
import { JackpotGamesComponent } from "src/app/modules/game-groups/components/jackpot-games/jackpot-games.component";
import { VimeoPlayerComponent } from "src/app/modules/game-groups/components/vimeo-player/vimeo-player.component";
import { ShowCaseComponent } from "src/app/modules/game-groups/components/showcase-component/showcase.component";
import { ShowreelComponent } from "src/app/modules/game-groups/components/showreel-component/showreel.component";
import { PostersComponent } from "src/app/modules/game-groups/components/poster-components/posters.component";
import { AllGamesComponent } from "src/app/modules/game-groups/components/all-games/all-games.component";
import { GameCardComponent } from "src/app/modules/game-groups/components/game-card/game-card.component";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";
import { LivespinsModule } from "src/app/modules/livespins/livespins.module";
import { SharedModule } from "src/app/modules/shared/shared.module";

@NgModule({
  declarations: [
    LivecasinoGroupDisplayComponent,
    LiveCasinoPlayagainComponent,
    DefaultLiveCasinoComponent,
    GameGoupsDisplayComponent,
    PosterLiveCasinoComponent,
    DefaultCasinoComponent,
    PosterJockpotComponent,
    ProviderLobbyComponent,
    GameProviderComponent,
    JackpotGamesComponent,
    ViewAllGamesComponent,
    VimeoPlayerComponent,
    ShowCaseComponent,
    ShowreelComponent,
    GameCardComponent,
    AllGamesComponent,
    PostersComponent,
  ],
  imports: [CommonModule, SharedModule, MbscModule, LivespinsModule],
  exports: [
    LivecasinoGroupDisplayComponent,
    LiveCasinoPlayagainComponent,
    DefaultLiveCasinoComponent,
    GameGoupsDisplayComponent,
    PosterLiveCasinoComponent,
    DefaultCasinoComponent,
    PosterJockpotComponent,
    ProviderLobbyComponent,
    GameProviderComponent,
    JackpotGamesComponent,
    ViewAllGamesComponent,
    VimeoPlayerComponent,
    ShowCaseComponent,
    ShowreelComponent,
    GameCardComponent,
    AllGamesComponent,
    PostersComponent,
  ],
})
export class GameGroupsModule {}
