import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { DatePipe } from "@angular/common";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-self-exclusion",
  templateUrl: "./self-exclusion.component.html",
  styleUrls: ["./self-exclusion.component.scss"],
  providers: [DatePipe],
})
export class SelfExclusionComponent extends FormValidationComponent
  implements OnInit {
  suspendAccountForm = this.formBuilder.group({
    rgCoolOffPeriodInDays: ["", [this.customValidators.required]],
    rgClose: ["false"],
    reason: ["Self Exclusion", [this.customValidators.required]],
  });
  serverError: any;
  startDate: Date | string;
  endDate: Date | string;
  isPassword: boolean = false;
  selfExclusionPopup: boolean = false;

  constructor(
    public utils: UtilityService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private accountService: AccountService,
    private customValidators: CustomValidatorService
  ) {
    super(utils);
  }

  ngOnInit() {}

  getExclusionPeriod() {
    return this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value;
  }

  setExclusionPeriod(period) {
    if (
      period !== this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value
    ) {
      this.calculateDateRange(period);
      this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].setValue(
        period
      );
    } else {
      this.clearExclusionPeriod();
    }
  }

  openConfirmationDailogue() {
    if (this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].value) {
      this.selfExclusionPopup = true;
    }
  }

  closeConfirmationDailogue() {
    this.clearExclusionPeriod();
    this.selfExclusionPopup = false;
  }

  clearExclusionPeriod() {
    this.suspendAccountForm.controls["rgCoolOffPeriodInDays"].setValue("");
    this.startDate = undefined;
    this.endDate = undefined;
  }

  calculateDateRange(period) {
    let days = period;
    this.startDate = this.datePipe.transform(new Date(), "dd/MM/yyyy HH:MM");
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate() + days);
    this.endDate = this.datePipe.transform(this.endDate, "dd/MM/yyyy HH:MM");
  }

  confirmSuspendAccount() {
    this.selfExclusionPopup = false;
    let data = this.utils.formControlToParams(this.suspendAccountForm, {});
    this.accountService.suspendAccount(data).subscribe((data) => {
      if (data && data["success"] && data["success"]["status"] === "SUCCESS") {
        this.utils.closeAccountComponent();
        localStorage.removeItem("kan_user");
        sessionStorage.removeItem("session");
        sessionStorage.removeItem("_player");
        this.commonService.broadCastIsLoggedOut(true);
        this.commonService.doLogout();
      } else {
        this.clearExclusionPeriod();
        this.serverError = data["errors"]["password"];
      }
    });
    setTimeout(() => {
      this.serverError = undefined;
    }, 5000);
  }

  closeComponent(navigateTo?) {
    this.utils.closeAccountComponent(navigateTo);
  }

  isButtonDisabled(formName) {
    return this.utils.isButtonDisabled(formName);
  }
}
