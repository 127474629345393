import {
  SimpleChange,
  Component,
  OnChanges,
  Input,
} from "@angular/core";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-default-casino",
  templateUrl: "./default-casino.component.html",
  styleUrls: ["./default-casino.component.scss"],
})
export class DefaultCasinoComponent implements OnChanges {
  @Input() gameGroupData;
  @Input() callingFromlobby;
  games: any;
  isLoading: boolean;
  filteredGames: any[];
  swiperLazyConfig: {
    totalNumberOfGamesExist: number;
    indexNumberOfGameTilesToshow: number;
    numberOfExtraGamesToLoadForSlide: number;
    forwardslideIndex: number;
    backwardslideIndex: number;
  } = {
    totalNumberOfGamesExist: 0,
    indexNumberOfGameTilesToshow: 14,
    numberOfExtraGamesToLoadForSlide: 7,
    forwardslideIndex: 0,
    backwardslideIndex: 0,
  };

  constructor(private utility: UtilityService) {}

  swiperCasino: SwiperConfigInterface = {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      2000: {
        slidesPerView: 7,
        slidesPerGroup: 7,
        spaceBetween: 16,
      },
      1599: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 16,
      },
      1365: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      767: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 8,
      },
      480: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 8,
      },
    },
  };

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["gameGroupData"]) {
      this.gameGroupData = changes["gameGroupData"].currentValue;
      if (this.gameGroupData["group_type"] === "manual") {
        this.games = this.utility.sortGameByCountryOrder(
          this.gameGroupData["games"]
        );
      } else {
        this.games = this.gameGroupData["games"];
      }
      if (this.games && this.games.length > 0) {
        this.swiperLazyConfig["totalNumberOfGamesExist"] = this.games.length;
      }
    }
    if (changes["callingFromlobby"]) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  /**Function for Lazy loading of games goes here
   * on every next click of silde below function will be trigerred
   * & calculated how many game have to load amount that total games
   * present
   * This works on bases of swiperLazyConfig defined above...
   */
  onSwiperNextEvent() {
    if (this.swiperLazyConfig["backwardslideIndex"]) {
      this.swiperLazyConfig["backwardslideIndex"] =
        this.swiperLazyConfig["backwardslideIndex"] - 1;
      return;
    }
    if (
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] <
      this.swiperLazyConfig["totalNumberOfGamesExist"]
    ) {
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] =
        this.swiperLazyConfig["indexNumberOfGameTilesToshow"] +
        this.swiperLazyConfig["numberOfExtraGamesToLoadForSlide"];
      this.swiperLazyConfig["forwardslideIndex"] =
        this.swiperLazyConfig["forwardslideIndex"] + 1;
    }
  }

  onSwiperPrevEvent() {
    this.swiperLazyConfig["backwardslideIndex"] =
      this.swiperLazyConfig["backwardslideIndex"] + 1;
  }

  navigateAllGamesPage() {
    this.utility.navigateAllGamesPage(this.gameGroupData);
  }
}
