import { Component, Input, OnDestroy, SimpleChange } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-provider-lobby",
  templateUrl: "./provider-lobby.component.html",
  styleUrls: ["./provider-lobby.component.scss"],
})
export class ProviderLobbyComponent implements OnDestroy {
  @Input() games;
  @Input() lobbyProviderData;
  providerLevelGamesList;
  gamesUrlPath: string = environment.gamesUrlPath;
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  langCode: string;
  isLoading: boolean = true;
  imgixParams: string;

  constructor(
    private router: Router,
    private utility: UtilityService,
    private commonService: CommonService,
    private gamePlayService: GamePlayService,
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.getProviderLevelGames();
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
    this.translationService.langCodeSb$.subscribe((langcode) => {
      this.langCode = langcode;
    });
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["games"] && changes["games"].currentValue) {
      this.games = changes["games"].currentValue;
      this.isLoading = false;
    }
    if (
      changes["lobbyProviderData"] &&
      changes["lobbyProviderData"].currentValue
    ) {
      this.lobbyProviderData = changes["lobbyProviderData"].currentValue;
    }
  }

  getProviderLevelGames() {
    Promise.resolve(this.gameGroupsService.getProviderLevelGames()).then(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.providerLevelGamesList = data;
        }
      }
    );
  }

  gameLanuch(gameName) {
    gameName = this.utility.convertGameNameToUrl(gameName);
    this.gamePlayService.clearGameWindowData();
    if (this.isLoggedIn) {
      this.router.navigate([
        this.langCode +
          "/" +
          this.translationService.instant("url.game") +
          "/" +
          gameName,
      ]);
    } else {
      this.commonService.setNavigateAfterLogin({
        type: "url",
        data: { navigateTo: "gameWindow", path: gameName },
      });
      this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
    }
  }

  ngOnDestroy() {
    this.logOutCompleteSubscription.unsubscribe();
    this.loginCompleteSubscription.unsubscribe();
  }
}
