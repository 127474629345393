import { TitleCasePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";

// Services
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { RewardsService } from "src/app/pages/rewards/service/reward.service";

@Injectable({
  providedIn: "root",
})
export class LiveChatService {
  profileDetails;
  userDataSub;
  isLoggedIn;
  loginCompleteSubscription: Subscription;
  constructor(
    public userDetailsService: UserDetailsService,
    public commonService: CommonService,
    public utility: UtilityService,
    private rewardsService: RewardsService,
    public titleCasePipe: TitleCasePipe
  ) {
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.loadLiveChat();
      }
    );
  }

  loadLiveChat() {
    if (!document.getElementById("lc")) {
      this.appendNoScriptTag();
      this.appendLcScriptTag();
    }
  }

  initChat() {
    this.loadLiveChat();
    if (window["LiveChatWidget"] && document.getElementById("lc")) {
      if (this.utility.isUserLoggedIn()) {
        this.getUserProfileData();
      }
      window["LiveChatWidget"].call("maximize");
    }
  }

  appendLcScriptTag() {
    var script = document.createElement("script");
    script.id = "lc";
    script.textContent =
      'window.__lc = window.__lc || {}; window.__lc.license = 12701157;;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You cant use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))';
    document.body.appendChild(script);
  }

  appendNoScriptTag() {
    let noscript = document.createElement("noscript");
    let appendTag = document.createElement("a");
    appendTag.href = "https://www.livechatinc.com/chat-with/12701157/";
    appendTag.rel = "nofollow";
    appendTag.textContent = "Chat with us powered by ";
    let appendHrefTag = document.createElement("a");
    appendHrefTag.href = "https://www.livechatinc.com/?welcome";
    appendHrefTag.rel = "noopener nofollow";
    appendHrefTag.target = "_blank";
    appendHrefTag.textContent = "LiveChat";
    noscript.append(appendTag);
    noscript.append(appendHrefTag);
    document.body.appendChild(noscript);
  }

  getUserProfileData() {
    if (this.userDetailsService.getUserProfileDetails()) {
      this.profileDetails = this.userDetailsService.getUserProfileDetails();
      this.setUserDetails(this.profileDetails);
    } else {
      this.userDataSub = this.commonService.getUserData().subscribe((data) => {
        this.profileDetails = data;
        this.setUserDetails(this.profileDetails);
      });
    }
  }

  setUserDetails(profileData) {
    /**please lines of code is used to set customer name & customer email Id
     * which will be auto populated & visisble directly when customer
     * opens chat
     */
    if (profileData["email"] && profileData["firstName"]) {
      window["LiveChatWidget"].call("set_customer_email", profileData["email"]);
      window["LiveChatWidget"].call(
        "set_customer_name",
        this.getTitleCase(profileData["firstName"]) +
          " " +
          this.getTitleCase(profileData["lastName"])
      );
    }
    if (profileData) {
      this.setSessionData(profileData);
    }
  }

  /**
   * This set of code is used to create a user session on live chat,
   * which help used in identifying  user session along
   * with back on live chat back office
   * */

  setSessionData(profileData) {
    window["LiveChatWidget"].call("set_session_variables", {
      name:
        this.getTitleCase(profileData["firstName"]) +
        " " +
        this.getTitleCase(profileData["lastName"]),
      emailID: profileData["email"],
      ecrID: profileData["playerID"],
      currentLevel: this.rewardsService.getUserCurrentLevelId(),
      loginStatus: "logged In",
    });
  }

  updateSessionData() {
    if (window["LiveChatWidget"] && document.getElementById("lc")) {
      window["LiveChatWidget"].call("update_session_variables", {
        loginStatus: "logged out",
      });
    }
  }

  getTitleCase(value) {
    return this.titleCasePipe.transform(value);
  }
}
