import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  AfterViewInit,
  HostListener,
  SimpleChange,
  Component,
  OnDestroy,
  Input,
} from "@angular/core";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { RewardsService } from "src/app/pages/rewards/service/reward.service";

@Component({
  selector: "app-rewards-indicator",
  templateUrl: "./rewards-indicator.component.html",
  styleUrls: ["./rewards-indicator.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RewardsIndicatorComponent implements OnDestroy, AfterViewInit {
  @Input() callingFrom;
  rewardsIndicatorData = {
    levelDetails: {
      oldLevelId: 0,
      currentLevelID: 0,
      gameId: undefined,
    },
    pointsDetails: {
      currentSpinCount: undefined,
      spinsNeededForNextLevel: undefined,
    },
  };
  progressBarValue: number = 0;
  notificationCount: number = 0;
  currentLevelSub: Subscription;
  currentPointtDetailsSub: Subscription;
  username: any;
  windowType;
  userLevelupSub: Subscription;
  progressValueInpct;

  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }

  constructor(
    private utility: UtilityService,
    private rewardsService: RewardsService
  ) {
    this.getUserLevelupDetails();
    this.currentLevelSub = this.rewardsService.userLevelUpDetails$.subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.rewardsIndicatorData["levelDetails"] = {
            oldLevelId: data["oldLevelId"],
            currentLevelID: data["newLevelId"],
            gameId: data["gameId"],
          };
          this.updateNotificationCount();
        } else {
          /**This case Never occour, but added as fallback incase there
           * is any issue with socket to make error free code */
          this.clearNotification();
          this.getUserLevelupDetails();
        }
      }
    );

    this.currentPointtDetailsSub = this.rewardsService.userLevelUpPointDetails$.subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.rewardsIndicatorData["pointsDetails"] = {
            currentSpinCount: data["currentSpinCount"],
            spinsNeededForNextLevel: data["spinsNeededForNextLevel"],
          };
          this.rewardsIndicatorData["levelDetails"]["currentLevelID"] =
            data["levelId"];
          this.calculateNextLevelProgressBarValue(
            this.rewardsIndicatorData["pointsDetails"],
            "push"
          );
        }
      }
    );
    this.rewardsService.clearLevelUpNotification$.subscribe((flag) => {
      if (flag) {
        this.rewardsService.setLevelBeforeLevelUpgraded(
          this.rewardsIndicatorData["levelDetails"]["currentLevelID"]
        );
      }
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  getUserLevelupDetails() {
    this.userLevelupSub = this.rewardsService
      .getUserLevelupDetails()
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS") {
          this.rewardsIndicatorData["levelDetails"]["currentLevelID"] =
            data["levelId"];
          Object.assign(this.rewardsIndicatorData["pointsDetails"], {
            currentSpinCount: data["currentSpinCount"],
            spinsNeededForNextLevel: data["spinsNeededForNextLevel"],
          });
          if (this.callingFrom !== "account-menu") {
            this.rewardsService.setLevelBeforeLevelUpgraded(
              this.rewardsIndicatorData["levelDetails"]["currentLevelID"]
            );
          }
          this.calculateNextLevelProgressBarValue(
            this.rewardsIndicatorData["pointsDetails"]
          );
        }
      });
  }

  /**
   * Clears local variable currentSpinCount & spinsNeededForNextLevel
   * update notification count
   */
  updateNotificationCount() {
    this.rewardsIndicatorData["pointsDetails"] = {
      currentSpinCount: 0,
      spinsNeededForNextLevel: 0,
    };
    this.notificationCount = this.rewardsService.getNotificationCount(
      this.rewardsIndicatorData["levelDetails"]["currentLevelID"]
    );
    this.rewardsService.broadCastUpdateLevelupNotifications(
      this.notificationCount
    );
  }

  calculateNextLevelProgressBarValue(data, callingFrom?: string) {
    if (
      data &&
      data.hasOwnProperty("currentSpinCount") &&
      data.hasOwnProperty("spinsNeededForNextLevel")
    ) {
      let totalSpins =
        data["currentSpinCount"] + data["spinsNeededForNextLevel"];
      if (totalSpins) {
        this.progressValueInpct = (
          (data["currentSpinCount"] / totalSpins) *
          100
        ).toFixed(2);
        if (this.progressValueInpct > 100) {
          this.progressValueInpct = 100;
        }
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getWindowType();
    }, 100);
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "desktop";
    } else {
      this.windowType = "mobile";
    }
  }

  //@required later..
  openAccountComponent(viewname) {
    this.clearNotification();
    this.utility.openAccountComponent(viewname);
  }

  //@required later..
  clearNotification() {
    this.rewardsService.setLevelBeforeLevelUpgraded(
      this.rewardsIndicatorData["levelDetails"]["currentLevelID"]
    );
    this.notificationCount = 0;
  }

  ngOnDestroy() {
    this.currentLevelSub.unsubscribe();
    this.currentPointtDetailsSub.unsubscribe();
    if (this.userLevelupSub) {
      this.userLevelupSub.unsubscribe();
    }
  }
}
