import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  HostListener,
  ElementRef,
  Component,
  Renderer2,
  OnDestroy,
  ViewChild,
  OnInit,
} from "@angular/core";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { CashbackPromoService } from "src/app/pages/rewards/service/cashback-promo.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-promo-race-counter",
  templateUrl: "./promo-race-counter.component.html",
  styleUrls: ["./promo-race-counter.component.scss"],
})
export class PromoRaceCounterComponent implements OnInit, OnDestroy {
  promoRaceCounter;
  promoOptInSub;
  promoRaceId;
  playerPromoProgressSub: Subscription;
  expiryDate;
  isOptnCounter: boolean = false;
  windowType: string;
  userOptinDuration;
  promoRaceInfoList;
  destroy$: Subject<boolean> = new Subject<boolean>();
  cashBackDepositOfferPopup: boolean = false;
  awardCurrency;
  currencySymbol: string;
  userEligibleOffer;
  @ViewChild("promoRaceCounter", { static: false }) el: ElementRef;

  @HostListener("touchstart", ["$event"])
  onStart(e) {
    if (e) {
      this.initiateDragDrop();
    }
  }

  @HostListener("window:orientationchange") onRotate() {
    if (this.el && this.el.nativeElement) {
      this.setPromoRaceCounterOrientatation();
    }
    this.getWindowType();
  }

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  constructor(
    private cashBackPromoService: CashbackPromoService,
    private render: Renderer2,
    private userDetailsService: UserDetailsService,
    private translationService: TranslateService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private commonService: CommonService
  ) {
    this.cashBackPromoService.promoCashbackOptnSuccess$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getUserCampaignDetails();
      });
    this.cashBackPromoService.StopCbPromoCounter$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((promoRaceId) => {
      this.isOptnCounter = false;
      this.cashbackOfferAmount(promoRaceId);
    });
    this.userDetailsService.currencySymbolSb$
      .pipe(takeUntil(this.destroy$))
      .subscribe((symbol) => {
        this.currencySymbol = symbol;
      });
    this.commonService.logOutComplete$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isOptnCounter = false;
      });
  }

  cashbackOfferAmount(promoRaceId) {
    let requestData = {
      campaignActivityId: promoRaceId,
    };
    this.cashBackPromoService
      .getPlayerPromoProgress(requestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (
          data &&
          data["promoProgressList"] &&
          data["promoProgressList"].length > 0
        ) {
          let playerPromoRankingDetails = data["promoProgressList"][0];
          let awardValue, awardType;
          if (
            playerPromoRankingDetails &&
            playerPromoRankingDetails["eligibleAward"]
          ) {
            this.userEligibleOffer = playerPromoRankingDetails["eligibleAward"];
            awardValue = parseFloat(
              this.userEligibleOffer.replace(/[^0-9.]/g, "")
            ).toFixed(2);
            if (this.userEligibleOffer.indexOf("Bonus") > -1) {
              awardType = this.translationService.instant("rewards.bonus");
            } else if (this.userEligibleOffer.indexOf("Cash") > -1) {
              awardType = this.translationService.instant("rewards.cash");
            }
            if (!isNaN(awardValue) && awardType) {
              this.userEligibleOffer =
                this.currencyFormatPipe.transform(awardValue, true) +
                " " +
                awardType;
            }
            this.cashBackPromoService.broadCastCashBackOfferAmount(
              this.userEligibleOffer
            );
          }
        }
      });
  }

  initiateDragDrop() {
    if (this.windowType === "mobile") {
      let promoRaceCounter = document.getElementById("promoRaceCounter");
      let timerWidth = promoRaceCounter.clientWidth;
      let timerHeight = promoRaceCounter.clientHeight;
      if (promoRaceCounter) {
        promoRaceCounter.addEventListener(
          "touchmove",
          (e) => {
            e.preventDefault();
            let ww = window.innerWidth;
            let wh = window.innerHeight;
            let touchLocation = e.targetTouches[0];
            if (
              touchLocation.clientX > 0 &&
              touchLocation.clientX < ww - timerWidth
            ) {
              promoRaceCounter.style.left = touchLocation.clientX + "px";
            }
            if (
              touchLocation.clientY > 0 &&
              touchLocation.clientY < wh - timerHeight
            ) {
              promoRaceCounter.style.top = touchLocation.clientY + "px";
            }
          },
          false
        );
      }
    }
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  setPromoRaceCounterOrientatation() {
    if (window.matchMedia("(orientation: landscape)").matches) {
      this.render.setStyle(this.el.nativeElement, "left", 16 + "px");
      this.render.setStyle(this.el.nativeElement, "bottom", 64 + "px");
      this.render.setStyle(this.el.nativeElement, "top", "unset");
    } else {
      this.render.setStyle(this.el.nativeElement, "top", 16 + "px");
      this.render.setStyle(this.el.nativeElement, "left", 64 + "px");
    }
  }

  ngOnInit(): void {
    this.getUserCampaignDetails();
  }

  getUserCampaignDetails() {
    this.cashBackPromoService
      .getUserCampaigns()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data && data["campaignDetails"] && data["status"] === "SUCCESS") {
          this.promoRaceInfoList = data["campaignDetails"];
          this.promoRaceInfoList = this.promoRaceInfoList.filter((data) => {
            var userLastAuthTime;
            let currentTime, promoStartTime, promoOfferExpiryDate;
            currentTime = new Date();
            promoStartTime = new Date(data["campaignStartDate"]);
            promoOfferExpiryDate = new Date(data["campaignEndDate"]);
            if (this.cashBackPromoService.getUserLastAuthTime()) {
              userLastAuthTime = new Date(
                this.cashBackPromoService.getUserLastAuthTime()
              );
            }
            if (
              data &&
              data["campaignType"] === "CASHBACK_PROMO" &&
              (data["playerOptinTime"] || !data["optInRequired"]) &&
              currentTime >= promoStartTime &&
              currentTime < promoOfferExpiryDate
            ) {
              if (
                !data["optInRequired"] &&
                userLastAuthTime &&
                userLastAuthTime < promoStartTime
              ) {
                this.cashBackPromoService.broadCastcashBackstartedPromoPopup(
                  data
                );
                this.cashBackPromoService.setUserLastAuthTime(undefined);
              }
              return data;
            }
          });
          console.log("=====>promo race counter data", this.promoRaceInfoList);
          if (this.promoRaceInfoList && this.promoRaceInfoList.length > 0) {
            this.promoRaceInfoList = this.promoRaceInfoList[0];
            this.setPromoRaceCounter(this.promoRaceInfoList);
          } else {
            this.isOptnCounter = false;
          }
        } else {
          this.isOptnCounter = false;
        }
      });
  }

  setPromoRaceCounter(data) {
    if (data && data["campaignEndDate"]) {
      let date_future = new Date(data["campaignEndDate"]);
      let date_now = new Date();
      if (date_now < date_future) {
        this.cashBackPromoService.setUserCashBackPromoInfo(data);
        this.userOptinDuration = data["campaignEndDate"];
        this.isOptnCounter = true;
      } else {
        this.isOptnCounter = false;
      }
    }
  }

  openRewardTCPopUp() {
    let userPromoInfo = this.cashBackPromoService.getUserCashBackPromoInfo();
    if (userPromoInfo) {
      this.cashBackPromoService.broadCastcashBackstartedPromoPopup(
        userPromoInfo
      );
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
