import { Component, HostListener, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

// Libraries
import * as _ from "underscore";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-provider-games",
  templateUrl: "./provider-games-display.component.html",
  styleUrls: ["./provider-games-display.component.scss"],
})
export class ProvideGamesDisplay implements OnDestroy {
  isLoading: boolean = true;
  langCodeSubscription: Subscription;
  langCode: any;
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  routerSub: Subscription;
  activeLobby: string;
  activeGroupName: string;
  providerName: string;
  isGamesAvaialable: boolean;
  allAvailableProviders: unknown[];
  providersList: unknown[];
  filteredGames;
  gamesBeforeSorted;
  gameData: any;
  isSortedBy: string;
  deviceType;
  pageHeaderBg: boolean;

  @HostListener("window:resize") onResize() {
    this.utility.getFixedHeaderHeight();
    this.checkDeviceType();
    this.setOverflowHiddenClass();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.utility.getFixedHeaderHeight();
  }
  @HostListener("window:scroll") onScroll() {
    this.pageHeaderBg = this.utility.scrollAddClass();
  }

  constructor(
    private utility: UtilityService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private commonService: CommonService,
    private gameGroupsService: GameGroupsService,
    private translationService: TranslationService
  ) {
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
    this.routerSub = this.activateRoute.params.subscribe((params) => {
      if (params && params["providerName"]) {
        this.providerName = params["providerName"];
        this.getGroupGames(false);
      }
    });

    this.updateSEOContent();
    this.setSEOContent();
  }

  getGroupGames(isForce) {
    this.isLoading = true;
    Promise.resolve(this.gameGroupsService.getGameGroupGamesp(isForce)).then(
      (games) => {
        if (games && games.length > 0) {
          this.gameData = games;
          this.setGamesInProvider(this.providerName);
        } else {
          this.isLoading = false;
          this.filteredGames = [];
        }
      }
    );
  }

  setGamesInProvider(providerName) {
    let selectedProviderData;
    if (providerName && this.gameData) {
      this.providersList = this.utility.getProviderList(this.gameData);
      if (this.providersList) {
        selectedProviderData = _.filter(this.providersList, (provider) => {
          if (
            this.utility.convertSpecialCharactersWithSpace(
              provider["vendorDisplayName"]
            ) === this.utility.convertSpecialCharactersWithSpace(providerName)
          ) {
            this.providerName = provider["vendorDisplayName"];
            return true;
          }
          //return  this.utility.convertSpecialCharactersWithSpace(provider['vendorDisplayName']) === this.utility.convertSpecialCharactersWithSpace(providerName);
        });
        if (!_.isEmpty(selectedProviderData)) {
          this.filteredGames = this.utility.getGamesByProviderName(
            this.gameData,
            selectedProviderData[0].vendorCode
          );
          this.gamesBeforeSorted = this.filteredGames;
        }
      }
    }
    if (
      !this.filteredGames ||
      (this.filteredGames && this.filteredGames.length === 0)
    ) {
      this.filteredGames = [];
    }
    this.isLoading = false;
    //   console.log("filter games:provider name:",this.providerName,this.filteredGames);
  }

  sortByGameName() {
    if (this.isSortedBy !== "a-z") {
      this.isSortedBy = "a-z";
      this.filteredGames = this.utility.sortByGameName(
        this.filteredGames,
        "name"
      );
    } else {
      this.isSortedBy = undefined;
      this.filteredGames = this.gamesBeforeSorted;
    }
  }

  goBack() {
    if (!this.commonService.previousURL) {
      this.router.navigate([this.utility.getLangCode()]);
    } else {
      // this.router.navigate([this.commonService.previousURL], {fragment: this.utility.convertGameNameToUrl(this.providerName)});
      this.router.navigate([this.commonService.previousURL]);
    }
    this.utility.removeClassFromAppBody("overflow-hidden");
  }
  /**TODO not using right now will enable when required */
  updateSEOContent() {
    let segments = this.utility.getDecodedCurrentPath().split("/");
    if (segments && segments.length === 4) {
      let providerName = segments[3].replace(" ", "-");
      this.utility.setSEO({
        metaTitle: this.translationService.instant(
          "SEO." + providerName + "_metaTitle"
        ),
        metaDescription: this.translationService.instant(
          "SEO." + providerName + "_metaDescription"
        ),
        metaTags: this.translationService.instant(
          "SEO." + providerName + "_metaTag"
        ),
        title: this.utility.capitalizeFirstLetter(providerName),
      });
    }
  }
  setSEOContent() {
    this.commonService.setCanonicalURL("studiopage");
  }

  setOverflowHiddenClass() {
    setTimeout(() => {
      if (
        document.querySelector(".page-body") &&
        this.deviceType === "mobile"
      ) {
        this.utility.addClassToAppBody("overflow-hidden");
      } else {
        this.utility.removeClassFromAppBody("overflow-hidden");
      }
    });
  }

  ngAfterViewInit() {
    this.checkDeviceType();
    this.setOverflowHiddenClass();
  }

  checkDeviceType() {
    let ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.deviceType = "desktop";
    } else {
      this.deviceType = "mobile";
    }
  }

  ngOnDestroy() {
    this.utility.removeClassFromAppBody("overflow-hidden");
    this.routerSub.unsubscribe();
    this.langCodeSubscription.unsubscribe();
    this.loginCompleteSubscription.unsubscribe();
    this.logOutCompleteSubscription.unsubscribe();
  }
}
