export enum LanguageCodes {
  // FINNISH
  fi = "fi",
  // ENGLISH
  en = "en",
  // Estonian
  et = "et",
  // en-row
  "en-row" = "en-row",
  // fi-fi
  "fi-fi" = "fi-fi",
  // et-ee
  "et-ee" = "et-ee"
}
