import { Component, OnDestroy, HostListener } from "@angular/core";
import { Subscription } from "rxjs";
import {
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  NavigationEnd,
  UrlSegment,
  UrlTree,
  Router,
} from "@angular/router";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { RewardsService } from "src/app/pages/rewards/service/reward.service";

@Component({
  selector: "app-left-navigation",
  templateUrl: "./left-navigation.component.html",
  styleUrls: ["./left-navigation.component.scss"],
})
export class LeftNavigationComponent implements OnDestroy {
  todayDate: Date = new Date();
  formattedDate;
  timeFormat = "HH:mm";
  loginCompleteSubscription: Subscription;
  isLoggedIn: boolean = false;
  userProfileSubscription: Subscription;
  username: string;
  currencySymbolSubscription: Subscription;
  currencySymbol: string;
  userBalanceSubscription: Subscription;
  userTotalBalance: number = 0;
  langCode: string;
  langCodeSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  lobbyLeftMenuListConfig: any;
  activePage: string;
  activeLeftPageSub: Subscription;
  activeLeftMenuOption: string;
  isLoading: boolean = false;
  isbalanceLoading: boolean = true;
  activeLeftMenuSub: Subscription;
  levelUpNotificationCount;
  updatelevelCountSub: Subscription;
  clearLevelupNotSub: Subscription;

  @HostListener("window:resize") onResize() {
    this.setGameActivePage();
  }

  constructor(
    private commonService: CommonService,
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private router: Router,
    private utility: UtilityService,
    private rewardsService: RewardsService,
    public gamePlayService: GamePlayService,
    public paynplayCashierService: PayNPlayCashierService,
    public liveChatService: LiveChatService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        /**Condition to hide banner where ever it not required
         * comes here...
         */
        const tree: UrlTree = router.parseUrl(event.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g ? g.segments : [];
        if (s && s[1] && s[1].path) {
          this.activePage = this.utility.getActiveLobby(s[1].path);
        } else {
          this.activePage = "";
        }
      }
    });
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
        this.userTotalBalance = 0;
        this.currencySymbol = undefined;
        this.isbalanceLoading = true;
      }
    );

    this.userProfileSubscription = this.userDetailsService.userProfileUpdated$.subscribe(
      (data) => {
        const userProfileData = this.userDetailsService.getUserProfileDetails();
        if (userProfileData) {
          this.username = userProfileData["firstName"];
        }
      }
    );

    /**Here we are using BehaviorSubject on initialization also v'll get default value..
     * we differentiate subject & BehaviorSubject with "Sb" at end of Event Name.
     */
    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );

    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
      this.isbalanceLoading = false;
    }
    this.userBalanceSubscription = this.userDetailsService.userBalanceUpdated$.subscribe(
      (balanceUpdateMessage) => {
        if (balanceUpdateMessage == "UserBalanceUpdated") {
          this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
          this.isbalanceLoading = false;
        }
      }
    );
    this.activeLeftMenuSub = this.commonService.activeLeftMenu$.subscribe(
      (name) => {
        this.activeLeftMenuOption = name;
      }
    );
    this.updatelevelCountSub = this.rewardsService.updateLevelUpNotification$.subscribe(
      (count) => {
        this.levelUpNotificationCount = count;
      }
    );
    this.clearLevelupNotSub = this.rewardsService.clearLevelUpNotification$.subscribe(
      (flag) => {
        if (flag) {
          this.levelUpNotificationCount = 0;
        }
      }
    );
  }

  setGameActivePage() {
    if (
      this.utility.getDecodedCurrentPath().split("/")[2] ===
      this.translationService.instant("url.game")
    ) {
      this.activePage = this.utility.getActiveLobby(
        this.utility.getDecodedCurrentPath().split("/")[2]
      );
    }
  }

  ngOnInit() {
    this.getLobbyMenuList();
    this.setGameActivePage();
  }

  doLogout() {
    this.commonService.doLogout();
  }

  openAccountMenuList() {
    this.commonService.broadCastActiveAcountMenu("menuOptions");
  }

  openAccountComponent(viewname) {
    this.checkSearchStatus();
    this.utility.openAccountComponent(viewname);
  }

  /**
   * All the left menu navigation all handled in below
   * callback function
   */

  navigateTo(path?) {
    if (this.commonService.getActiveAccountMenu()) {
      this.commonService.broadCastActiveAcountMenu("");
    }
    this.checkSearchStatus();
    this.utility.updateActiveLeftMenu();
    if (path === "casino" && this.activePage === "game") {
      this.gamePlayService.broadCastGameWindowMinimized(true);
      this.router.navigate([this.langCode + "/casino"]);
    } else if (path === "rewards") {
      if (path === "rewards") {
        this.clearRewardNotifications();
      }
      this.commonService.broadCastActiveAcountMenu(path);
    } else if (path === "DEPOSIT") {
      this.paynplayCashierService.broadCastInitiateDeposit({
        callingFrom: "leftMenu",
      });
    } else if (path === "LOGIN") {
      this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
    } else if (path) {
      this.router.navigate([
        this.langCode + "/" + this.translationService.instant("url." + path),
      ]);
    } else {
      this.commonService.broadCastUpdateActiveLobby({ lobby: "casino" });
      this.router.navigate([this.langCode + "/casino"]);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  /**On every navigation on left menu we have make sure is search open
   * If yes we have close it by broadcasting event or else
   * just ignore..
   */
  checkSearchStatus() {
    if (this.commonService.getCasinoPageSearchEnabledStatus()) {
      this.commonService.broadSearchEnabledStatus(false);
    }
  }

  /**
   * Below function helps us to get left menu navigation options
   * If we want to add any new navigation link into left menu simply
   * we have to first configure under /configurations/menulist.config/caf.json
   */
  getLobbyMenuList() {
    this.lobbyLeftMenuListConfig = this.utility.getBrandBasedConfig(
      "leftMenuConfig"
    );
  }

  clearRewardNotifications() {
    if (this.levelUpNotificationCount) {
      this.levelUpNotificationCount = 0;
    }
    this.rewardsService.broadCastClearLevelupNotifications(true);
  }

  ngOnDestroy() {
    this.activeLeftMenuSub.unsubscribe();
    this.clearLevelupNotSub.unsubscribe();
    this.loginCompleteSubscription.add(this.activeLeftPageSub);
    this.loginCompleteSubscription.add(this.currencySymbolSubscription);
    this.loginCompleteSubscription.add(this.userBalanceSubscription);
    this.loginCompleteSubscription.add(this.userProfileSubscription);
    this.loginCompleteSubscription.add(this.langCodeSubscription);
    this.loginCompleteSubscription.add(this.logOutCompleteSubscription);
    this.logOutCompleteSubscription.add(this.updatelevelCountSub);
    this.loginCompleteSubscription.unsubscribe();
  }
}
