import { Subscription } from "rxjs";
import {
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  ActivatedRoute,
  NavigationEnd,
  UrlSegment,
  UrlTree,
  Router,
} from "@angular/router";
import {
  AfterViewInit,
  HostListener,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-all-games",
  templateUrl: "./all-games.component.html",
  styleUrls: ["./all-games.component.scss"],
})
export class AllGamesComponent implements OnInit, OnDestroy, AfterViewInit {
  langCode: string;
  routerSub: Subscription;
  lobby: string;
  lobbyGameGroupDetails;
  games: any;
  filteredGames: any;
  providerList: any;
  isFilteredBy: string = "providers";
  providerName: string;
  langCodeSubscription: Subscription;
  isLoading: boolean;
  isProviderGamesEnabled: boolean = false;

  mediaUrlPath: string = environment.mediaUrlPath;

  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;

  totalFilterGames;
  windowType: string;
  isprovideratozEnabled: boolean = false;
  beforeSortProviderGames;
  filteredGamesDefaultSize = 28;
  loadMoreFilteredGames = this.filteredGamesDefaultSize;
  footerHeight;
  imgixParams: string;
  pageHeaderBg: boolean;

  @HostListener("window:resize") onResize() {
    this.getWindowType();
    this.utility.getFixedHeaderHeight();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.utility.getFixedHeaderHeight();
  }
  @HostListener("window:scroll") onScroll() {
    this.pageHeaderBg = this.utility.scrollAddClass();
  }
  constructor(
    private router: Router,
    private translationService: TranslationService,
    private commonService: CommonService,
    private gameGroupsService: GameGroupsService,
    public utility: UtilityService,
    private route: ActivatedRoute
  ) {
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );

    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const tree: UrlTree = router.parseUrl(event.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g.segments;
        if (s[1] && s[1].path) {
          this.lobby = s[1].path;
        }
        this.commonService.broadCastActiveLeftMenuLobby(this.lobby);
      }
    });
    this.utility.getFixedHeaderHeight();
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnInit() {
    window["prerenderReady"] = false;
    this.getWindowType();
    this.getLobbyGroupsAndGames();
  }

  ngAfterViewInit() {
    // this.route.fragment.subscribe((fragment:string) => {
    //   this.utility.scrollToFragment(fragment);
    // })
  }

  getWindowType() {
    this.footerHeight = undefined;
    const ww = document.body.clientWidth;
    if (ww <= 768) {
      this.windowType = "mobile";
    } else if (ww > 768 && ww <= 1024) {
      this.windowType = "Tab";
    } else {
      this.windowType = "device";
    }
  }

  getLobbyGroupsAndGames(isForce?: boolean) {
    this.isLoading = true;
    let lobbyGroupApi = this.gameGroupsService.getLobbyListWithGameGroupsp(
      isForce
    );
    let groupGamesApi = this.gameGroupsService.getGameGroupGamesp(isForce);
    Promise.all([lobbyGroupApi, groupGamesApi]).then(
      ([lobbyGameGroups, games]) => {
        window["prerenderReady"] = true;
        /**we persist orginal game data in games variable &
         * floating games after filter applied will be stored in
         * filteredGames varaiable
         */
        this.games = games;
        this.lobbyGameGroupDetails = this.getGameGroupDetails(lobbyGameGroups);
        if (this.games) {
          this.displayByFilterType("providers");
        } else {
          this.filteredGames = [];
          this.providerList = [];
        }
        this.isLoading = false;
      }
    );
  }

  /**
   * Function return lobby details & It's inner game group details based
   * on provide lobbyName or selected lobbyName
   */

  getGameGroupDetails(lobbyDetails) {
    return _.filter(lobbyDetails, (lobby) => {
      return lobby["name"].toLowerCase() === this.lobby;
    });
  }

  getAllproviders(games) {
    if (this.providerList) {
      return this.providerList;
    } else {
      return this.utility.getProvidesList(games);
    }
  }

  navigateToStudio(vendorDisplayName) {
    vendorDisplayName = this.utility.convertGameNameToUrl(vendorDisplayName);
    this.router.navigate([this.langCode + "/studio/" + vendorDisplayName]);
  }

  displayByFilterType(type, providerName?) {
    this.isFilteredBy = type;
    this.providerName = providerName;
    setTimeout(() => {
      this.utility.getFixedHeaderHeight();
    }, 1000);
    if (this.isFilteredBy === "a-z") {
      this.filteredGames = this.utility.sortByGameName(this.games, "name");
      this.totalFilterGames = this.filteredGames;
      this.filteredGames = this.filteredGames.slice(
        0,
        this.filteredGamesDefaultSize
      );
    } else {
      /**always default is providers */
      this.providerList = this.utility.getSortedUniqProviderList(this.games);
    }
    window.scrollTo(0, 0);
  }

  providerLevelGameSort() {
    if (!this.isprovideratozEnabled) {
      this.isprovideratozEnabled = true;
      this.beforeSortProviderGames = this.filteredGames;
      this.filteredGames = this.utility.sortByGameName(
        this.filteredGames,
        "name"
      );
    } else {
      this.isprovideratozEnabled = false;
      this.filteredGames = this.beforeSortProviderGames;
    }
  }

  gobackToProviders() {
    this.isprovideratozEnabled = false;
    this.beforeSortProviderGames = undefined;
    this.displayByFilterType("providers");
  }

  ngOnDestroy() {
    if (this.loginCompleteSubscription) {
      this.loginCompleteSubscription.unsubscribe();
    }
  }

  @HostListener("window:scroll") onWindowScroll() {
    if (this.filteredGames && this.filteredGames.length > 0) {
      this.loadMoreGames();
    }
  }

  /**
   * Below function is to handle lazy load based on window scroll.
   */

  loadMoreGames() {
    const footerContainerDom = document.getElementsByClassName(
      "footer-conatiner"
    );
    const gamesConatinerDom = document.getElementsByClassName(
      "vp__game-container"
    );
    const gameCardDom = document.getElementsByClassName("game-card");

    if (this.footerHeight === undefined) {
      this.footerHeight = footerContainerDom[0].clientHeight + 150;
    }
    if (footerContainerDom.length > 0 && gameCardDom.length > 0) {
      const allgameswrapper = gamesConatinerDom[0].clientWidth;
      const gameCard = gameCardDom[0].clientWidth;
      const gamesLengthInRow = Math.round(allgameswrapper / gameCard);
      const fillMissingGaps = Math.ceil(
        this.filteredGamesDefaultSize -
          Math.floor(this.filteredGamesDefaultSize / gamesLengthInRow) *
            gamesLengthInRow
      );
      if (fillMissingGaps !== 0) {
        this.filteredGamesDefaultSize += Math.ceil(
          gamesLengthInRow - fillMissingGaps
        );
        this.loadMoreFilteredGames = this.filteredGamesDefaultSize;
        this.filteredGames = this.totalFilterGames.slice(
          0,
          this.loadMoreFilteredGames
        );
      }
    }
    if (
      window.scrollY + window.outerHeight >=
      document.body.scrollHeight - this.footerHeight
    ) {
      this.loadMoreFilteredGames += this.filteredGamesDefaultSize;
      this.filteredGames = this.totalFilterGames.slice(
        0,
        this.loadMoreFilteredGames
      );
    }
  }
}
