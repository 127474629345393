import { Directive, HostListener } from "@angular/core";

// Libraries
import { SwiperComponent } from "ngx-swiper-wrapper";

@Directive({
  selector: ".mySwiper",
})
export class SwiperResizeDirective {
  constructor(private swiperComponent: SwiperComponent) {}

  @HostListener("window:resize") onResize(): void {
    if (
      !/Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.swiperComponent.directiveRef.setIndex(0);
    }
    
    this.swiperComponent.directiveRef.update();
  }
}
