// Models
import { EndpointConfigurations } from "src/app/models/configurations/endpoint-configuration.model";

/*
All Endpoint URL which we are using in the application
comes here...
*/
export const endpointConfigurations: EndpointConfigurations = {
  dologin_url: "/ajax/login",
  doRegistration_url: "/ajax/registration",
  profileUpdate_url: "updateProfile",
  loginStatus_url: "/ajax/login/status",
  dologout_url: "/ajax/login/logout",
  profileData_url: "/ajax/profile/getData",
  getbanner_url: "/ajax/banner/getBanners",
  uniqueEmail_url: "/ajax/registration/isUniqueEmail",
  uniqueUserName_url: "/ajax/registration/isUniqueNickname",
  getUserData_url: "/ajax/profile/getData",
  getData_url: "/ajax/profile/getData",
  getprofilebalance_url: "/ajax/profile/getProfileBalanceCurrency",
  getBalance_url: "/ajax/profile/getBalance",
  getLoginStatus_url: "/ajax/login/status",
  getStaticPage_url: "/ajax/staticPage/getPage",
  isCountryBlock_url: "/ajax/country/countryblock",
  getUserUsedPIQAccounts: "/api/user/account/:merchantId/:userId",
  getToken: "/ajax/token/getToken",
  getEligibleBonus: "/ajax/bonus/getEligibleBonuses",
  getUserPIQMethods: "/api/user/payment/method/:merchantId/:userId",
  makePayment_url: "/api/:provider/:type/:method",
  gettransactionStatus_url:
    "/api/user/transaction/:merchantId/:userId/:txRefId/status",
  getGames_url: "/ajax/game/getGames",
  lanuchFreeGames_url: "/ajax/launcher/getFreeGames",
  getLobbyGameGroups_url: "/ajax/game/getLobbyListWithGameGroups",
  getGameGroupGames_url: "/ajax/game/getLobbyGameGroupGames",
  getFavoriteGame_url: "/ajax/profile/getFavouriteGames",
  getLastPlayedGames_url: "/ajax/profile/getLastPlayedGames",
  getFreeGame_url: "/ajax/launcher/getFreeGames",
  getRealgame_url: "/ajax/launcher/getRealGames",
  toggleFavoriteGame_url: "/ajax/profile/toggleFavoriteGame",
  deletePaymentMethod_url: "/api/user/account/:merchantId/:userId/:accountId",
  getPromotions_url: "/ajax/promotion/getPromotions",
  getTransactionhistory_url: "/ajax/Report/getTransactionHistory",
  getCashierTransactionHistory_url:
    "/ajax/CashierTransactionReport/withdrawalDeposit",
  forgot_password_url: "/ajax/resetPassword",
  reset_password_url: "/ajax/resetPassword/doResetPassword",
  getBetTransactions_url: "/ajax/Report/getTransactionHistory",
  getSubscriptionpreferences_url: "/ajax/profile/getSubscriptionPreferenes",
  updatesubscriptionpreferenes_url:
    "/ajax/profile/updateSubscriptionPreferenes",
  change_password_url: "/ajax/profile/changePassword",
  getActiveBonus_url: "/ajax/Bonus/activeBonusDetails",
  dropBonus_url: "/ajax/bonus/drop",
  doprofile_update_url: "/ajax/profile/update",
  getProfileCountryDetails_url: "/ajax/profile/getcountryDetails",
  setResponsibleGamingLimits_url: "/ajax/ResponsibleGaming/setLimits",
  getLossLimits_url: "/ajax/balance/getLossLimit",
  getDepositLimit_url: "/ajax/balance/getDepositLimit",
  getWagerLimit_url: "/ajax/balance/getWagerLimit",
  suspendAccount_url: "/ajax/ResponsibleGaming/lockAccount",
  getSessionLimit_url: "/ajax/balance/getSessionLimit",
  uploadnationalId_url:
    "/ajax/account/Documents/upload?file=nationalid&accountId=",
  uploadResidenceId_url:
    "/ajax/account/Documents/upload?file=utilitybill&accountId=",
  uploadCAFId_url: "/ajax/account/Documents/upload?file=caf&accountId=",
  uploadDocuments: "/ajax/account/Documents/upload",
  documentSend_url: "/ajax/account/Documents/send",
  accountVerficationstatus_url: "/ajax/profile/getAccountVerificationStatus",
  cancelDepositLimits: "/ajax/ResponsibleGaming/confirmDepositLimits",
  cancelRgLimits: "/ajax/ResponsibleGaming/confirmLimits",
  getPendingWithdrawal_url: "/api/user/transaction/:merchantId/:userId",
  cancelTransaction_url:
    "/api/user/transaction/:merchantId/:userId/:transactionId",
  getUserLifeTimeTransactionSummary_url:
    "/ajax/cashier/getPlayerLifeTimeSummary",
  checkUserPhoneAvailablity_url: "/ajax/registration/checkUserPhoneAvailablity",
  getUserLevelupDetails_url: "/ajax/loyality/getUserLevelupDetails",
  getFaqQuestionAndCategories_url: "/ajax/faq/getQuestions",
  zendeskToken: "/ajax/profile/getZendeskToken",
  mgsJackpotFeed: "/counters",
  mgsJackpotsTotal: "/jackpots/total",
  getUserKycLevelDetails_url: "/ajax/profile/getUserKYCDetails",
  getPNPMinMaxTxnLimits: "/ajax/PayAndPlayTrustly/getPNPMinMaxTxnLimits",
  getCashOutDetails: "/ajax/cashier/getCashierDetails",
  zimplerPayAndPlayInit: "/ajax/zimpler/zimplerPayAndPlayInit",
  getPNPUserFromTransaction:
    "/ajax/payAndPlayTrustly/getPNPUserFromTransaction",
  quickRegisterZimpler: "/ajax/zimpler/quickRegisterZimpler",
  getPNPThirdPartyLogin: "/ajax/PayAndPlayTrustly/getPNPThirdPartyLogin",
  updateUserCredentials: "/ajax/profile/updateUserCredentials",
  zimplerWithdrawInit: "/ajax/cashier/makeWalletWithdrawal",
  getUserPSPTransactionStatus: "/ajax/cashier/gettransationStatus",
  getPendingCashOuts_url: "/ajax/cashier/getPendingCashouts",
  revertWithdraw_url: "/ajax/Cashier/revertWithdrawal",
  getUnclaimedLevels_url: "/ajax/loyality/getUnclaimedLevels",
  claimLevelupBonus_url: "/ajax/loyality/claimLevelupBonus",
  getUserBalanceByPocket_url: "/ajax/profile/getBalanceByPocket",
  getUserCampaigns: "/ajax/bonus/getUserCampaigns",
  getPromoConfigDetails: "/ajax/bonus/getPromoConfigDetails",
  getOptIn_url: "/ajax/bonus/optIn",
  getPlayerPromoProgress: "/ajax/bonus/getPlayerPromoProgress",
};
