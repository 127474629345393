import { Observable, Subject, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";

// Configurations
import {
  localToCmsLanguageConfigurations,
  languageToRegisterConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { CashbackPromoService } from "src/app/pages/rewards/service/cashback-promo.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { MainService } from "src/app/services/main-service/main.service";
import { SocketService } from "src/app/services/socket/socket.service";
import { GtmService } from "src/app/services/gtm-service/gtm.service";

@Injectable({
  providedIn: "root",
})
export class PayNPlayCashierService {
  activeDepositBonus: any = {};
  userLoggedTime;
  langCode: string;
  userRegisteredLang;
  isLoggedIn: any;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  cashOutLimits = {
    minLimit: 0,
    maxLimit: 0,
  };

  private isUserTractionFetchingRequired: string = "";
  constructor(
    private gtmService: GtmService,
    private socketService: SocketService,
    private mainService: MainService,
    private commonService: CommonService,
    private userDetailService: UserDetailsService,
    private translationService: TranslationService,
    private router: Router,
    private cashBackPromoService: CashbackPromoService
  ) {
    this.translationService.langCodeSb$.subscribe((langCode) => {
      this.langCode = langCode;
    });
  }

  private initiateLogin = new Subject<any>();
  public initiateLogin$ = this.initiateLogin.asObservable();

  broadCastInitiateLogin(txnType) {
    this.initiateLogin.next(txnType);
  }

  private initiateDeposit = new Subject<any>();
  public initiateDespoit$ = this.initiateDeposit.asObservable();

  broadCastInitiateDeposit(data) {
    this.initiateDeposit.next(data);
  }

  private initiateWithdraw = new Subject<any>();
  public initiateWithdraw$ = this.initiateWithdraw.asObservable();

  broadCastInitiateWithdraw() {
    this.initiateWithdraw.next();
  }

  private processUserTransaction = new Subject<any>();
  public processUserTransaction$ = this.processUserTransaction.asObservable();

  broadCastProcessUserTranaction(type) {
    this.setUserTranactionRequired(type);
    this.processUserTransaction.next(type);
  }

  private zimplerProcessCompleted = new Subject<any>();
  public zimplerProcessCompleted$ = this.zimplerProcessCompleted.asObservable();

  broadCastZimplerProcessCompleted() {
    this.zimplerProcessCompleted.next();
  }

  setUserTranactionRequired(type) {
    this.isUserTractionFetchingRequired = type;
  }

  getUserTranactionRequired() {
    return this.isUserTractionFetchingRequired;
  }
  setUserLoggedTime(time) {
    localStorage.setItem("loggedInTime", time);
    this.userLoggedTime = time;
  }
  getUserLoggedTime() {
    return this.userLoggedTime;
  }

  setActiveDepositBonus(depositBonusId) {
    this.activeDepositBonus = depositBonusId;
  }

  getActiveDepositBonus() {
    return this.activeDepositBonus;
  }

  depositLimits = {
    minLimit: 0,
    maxLimit: 0,
  };
  getPNPMinMaxTxnLimits(minMaxRequestData): Observable<any> {
    return this.mainService.getPNPMinMaxTxnLimits(minMaxRequestData).pipe(
      map((response) => {
        if (
          response["status"] === "SUCCESS" &&
          response["minMaxTxnLimitsData"] &&
          response["minMaxTxnLimitsData"][0]
        ) {
          this.depositLimits = {
            minLimit: response["minMaxTxnLimitsData"][0]["minTxnLimit"],
            maxLimit: response["minMaxTxnLimitsData"][0]["maxTxnLimit"],
          };
        }
        return this.depositLimits;
      })
    );
  }

  getCashoutTxnLimits(requestData): Observable<any> {
    return this.mainService.getCashoutTxnLimits(requestData).pipe(
      map((response) => {
        if (
          response &&
          response["cashierData"] &&
          response["cashierData"]["cashoutMethods"]
        ) {
          let cashoutData =
            response["cashierData"]["cashoutMethods"]["ZIMPLER_DIRECT"];
          this.cashOutLimits = {
            minLimit: parseInt(cashoutData["limits"]["minTxnLimit"]),
            maxLimit: parseInt(cashoutData["limits"]["maxTxnLimit"]),
          };
        }
        return this.cashOutLimits;
      })
    );
  }

  zimplerPayAndPlayInit(initRequestData): Observable<any> {
    return this.mainService.zimplerPayAndPlayInit(initRequestData);
  }

  getPNPUserFromTransaction(transactionData): Observable<any> {
    return this.mainService.getPNPUserFromTransaction(transactionData);
  }

  quickRegisterZimpler(transactionData): Observable<any> {
    return this.mainService.quickRegisterZimpler(transactionData);
  }
  getPNPThirdPartyLogin(transactionData): Observable<any> {
    return this.mainService.getPNPThirdPartyLogin(transactionData).pipe(
      map((response) => {
        if (response && response["status"] === "SUCCESS") {
          this.navigateToUserProfileLang();
          this.socketService.connectToScoket(
            response["pragmaticUrl"],
            response["pragmaticSessionId"],
            true
          );
          localStorage.setItem("isLoggedIn", "true");
          // this.userLoggedTime = new Date();
          this.setUserLoggedTime(new Date());
          localStorage.setItem("loggedInTime", this.userLoggedTime);
          this.gtmService.trackLoginGTMEvent("login-success", response);
          localStorage.setItem("kan_user", "true");
          this.commonService.broadCastLoginSuccess(true);
          if (response["lastAuthTime"]) {
            this.cashBackPromoService.setUserLastAuthTime(
              response["lastAuthTime"]
            );
          }
        }
        return response;
      })
    );
  }

  navigateToUserProfileLang() {
    Promise.resolve(this.mainService.getProfileBalanceCurrency()).then(
      (data) => {
        if (data["profile"] && data["profile"]["language"]) {
          this.userRegisteredLang = this.userDetailService.getUserProfileLang(
            languageToRegisterConfigurations,
            data["profile"]["language"]
          );
          let userLang = this.userRegisteredLang
            ? this.userRegisteredLang
            : environment.defaultLang;
          const params = this.getQueryParams();
          if (userLang !== this.langCode) {
            this.userDetailService.setUserLangCode(userLang);
            this.translationService.use(localToCmsLanguageConfigurations[this.langCode]);
            localStorage.setItem("langCode", userLang);
            this.navigateToLandingPage(params, userLang);
            setTimeout((_) => {
              window.location.reload();
            });
          }
        }
      }
    );
  }

  navigateToLandingPage(params = {}, userLang) {
    this.router.navigate([userLang + "/casino"], { queryParams: params });
  }

  getQueryParams() {
    if (window.location.search) {
      return JSON.parse(
        '{"' +
          decodeURI(
            window.location.search
              .substring(1)
              .replace(/&/g, '","')
              .replace(/=/g, '":"')
          ) +
          '"}'
      );
    } else {
      return {};
    }
  }

  zimplerWithdrawInit(withdrawInitData) {
    return this.mainService.zimplerWithdrawInit(withdrawInitData);
  }

  getUserPSPTranstionStatus(requestData): Observable<any> {
    return this.mainService.getUserPSPTranstionStatus(requestData);
  }

  getPendingCashOuts(pendingWithdrawalObject): Observable<any> {
    return this.mainService.getPendingCashOuts(pendingWithdrawalObject);
  }

  revertWithdrawal(requestData): Observable<any> {
    return this.mainService.revertWithdrawal(requestData);
  }
}
