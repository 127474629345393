import { Subscription } from "rxjs";
import {
  HostListener,
  SimpleChange,
  Component,
  OnChanges,
  Input,
} from "@angular/core";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-livecasino-group-display",
  templateUrl: "./livecasino-group-display.component.html",
  styleUrls: ["./livecasino-group-display.component.scss"],
})
export class LivecasinoGroupDisplayComponent implements OnChanges {
  @Input() games;
  @Input() groupData;
  @Input() activeLobbyName: string;
  @Input() finalLobbyGames;
  @Input() lobbyProviderData;
  isLoggedIn: any;
  filteredGames;
  gamesDefaultSize: number = 15;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  windowType: string;
  islandscape: boolean;
  playAgainCutOffValue: number = 4;
  providerLobbyCutOffValue: number;
  showProviderLobby: boolean = false;

  @HostListener("window:resize") onWindowResize() {
    this.getWindowType();
    this.setGameCutOffValue();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
    this.setGameCutOffValue();
  }

  @HostListener("window:scroll") onWindowScroll() {
    if (
      this.games &&
      this.games.length > 0 &&
      this.filteredGames &&
      this.filteredGames.length < this.games.length
    ) {
      this.onScroll();
    }
  }
  constructor(private utility: UtilityService, private commonService: CommonService) {
    this.getWindowType();
    this.setGameCutOffValue();
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
  }

  getLobbyGames() {
    if (this.games) {
      this.initializeLazyLoading();
    } else {
      this.filteredGames = [];
    }
  }

  initializeLazyLoading() {
    this.gamesDefaultSize = 15;
    if (this.games && this.games.length > 0) {
      this.filteredGames = this.games.slice(0, this.gamesDefaultSize);
    }
  }

  onScroll() {
    let metaContentHeight = 0;
    let footerHeight = this.utility.getFooterHeight();
    const metaContentDom: any = document.getElementsByClassName("meta-content");
    if (metaContentDom && metaContentDom.length > 0) {
      metaContentHeight = metaContentDom[0].offsetHeight;
    }
    if (
      window.scrollY + window.innerHeight >=
      document.body.scrollHeight - (footerHeight + metaContentHeight + 250)
    ) {
      this.gamesDefaultSize = this.gamesDefaultSize + 15;
      this.filteredGames = this.games.slice(0, this.gamesDefaultSize);
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["games"]) {
      this.games = changes["games"].currentValue;
      this.getLobbyGames();
    }
    if (changes["groupData"]) {
      this.groupData = changes["groupData"].currentValue;
    }
    if (changes["activeLobbyName"]) {
      this.activeLobbyName = changes["activeLobbyName"].currentValue;
    }
    if (changes["finalLobbyGames"]) {
      this.finalLobbyGames = changes["finalLobbyGames"].currentValue;
    }
    if (changes["lobbyProviderData"]) {
      this.lobbyProviderData = changes["lobbyProviderData"].currentValue;
    }
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
    if (window.matchMedia("(orientation: portrait)").matches) {
      // you're in PORTRAIT mode
      this.islandscape = false;
    }
    if (window.matchMedia("(orientation: landscape)").matches) {
      // you're in LANDSCAPE mode
      this.islandscape = true;
    }
  }

  setGameCutOffValue() {
    this.setPlayAgainGamesCutOff();
    this.setProviderLobbyGamesCutoff();
  }

  setPlayAgainGamesCutOff() {
    const ww = document.body.clientWidth;
    if (ww >= 1600) {
      this.playAgainCutOffValue = 4;
    } else if (ww <= 480) {
      this.playAgainCutOffValue = 2;
    } else if (ww <= 1599) {
      this.playAgainCutOffValue = 3;
    }
  }

  setProviderLobbyGamesCutoff() {
    const ww = document.body.clientWidth;
    if (ww <= 860 && this.islandscape) {
      this.showProviderLobby = true;
      this.providerLobbyCutOffValue = 9;
    } else if (ww > 481 && !this.islandscape) {
      this.showProviderLobby = false;
    } else if (ww <= 480) {
      this.showProviderLobby = true;
      this.providerLobbyCutOffValue = 8;
    } else {
      this.showProviderLobby = false;
    }
  }
}
