import { BehaviorSubject, Subject } from "rxjs";
import { Injectable } from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

@Injectable()
export class UserDetailsService {
  userProfileDetails;
  userCashBalance;
  userBonusBalance;
  userTotalBalance: number = 0;
  userBalanceDetails;
  userCurrencyDetails;
  userKycDetails;
  userBrowserCountry;
  logoutSubs;
  userCurrencyCode;
  userCurrencySymbol: any;
  defaultCurrencySymbol: any = environment.defaultCurrencySymbol;
  defaultCurrency: any = environment.defaultCurrencyCode;

  CurrencyAndCountries;
  profileCOmpletionDetails = {};
  kycStatus;
  lastLoginTime;
  signUpdata;
  userTotalBonus;
  userCountry;
  userCountryLang;
  langCode;
  userLangCode: string;

  private userProfileUpdated = new Subject<string>();
  userProfileUpdated$ = this.userProfileUpdated.asObservable();

  private userBalanceUpdated = new Subject<string>();
  userBalanceUpdated$ = this.userBalanceUpdated.asObservable();

  private currencySymbolSb = new BehaviorSubject(
    environment.defaultCurrencySymbol
  );
  currencySymbolSb$ = this.currencySymbolSb.asObservable();

  private currencyCodeSb = new BehaviorSubject(environment.defaultCurrencyCode);
  currencyCodeSb$ = this.currencyCodeSb.asObservable();
  withdrawableBalance: any;

  setCurrencySymbol(currencySymbol) {
    this, (this.userCurrencySymbol = currencySymbol);
    this.currencySymbolSb.next(currencySymbol);
  }

  setCurrency(currencyCode) {
    this.userCurrencyCode = currencyCode;
  }
  setCurrencyCode(currencyCode) {
    this.userCurrencyCode = currencyCode;
    this.currencyCodeSb.next(currencyCode);
  }

  getCurrencySymbol(): string {
    return this.userCurrencySymbol
      ? this.userCurrencySymbol
      : this.defaultCurrencySymbol;
  }

  getCurrencyCode() {
    return this.userCurrencyCode ? this.userCurrencyCode : this.defaultCurrency;
  }
  constructor() {}

  setUserProfileDetails(userProfile) {
    this.userProfileDetails = userProfile;
    this.userProfileDetails["firstName"] = this.userProfileDetails["firstName"]
      ? this.userProfileDetails["firstName"].toLowerCase()
      : "";
    this.userProfileDetails["lastName"] = this.userProfileDetails["lastName"]
      ? this.userProfileDetails["lastName"].toLowerCase()
      : "";
    this.setUserLangCode(userProfile["language"]);
    this.userProfileUpdated.next("UserProfileUpdated");
  }

  setUserLangCode(countryCode: string) {
    this.userLangCode = countryCode;
  }

  getUserLangCode() {
    return this.userLangCode;
  }

  getUserProfileLang(object, registeredLang) {
    return Object.keys(object).find((key) => object[key] === registeredLang);
  }

  setUserCurrencyDetails(currencyDetails) {
    this.userCurrencyDetails = currencyDetails;
  }

  getUserCurrencyDetails() {
    return this.userCurrencyDetails;
  }

  getUserBrowserCountry() {
    return this.userBrowserCountry;
  }

  setUserBrowserCountry(userBrowserCountry) {
    this.userBrowserCountry = userBrowserCountry;
  }

  getUserProfileDetails() {
    return this.userProfileDetails;
  }

  setUserTotalBalance(userTotalBalance) {
    this.userTotalBalance = userTotalBalance;
  }

  getUserTotalBalance() {
    return this.userTotalBalance;
  }

  setWithdrawableBalance(withdrawableBalance) {
    this.withdrawableBalance = withdrawableBalance;
  }

  getWithdrawableBalance() {
    return this.withdrawableBalance;
  }

  setUserTotalBonus(userTotalBonus) {
    this.userTotalBonus = userTotalBonus;
  }

  getUserTotalBonus() {
    return this.userTotalBonus;
  }

  setUserBalanceDetails(userBalancePocketDetails) {
    this.userTotalBalance =
      userBalancePocketDetails["realCashBalance"] +
      userBalancePocketDetails["crpBalance"] +
      userBalancePocketDetails["wrpBalance"] +
      userBalancePocketDetails["drpBalance"];
    this.userTotalBonus =
      userBalancePocketDetails["crpBalance"] +
      userBalancePocketDetails["wrpBalance"];
    this.withdrawableBalance =
      userBalancePocketDetails["realCashBalance"] +
      userBalancePocketDetails["drpBalance"];
    this.userBalanceDetails = {
      userTotalBalance: this.userTotalBalance,
      userTotalBonus: this.userTotalBalance,
      withdrawableBalance: this.withdrawableBalance,
    };
    this.userBalanceUpdated.next("UserBalanceUpdated");
  }

  getUserBalanceDetails() {
    return this.userBalanceDetails;
  }

  getLastLoginTime() {
    return this.lastLoginTime;
  }

  setLastLoginTime(time) {
    this.lastLoginTime = new Date(parseInt(time) * 1000);
  }

  getUserKycDetails() {
    return this.userKycDetails;
  }

  setProfileCompletionStatus(status) {
    let self = this;
    _.each(status, function (value, key) {
      self.profileCOmpletionDetails[key] = value;
    });
  }

  resetAllDetails() {
    this.userProfileDetails = undefined;
    this.userCashBalance = undefined;
    this.userBonusBalance = undefined;
    this.userTotalBalance = undefined;
    this.userBalanceDetails = undefined;
    this.userCurrencyDetails = undefined;
    this.userKycDetails = undefined;
    this.userBrowserCountry = undefined;
    this.profileCOmpletionDetails = {};
    this.kycStatus = undefined;
    this.userCurrencySymbol = undefined;
    this.userCurrencyCode = undefined;
    this.userCurrencyDetails = undefined;
    this.userLangCode = undefined;
    if (window["$zopim"]) {
      window["$zopim"].livechat.clearAll();
    }
    this.userProfileUpdated.next("UserProfileUpdated");
  }
}
