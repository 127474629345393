import { Subscription } from "rxjs";
import {
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  Renderer2,
  ViewChild,
  OnInit,
} from "@angular/core";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-session-timer",
  templateUrl: "./session-timer.component.html",
  styleUrls: ["./session-timer.component.scss"],
})
export class SessionTimerComponent implements OnInit, OnDestroy {
  // View Childs
  @ViewChild("userSessionTime", { static: false }) el: ElementRef;

  // Strings
  counter: string = "00hr 00m 00s";

  // Date
  userLoggedTime: Date = new Date();
  
  // Timeouts
  timeouts: NodeJS.Timer;
  intervals: NodeJS.Timer;

  // Enums
  windowType: "device" | "mobile" = "device";

  // Subscriptions
  subscriptions: Subscription[] = [];

  @HostListener("touchstart", ["$event"])
  onStart(e) {
    if (e) {
      this.initiateDragDrop();
    }
  }

  @HostListener("window:orientationchange") onRotate() {
    this.setSessionTimerScreenOrientation();
    this.getWindowType();
  }

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  constructor(
    private commonService: CommonService,
    private render: Renderer2,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.commonService.loginComplete$.subscribe((flag) => {
      this.userLoggedTime = this.paynplayCashierService.getUserLoggedTime();
    });

    this.getWindowType();
  }

  ngOnInit() {
    setTimeout(() => {
      this.getUserLoginTime();
      this.startLoginSession();
    }, 1000);
  }

  setSessionTimerScreenOrientation() {
    if (window.matchMedia("(orientation: landscape)").matches) {
      this.render.setStyle(this.el.nativeElement, "left", 16 + "px");
      this.render.setStyle(this.el.nativeElement, "bottom", 64 + "px");
      this.render.setStyle(this.el.nativeElement, "top", "unset");
    } else {
      this.render.setStyle(this.el.nativeElement, "top", 16 + "px");
      this.render.setStyle(this.el.nativeElement, "left", 64 + "px");
    }
  }

  getUserLoginTime() {
    if (this.paynplayCashierService.getUserLoggedTime()) {
      this.userLoggedTime = this.paynplayCashierService.getUserLoggedTime();
    } else if (localStorage.getItem("loggedInTime")) {
      this.userLoggedTime = new Date(
        Date.parse(localStorage.getItem("loggedInTime"))
      );
    }
  }

  startLoginSession() {
    let userLoggedInTime = this.userLoggedTime;
    this.intervals = setInterval(() => {
      if (userLoggedInTime) {
        let totalMilliSecondAfterDiff =
          new Date().getTime() - userLoggedInTime.getTime();
        this.displayUserLoginTime(totalMilliSecondAfterDiff);
      }
    }, 100);
  }

  initiateDragDrop() {
    if (this.windowType === "mobile") {
      let userSessionElem = document.getElementById("userSessionTimer");
      let timerWidth = userSessionElem.clientWidth;
      let timerHeight = userSessionElem.clientHeight;
      if (userSessionElem) {
        userSessionElem.addEventListener(
          "touchmove",
          (e) => {
            e.preventDefault();
            let ww = window.innerWidth;
            let wh = window.innerHeight;
            let touchLocation = e.targetTouches[0];
            if (
              touchLocation.clientX > 0 &&
              touchLocation.clientX < ww - timerWidth
            ) {
              // console.log("====>entered inside touchLocation.clientX",touchLocation.clientX);
              userSessionElem.style.left = touchLocation.clientX + "px";
            }
            if (
              touchLocation.clientY > 0 &&
              touchLocation.clientY < wh - timerHeight
            ) {
              userSessionElem.style.top = touchLocation.clientY + "px";
            }
          },
          false
        );
      }
    }
  }

  displayUserLoginTime(totalMilliSecondAfterDiff) {
    let hours: any, minutes: any, seconds: any;
    hours = Math.floor((totalMilliSecondAfterDiff / (1000 * 60 * 60)) % 24);
    minutes = Math.floor((totalMilliSecondAfterDiff / (1000 * 60)) % 60);
    seconds = ((totalMilliSecondAfterDiff / 1000) % 60).toFixed(0);
    this.counter =
      ("00" + hours).slice(-2) +
      "hr" +
      " " +
      ("00" + minutes).slice(-2) +
      "m" +
      " " +
      ("00" + seconds).slice(-2) +
      "s";
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  ngOnDestroy() {
    if (this.intervals) {
      clearInterval(this.intervals);
    }
  }
}
