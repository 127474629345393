import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-form-radio",
  templateUrl: "./form-radio.component.html",
  styleUrls: ["./form-radio.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FormRadioComponent extends FormValidationComponent
  implements OnInit {
  config;
  group: FormGroup;
  selectedMethodData: any;
  callingFrom: any;
  usedAccount: boolean;
  constructor(private utils: UtilityService) {
    super(utils);
  }

  ngOnInit() {}
}
