import { Component, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-live-casino-playagain",
  templateUrl: "./live-casino-playagain.component.html",
  styleUrls: ["./live-casino-playagain.component.scss"],
})
export class LiveCasinoPlayagainComponent implements OnDestroy {
  filterByData = { typeOfGames: "live-game" };
  isLoggedIn: boolean;
  lastPlayedGames;
  lastPlayedLiveGames;
  gamesUrlPath: string = environment.gamesUrlPath;
  isLoading: boolean = true;
  langCodeSubscription: Subscription;
  langCode: string;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  imgixParams: string;

  constructor(
    private router: Router,
    private utility: UtilityService,
    private translationService: TranslationService,
    private gamesFilterPipe: GamesFilterPipe,
    private commonService: CommonService,
    private gameGroupsService: GameGroupsService,
    private gamePlayService: GamePlayService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.getLastPlayedGames();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
    this.imgixParams = this.utility.getImgixParams();
  }

  getLastPlayedGames() {
    this.lastPlayedGames = [];
    Promise.all([
      this.gameGroupsService.getGameGroupGamesp(),
      this.gameGroupsService.getLastedPlayedGamesp(),
    ]).then(([games, lastPlayedGameIds]) => {
      if (
        games &&
        games.length > 0 &&
        lastPlayedGameIds &&
        lastPlayedGameIds.length > 0
      ) {
        _.each(lastPlayedGameIds, (typeId) => {
          let game = _.filter(games, { beGameTypeId: typeId })[0];
          if (game) {
            this.lastPlayedGames.push(game);
          }
        });
        this.applyLiveCasinoFilter();
      } else {
        this.lastPlayedLiveGames = [];
      }
      this.isLoading = false;
    });
  }

  applyLiveCasinoFilter() {
    if (this.lastPlayedGames.length > 0) {
      this.lastPlayedLiveGames = this.gamesFilterPipe.transform(
        this.lastPlayedGames,
        this.filterByData
      );
      if (this.lastPlayedLiveGames && this.lastPlayedLiveGames.length > 0) {
        this.gameGroupsService.broadCastIsLastPlayedLiveGamesExist(true);
      }
    }
  }

  gameLaunch(gameName) {
    gameName = this.utility.convertGameNameToUrl(gameName);
    this.gamePlayService.clearGameWindowData();
    setTimeout(() => {
      if (gameName && this.isLoggedIn) {
        this.router.navigate([
          this.langCode +
            "/" +
            this.translationService.instant("url.game") +
            "/" +
            gameName,
        ]);
      } else {
        this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
        this.commonService.setNavigateAfterLogin({
          type: "url",
          data: { navigateTo: "gameWindow", path: gameName },
        });
      }
    });
  }
  viewAllHandler() {
    this.commonService.broadCastUpdateActiveLobby({ lobby: "play-again" });
  }

  ngOnDestroy() {
    this.langCodeSubscription.unsubscribe();
    this.loginCompleteSubscription.unsubscribe();
    this.logOutCompleteSubscription.unsubscribe();
  }
}
