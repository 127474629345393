import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";

@Component({
  selector: "app-promotion-card",
  templateUrl: "./promotion-card.component.html",
  styleUrls: ["./promotion-card.component.scss"],
})
export class PromotionCardComponent implements OnInit {
  langCode: string;
  langCodeSubscription: Subscription;

  constructor(
    private router: Router,
    private translationService: TranslationService
  ) {
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
  }

  ngOnInit() {}

  gotoPromotionPage() {
    this.router.navigate([this.langCode + "promotions/promo1"]);
  }
}
