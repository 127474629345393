import { Component, HostListener, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import {
  NavigationStart,
  ActivatedRoute,
  NavigationEnd,
  Router,
} from "@angular/router";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { EmitterService } from "src/app/services/emitter.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnDestroy {
  isLoggedIn: boolean = false;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  windowType: string;
  routerSub: Subscription;
  isSearchEnabled: boolean = false;
  isSearchRequired: boolean = true;
  hidden: boolean = false;
  langCode: string;
  langCodeSub: Subscription;

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }

  constructor(
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utility: UtilityService,
    private translationService: TranslationService,
    private emitterService: EmitterService
  ) {
    this.enableSearch();
    this.getWindowType();
    this.storageHandlers();
    this.langCode = this.utility.getLangCode();
    this.langCodeSub = this.translationService.langCodeSb$.subscribe(
      (langcode) => {
        this.langCode = langcode;
      }
    );
    this.routerSub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.enableSearch();
      }
      if (event instanceof NavigationStart) {
        this.hidden = event.url.includes("livespins");
      }
    });
    /**
     * Below Logic is to get the affiliate information form URL which is passed by
     * affiliate system
     * & we save it in cookies & pass it to BE when user get registered after navigating
     * from affiliate system
     */
    this.activatedRoute.queryParamMap.subscribe((queryParams) => {
      let affid = queryParams.get("affid");
      let tracker = queryParams.get("trackerid");
      let banner = queryParams.get("bannerid");
      this.utility.setAffCookies(affid, tracker, banner);
    });
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (data) => {
        this.isLoggedIn = true;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );

    /**we call this method onfresh of page...To check user login
     * session exist or not and bases on it's response we land user on
     * login state or logout state  */
    this.getLoginStatus();
  }

  /**
   * This Function decides under which route search should be enabled in header
   *  section, Basically search is enable on all routes except casino, live casino
   *  reset-password & unsubscribe
   *
   * where on casino & live casino we have seach which is at different position
   * so we call search reusable component with in casino & live casino components
   */

  enableSearch() {
    const urlSegments = this.utility.getDecodedCurrentPath()
      ? this.utility.getDecodedCurrentPath().split("/")
      : "";
    if (
      urlSegments &&
      (urlSegments.length <= 2 ||
        (urlSegments.length === 3 &&
          (urlSegments[2] === "casino" ||
            urlSegments[2] === "live-casino" ||
            urlSegments[2] === "reset-password" ||
            urlSegments[2] === "unsubscribe")))
    ) {
      this.isSearchRequired = false;
    } else {
      this.isSearchRequired = true;
    }
  }

  getLoginStatus() {
    Promise.resolve(this.commonService.getLoginStatus()).then((data) => {
      if (data && data["status"]) {
        this.isLoggedIn = true;
      }
    });
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww <= 1023) {
      this.windowType = "mobile";
    } else {
      this.windowType = "desktop";
    }
  }

  storageHandlers() {
    /**
     * This even will listen for removal of localstorage item in new Tabs
     * and logout user from all tabs
     */
    window.addEventListener("storage", (e) => {
      if (e.type === "storage" && e.key === "kan_user" && e.newValue === null) {
        this.commonService.doLogout();
      }
      /**
       * This will trigger login in all other Tabs
       */
      if (
        e.type === "storage" &&
        e.key === "kan_user" &&
        e.newValue === "true"
      ) {
        this.router.navigate([this.langCode + "/casino"]);
        this.emitterService.broadCastCloseCashierPopup(true);
        this.commonService.broadCastLoginSuccess(true);
      }
    });
  }

  ngOnDestroy() {
    this.loginCompleteSubscription.add(this.logOutCompleteSubscription);
    this.loginCompleteSubscription.add(this.langCodeSub);
    this.loginCompleteSubscription.unsubscribe();
  }
}
