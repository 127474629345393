import {
  ViewEncapsulation,
  EventEmitter,
  SimpleChange,
  ElementRef,
  Component,
  OnChanges,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-custom-banner",
  templateUrl: "./custom-banner.component.html",
  styleUrls: ["./custom-banner.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CustomBannerComponent implements OnChanges {
  @ViewChild("banner", { static: false }) cashier: ElementRef;
  @Input() zoneId;
  @Input() callingFrom;
  @Output() callOnBannerApiSuccess: EventEmitter<any> = new EventEmitter();
  activeBanners: any;
  isLoading: boolean = false;

  constructor(
    private customBannerService: CustomBannerService,
    private utility: UtilityService
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["zoneId"]) {
      this.activeBanners = undefined;
      this.zoneId = changes["zoneId"].currentValue;
      this.zoneId = this.zoneId;
      this.getBannersByZoneId({ zoneId: this.zoneId });
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  getBannersByZoneId(bannerReqData) {
    this.activeBanners = undefined;
    this.isLoading = true;
    Promise.resolve(this.customBannerService.getBannersp(bannerReqData)).then(
      (banners) => {
        this.isLoading = false;
        if (banners && banners.length > 0) {
          this.activeBanners = banners;
          setTimeout(() => {
            this.activeBanners.forEach((banner) => {
              banner.content = this.utility.replaceAll(banner.content, {
                "{{site_url}}": environment.siteUrl,
                "{{media_url}}": environment.mediaUrl,
              });
            });

            this.customBannerService.broadCaseIsBannerAvialable(true);
          });
        } else {
          this.activeBanners = [];
          this.customBannerService.broadCaseIsBannerAvialable(false);
        }
      }
    );
  }
}
