import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  HostListener,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-quickpick-amount",
  templateUrl: "./quickpick-amount.component.html",
  styleUrls: ["./quickpick-amount.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class QuickpickAmountComponent extends FormValidationComponent
  implements OnInit, OnDestroy {
  getPNPMinMaxTxnLimitSub: Subscription;
  zimplerForm = this.formBuilder.group({
    amount: ["", [this.customValidators.required]],
  });
  minValue: any;
  maxValue: any;
  zimplerCompleteSub: Subscription;
  currencySymbolSub: Subscription;
  currencySymbol: string;
  isFocus: boolean = false;
  isVisible: boolean = false;
  isLoggedIn: boolean = false;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  langCode;
  langCodeSubscription: Subscription;

  @HostListener("keydown", ["$event"]) onKeydown(e) {
    if (document.getElementById("qucikPickInputValue")) {
      var inputValue =
        (document.getElementById("qucikPickInputValue") as HTMLInputElement)
          .value.length - 1;
      if (e.which === 8 && inputValue <= 0) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    }
  }
  constructor(
    private utils: UtilityService,
    private formBuilder: FormBuilder,
    private customValidators: CustomValidatorService,
    private userDetailsService: UserDetailsService,
    private paynplayCashierService: PayNPlayCashierService,
    private commonService: CommonService,
    private translationService: TranslationService
  ) {
    super(utils);
    this.getPNPMinMaxTxnLimits();
    this.zimplerCompleteSub = this.paynplayCashierService.zimplerProcessCompleted$.subscribe(
      () => {
        this.zimplerForm.controls["amount"].setValue("");
        this.zimplerForm.reset();
      }
    );
    this.currencySymbolSub = this.userDetailsService.currencySymbolSb$.subscribe(
      (currencySymbol) => {
        this.currencySymbol = currencySymbol;
      }
    );

    this.isLoggedIn = this.utils.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );

    this.langCode = this.utils.getLangCode();
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
  }

  setTransactionAmount(event) {
    if (event) {
      this.zimplerForm.controls["amount"].setValue(event.target.value);
      this.isVisible = true;
    }
  }

  uncheckQuickPicks() {
    const inputRadioElement = document.querySelectorAll(
      '.pnp-qd__btn-wrapper input[type="radio"]:checked'
    ) as NodeListOf<Element>;
    if (inputRadioElement && inputRadioElement.length > 0) {
      (inputRadioElement[0] as HTMLInputElement).checked = false;
    }
  }

  getPNPMinMaxTxnLimits() {
    let minMaxRequestData = {
      option: "ZIMPLER_DIRECT",
      currency: this.userDetailsService.getCurrencyCode(),
    };
    this.getPNPMinMaxTxnLimitSub = this.paynplayCashierService
      .getPNPMinMaxTxnLimits(minMaxRequestData)
      .subscribe((data) => {
        if (data && data["minLimit"] && data["maxLimit"]) {
          this.minValue = data["minLimit"];
          this.maxValue = data["maxLimit"];
          this.zimplerForm.controls["amount"].setValidators(
            this.customValidators.minValueNumber(
              data["minLimit"],
              data["maxLimit"],
              "deposit"
            )
          );
        }
      });
  }

  zimplerPayAndPlayInit() {
    if (this.zimplerForm.valid) {
      this.paynplayCashierService.broadCastInitiateDeposit({
        callingFrom: "customBanner",
        amount: this.zimplerForm.controls["amount"].value,
      });
    }
  }

  isButtonDisabled(formName) {
    return this.utils.isButtonDisabled(formName);
  }

  ngOnInit() {}
  ngAfterViewInt() {}

  ngOnDestroy() {
    this.zimplerCompleteSub.unsubscribe();
    if (this.getPNPMinMaxTxnLimitSub) {
      this.getPNPMinMaxTxnLimitSub.unsubscribe();
    }
    this.logOutCompleteSubscription.unsubscribe();
    this.loginCompleteSubscription.unsubscribe();

    this.langCodeSubscription.unsubscribe();
  }
}
