import { Component, EventEmitter, Output } from "@angular/core";

// Configurations
import { liveProviderCheckListPreferredOrderConfigurations } from "src/app/configurations/main.configurations";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-provider-filter",
  templateUrl: "./provider-filter.component.html",
  styleUrls: ["./provider-filter.component.scss"],
})
export class ProviderFilterComponent {
  @Output() callOnProvidersChanged: EventEmitter<any> = new EventEmitter();
  liveProviderList: any;
  isLoading: boolean = true;
  checkedProviderNames: any[] = [];
  constructor(
    private utility: UtilityService,
    private gamesFilterPipe: GamesFilterPipe,
    private gameGroupsService: GameGroupsService
  ) {
    this.prepareLiveProviderList();
  }

  prepareLiveProviderList() {
    Promise.resolve(this.gameGroupsService.getGameGroupGamesp()).then(
      (games) => {
        this.isLoading = false;
        if (games && games.length > 0) {
          games = JSON.parse(JSON.stringify(games));
          games = this.gamesFilterPipe.transform(games, {
            typeOfGames: "live-game",
          });
          this.liveProviderList = this.getPreferredSortedOrder(
            this.utility.getProviderList(games)
          );
        } else {
          this.liveProviderList = [];
        }
      }
    );
  }

  changeSelection() {
    this.checkedProviderNames = [];
    this.liveProviderList.forEach((value, index) => {
      if (!value.isChecked) {
        this.checkedProviderNames.push(value.vendorCode);
      }
    });
    this.callOnProvidersChanged.emit(this.checkedProviderNames);
  }

  getPreferredSortedOrder(providerList) {
    const providerPreferredOrder = liveProviderCheckListPreferredOrderConfigurations;
    return providerList.sort(function (object1, object2) {
      return (
        providerPreferredOrder.indexOf(object1["vendorCode"]) -
        providerPreferredOrder.indexOf(object2["vendorCode"])
      );
    });
  }
}
