import { CommonModule, DecimalPipe, TitleCasePipe } from "@angular/common";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TranslateModule } from "@ngx-translate/core";
import { NgModule } from "@angular/core";

// Components
import { UnclaimedFreeSpinRewardCardComponent } from "src/app/pages/rewards/reward-card/unclaimed-free-spin-reward-card/unclaimed-free-spin-reward-card.component";
import { ReleaseRestrictRewardComponent } from "src/app/pages/rewards/reward-card/deposit-reward-card/release-restrict-reward/release-restrict-reward.component";
import { RewardBonusTermsCondtionsComponent } from "src/app/modules/rewards/components/reward-bonus-terms-condtions/reward-bonus-terms-condtions.component";
import { CashoutRewardComponent } from "src/app/pages/rewards/reward-card/deposit-reward-card/cashout-reward/cashout-reward.component";
import { CashbackPromoRewardComponent } from "src/app/pages/rewards/reward-card/cashback-promo-reward/cashback-promo-reward.component";
import { FreeSpinRewardCardComponent } from "src/app/pages/rewards/reward-card/free-spin-reward-card/free-spin-reward-card.component";
import { DepositRewardCardComponent } from "src/app/pages/rewards/reward-card/deposit-reward-card/deposit-reward-card.component";
import { PayNPlayCashierComponent } from "src/app/modules/account/components/pay-n-play-cashier/pay-n-play-cashier.component";
import { QuickpickAmountComponent } from "src/app/modules/account/components/quickpick-amount/quickpick-amount.component";
import { SideMenuModalComponent } from "src/app/modules/navigation/components/side-menu-modal/side-menu-modal.component";
import { SubscribeOffersComponent } from "src/app/modules/shared/components/subscribe-offers/subscribe-offers.component";
import { CountdownTimerComponent } from "src/app/modules/shared/components/countdown-timer/countdown-timer.component";
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";
import { PageNotFoundComponent } from "src/app/modules/shared/components/page-not-found/page-not-found.component";
import { PaymentBonusComponent } from "src/app/modules/rewards/components/payment-bonus/payment-bonus.component";
import { ButtonLoaderComponent } from "src/app/modules/shared/components/button-loader/button-loader.component";
import { CustomBannerComponent } from "src/app/modules/banner/components/custom-banner/custom-banner.component";
import { LoaderComponent } from "src/app/modules/shared/components/loader/loader.component";

// Directives
import { InputCurrencyPlacementDirective } from "src/app/modules/shared/directives/input-currency-placement.directive";
import { PayNPlayCashierDirective } from "src/app/modules/shared/directives/pay-n-play-cashier.directive";
import { SwiperResizeDirective } from "src/app/modules/shared/directives/swiper-resize.directive";
import { NumberDirective } from "src/app/modules/shared/directives/number.directive";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperModule } from "ngx-swiper-wrapper";
import { AccordionModule } from "ngx-accordion";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";
import { SafeHtmlPipe } from "src/app/modules/shared/pipes/safe-html.pipe";

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.langpath,
    ".json?cb=" + new Date().getTime()
  );
}

@NgModule({
  declarations: [
    UnclaimedFreeSpinRewardCardComponent,
    RewardBonusTermsCondtionsComponent,
    InputCurrencyPlacementDirective,
    ReleaseRestrictRewardComponent,
    CashbackPromoRewardComponent,
    FreeSpinRewardCardComponent,
    DepositRewardCardComponent,
    PayNPlayCashierDirective,
    PayNPlayCashierComponent,
    SubscribeOffersComponent,
    QuickpickAmountComponent,
    FormValidationComponent,
    CountdownTimerComponent,
    CashoutRewardComponent,
    SideMenuModalComponent,
    CustomBannerComponent,
    PageNotFoundComponent,
    SwiperResizeDirective,
    ButtonLoaderComponent,
    PaymentBonusComponent,
    CurrencyFormatPipe,
    LoaderComponent,
    GamesFilterPipe,
    NumberDirective,
    SafeHtmlPipe,
  ],
  entryComponents: [
    PayNPlayCashierComponent,
    SubscribeOffersComponent,
    QuickpickAmountComponent,
  ],
  imports: [
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    AccordionModule,
    CommonModule,
    SwiperModule,
    FormsModule,
    MbscModule,
  ],
  providers: [
    CustomBannerService,
    CurrencyFormatPipe,
    GamesFilterPipe,
    TitleCasePipe,
    DecimalPipe,
  ],
  exports: [
    UnclaimedFreeSpinRewardCardComponent,
    RewardBonusTermsCondtionsComponent,
    InputCurrencyPlacementDirective,
    ReleaseRestrictRewardComponent,
    CashbackPromoRewardComponent,
    FreeSpinRewardCardComponent,
    DepositRewardCardComponent,
    PayNPlayCashierDirective,
    PayNPlayCashierComponent,
    SubscribeOffersComponent,
    QuickpickAmountComponent,
    FormValidationComponent,
    CountdownTimerComponent,
    CashoutRewardComponent,
    SideMenuModalComponent,
    CustomBannerComponent,
    PageNotFoundComponent,
    SwiperResizeDirective,
    ButtonLoaderComponent,
    PaymentBonusComponent,
    CurrencyFormatPipe,
    HttpClientModule,
    LoaderComponent,
    TranslateModule,
    AccordionModule,
    GamesFilterPipe,
    NumberDirective,
    CommonModule,
    SwiperModule,
    SafeHtmlPipe,
    DecimalPipe,
  ],
})
export class SharedModule {}
