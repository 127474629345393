import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  AfterViewInit,
  HostListener,
  Component,
  OnDestroy,
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-live-casino",
  templateUrl: "./live-casino.component.html",
  styleUrls: ["./live-casino.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LiveCasinoComponent implements AfterViewInit, OnDestroy {
  activeLobbyName: string;
  activeLobbyData;
  activeGroupData: any;
  activeLobbyProviderData;
  games;
  isLoading: boolean = false;
  lastPlayedGames;
  lobbyGameGroups;
  finalGames;
  finalLobbyGames;
  filterByData: {
    typeOfGames: string;
    unCheckedProviders?: string[];
  } = {
    typeOfGames: "live-game",
    unCheckedProviders: [],
  };
  langCodeSubscription: Subscription;
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  logoutCompleteSubscription: Subscription;
  windowType: string;
  lastedPlayedLiveGamesSub: Subscription;
  isLastPlayedLiveGamesExist: boolean = false;

  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }
  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  constructor(
    private gamesFilterPipe: GamesFilterPipe,
    private gameGroupsService: GameGroupsService,
    private utility: UtilityService,
    private translationService: TranslationService,
    public commonService: CommonService
  ) {
    this.addThemeChangeClass();
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langCode) => {
        this.updateSEOContent();
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        this.getLobbyGroupsAndGames(true);
      }
    );
    this.logoutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
        this.isLastPlayedLiveGamesExist = false;
        this.getLobbyGroupsAndGames(true);
      }
    );
    this.lastedPlayedLiveGamesSub = this.gameGroupsService.isLastPlayedLiveGamesExistSb$.subscribe(
      (isExist) => {
        this.isLastPlayedLiveGamesExist = isExist;
      }
    );
  }

  ngAfterViewInit() {
    this.updateSEOContent();
    setTimeout(() => {
      this.getWindowType();
    }, 100);
  }

  updateSEOContent() {
    this.utility.setSEO({
      metaTitle: this.translationService.instant(
        "SEO.liveCasinoPage_metaTitle"
      ),
      metaDescription: this.translationService.instant(
        "SEO.liveCasinoPage_metaDescription"
      ),
      metaTags: this.translationService.instant("SEO.liveCasinoPage_metaTag"),
      title: this.translationService.instant("SEO.liveCasinoPage_title"),
    });
    this.commonService.setCanonicalURL("liveCasinoPage");
  }

  getLobbyGroupsAndGames(isForce: boolean = false) {
    this.isLoading = true;
    let apiURL = [
      this.gameGroupsService.getLobbyListWithGameGroupsp(isForce),
      this.gameGroupsService.getGameGroupGamesp(isForce),
    ];
    if (this.isLoggedIn) {
      apiURL.push(this.gameGroupsService.getLastedPlayedGamesp(isForce));
    }
    Promise.all(apiURL).then(
      ([lobbyGameGroupsReps, gamesResp, lastPlayedResp]) => {
        this.lastPlayedGames = _.clone(lastPlayedResp);
        /**To break object referance we using below syntax because _clone works only
         * one level of nested Objects..
         */
        this.lobbyGameGroups = JSON.parse(JSON.stringify(lobbyGameGroupsReps));
        this.games = JSON.parse(JSON.stringify(gamesResp));
        this.updateMetaTagBasedOnLobby();
        let gameGGroupfilterByLobby;
        let lobbyGGroupsWithGames;
        if (this.lobbyGameGroups && this.lobbyGameGroups.length > 0) {
          gameGGroupfilterByLobby = this.utility.getGameGroupsByLobby(
            _.clone(this.lobbyGameGroups),
            this.activeLobbyName
          );
        }
        if (
          !_.isEmpty(gameGGroupfilterByLobby) &&
          this.activeLobbyName &&
          this.games &&
          this.games.length > 0
        ) {
          lobbyGGroupsWithGames = this.gameGroupsService.processGameGroupGames(
            gameGGroupfilterByLobby,
            this.games,
            this.lastPlayedGames
          );
        }
        if (lobbyGGroupsWithGames && this.games) {
          this.setGroupGameData(lobbyGGroupsWithGames, this.games);
          this.setProviderLobbiesData(lobbyGGroupsWithGames);
        }
      }
    );
  }

  updateMetaTagBasedOnLobby() {
    if (this.lobbyGameGroups && this.activeLobbyName) {
      this.activeLobbyData = this.utility.getLobbyDataByLobbyName(
        this.lobbyGameGroups,
        this.activeLobbyName
      );
    }
  }

  onSelectedLobby(name) {
    this.activeLobbyName = name;
    if (this.activeLobbyName) {
      this.getLobbyGroupsAndGames();
    }
  }

  setGroupGameData(lobbyGGroupsWithGames, games) {
    this.activeGroupData = { games: [] };
    if (this.activeLobbyName === "all-live" && games && games.length > 0) {
      this.activeGroupData["games"] = games;
    } else if (lobbyGGroupsWithGames && lobbyGGroupsWithGames.length > 0) {
      if (this.activeLobbyName === "play-again") {
        this.activeGroupData = this.filterGroupByAssetType(
          lobbyGGroupsWithGames,
          "default-live-casino"
        );
      } else {
        this.activeGroupData = this.filterGroupByAssetType(
          lobbyGGroupsWithGames,
          "default-live-casino"
        );
      }
    } else {
      this.finalGames = [];
      this.activeGroupData["games"] = [];
    }
    this.prepareGroupGameData();
  }

  prepareGroupGameData() {
    if (this.activeGroupData && this.activeGroupData["games"].length > 0) {
      this.finalGames = this.applyFilters(this.activeGroupData["games"]);
      if (this.activeLobbyName !== "all-live") {
        this.finalGames = this.utility.sortGameByCountryOrder(this.finalGames);
      }
    } else {
      this.finalGames = [];
    }
    this.isLoading = false;
  }

  setProviderLobbiesData(lobbyGGroupsWithGames) {
    /**
     * This active Lobby filter code is common
     * for all the categories
     * */
    if (lobbyGGroupsWithGames && lobbyGGroupsWithGames.length > 0) {
      this.activeLobbyProviderData = this.filterGroupByAssetType(
        lobbyGGroupsWithGames,
        "live-lobby-provider"
      );
    } else {
      this.activeLobbyProviderData = {};
    }
    this.prepareProviderLobbiesData();
  }

  prepareProviderLobbiesData() {
    if (
      this.activeLobbyProviderData &&
      this.activeLobbyProviderData["games"] &&
      this.activeLobbyProviderData["games"].length > 0
    ) {
      this.finalLobbyGames = this.utility.sortGameByCountryOrder(
        this.applyFilters(this.activeLobbyProviderData["games"])
      );
    } else {
      this.finalLobbyGames = [];
    }
  }

  filterGroupByAssetType(lobbyGGroupsWithGames, assetType) {
    let groupData = _.filter(lobbyGGroupsWithGames, (data) => {
      if (data && data["game_asset_style"] === assetType) {
        return data;
      }
    });
    return groupData.length > 0 ? groupData[0] : { games: [] };
  }

  applyFilters(games) {
    return this.gamesFilterPipe.transform(games, this.filterByData);
  }

  onProviderListChanged(data) {
    this.filterByData["unCheckedProviders"] = data;
    this.prepareGroupGameData();
    this.prepareProviderLobbiesData();
  }

  /**Yet to work on it still requirement is pending from CMS side */
  // onBetRangeChanged(data){}

  addThemeChangeClass() {
    this.commonService.broadCastIsLiveCasinoPage(true);
  }
  removeThemeChangeCalss() {
    this.commonService.broadCastIsLiveCasinoPage(false);
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "desktop";
    } else {
      this.windowType = "mobile";
    }
  }

  ngOnDestroy() {
    this.removeThemeChangeCalss();
    this.loginCompleteSubscription.unsubscribe();
    this.logoutCompleteSubscription.unsubscribe();
    this.lastedPlayedLiveGamesSub.unsubscribe();
  }
}
