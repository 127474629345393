import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Output,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-deposit-reward-card",
  templateUrl: "./deposit-reward-card.component.html",
  styleUrls: ["./deposit-reward-card.component.scss"],
})
export class DepositRewardCardComponent implements OnChanges, OnDestroy {
  @Input() bonusDetails;
  @Input() callingFrom;
  @Input() rewardSize;
  /**this property is required only for payment bonus */
  @Input() selectedBonus;
  @Output() callOpenRewardTCPopUp: EventEmitter<any> = new EventEmitter();
  @Output() callOnNavigateToDeposit: EventEmitter<any> = new EventEmitter();
  @Output() callOnBonusSelected: EventEmitter<any> = new EventEmitter();

  gamesUrlPath: string = environment.gamesUrlPath;
  bonusType: string;
  langCodeSub: Subscription;
  isRealUser: boolean = false;
  currencyCodeSub: Subscription;
  currencyCode: string;
  currencySymbolSubscription: Subscription;
  currencySymbol: string;
  imgixParams: string;

  constructor(
    private utility: UtilityService,
    private commonService: CommonService,
    private userDetailsService: UserDetailsService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.currencyCodeSub = this.userDetailsService.currencyCodeSb$.subscribe(
      (curreny) => {
        this.currencyCode = curreny;
      }
    );
    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );
    if (this.userDetailsService.getUserProfileDetails()) {
      this.isRealUser =
        this.userDetailsService.getUserProfileDetails().userStatus === "real"
          ? true
          : false;
    }
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["bonusDetails"]) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["rewardSize"]) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }
    if (changes["selectedBonus"]) {
      this.selectedBonus = changes["selectedBonus"].currentValue;
    }
  }

  selectBonusHanlder(bonus) {
    if (
      this.selectedBonus &&
      this.selectedBonus["bonusCode"] === bonus["bonusCode"]
    ) {
      this.selectedBonus = {};
      this.paynplayCashierService.setActiveDepositBonus({});
      this.callOnBonusSelected.emit({});
    } else {
      this.selectedBonus = bonus;
      this.paynplayCashierService.setActiveDepositBonus(this.selectedBonus);
      this.callOnBonusSelected.emit(bonus);
    }
  }

  openRewardTCPopUp(event) {
    this.callOpenRewardTCPopUp.emit(event);
  }

  addBonus(bonusData) {
    this.paynplayCashierService.setActiveDepositBonus(bonusData);
    if (["pnp-cashier"].indexOf(this.callingFrom) > -1) {
      this.callOnNavigateToDeposit.emit();
    } else {
      this.initiateDeposit();
    }
  }

  initiateDeposit() {
    this.utility.closeAccountComponent();
    this.paynplayCashierService.broadCastInitiateDeposit({
      callingFrom: this.callingFrom,
    });
  }

  ngOnDestroy() {
    this.currencyCodeSub.unsubscribe();
    this.currencySymbolSubscription.unsubscribe();
  }
}
