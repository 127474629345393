import { Observable, throwError, Subject, BehaviorSubject, of } from "rxjs";
import { map, catchError, pairwise, filter } from "rxjs/operators";
import { Router, RoutesRecognized } from "@angular/router";
import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";
import {
  seoLanguageCodeUpdateConfigurations,
  localToCmsLanguageConfigurations,
  currencyMappingConfigurations,
  hrefLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { MainService } from "src/app/services/main-service/main.service";
import { SocketService } from "src/app/services/socket/socket.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Injectable()
export class CommonService {
  langCode: string;
  profileCountryDetails: any;
  sessionLimitInterval: any;
  activeAccountViewInfo: any;
  activeAccountTab: any;
  isKycBlocked: boolean = true;
  previousURL: any;
  previousComponentURL: any;
  kycDetailsToUnblock = {
    address: false,
    identity: false,
    paymentInstrument: false,
    sourceOfIncome: false,
    enableKyc: false,
  };

  constructor(
    private mainService: MainService,
    private translationService: TranslationService,
    private userDetailService: UserDetailsService,
    private router: Router,
    private socketService: SocketService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.translationService.langCodeSb$.subscribe((langCode) => {
      this.langCode = langCode;
    });
    this.loginComplete$.subscribe((isLoggedIn) => {
      Promise.all([
        this.getUserBalanceByPockets(),
        this.getProfileBalanceCurrency(),
      ]).then();
    });

    this.logOutComplete$.subscribe((data) => {
      this.kycDetailsToUnblock = {
        address: false,
        identity: false,
        paymentInstrument: false,
        sourceOfIncome: false,
        enableKyc: false,
      };
      //this.setIsKycBlocked(true);
    });

    /**Game window back navigation handling related code*/
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        // let urlAfterRedirect = (events[0].urlAfterRedirects).split("#")[0];
        let urlAfterRedirect = decodeURIComponent(events[0].urlAfterRedirects);
        let temp = this.getDecodedCurrentPath().split("/");
        let trans = this.translationService.instant("url.game").toLowerCase();
        if (temp.length > 2 && temp[2] != trans) {
          // console.log(true);
          this.previousComponentURL = urlAfterRedirect;
          if (temp[2] != "studio") {
            this.previousURL = this.previousComponentURL;
          }
        } else if (temp.length == 2) {
          this.previousComponentURL = undefined;
          this.previousURL = undefined;
        }
      });
  }

  /**
   * triggers after succesfull login
   */

  public loginComplete = new Subject<boolean>();
  public loginComplete$: Observable<
    boolean
  > = this.loginComplete.asObservable();

  broadCastLoginSuccess(islogin: boolean) {
    this.loginComplete.next(islogin);
  }

  /**
   * Triggers after succesfull logout
   */

  public logOutComplete = new Subject<boolean>();
  public logOutComplete$: Observable<
    boolean
  > = this.logOutComplete.asObservable();

  broadCastIsLoggedOut(islogin: boolean) {
    this.logOutComplete.next(islogin);
  }

  /**Information related to socket is stored her
   * we use this data in socket service ts file
   */
  public socketDetails;
  setSocketDetails(socketInfo) {
    this.socketDetails = socketInfo;
  }

  getSocketDetails() {
    return this.socketDetails;
  }

  private activeAccountView = new Subject<any>();
  public activeAccountView$ = this.activeAccountView.asObservable();

  /**left menu active tab related subject Observable */

  private activeLeftMenuLobby = new BehaviorSubject<string>("casino");
  public activeLeftMenuLobby$ = this.activeLeftMenuLobby.asObservable();

  broadCastActiveLeftMenuLobby(name: string) {
    this.activeLeftMenuLobby.next(name);
  }

  private activeLeftMenu = new Subject<any>();
  public activeLeftMenu$ = this.activeLeftMenu.asObservable();

  broadCastActiveLeftMenu(name) {
    this.activeLeftMenu.next(name);
  }

  activeLobbyName: string;

  setActiveLobby(name) {
    this.activeLobbyName = name;
  }

  getActiveLobby() {
    return this.activeLobbyName;
  }

  navigateAfterLogin: any = {};
  setNavigateAfterLogin(navigateAfterLogin) {
    this.navigateAfterLogin = navigateAfterLogin;
  }
  getNavigateAfterLogin() {
    return this.navigateAfterLogin;
  }

  private toggleAccountMenu = new Subject<boolean>();
  public toggleAccountMenu$ = this.toggleAccountMenu.asObservable();

  private toggleComponentWrap = new Subject<boolean>();
  public toggleComponentWrap$ = this.toggleComponentWrap.asObservable();

  private sessionTimeUpdated = new Subject<boolean>();
  public sessionTimeUpdated$ = this.sessionTimeUpdated.asObservable();

  broadCastSessionUpdated(flag) {
    this.sessionTimeUpdated.next(flag);
  }

  broadCastAccountMenuStatus(flag) {
    this.toggleAccountMenu.next(flag);
  }

  broadCastComponentWrapStatus(flag) {
    this.toggleComponentWrap.next(flag);
  }

  broadCastActiveAcountMenu(tab) {
    this.setActiveAccountMenu(tab);
    this.activeAccountView.next(tab);
  }

  setActiveAccountMenu(activeMenu) {
    this.activeAccountTab = activeMenu;
  }

  getActiveAccountMenu() {
    return this.activeAccountTab;
  }

  private userAccountKycStatus = new Subject<boolean>();
  public userAccountKycStatus$ = this.userAccountKycStatus.asObservable();

  broadCastAccountKycStatus(kycData) {
    this.userAccountKycStatus.next(kycData);
  }

  setUserAccountKycStatus(kycData) {
    this.userAccountKycStatus = kycData;
  }

  getUserAccountKycStatus() {
    return this.userAccountKycStatus;
  }

  private updateActivePage = new Subject<any>();
  public updateActivePage$ = this.updateActivePage.asObservable();

  broadCastActivePage(pageName) {
    this.updateActivePage.next(pageName);
  }

  isCasinoPageSearchEnabled: boolean = false;
  private searchEnabledStatus = new Subject<any>();
  public searchEnabledStatus$ = this.searchEnabledStatus.asObservable();

  broadSearchEnabledStatus(status) {
    this.setCasinoPageSearchEnabledStatus(status);
    this.searchEnabledStatus.next(status);
  }

  setCasinoPageSearchEnabledStatus(status) {
    this.isCasinoPageSearchEnabled = status;
  }

  getCasinoPageSearchEnabledStatus() {
    return this.isCasinoPageSearchEnabled;
  }

  /**ApI calls....*/

  validateUniqueness(fieldToValidate): Observable<any> {
    let url;
    _.each(fieldToValidate, function (value, key) {
      url =
        key == "txtNickname"
          ? "/ajax/registration/isUniqueNickname"
          : "/ajax/registration/isUniqueEmail";
    });
    return this.mainService.psPost(url, fieldToValidate);
  }

  countryBlockCallQueue = [];
  getCountryBlockData() {
    return new Promise((resolve, reject) => {
      if (this.countryBlockCallQueue.length > 0) {
        this.countryBlockCallQueue.push(resolve);
      } else {
        this.countryBlockCallQueue.push(resolve);
        Promise.resolve(this.mainService.getCountryBlockData()).then((data) => {
          for (let callback in this.countryBlockCallQueue) {
            this.countryBlockCallQueue[callback](data);
          }
          this.countryBlockCallQueue = [];
        });
      }
    });
  }

  profileBalanceCurrencyCallQueue = [];
  getProfileBalanceCurrency() {
    return new Promise((resolve, reject) => {
      if (this.profileBalanceCurrencyCallQueue.length > 0) {
        this.profileBalanceCurrencyCallQueue.push(resolve);
      } else {
        this.profileBalanceCurrencyCallQueue.push(resolve);
        Promise.resolve(this.mainService.getProfileBalanceCurrency())
          .then((profileBalanceCurrency) => {
            if (profileBalanceCurrency) {
              // this.userDetailService.setUserBalanceDetails(profileBalanceCurrency['profile']['balanceDetails']);
              this.userDetailService.setCurrencyCode(
                profileBalanceCurrency["currency"].code
              );
              this.userDetailService.setCurrencySymbol(
                profileBalanceCurrency["currency"].symbol
              );
              this.userDetailService.setUserCurrencyDetails(
                profileBalanceCurrency["currency"]
              );
              this.userDetailService.setUserProfileDetails(
                profileBalanceCurrency["profile"]
              );
              sessionStorage.setItem(
                "_player",
                btoa(profileBalanceCurrency["profile"]["playerID"])
              );
            }
            for (let callback in this.profileBalanceCurrencyCallQueue) {
              this.profileBalanceCurrencyCallQueue[callback](
                profileBalanceCurrency
              );
            }
            this.profileBalanceCurrencyCallQueue = [];
            return profileBalanceCurrency;
          })
          .catch((error) => {
            return {};
          });
      }
    });
  }

  getUserData(): Observable<any> {
    return this.mainService.getUserData().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  doProfileUpdate(requestObj): Observable<any> {
    return this.mainService.doProfileUpdate(requestObj).pipe(
      map((response) => {
        if (response) {
          this.userDetailService.setUserProfileDetails(response);
        }
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getProfileCountryDetails(): Observable<any> {
    if (!_.isEmpty(this.profileCountryDetails)) {
      return of(this.profileCountryDetails);
    } else {
      return this.mainService.getProfileCountryDetails().pipe(
        map((response) => {
          this.profileCountryDetails = response;
          return response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }
  getProfileData(): Observable<any> {
    return this.mainService.psGet(endpointConfigurations.getData_url).pipe(
      map((profileDataResponse) => {
        if (profileDataResponse && !_.isEmpty(profileDataResponse)) {
          this.userDetailService.setUserProfileDetails(profileDataResponse);
          sessionStorage.setItem(
            "_player",
            btoa(profileDataResponse["playerID"])
          );
          localStorage.setItem("kan_user", "true");
        }
        return profileDataResponse;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  forceGetUserProfileDetails(): Observable<any> {
    return this.mainService.psGet(endpointConfigurations.getBalance_url).pipe(
      map((balanceDetails) => {
        if (balanceDetails) {
          this.userDetailService.setUserBalanceDetails(balanceDetails);
        }
        return balanceDetails;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  /**Here we will logout user..
   * even Logout api fails by removing active user &
   * active session from sessionStorage
   * */

  doLogout() {
    if (this.getActiveAccountMenu()) {
      this.broadCastActiveAcountMenu("");
    }
    localStorage.removeItem("kan_user");
    sessionStorage.removeItem("session");
    sessionStorage.removeItem("_player");
    localStorage.removeItem("loggedInTime");
    localStorage.removeItem("isLoggedIn");
    this.userDetailService.resetAllDetails();

    this.mainService.psPost(endpointConfigurations.dologout_url, {}).subscribe((data) => {
      this.router.navigate([this.langCode + "/casino"]);
      this.setCurrencyByLocality();
      this.broadCastIsLoggedOut(true);
    });
  }

  loginStatusCallQueue = [];
  getLoginStatus() {
    return new Promise((resolve, reject) => {
      if (this.loginStatusCallQueue.length > 0) {
        this.loginStatusCallQueue.push(resolve);
      } else {
        this.loginStatusCallQueue.push(resolve);
        Promise.resolve(this.mainService.getLoginStatus())
          .then((data) => {
            if (data && data["status"] === true) {
              this.socketService.connectToScoket(
                data["riverplayUrl"],
                data["rvpSessionId"],
                true
              );
              localStorage.setItem("kan_user", "true");
              this.broadCastLoginSuccess(true);
              //this double check is need for one different case.(& due to deploy in response)
              this.setSocketDetails({
                message: "",
                rvpSessionId: data["rvpSessionId"],
                socketUrl: data["riverplayUrl"],
                partnerID: data["partnerId"],
              });
            } else {
              sessionStorage.removeItem("user");
              sessionStorage.removeItem("session");
            }
            for (let callback in this.loginStatusCallQueue) {
              this.loginStatusCallQueue[callback](data);
            }
            this.loginStatusCallQueue = [];
          })
          .catch((error) => {
            return [];
          });
      }
    });
  }

  setSessionVariable(sessionKey, sessionObj) {
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionObj));
  }

  sessionContinue() {
    let session = JSON.parse(sessionStorage.getItem("session"));
    if (session && session["sessionLimit"]) {
      this.setSessionVariable("session", {
        sessionLimit: session["sessionLimit"],
        availableSessionLimit: session["sessionLimit"] * 60,
      });
      this.startLoginSession();
    } else {
      this.mainService
        .getResponsibleGamingLimits("session")
        .subscribe((data) => {
          if (data && data["limits"] && data["limits"]["overall"]) {
            let limits = data["limits"]["overall"]["value"];
            this.setSessionVariable("session", {
              sessionLimit: limits,
              availableSessionLimit: limits * 60,
            });
            this.startLoginSession();
          }
        });
    }
  }

  startLoginSession() {
    var self = this;
    this.broadCastSessionUpdated(true);
    if (!this.sessionLimitInterval) {
      this.sessionLimitInterval = setInterval(() => {
        let sessionLimit = JSON.parse(sessionStorage.getItem("session"));
        if (sessionLimit) {
          if (sessionLimit.availableSessionLimit - 1 > 0) {
            sessionLimit.availableSessionLimit =
              sessionLimit.availableSessionLimit - 1;
            sessionStorage.setItem("session", JSON.stringify(sessionLimit));
          } else {
            clearInterval(self.sessionLimitInterval);
            this.sessionLimitInterval = undefined;
            setTimeout(() => {
              document.getElementById("sessionPopup").style.display = "flex";
              document.body.classList.add("overflow-hidden");
            }, 100);
          }
        } else {
          clearInterval(self.sessionLimitInterval);
          this.sessionLimitInterval = undefined;
          console.log("clear Interval");
        }
      }, 1000);
    }
  }

  private quickDepositToggle = new Subject<boolean>();
  public quickDepositToggle$ = this.quickDepositToggle.asObservable();

  broadcastQuickDepositToggle(flag) {
    this.quickDepositToggle.next(flag);
  }

  private pendingWithdrawCancel = new Subject<boolean>();
  public pendingWithdrawCancel$ = this.pendingWithdrawCancel.asObservable();

  broadcastPendingWithdrawCancel(flag) {
    this.pendingWithdrawCancel.next(flag);
  }

  getUserKycLevelDetails(): Observable<any> {
    return this.mainService.getUserKycLevelDetails().pipe(
      map((useKycDetails) => {
        if (useKycDetails && useKycDetails.status === "SUCCESS") {
          let requiredDocs = useKycDetails["requiredDocs"];
          if (requiredDocs && requiredDocs.length > 0) {
            requiredDocs.filter((doc) => {
              if (useKycDetails["kycDetails"][doc]) {
                this.kycDetailsToUnblock[doc] = "vrfn_new"; // useKycDetails["kycDetails"][doc]["verificationStatus"];
                this.kycDetailsToUnblock.enableKyc = true;
              }
            });
          }
        }
        return this.kycDetailsToUnblock;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getZendeskToken(): Observable<any> {
    return this.mainService.getZendeskToken().pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  setCanonicalURL(multilingualUrlPath) {
    let urlPath;
    if (typeof multilingualUrlPath === "string") {
      /**we don't have scope for some url in CMS, so handling it from front end
       */
      switch (multilingualUrlPath) {
        case "homepage":
          multilingualUrlPath = {
            fi: "casino",
            et: "casino",
          };
          break;
        case "liveCasinoPage":
          multilingualUrlPath = {
            fi: "live-casino",
            et: "live-casino",
          };
          break;
        case "promotionspage":
          multilingualUrlPath = {
            fi: "kampanjat",
            et: "pakkumised",
          };
          break;
        case "faqpage":
          multilingualUrlPath = {
            fi: "faq",
            et: "faq",
          };
          break;
        case "studiopage":
          let path = this.getDecodedCurrentPath().replace(
            "/" + this.langCode + "/",
            ""
          );
          multilingualUrlPath = {
            fi: path,
            et: path,
          };
          break;
      }
    }

    if (multilingualUrlPath && Object.keys(multilingualUrlPath).length > 0) {
      urlPath = multilingualUrlPath[localToCmsLanguageConfigurations[this.langCode]];
      this.setCanonicalLink(urlPath);
      this.setAlternateLinks(multilingualUrlPath);
      this.setDefaultLink(multilingualUrlPath);
      document
        .querySelector("html")
        .setAttribute("lang", seoLanguageCodeUpdateConfigurations[this.langCode]);
    }
  }

  setCanonicalLink(urlPath) {
    // console.log("faq",this.document.querySelector(`link[hreflang='en-row']`));
    var canonicalLink: HTMLLinkElement =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (!canonicalLink) {
      const link: HTMLLinkElement = this.document.createElement("link");
      link.setAttribute("rel", "canonical");
      this.document.head.appendChild(link);
      link.setAttribute(
        "href",
        environment.siteUrl + "/" + this.langCode + "/" + urlPath
      );
      link.setAttribute("hreflang", hrefLanguageConfigurations[this.langCode]);
    } else {
      canonicalLink.setAttribute("rel", "canonical");
      canonicalLink.setAttribute(
        "href",
        environment.siteUrl + "/" + this.langCode + "/" + urlPath
      );
      canonicalLink.setAttribute(
        "hreflang",
        hrefLanguageConfigurations[this.langCode]
      );
    }
  }

  setAlternateLinks(multilingualUrlPath) {
    var alternatelink = this.document.querySelectorAll(`link[rel='alternate']`);
    if (alternatelink.length > 0) {
      alternatelink.forEach((removeExistedAlternateLink) =>
        removeExistedAlternateLink.remove()
      );
    }
    supportedLanguagesList().forEach((lang) => {
      const link: HTMLLinkElement = this.document.createElement("link");
      let urlPath = multilingualUrlPath[localToCmsLanguageConfigurations[lang]];
      link.setAttribute("rel", "alternate");
      this.document.head.appendChild(link);
      link.setAttribute(
        "href",
        environment.siteUrl + "/" + lang + "/" + urlPath
      );
      link.setAttribute("hreflang", hrefLanguageConfigurations[lang]);
    });
  }

  setDefaultLink(multilingualUrlPath) {
    const linkDefault: HTMLLinkElement = this.document.createElement("link");
    let urlPath =
      multilingualUrlPath[localToCmsLanguageConfigurations[environment.defaultLang]];
    linkDefault.setAttribute("rel", "alternate");
    this.document.head.appendChild(linkDefault);
    linkDefault.setAttribute(
      "href",
      environment.siteUrl + "/" + environment.defaultLang + "/" + urlPath
    );
    linkDefault.setAttribute("hreflang", "x-default");
  }

  countryCode: string;
  setCountryCode(countryCode: string): void {
    this.countryCode = countryCode;
  }

  getCountryCode(): string {
    return this.countryCode;
  }

  setCurrencyByLocality() {
    if (
      this.countryCode &&
      currencyMappingConfigurations &&
      currencyMappingConfigurations.hasOwnProperty(this.countryCode)
    ) {
      let currencyMapConfigClone = { ...currencyMappingConfigurations };
      this.userDetailService.setCurrencySymbol(
        currencyMapConfigClone[this.countryCode]["currencySymbol"]
      );
      this.userDetailService.setCurrencyCode(
        currencyMapConfigClone[this.countryCode]["currencyCode"]
      );
    } else {
      this.userDetailService.setCurrencySymbol(
        environment.defaultCurrencySymbol
      );
      this.userDetailService.setCurrencyCode(environment.defaultCurrencyCode);
    }
  }
  public updateActiveLobbyData = new Subject<any>();
  public updateActiveLobbyData$: Observable<
    any
  > = this.updateActiveLobbyData.asObservable();

  broadCastUpdateActiveLobby(data: any) {
    if (data && data["lobby"]) {
      this.activeLobbyName = data["lobby"];
    }
    this.updateActiveLobbyData.next(data);
  }

  private isLiveCasinoPage = new BehaviorSubject<any>(false);
  public isLiveCasinoPage$ = this.isLiveCasinoPage.asObservable();

  broadCastIsLiveCasinoPage(flag: boolean) {
    this.isLiveCasinoPage.next(flag);
  }

  updateUserCredentials(requestData) {
    return this.mainService.updateUserCredentails(requestData);
  }

  getZendeskRubikoDetails(userName): Observable<any> {
    return this.mainService.getZendeskRubikoDetails(userName).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  userBalanceByPocketsCallQueue = [];
  getUserBalanceByPockets() {
    return new Promise((resolve, reject) => {
      if (this.userBalanceByPocketsCallQueue.length > 0) {
        this.userBalanceByPocketsCallQueue.push(resolve);
      } else {
        this.userBalanceByPocketsCallQueue.push(resolve);
        Promise.resolve(this.mainService.getUserBalanceByPockets())
          .then((balancePockets) => {
            if (balancePockets) {
              this.userDetailService.setUserBalanceDetails(balancePockets);
            }
            for (let callback in this.userBalanceByPocketsCallQueue) {
              this.userBalanceByPocketsCallQueue[callback](balancePockets);
            }
            this.userBalanceByPocketsCallQueue = [];
            return balancePockets;
          })
          .catch((error) => {
            this.userBalanceByPocketsCallQueue = [];
            return {};
          });
      }
    });
  }

  getDecodedCurrentPath() {
    return decodeURIComponent(window.location.pathname);
  }
}
