import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  SimpleChange,
  Component,
  OnDestroy,
  OnChanges,
  OnInit,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-game-provider",
  templateUrl: "./game-provider.component.html",
  styleUrls: ["./game-provider.component.scss"],
})
export class GameProviderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() gameGroupData;
  @Input() callingFromlobby;
  providerList: any;
  isLoading: boolean = false;
  langCode: string;
  langCodeSubscription: Subscription;
  games: any;
  swiperLazyConfig: {
    totalNumberOfGamesExist: number;
    indexNumberOfGameTilesToshow: number;
    numberOfExtraGamesToLoadForSlide: number;
    forwardslideIndex: number;
    backwardslideIndex: number;
  } = {
    totalNumberOfGamesExist: 0,
    indexNumberOfGameTilesToshow: 14,
    numberOfExtraGamesToLoadForSlide: 7,
    forwardslideIndex: 0,
    backwardslideIndex: 0,
  };

  mediaUrlPath: string = environment.mediaUrlPath;
  imgixParams: string;

  constructor(
    private router: Router,
    private utility: UtilityService,
    private translationService: TranslationService
  ) {
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.imgixParams = this.utility.getImgixParams();
  }

  swiperProviders: SwiperConfigInterface = {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      2000: {
        slidesPerView: 7,
        slidesPerGroup: 7,
        spaceBetween: 16,
      },
      1599: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 16,
      },
      1365: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      767: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 8,
      },
      480: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 8,
      },
    },
  };

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["gameGroupData"]) {
      this.gameGroupData = changes["gameGroupData"].currentValue;
      this.games = this.gameGroupData["games"];
      this.setProviderList(this.games);
    }
    if (changes["callingFromlobby"]) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  setProviderList(games) {
    if (games && games.length > 0) {
      this.providerList = this.utility.getSortedUniqProviderList(games);
      if (this.providerList && this.providerList.length > 0) {
        this.swiperLazyConfig[
          "totalNumberOfGamesExist"
        ] = this.providerList.length;
      }
    }
  }

  getProviderList(games) {
    if (games) {
      this.providerList = [...new Set(games.map((game) => game.vendorCode))];
    }
  }

  navigateAllGamesPage(gameprovider?) {
    this.utility.navigateAllGamesPage(this.gameGroupData, gameprovider);
  }

  navigateToStudio(vendorDisplayName) {
    vendorDisplayName = this.utility.convertGameNameToUrl(vendorDisplayName);
    this.router.navigate([this.langCode + "/studio/" + vendorDisplayName]);
  }

  /**Function for Lazy loading of games goes here
   * on every next click of silde below function will be trigerred
   * & calculated how many game have to load amount that total games
   * present
   * This works on bases of swiperLazyConfig defined above...
   */
  onSwiperNextEvent() {
    if (this.swiperLazyConfig["backwardslideIndex"]) {
      this.swiperLazyConfig["backwardslideIndex"] =
        this.swiperLazyConfig["backwardslideIndex"] - 1;
      return;
    }
    if (
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] <
      this.swiperLazyConfig["totalNumberOfGamesExist"]
    ) {
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] =
        this.swiperLazyConfig["indexNumberOfGameTilesToshow"] +
        this.swiperLazyConfig["numberOfExtraGamesToLoadForSlide"];
      this.swiperLazyConfig["forwardslideIndex"] =
        this.swiperLazyConfig["forwardslideIndex"] + 1;
    }
  }

  onSwiperPrevEvent() {
    this.swiperLazyConfig["backwardslideIndex"] =
      this.swiperLazyConfig["backwardslideIndex"] + 1;
  }

  ngOnDestroy() {
    this.langCodeSubscription.unsubscribe();
  }
}
