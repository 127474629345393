import { MenuConfigurations } from "src/app/models/configurations/menu/menu-configuration.model";

export const menuConfigurations = (): MenuConfigurations => {
  const menuConfigurations: MenuConfigurations = {
    leftMenuConfig: [
      {
        icon: "icon-lobby icon-casino",
        linkText: "general.casino",
        navigateTo: "casino",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-live-casino",
        linkText: "general.live_casino",
        navigateTo: "live-casino",
        onlyAfterLogin: false,
      },
      {
        icon: "icon-lobby icon-casino",
        linkText: "rewards.rewards",
        navigateTo: "rewards",
        onlyAfterLogin: true,
      },
      {
        icon: "icon-lobby icon-casino",
        linkText: "promotion.promotions",
        navigateTo: "promotions",
        onlyAfterLogin: false,
      },
    ],
    casinoLobbyConfig: [
      {
        lobbyName: "Casino",
        filterKey: "casino",
        linkText: "general.popular",
        icon: "icon-popular",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Slots",
        filterKey: "slots",
        linkText: "general.slots",
        icon: "icon-slots",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Instant Wins",
        filterKey: "instant-wins",
        linkText: "general.instant_Wins",
        icon: "icon-instawins",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Table Games",
        filterKey: "table-games",
        linkText: "general.table_Games",
        icon: "icon-table-games",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "All Games",
        filterKey: "all",
        linkText: "general.allgames",
        icon: "icon-all-games",
        onlyAfterLogin: false,
      },
    ],
    liveCasinolobbyConfig: [
      {
        lobbyName: "All",
        filterKey: "all-live",
        linkText: "general.all",
        icon: "icon-popular",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Play Again",
        filterKey: "play-again",
        linkText: "livecasino.play_again",
        icon: "icon-slots",
        onlyAfterLogin: true,
      },
      {
        lobbyName: "Roulette",
        filterKey: "roulette",
        linkText: "livecasino.roulette",
        icon: "icon-table-games",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Games Shows",
        filterKey: "games-shows",
        linkText: "livecasino.game_shows",
        icon: "icon-all-games",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Blackjack",
        filterKey: "blackjack",
        linkText: "livecasino.blackjack",
        icon: "icon-all-games",
        onlyAfterLogin: false,
      },
      {
        lobbyName: "Baccarat",
        filterKey: "baccarat",
        linkText: "livecasino.baccarat",
        icon: "icon-all-games",
        onlyAfterLogin: false,
      },
    ],
    lobbyByPageName: {
      casinolobbies: ["casino", "slots", "instant-wins", "table-games", "all"],
      livecasinolobbies: [
        "all-live",
        "play-again",
        "roulette",
        "games-shows",
        "blackjack",
        "baccarat",
      ],
    },
  };

  return menuConfigurations;
};
