import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// Libraries
import * as _ from "underscore";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";

@Component({
  selector: "app-pending-withdrawal",
  templateUrl: "./pending-withdrawal.component.html",
  styleUrls: ["./pending-withdrawal.component.scss"],
})
export class PendingWithdrawalComponent implements OnInit, OnDestroy {
  pendingWithdrawals = [];
  currencySymbolSub: Subscription;
  currencySymbol;
  pedingWitdrawalSubs;
  cancelWithdrawSubs;
  isLoading = false;
  selectedWithdrawCancelTransaction;
  currencyCodeSub: Subscription;
  currencyCode: string;
  pendingWithdrawConfirmationPopup: boolean = false;
  constructor(
    private userDetailsService: UserDetailsService,
    private currencyFormatPipe: CurrencyFormatPipe,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.getPendingWithdrawals();
  }

  swiperPendingWithdraw: SwiperConfigInterface = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 12,
    observer: true,
    observeParents: true,
    watchOverflow: true,
    resistanceRatio: 0,
    pagination: {
      el: ".swipper-pagination",
      clickable: true,
    },
  };

  ngOnInit() {
    this.currencySymbolSub = this.userDetailsService.currencySymbolSb$.subscribe(
      (currencySymbol) => {
        this.currencySymbol = currencySymbol;
      }
    );

    this.currencyCodeSub = this.userDetailsService.currencyCodeSb$.subscribe(
      (currencyCode) => {
        this.currencyCode = currencyCode;
      }
    );
  }

  openConfirmationPopup(transaction) {
    this.selectedWithdrawCancelTransaction = transaction;
    this.pendingWithdrawConfirmationPopup = true;
  }

  closeConfirmationPopup() {
    this.pendingWithdrawConfirmationPopup = false;
  }

  amountCurrencyFormat(value) {
    if (value && this.currencyCode) {
      var regex = new RegExp("-|" + this.currencyCode + "| ", "gi");
      return this.currencyFormatPipe.transform(value.replace(regex, ""), false);
    } else {
      return value;
    }
  }

  getPendingWithdrawals() {
    this.isLoading = true;
    let pendingWithdrawalObject = {
      cashoutVerifiedtxnRequired: true,
    };
    this.pedingWitdrawalSubs = this.paynplayCashierService
      .getPendingCashOuts(pendingWithdrawalObject)
      .subscribe(
        (pendingWithdrawalData) => {
          this.isLoading = false;
          if (pendingWithdrawalData && pendingWithdrawalData.length > 0) {
            this.pendingWithdrawals = pendingWithdrawalData;
          } else {
            this.pendingWithdrawals = [];
          }
        },
        (rejectError) => {
          this.pendingWithdrawals = [];
          this.isLoading = false;
        }
      );
  }

  cancelTransaction(selectedWithdrawCancelTransaction) {
    if (selectedWithdrawCancelTransaction) {
      let cancelWithdrawObject = {
        txnId: selectedWithdrawCancelTransaction.txnId,
        txnAmount: selectedWithdrawCancelTransaction.amountInEcrCurrency,
      };
      this.closeConfirmationPopup();
      this.cancelWithdrawSubs = this.paynplayCashierService
        .revertWithdrawal(cancelWithdrawObject)
        .subscribe(
          (cancelWithdraw) => {
            if (cancelWithdraw && cancelWithdraw["status"] === "SUCCESS") {
              this.getPendingWithdrawals();
            } else {
              console.log("Unable to cancel Withdraw");
              this.isLoading = false;
            }
          },
          (rejectError) => {
            console.log("Unable to cancel Withdraw");
            this.isLoading = false;
          }
        );
    }
  }

  ngOnDestroy() {
    this.currencyCodeSub.unsubscribe();
    if (this.currencySymbolSub) {
      this.currencySymbolSub.unsubscribe();
    }
    if (this.pedingWitdrawalSubs) {
      this.pedingWitdrawalSubs.unsubscribe();
    }
    if (this.cancelWithdrawSubs) {
      this.cancelWithdrawSubs.unsubscribe();
    }
  }
}
