import { Subscription } from "rxjs";
import {
  HostListener,
  SimpleChange,
  Component,
  OnDestroy,
  OnChanges,
  ViewChild,
  OnInit,
  Input,
} from "@angular/core";

// Libraries
import * as _ from "underscore";
import {
  SwiperConfigInterface,
  SwiperDirective,
  SwiperComponent,
} from "ngx-swiper-wrapper";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-tag-game",
  templateUrl: "./tag-game.component.html",
  styleUrls: ["./tag-game.component.scss"],
})
export class TagGameComponent implements OnInit, OnChanges, OnDestroy {
  @Input() gameSpecificTag;
  isGamesToasterOpen: boolean = false;
  gameGroupsub: Subscription;
  allGames: any;
  allAvaialbleTags: any = [];
  activeTagGames: any;
  activeTag: string;
  isLoading: boolean = false;
  isLoggedIn: any;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  windowType: string;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;
  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  swiperLazyConfig: {
    totalNumberOfGamesExist: number;
    indexNumberOfGameTilesToshow: number;
    numberOfExtraGamesToLoadForSlide: number;
    forwardslideIndex: number;
    backwardslideIndex: number;
  } = {
    totalNumberOfGamesExist: 0,
    indexNumberOfGameTilesToshow: 28,
    numberOfExtraGamesToLoadForSlide: 14,
    forwardslideIndex: 0,
    backwardslideIndex: 0,
  };
  accountViewSub: Subscription;
  isTouchScreen: boolean = false;
  userActivity;

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }
  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }
  @HostListener("document:click")
  @HostListener("window:mousemove")
  refreshUserState() {
    if (this.isGamesToasterOpen) {
      this.checkUserSessionActivity();
    }
  }
  @HostListener("touchstart") onStart() {
    if (this.isGamesToasterOpen) {
      this.checkUserSessionActivity();
    }
  }
  constructor(
    private utility: UtilityService,
    private commonService: CommonService,
    private gameGroupsService: GameGroupsService
  ) {
    this.getGameGroupGames();
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
    this.accountViewSub = this.commonService.activeAccountView$.subscribe(
      (tabname) => {
        if (tabname === "menuOptions" && this.isGamesToasterOpen) {
          this.closeGamesToaster();
        }
      }
    );
  }
  ngOnInit(): void {
    this.getWindowType();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["gameSpecificTag"]) {
      this.gameSpecificTag = changes["gameSpecificTag"].currentValue;
      if (this.gameSpecificTag && this.gameSpecificTag.length > 0) {
        this.allAvaialbleTags = this.gameSpecificTag;
        this.openGameTagOnpageLoad();
      } else {
        this.allAvaialbleTags = [];
      }
    }
  }

  openGameTagOnpageLoad() {
    this.filterGamesByTagName(this.allAvaialbleTags[0]);
    setTimeout(() => {
      this.closeGamesToaster();
    }, 2000);
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
    if (window.matchMedia("(pointer: coarse)").matches) {
      this.isTouchScreen = true;
    } else {
      this.isTouchScreen = false;
    }
  }

  swiperTagFilter: SwiperConfigInterface;
  swiperTagButton: SwiperConfigInterface = {
    slidesPerView: "auto",
    slidesPerGroup: 2,
    freeMode: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    navigation: true,
    resistanceRatio: 0,
  };

  getGameGroupGames() {
    this.allAvaialbleTags = [];
    this.gameGroupsub = this.gameGroupsService
      .getGameGroupGames()
      .subscribe((games) => {
        if (games && games.length > 0) {
          this.allGames = games;
        } else {
          this.allGames = [];
        }
      });
  }

  filterGamesByTagName(tagName) {
    this.reinitializeSwiperLazyConfig();
    if (this.activeTag !== tagName) {
      this.activeTagGames = undefined;
      this.activeTag = tagName;
      this.isGamesToasterOpen = true;
      this.isLoading = true;
      if (this.activeTag === "last-played") {
        this.getLastPlayedGames();
      } else if (this.activeTag === "favourite") {
        this.getFavoriteGames();
      } else {
        this.activeTagGames = _.filter(this.allGames, (game) => {
          if (game && game["tagList"].length > 0) {
            return game["tagList"].indexOf(tagName) !== -1;
          }
        });
        if (this.activeTagGames && this.activeTagGames.length > 0) {
          this.swiperLazyConfig[
            "totalNumberOfGamesExist"
          ] = this.activeTagGames.length;
        }
        this.isLoading = false;
      }
    } else {
      this.isGamesToasterOpen = !this.isGamesToasterOpen;
      this.activeTag = "";
    }
  }

  getLastPlayedGames() {
    Promise.resolve(
      this.gameGroupsService
        .getLastedPlayedGamesp(true)
        .then((lastPlayedGames: any) => {
          if (lastPlayedGames && lastPlayedGames.length) {
            _.each(lastPlayedGames, (typeId) => {
              let game = _.filter(this.allGames, { beGameTypeId: typeId })[0];
              if (game && this.activeTagGames) {
                this.activeTagGames.push(game);
              } else if (game) {
                this.activeTagGames = [];
                this.activeTagGames.push(game);
              }
            });
            this.isLoading = false;
          } else if (this.activeTagGames && this.activeTagGames.length > 0) {
            this.isLoading = false;
          } else {
            this.activeTagGames = [];
            this.isLoading = false;
          }
          if (this.activeTagGames && this.activeTagGames.length > 0) {
            this.swiperLazyConfig[
              "totalNumberOfGamesExist"
            ] = this.activeTagGames.length;
          }
          // this.isLoading = false;
        })
    );
  }

  getFavoriteGames() {
    this.gameGroupsService.getFavoriteGames(true).subscribe((favoriteGames) => {
      if (
        favoriteGames &&
        favoriteGames["favorite"] &&
        favoriteGames["favorite"]["favoriteGamesList"].length > 0
      ) {
        _.each(
          favoriteGames["favorite"]["favoriteGamesList"],
          (favoriteGame) => {
            let game = _.findWhere(this.allGames, {
              beGameTypeId: favoriteGame["gameTypeId"],
            });
            if (game && this.activeTagGames) {
              this.activeTagGames.push(game);
            } else if (game) {
              this.activeTagGames = [];
              this.activeTagGames.push(game);
            }
          }
        );
        this.isLoading = false;
      } else {
        this.activeTagGames = [];
        this.isLoading = false;
      }
      if (this.activeTagGames && this.activeTagGames.length > 0) {
        this.swiperLazyConfig[
          "totalNumberOfGamesExist"
        ] = this.activeTagGames.length;
      }
    });
  }

  closeGamesToaster() {
    this.isGamesToasterOpen = false;
    this.activeTag = "";
  }

  reinitializeSwiperLazyConfig() {
    this.swiperTagFilter = {
      slidesPerView: 14,
      slidesPerGroup: 14,
      freeMode: true,
      navigation: true,
      watchSlidesVisibility: true,
      observer: true,
      observeParents: true,
      resistanceRatio: 0,
      breakpoints: {
        // breakpoints works like max-width css
        2500: {
          slidesPerView: 17,
          slidesPerGroup: 17,
        },

        1920: {
          slidesPerView: 14,
          slidesPerGroup: 14,
        },

        1600: {
          slidesPerView: 12,
          slidesPerGroup: 12,
        },
        1599: {
          slidesPerView: 10,
          slidesPerGroup: 10,
        },
        1024: {
          slidesPerView: 8,
          slidesPerGroup: 8,
        },
      },
    };

    this.swiperLazyConfig = {
      totalNumberOfGamesExist: 0,
      indexNumberOfGameTilesToshow: 28,
      numberOfExtraGamesToLoadForSlide: 14,
      forwardslideIndex: 0,
      backwardslideIndex: 0,
    };

    if (this.componentRef && this.componentRef.directiveRef) {
      this.componentRef.directiveRef.update();
    }
  }

  /**Function for Lazy loading games on click of Every silde..
   * This works on bases of swiperLazyConfig defined above...
   */
  onSwiperNextEvent() {
    if (this.swiperLazyConfig["backwardslideIndex"]) {
      this.swiperLazyConfig["backwardslideIndex"] =
        this.swiperLazyConfig["backwardslideIndex"] - 1;
      return;
    }
    if (
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] <
      this.swiperLazyConfig["totalNumberOfGamesExist"]
    ) {
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] =
        this.swiperLazyConfig["indexNumberOfGameTilesToshow"] +
        this.swiperLazyConfig["numberOfExtraGamesToLoadForSlide"];
      this.swiperLazyConfig["forwardslideIndex"] =
        this.swiperLazyConfig["forwardslideIndex"] + 1;
    }
  }

  onSwiperPrevEvent() {
    this.swiperLazyConfig["backwardslideIndex"] =
      this.swiperLazyConfig["backwardslideIndex"] + 1;
  }

  checkUserSessionActivity() {
    if (this.userActivity) {
      clearTimeout(this.userActivity);
    }
    if (this.isGamesToasterOpen) {
      this.userActivity = setTimeout(() => {
        this.closeGamesToaster();
      }, 5000);
    }
  }

  ngOnDestroy() {
    if (this.gameGroupsub) {
      this.gameGroupsub.unsubscribe();
    }
    this.accountViewSub.unsubscribe();
    this.loginCompleteSubscription.unsubscribe();
    this.logOutCompleteSubscription.unsubscribe();
  }
}
