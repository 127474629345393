import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-confirmidentity-kyc",
  templateUrl: "./confirmidentity-kyc.component.html",
  styleUrls: ["./confirmidentity-kyc.component.scss"],
})
export class ConfirmidentityKycComponent implements OnInit, OnDestroy {
  documentSendStatus: string;
  accountDocumentStatus: any;
  sendDocumentSubs: Subscription;
  kycLevelDetails;
  userKycLevelSubs: Subscription;
  isButtonDisabled: boolean;

  constructor(
    private utility: UtilityService,
    private translationService: TranslationService,
    private accountService: AccountService,
    private commonService: CommonService
  ) {
    this.getAccountVerficationStatus();
    this.getUserKycLevelDetails();
  }

  ngOnInit() {}

  updateStatusData = {
    nationalid: {
      message: "",
      status: "",
    },
    utilitybill: {
      message: "",
      status: "",
    },
    "proof-of-payment": {
      message: "",
      status: "",
    },
    "source-of-fund": {
      message: "",
      status: "",
    },
  };
  uploadDocument(data) {
    if (data) {
      let requestData = {
        file: data.event.target.files[0],
        type: data["type"],
      };
      this.isButtonDisabled = true;
      this.accountService.upLoadDocuments(requestData).subscribe((response) => {
        if (response && response["status"] === "success") {
          if (data["sourceOfIncome"]) {
            this.sendDocument(data, {
              listOfSources: data["sourceOfIncome"],
              fileType: "sourceOfIncome",
            });
          } else {
            this.sendDocument(data);
          }
        } else {
          this.failedDocumentHanlder(data, response);
        }
      });
    }
  }

  sendDocument(data, requestData?) {
    this.accountService.sendDocuments(requestData).subscribe((response) => {
      this.isButtonDisabled = false;
      if (response && response["status"] == "success") {
        if (data["type"] === "nationalid") {
          this.updateStatusData["nationalid"] = {
            message: this.translationService.instant(
              "confirmidentity.file_upload_successs"
            ),
            status: response["status"],
          };
        } else if (data["type"] === "utilitybill") {
          this.updateStatusData["utilitybill"] = {
            message: this.translationService.instant(
              "confirmidentity.file_upload_successs"
            ),
            status: response["status"],
          };
        } else if (data["type"] === "caf") {
          this.updateStatusData["proof-of-payment"] = {
            message: this.translationService.instant(
              "confirmidentity.file_upload_successs"
            ),
            status: response["status"],
          };
        } else {
          this.updateStatusData["source-of-fund"] = {
            message: this.translationService.instant(
              "confirmidentity.file_upload_successs"
            ),
            status: response["status"],
          };
        }
        (this.documentSendStatus = this.translationService.instant(
          "confirmidentity.doc_send_success"
        )),
          this.getAccountVerficationStatus();
      } else {
        this.documentSendStatus = this.translationService.instant(
          "confirmidentity.doc_send_fail"
        );
        this.failedDocumentHanlder(data, response);
      }
    });
  }

  failedDocumentHanlder(data, response) {
    this.isButtonDisabled = false;
    if (data["type"] === "nationalid") {
      this.updateStatusData["nationalid"] = {
        message: this.translationService.instant(
          "confirmidentity.file_upload_error"
        ),
        status: response["status"],
      };
    } else if (data["type"] === "utilitybill") {
      this.updateStatusData["utilitybill"] = {
        message: this.translationService.instant(
          "confirmidentity.file_upload_error"
        ),
        status: response["status"],
      };
    } else if (data["type"] === "caf") {
      this.updateStatusData["proof-of-payment"] = {
        message: this.translationService.instant(
          "confirmidentity.file_upload_error"
        ),
        status: response["status"],
      };
    } else {
      this.updateStatusData["source-of-fund"] = {
        message: this.translationService.instant(
          "confirmidentity.file_upload_error"
        ),
        status: response["status"],
      };
    }
  }

  getUserKycLevelDetails() {
    this.userKycLevelSubs = this.commonService
      .getUserKycLevelDetails()
      .subscribe((data) => {
        this.kycLevelDetails = data;
      });
  }
  getAccountVerficationStatus() {
    this.accountService.getAccountVerficationStatus().subscribe((data) => {
      if (data && data["documents"]) {
        this.accountDocumentStatus = {
          addressStatus: data["documents"]["addressStatus"],
          identityStatus: data["documents"]["identityStatus"],
          cafStatus: data["documents"]["cafStatus"],
          sourceOfIncomeStatus: data["documents"]["sourceOfIncomeStatus"],
        };
      }
    });
  }

  closeComponent(navigateTo?) {
    this.utility.closeAccountComponent(navigateTo);
  }

  ngOnDestroy() {
    if (this.sendDocumentSubs) {
      this.sendDocumentSubs.unsubscribe();
    }
    if (this.userKycLevelSubs) {
      this.userKycLevelSubs.unsubscribe();
    }
  }
}
