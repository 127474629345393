import { UrlSegment } from "@angular/router";

export function extractPathUtility(url: UrlSegment[]): string {
  return url[1].path;
}

export function lobbyRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["live-casino"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }

  return null;
}

export function allGamePageRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 3 || url.length === 4) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["casino"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }

  return null;
}

export function allGameComponentRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["all", "alle", "kaikki", "koek"];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }

  return null;
}

export function gamePageRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
  posParams: {
    gameId: UrlSegment;
  };
} {
  if (url.length === 3) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = ["game", "spiel", "spill", "peli", "maeng"];

    if (translationList.includes(path)) {
      return {
        consumed: url,
        posParams: {
          gameId: new UrlSegment(url[2].path, {}),
        },
      };
    }
  }

  return null;
}

export function promotionPageRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
} {
  if (url.length === 2) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = [
      "promotions",
      "aktionen",
      "kampanjer",
      "kampanjat",
      "pakkumised",
    ];

    if (translationList.includes(path)) {
      return { consumed: url };
    }
  }

  return null;
}

export function promotionContentRouteMatcher(
  url: UrlSegment[]
): {
  consumed: UrlSegment[];
  posParams: {
    promoUrl: UrlSegment;
  };
} {
  if (url.length === 3) {
    const path: string = extractPathUtility(url);

    let translationList: string[] = [
      "promotions",
      "aktionen",
      "kampanjer",
      "kampanjat",
      "pakkumised",
    ];

    if (translationList.includes(path)) {
      return {
        consumed: url,
        posParams: {
          promoUrl: new UrlSegment(url[2].path, {}),
        },
      };
    }
  }
  
  return null;
}
