import {
  SimpleChange,
  EventEmitter,
  ElementRef,
  Component,
  OnChanges,
  ViewChild,
  OnInit,
  Output,
  Input,
} from "@angular/core";

// Configurations
import { maxSupportedFileSizeInBytesConfigurations } from "src/app/configurations/main.configurations";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";

@Component({
  selector: "app-identity-proof",
  templateUrl: "./identity-proof.component.html",
  styleUrls: ["./identity-proof.component.scss"],
})
export class IdentityProofComponent implements OnInit, OnChanges {
  @Output() uploadIdenityProofFiles: EventEmitter<any> = new EventEmitter();
  @Input() uploadResponseData;
  @Input() isButtonDisabled: boolean;
  @ViewChild("identityProofCamera", { static: false })
  identityProofCamera: ElementRef;
  @ViewChild("identityProof", { static: false }) identityProof: ElementRef;

  identityProofData;
  sizeExceeded: string;

  constructor(private translationService: TranslationService) {}

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["uploadResponseData"]) {
      this.uploadResponseData = changes["uploadResponseData"].currentValue;
      setTimeout(() => {
        this.uploadResponseData = undefined;
      }, 5000);
      this.clearField();
    } else if (changes["isButtonDisabled"]) {
      this.isButtonDisabled = changes["isButtonDisabled"].currentValue;
    }
  }

  upload(event, callingFrom?) {
    this.sizeExceeded = undefined;
    if (event.target.files[0].size >= maxSupportedFileSizeInBytesConfigurations) {
      this.sizeExceeded = this.translationService.instant(
        "confirmidentity.file_size_alert"
      );
      this.clearField();
    } else {
      this.identityProofData = {
        event: event,
        fileName: event.target.files[0].name,
        type: "nationalid",
      };
    }
  }

  submitIndenityProof() {
    if (this.identityProofData) {
      this.uploadIdenityProofFiles.emit(this.identityProofData);
    }
  }
  clearField() {
    this.identityProofData = undefined;
    if (this.identityProofCamera) {
      this.identityProofCamera.nativeElement.value = "";
    }
    if (this.identityProof) {
      this.identityProof.nativeElement.value = "";
    }
    if (this.sizeExceeded) {
      setTimeout(() => {
        this.sizeExceeded = undefined;
      }, 5000);
    }
  }
}
