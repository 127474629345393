import { Subscription } from "rxjs";
import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  Output,
  Input,
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { RewardsService } from "src/app/pages/rewards/service/reward.service";

@Component({
  selector: "app-payment-bonus",
  templateUrl: "./payment-bonus.component.html",
  styleUrls: ["./payment-bonus.component.scss"],
})
export class PaymentBonusComponent implements OnChanges, OnDestroy {
  @Input() callingFrom;
  @Input() activeBonusData;
  @Input() rewardSize: string;

  @Output() callOnNavigateToDeposit: EventEmitter<any> = new EventEmitter();
  @Output() callOnSelectBonus: EventEmitter<any> = new EventEmitter();

  finalFilteredBonusData = [];
  gameGroupGameSub: Subscription;
  games: any;
  isloading: boolean = false;
  selectedBonusIndex: number;
  selectedBonus: any;
  currencyCode: string;
  avialableDepositBonusDetails;
  depositBonusDetails;
  selectedBonusCode;
  bonusIndex;
  constructor(
    private utility: UtilityService,
    private rewardsService: RewardsService,
    private gameGroupsService: GameGroupsService,
    private paynplayCashierService: PayNPlayCashierService,
    private userDetailsService: UserDetailsService
  ) {
    this.userDetailsService.currencyCodeSb$.subscribe((curreny) => {
      this.currencyCode = curreny;
    });
    this.getEligibleBonus();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["rewardSize"]) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }
    if (changes["activeBonusData"] && changes["activeBonusData"].currentValue) {
      this.selectedBonus = changes["activeBonusData"].currentValue;
      if (this.selectedBonus["bonusCode"]) {
        this.selectedBonusCode = this.selectedBonus["bonusCode"];
      }
    }
  }

  getEligibleBonus() {
    this.isloading = true;
    this.rewardsService
      .getEligibleBonus({ criteriaType: "DEPOSIT" })
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS" && data["eligibleBonusList"]) {
          this.isloading = false;
          this.finalFilteredBonusData = data["eligibleBonusList"];
          if (this.finalFilteredBonusData.length > 0) {
            this.processEligibleBonusDetails(this.finalFilteredBonusData);
            this.getGameGroupGames(false);
          } else {
            this.navigateToDeposit();
          }
        }
      });
  }

  /**
   * Here sortBy by default sort values in ascending order, to sort it by
   * descending order multiple value by -1 or use .reverse() function
   * */
  processEligibleBonusDetails(bonusDeatils) {
    let filteredBonus = _.filter(bonusDeatils, (bonus) => {
      if (
        bonus["criteriaType"] === "DEPOSIT" &&
        bonus["currencyTier"] &&
        bonus["currencyTier"][this.currencyCode] &&
        bonus["currencyTier"][this.currencyCode].length > 0
      ) {
        return bonus;
      }
    });
    this.depositBonusDetails = _.sortBy(filteredBonus, (data) => {
      return data.campaignStartDate * -1;
    });
    this.changePosition();
  }

  /**
   * functionality to change position of selected bonus to first index in array,
   * so that it's display first in UI
   **/
  changePosition() {
    if (this.selectedBonusCode) {
      this.selectedBonusIndex = _.findIndex(this.depositBonusDetails, {
        bonusCode: this.selectedBonusCode,
      });
      if (this.selectedBonusIndex > 0) {
        this.depositBonusDetails.splice(this.selectedBonusIndex, 1);
        this.depositBonusDetails.unshift(this.selectedBonus);
      }
    }
    this.finalFilteredBonusData = this.depositBonusDetails;
    if (this.finalFilteredBonusData.length === 0) {
      this.navigateToDeposit();
    }
  }

  getGameGroupGames(isForce) {
    this.gameGroupGameSub = this.gameGroupsService
      .getGameGroupGames(isForce)
      .subscribe((games) => {
        if (games && games.length > 0) {
          this.games = games;
          this.finalFilteredBonusData = this.finalFilteredBonusData.filter(
            (data, bindex) => {
              if (
                data["freeSpinGameIds"] &&
                data["freeSpinGameIds"].length > 0
              ) {
                let filteredGame = _.findWhere(games, {
                  gameCode: data["freeSpinGameIds"][0],
                });
                if (filteredGame && Object.keys(filteredGame).length > 0) {
                  data["gameData"] = filteredGame;
                }
              } else if (data["gameIds"] && data["gameIds"].length > 0) {
                let filteredGame = _.findWhere(games, {
                  gameCode: data["gameIds"][0],
                });
                if (filteredGame && Object.keys(filteredGame).length > 0) {
                  data["gameData"] = filteredGame;
                }
              }
              return data;
            }
          );
        }
      });
  }

  openRewardTCPopUp(event) {
    if (event) {
      event.stopPropagation();
    }
    this.utility.toggleRewardTCPopUp(true);
  }

  navigateToDeposit() {
    this.callOnNavigateToDeposit.emit();
  }

  initiateDeposit() {
    this.paynplayCashierService.broadCastInitiateDeposit({
      callingFrom: "payment-rewards",
    });
  }

  selectBonusHandler(bonusData) {
    this.callOnSelectBonus.emit(bonusData);
  }

  ngOnDestroy() {
    if (this.gameGroupGameSub) {
      this.gameGroupGameSub.unsubscribe();
    }
  }
}
