import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  SimpleChange,
  EventEmitter,
  HostListener,
  ElementRef,
  Component,
  OnDestroy,
  ViewChild,
  Output,
  Input,
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-recent-search-games",
  templateUrl: "./recent-search-games.component.html",
  styleUrls: ["./recent-search-games.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RecentSearchGamesComponent implements OnDestroy {
  @Input() callingFrom;
  @Output() closeSearchResults: EventEmitter<any> = new EventEmitter();
  @ViewChild("searchInput", { static: false }) searchInput: ElementRef;

  games: any;
  filteredGames;
  isLoading: boolean = false;
  isLoggedIn: any;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  lastPlayedGames: any;
  allFavoriteGames: any;
  gameGroups: any;
  lobbyGamesSub: Subscription;
  windowType: string;
  searchText: any = "";
  searchResultsDefaultSize = 18;
  loadMoreSearchGames = this.searchResultsDefaultSize;
  calledFrom: any;
  langCode: string;
  langCodeSubscription: Subscription;
  gamePlayserviceSub: Subscription;
  enableSearching: boolean = false;
  isLiveCasinoSub: Subscription;
  isLiveCasinoPage: boolean = false;
  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }
  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }
  constructor(
    private utility: UtilityService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private gameGroupsService: GameGroupsService,
    private gamesFilterPipe: GamesFilterPipe
  ) {
    this.getWindowType();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        this.getLobbyGroupsAndGames(true);
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
        this.getLobbyGroupsAndGames(true);
      }
    );
    this.isLiveCasinoSub = this.commonService.isLiveCasinoPage$.subscribe(
      (flag) => {
        this.isLiveCasinoPage = flag;
      }
    );
    this.commonService.searchEnabledStatus$.subscribe((status) => {
      this.closeSearchResultsHandler();
    });
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
      this.focusInputText();
    }
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww <= 1023) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
    /**
     * Default search result will changes based on device
     * resolution so here we update the search result default
     * size to render..
     */
    if (ww >= 768 && ww <= 1023) {
      this.searchResultsDefaultSize = 25;
      this.loadMoreSearchGames = this.searchResultsDefaultSize;
      if (this.searchText && this.searchText.length > 0) {
        this.searchGames(this.searchText);
      }
    } else {
      this.searchResultsDefaultSize = 18;
      this.loadMoreSearchGames = this.searchResultsDefaultSize;
      if (this.searchText && this.searchText.length > 0) {
        this.searchGames(this.searchText);
      }
    }
  }

  ngAfterViewInit() {
    if (this.windowType === "mobile") {
      this.searchInput.nativeElement.focus();
    }
  }

  getLobbyGroupsAndGames(isForce?: boolean) {
    this.isLoading = true;
    Promise.resolve(this.gameGroupsService.getGameGroupGamesp(isForce)).then(
      (games) => {
        if (games && games.length > 0) {
          this.games = JSON.parse(JSON.stringify(games));
        } else {
          this.filteredGames = [];
        }
        this.isLoading = false;
      }
    );
  }

  totalSearchedGames;
  searchGames(value) {
    this.isLoading = true;
    Promise.resolve(this.gameGroupsService.getGameGroupGamesp()).then(
      (games) => {
        if (games && games.length > 0) {
          this.games = JSON.parse(JSON.stringify(games));
          let filterData = {};
          if (this.callingFrom === "liveCasinoLobby") {
            filterData = { typeOfGames: "live-game" };
          }
          this.games =
            Object.keys(filterData).length > 0
              ? this.gamesFilterPipe.transform(this.games, filterData)
              : this.games;
        } else {
          this.filteredGames = [];
        }
        this.isLoading = false;

        this.searchText = value;
        if (value && value.length > 0) {
          if (this.callingFrom === "header") {
            this.utility.addClassToAppBody("overflow-hidden");
          }
          this.loadMoreSearchGames = this.searchResultsDefaultSize;
          this.totalSearchedGames = undefined;
          /**
           * User can seach game not only based on name, he can search based on game
           * tag name & vendor display name (means provider name)
           */
          this.totalSearchedGames = _.filter(this.games, (game, index) => {
            return (
              game["name"].toLowerCase().indexOf(value.toLowerCase()) != -1 ||
              game["vendorDisplayName"]
                .toLowerCase()
                .indexOf(value.toLowerCase()) != -1 ||
              (game["tagList"] &&
                game["tagList"]
                  .join("")
                  .toLowerCase()
                  .indexOf(value.toLowerCase()) != -1)
            );
          });
          this.filteredGames = this.totalSearchedGames.slice(
            0,
            this.searchResultsDefaultSize
          );
        } else {
          if (this.callingFrom === "header") {
            this.utility.removeClassFromAppBody("overflow-hidden");
          }
          this.filteredGames = [];
        }
      }
    );
  }

  /**search loading related functionality
   * case 1:
   * Basically we load only 18 games on page load
   * when user scroll we will keep on adding 18(searchResultsDefaultSize) more games on each scroll..
   * case 2:
   * But only in ipad the case is different we load 25 games on page load
   * & we are handling this DefaultSize for different resolutions in getWindowType()...
   */

  loadMoreSearchResult(event) {
    if (
      event.srcElement.classList &&
      event.srcElement.classList.contains("searchresults-card")
    ) {
      let searchWrapper = document.getElementById("searchresults-card");
      if (
        searchWrapper.scrollTop + searchWrapper.offsetHeight >=
        searchWrapper.scrollHeight - 50
      ) {
        this.loadMoreSearchGames += this.searchResultsDefaultSize;
        this.filteredGames = this.totalSearchedGames.slice(
          0,
          this.loadMoreSearchGames
        );
      }
    }
  }

  ngOnDestroy() {
    this.isLiveCasinoSub.unsubscribe();
    if (this.lobbyGamesSub) {
      this.lobbyGamesSub.unsubscribe();
    }

    if (this.loginCompleteSubscription) {
      this.loginCompleteSubscription.unsubscribe();
    }

    if (this.logOutCompleteSubscription) {
      this.logOutCompleteSubscription.unsubscribe();
    }

    if (this.gamePlayserviceSub) {
      this.gamePlayserviceSub.unsubscribe();
    }

    if (this.langCodeSubscription) {
      this.langCodeSubscription.unsubscribe();
    }
    if (this.enableSearching) {
      this.stopSearching();
    }
  }

  closeSearchResultsHandler() {
    this.searchInput.nativeElement.value = "";
    this.searchGames("");
    if (this.enableSearching) {
      this.stopSearching();
    }
    if (
      this.callingFrom === "liveCasinoLobby" ||
      this.callingFrom === "casinoLobby"
    ) {
      this.utility.removeClassFromAppBody("overflow-hidden");
    }
  }
  startSearching() {
    this.enableSearching = true;
    this.commonService.setCasinoPageSearchEnabledStatus(this.enableSearching);
    this.focusInputText();
  }

  stopSearching() {
    this.enableSearching = false;
    this.commonService.setCasinoPageSearchEnabledStatus(this.enableSearching);
  }

  focusInputText() {
    setTimeout(() => {
      if (document.getElementById("searchtext")) {
        document.getElementById("searchtext").focus();
      }
    });
  }
}
