import { Pipe, PipeTransform } from "@angular/core";
import { DecimalPipe } from "@angular/common";
import { Subscription } from "rxjs";

// Configurations
import {
  marketLocaleCurrencyMappingConfigurations,
  avialableCountriesConfigurations,
  currencyMappingConfigurations,
} from "src/app/configurations/main.configurations";

// Libraries
import * as _ from "underscore";

// Enums
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { CurrencySymbol } from "src/app/models/configurations/enums/currency-symbol.enum";

// Models
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Pipe({
  name: "currencyformat",
})
export class CurrencyFormatPipe implements PipeTransform {
  // Strings
  currencySymbol: string = "";
  languageCode: string = "";

  // Booleans
  isLoggedIn: boolean = false;

  // Arrays
  avialableCountries: string[] = [];

  // Subscriptions
  subscriptions: Subscription[] = [];

  constructor(
    private userDetailsService: UserDetailsService,
    private translationService: TranslationService,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private decimalPipe: DecimalPipe,
  ) {
    this.onLoad();
  }

  // -----------------------------------------------------------------
  // Transform Methods
  transform(value: string | number, isDecimalNeeded = true): string {
    return this.getCurrencyFormat(value, isDecimalNeeded);
  }

  // -----------------------------------------------------------------
  // Get Methods
  /*
    Here currencyPosition mapping is complete from local for both login & logout
    we are not depending on profile currency data &
    currency postion is decided based on user country code for loggedIn users
    and for loggedOut user based in ip address
  */
  getCurrencyFormat(value: string | number, isDecimalNeeded = true): string {
    let isCountryExist: boolean = false;
    let countryCode: string = "";

    // Objects
    let marketLocaleCurrencyMappingClone: {
      [key: string]: CurrencyConfigurations;
    } = marketLocaleCurrencyMappingConfigurations;

    let currencyMapConfigurationsMappingClone: {
      [key: string]: CurrencyConfigurations;
    } = currencyMappingConfigurations;

    let data: CurrencyConfigurations;

    let languageCodeFromURL: string = this.utilityService
      .getDecodedCurrentPath()
      .split("/")[1];

    if (
      languageCodeFromURL &&
      languageCodeFromURL !== this.languageCode &&
      _.contains(supportedLanguagesList(), languageCodeFromURL)
    ) {
      this.languageCode = languageCodeFromURL;
    }

    if (this.userDetailsService.userProfileDetails) {
      countryCode = this.userDetailsService.userProfileDetails.country;
    } else {
      countryCode = this.commonService.getCountryCode();
    }

    if (!this.isLoggedIn) {
      data = marketLocaleCurrencyMappingClone.hasOwnProperty(this.languageCode)
        ? marketLocaleCurrencyMappingClone[this.languageCode]
        : undefined;
    } else {
      data = currencyMapConfigurationsMappingClone.hasOwnProperty(countryCode)
        ? currencyMapConfigurationsMappingClone[countryCode]
        : undefined;
    }

    if (data) {
      data.currencySymbol =
        CurrencySymbol[this.userDetailsService.getCurrencySymbol()];
    }

    isCountryExist =
      this.avialableCountries.indexOf(countryCode) >= 0 ? true : false;

    if (isDecimalNeeded) {
      value = this.decimalPipe.transform(value, "1.2-2");
    }

    if (isCountryExist && data && data.position === CurrencyPosition.before) {
      return `${data.currencySymbol}${data.isSpaceRequired ? " " : ""}${value}`;
    } else if (
      isCountryExist &&
      data &&
      data.position === CurrencyPosition.after
    ) {
      return `${value}${data.isSpaceRequired ? " " : ""}${data.currencySymbol}`;
    } else if (data) {
      return `${data.currencySymbol}${data.isSpaceRequired ? " " : ""}${value}`;
    } else {
      return `${this.currencySymbol} ${value}`;
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onLoad(): void {
    this.avialableCountries = avialableCountriesConfigurations;

    /*
      Here we receive both default currency(if we don't have user currency like
      before logged In state) & user currency
    */
    this.subscriptions = [
      this.userDetailsService.currencySymbolSb$.subscribe(
        (currencySymbol: string) => {
          this.currencySymbol = currencySymbol;
        }
      ),
      this.translationService.langCode$.subscribe((languageCode: string) => {
        this.languageCode = languageCode;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }
}
