import { Component, OnDestroy } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { LoginService } from "src/app/modules/auth/services/login.service";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent extends FormValidationComponent
  implements OnDestroy {
  serverError;
  langCode: string;
  isPassword: boolean = true;
  langCodeSubscription: Subscription;
  doLoginSubScription: Subscription;
  isButtonLoader: boolean = false;
  userKycLevelSub: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private customValidators: CustomValidatorService,
    private commonService: CommonService,
    private loginService: LoginService,
    private router: Router,
    private translationService: TranslationService,
    private utils: UtilityService
  ) {
    super(utils);
    this.langCode = this.utils.getLangCode();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
  }

  loginForm = this.formBuilder.group({
    txtEmail: [
      "",
      [
        this.customValidators.validateUniqueness(
          "txtEmail",
          this.commonService,
          false
        ),
      ],
    ],
    txtPassword: ["", [this.customValidators.required]],
  });

  doLogin() {
    this.isButtonLoader = true;
    this.serverError = undefined;
    if (this.loginForm.valid) {
      const credentials = {
        txtEmail: this.loginForm.controls["txtEmail"].value.trim(),
        txtPassword: this.loginForm.controls["txtPassword"].value,
      };
      this.doLoginSubScription = this.loginService
        .doLogin(credentials)
        .subscribe((loginData) => {
          this.isButtonLoader = false;
          if (
            loginData &&
            (loginData["success"] === "true" || loginData["success"] === true)
          ) {
            this.commonService.broadCastActiveAcountMenu("");
            this.router.navigate([this.langCode + "/casino"]);
          } else {
            this.loginFailure(loginData);
          }
        });
    }
  }

  loginFailure(loginData) {
    this.serverError = this.translationService.instant("common.error44");
    if (loginData && loginData["errors"]) {
      if (loginData["errors"]["accountLocked"]) {
        let msg = loginData["errors"]["accountLocked"];
        /**Below logic/work around is required to remove crossbrowser issue in safari, firefox & windows */
        let accountLockedDate = new Date(
          Date.parse(
            msg
              .substr(msg.indexOf(":") + 1)
              .trim()
              .replace(" ", "T") + "+02:00"
          )
        );
        const errorMessage = loginData["errors"]["accountLocked"].split(":")[0];
        this.serverError =
          errorMessage +
          new Date(
            loginData["errors"]["accountLocked"].substr(
              loginData["errors"]["accountLocked"].indexOf(":")
            )
          );
        this.serverError = errorMessage + accountLockedDate;
        return;
      }
      let errorCode;
      if (loginData["errors"]["errorCode"]) {
        errorCode = loginData["errors"]["errorCode"];
      } else if (loginData["errors"]["internalErrorCode"]) {
        errorCode = loginData["errors"]["internalErrorCode"];
      }
      if (errorCode) {
        this.displayLoginErrorMessage(errorCode);
      }
    }
  }

  displayLoginErrorMessage(errorCode) {
    switch (errorCode) {
      case "100152":
        this.serverError = this.translationService.instant(
          "logincomponent.error_100152_loginAccountdisabled"
        );
        break;
      case "100157":
        this.serverError = this.translationService.instant(
          "logincomponent.error_100157_rg_closed"
        );
        break;
      case "100173":
        this.serverError = this.translationService.instant(
          "logincomponent.error_100173_rg_cool_off"
        );
        break;
      case "100174":
        this.serverError = this.translationService.instant(
          "logincomponent.error_100174_login_attempts_exceeded"
        );
        break;
      case 100161:
        this.serverError = this.translationService.instant(
          "logincomponent.error_100161_wrong_password"
        );
        break;
      case 100166:
        this.serverError = this.translationService.instant(
          "logincomponent.error_100166_wrong_emailid"
        );
        break;

      default:
        this.serverError = this.translationService.instant("common.error44");
    }
  }

  closeComponent() {
    this.utils.closeAccountComponent("");
    this.router.navigate([this.langCode + "/casino"]);
  }

  ngOnDestroy() {
    this.langCodeSubscription.add(this.doLoginSubScription);
    this.langCodeSubscription.unsubscribe();
  }
}
