import { Component, OnDestroy } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { ForgotPasswordService } from "src/app/modules/auth/services/forgot-password.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent extends FormValidationComponent
  implements OnDestroy {
  langCode: string;
  langCodeSubscription: Subscription;
  emailSent: boolean = false;
  emailId;
  serverError: any;
  sendResetPasswordSub: Subscription;
  isButtonLoader: boolean = false;

  constructor(
    private utils: UtilityService,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private customValidators: CustomValidatorService,
    private translationService: TranslationService,
    private forgotPasswordService: ForgotPasswordService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    super(utils);
    this.langCode = this.utils.getLangCode();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
  }

  forgotPasswordForm = this.formBuilder.group({
    txtEmail: [
      "",
      [
        this.customValidators.validateUniqueness(
          "txtEmail",
          this.commonService,
          false
        ),
      ],
    ],
  });
  closeForgotPwdPage() {
    this.commonService.broadCastActiveAcountMenu("");
  }
  /* Here in reset password api response For error code 100164 we are displaying error msg as 
"User does not exist please check the email" & for remaining below error codes
we don't get any information when they occur 
so we are displaying "something went wrong message"
For all the below errorCodes
100157:TECHNICAL_ERROR
100190:AUTHENTICATION_ERROR
100191:UNAUTHORIZED_CLIENT
100192:INVALID_GRANT
100193:INVALID_CLIENT
100194:INVALID_TOKEN 
100100:SOME_THING_WENT_WRONG */

  sendResetPasswordlink() {
    this.isButtonLoader = true;
    this.emailId = this.forgotPasswordForm.controls["txtEmail"].value;
    this.sendResetPasswordSub = this.forgotPasswordService
      .sendResetPasswordLink({
        email: this.emailId,
      })
      .subscribe((resp) => {
        this.isButtonLoader = false;
        if (
          resp &&
          resp["success"] &&
          resp["success"]["status"] === "SUCCESS"
        ) {
          this.emailSent = true;
          this.utils.resetFormFields(this.forgotPasswordForm, true);
        } else if (resp["errorCode"] === 100164) {
          this.serverError = this.translationService.instant("errors.error66");
        } else if (
          resp &&
          resp["errors"] &&
          resp["errors"].hasOwnProperty("email")
        ) {
          this.serverError = this.translationService.instant(
            "forgotpwdComponent.email_field_required"
          );
        } else {
          this.serverError = this.translationService.instant("common.error44");
        }
        setTimeout(() => {
          this.emailSent = false;
          this.serverError = undefined;
        }, 3000);
      });
  }

  gotoLoginPage() {
    this.commonService.broadCastActiveAcountMenu("");
    this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
  }

  ngOnDestroy() {
    if (this.sendResetPasswordSub) {
      this.sendResetPasswordSub.unsubscribe();
    }
    this.langCodeSubscription.unsubscribe();
  }
}
