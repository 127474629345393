import {
  EventEmitter,
  SimpleChange,
  Component,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-release-restrict-reward",
  templateUrl: "./release-restrict-reward.component.html",
  styleUrls: ["./release-restrict-reward.component.scss"],
})
export class ReleaseRestrictRewardComponent implements OnChanges {
  imgixParams: any;

  @Input() callingFrom;
  @Input() rewardSize;
  @Input() currencyCode;
  @Input() bonusDetails;
  @Input() selectedBonus;
  @Output() callOnBonusSelected: EventEmitter<any> = new EventEmitter();
  @Output() callOpenRewardTCPopUp: EventEmitter<any> = new EventEmitter();
  @Output() callOnAddBonus: EventEmitter<any> = new EventEmitter();

  constructor(
    private utility: UtilityService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
    if (changes["rewardSize"]) {
      this.rewardSize = changes["rewardSize"].currentValue;
    }
    if (changes["bonusDetails"]) {
      this.bonusDetails = changes["bonusDetails"].currentValue;
    }
    if (changes["currencyCode"]) {
      this.currencyCode = changes["currencyCode"].currentValue;
    }
    if (changes["selectedBonus"]) {
      this.selectedBonus = changes["selectedBonus"].currentValue;
    }
  }

  selectBonusHandler(bonus) {
    this.callOnBonusSelected.emit(bonus);
  }

  openRewardTCPopUp(event) {
    this.callOpenRewardTCPopUp.emit(event);
  }

  addBonus(bonus) {
    this.callOnAddBonus.emit(bonus);
  }
}
