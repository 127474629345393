import { Observable, Subscription } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { MainService } from "src/app/services/main-service/main.service";
import { SocketService } from "src/app/services/socket/socket.service";
import { GtmService } from "src/app/services/gtm-service/gtm.service";

@Injectable()
export class LoginService {
  langCode: string;
  langCodeSubcription: Subscription;

  constructor(
    private mainService: MainService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private socketService: SocketService,
    private gtmService: GtmService,
    private uility: UtilityService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.translationService.langCodeSb$.subscribe((langCode) => {
      this.langCode = langCode;
    });
  }

  doLogin(credentials): Observable<any> {
    return this.mainService.psPost(endpointConfigurations.dologin_url, credentials).pipe(
      map((loginResponse) => {
        if (
          loginResponse &&
          (loginResponse["success"] === "true" ||
            loginResponse["success"] === true)
        ) {
          this.socketService.connectToScoket(
            loginResponse["pragmaticUrl"],
            loginResponse["pragmaticSessionId"],
            true
          );
          localStorage.setItem("isLoggedIn", "true");
          this.paynplayCashierService.setUserLoggedTime(new Date());
          localStorage.setItem("kan_user", "true");
          this.commonService.broadCastLoginSuccess(true);
          if (loginResponse && loginResponse["user"]) {
            this.commonService.setSocketDetails(loginResponse["user"]);
          }
        }
        return loginResponse;
      })
    );
  }
}
