import {
  EventEmitter,
  Component,
  OnDestroy,
  Output,
} from "@angular/core";

// Modules
import { MbscFormOptions } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";

// Services
import { UserDetailsService } from "src/app/services/user-details/user-details.service";

@Component({
  selector: "app-bet-range-filter",
  templateUrl: "./bet-range-filter.component.html",
  styleUrls: ["./bet-range-filter.component.scss"],
})
export class BetRangeFilterComponent implements OnDestroy {
  betRangeValues = [100, 2200];
  betRangeMinValue: number = 0;
  betRangeMaxValue: number = 2500;
  formSettings: MbscFormOptions = {
    theme: "ios",
  };

  @Output() callOnBetRangeChanged: EventEmitter<any> = new EventEmitter();

  currencySymbolSubscription: any;
  currencySymbol: string;
  constructor(private userDetailsService: UserDetailsService) {
    this.currencySymbolSubscription = this.userDetailsService.currencySymbolSb$.subscribe(
      (curSymbol) => {
        this.currencySymbol = curSymbol;
      }
    );
  }

  onInputRangeChange() {
    this.callOnBetRangeChanged.emit(this.betRangeValues);
  }
  clearBetRange() {
    this.betRangeValues = [this.betRangeMinValue, this.betRangeMaxValue];
    this.onInputRangeChange();
  }

  ngOnDestroy() {
    this.currencySymbolSubscription.unsubscribe();
  }
}
