import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

/** we are not using this below syntax..to maintain
 * readability.instead of
 * @Injectable({providedIn: 'root' })
 */
@Injectable()
export class MainService {
  baseUrl: string = environment.apiUrl;
  headerOptions: any;
  jackpotHeader: any;

  constructor(private httpClient: HttpClient) {
    this.headerOptions = {
      headers: new HttpHeaders({
        "content-type": "application/x-www-form-urlencoded",
        accept: "application/json, text/plain, */*",
      }),
      withCredentials: true,
      observe: "body",
    };

    this.jackpotHeader = {
      headers: new HttpHeaders({
        "content-type": "application/json",
        "X-CorrelationId": "4d8a92b5-d836-4354-95c2-75924cede1d5",
        "X-ClientTypeId": "1231",
      }),
      observe: "body",
    };
  }

  getUrlParams(fields): any {
    const urlParams = new HttpParams({ fromObject: fields });
    return urlParams;
  }

  /**
   * @param url endpoint url
   * @param queryParams optional query params
   */
  psGet(url, queryParams?): Observable<any> {
    return this.httpClient.get(this.baseUrl + url, {
      params: queryParams,
      observe: "body",
      withCredentials: true,
    });
  }

  /**
   * @param url endpoint url
   * @param requestBody data that need to be saved/posted
   */

  psPost(url, requestBody?): Observable<any> {
    if (!_.isEmpty(requestBody)) {
      return this.httpClient.post(
        this.baseUrl + url,
        this.getUrlParams(requestBody),
        this.headerOptions
      );
    } else {
      return this.httpClient.post(this.baseUrl + url, null, this.headerOptions);
    }
  }

  /**
   * @param url endpoint url
   * @param queryParams optional query params
   */
  microGamingJackpotFeed(currencyCode): Observable<any> {
    return this.httpClient.get(
      environment.microGamingJackpotsUrl +
        endpointConfigurations.mgsJackpotFeed +
        "?currencyIsoCode=" +
        currencyCode,
      this.jackpotHeader
    );
  }

  microGamingTotalJackpotsAmount(currencyCode): Observable<any> {
    return this.httpClient.get(
      environment.microGamingJackpotsUrl +
        endpointConfigurations.mgsJackpotsTotal +
        "?currencyIsoCode=" +
        currencyCode,
      this.jackpotHeader
    );
  }

  /**
   * @param url endpoint url
   * @param requestBody data that need to be saved/posted
   */
  psUploadPost(url, requestBody): Observable<any> {
    return this.httpClient.post(this.baseUrl + url, requestBody, {
      observe: "body",
      withCredentials: true,
    });
  }

  /**
   * @param url endpoint url
   * @param queryParams optional query params
   */
  piqGet(url, queryParams?): Observable<any> {
    return this.httpClient.get(environment.paymentIqUrl + url, {
      params: queryParams,
      observe: "body",
    });
  }

  piqPost(url, requestBody): Observable<any> {
    return this.httpClient.post(environment.paymentIqUrl + url, requestBody);
  }

  piqDelete(url, queryParams): Observable<any> {
    return this.httpClient.delete(environment.paymentIqUrl + url, {
      params: queryParams,
      observe: "body",
    });
  }

  getUserUsedPIQAccounts(findPIQAccountObject): Observable<any> {
    const findPIQAccountUrl = endpointConfigurations.getUserUsedPIQAccounts
      .replace(":merchantId", findPIQAccountObject.merchantId)
      .replace(":userId", findPIQAccountObject.userId);
    delete findPIQAccountObject.merchantId;
    delete findPIQAccountObject.userId;
    return this.piqGet(findPIQAccountUrl, findPIQAccountObject);
  }

  getEligibleBonus(bonusParamsObject): Observable<any> {
    return this.psGet(endpointConfigurations.getEligibleBonus, bonusParamsObject);
  }

  getStaticPage(requestObject): Observable<any> {
    return this.psGet(endpointConfigurations.getStaticPage_url, requestObject);
  }

  getUserPIQMethods(findUserPIQMethods) {
    const findPIQMethods = endpointConfigurations.getUserPIQMethods
      .replace(":merchantId", findUserPIQMethods.merchantId)
      .replace(":userId", findUserPIQMethods.userId);
    delete findUserPIQMethods.merchantId;
    delete findUserPIQMethods.userId;
    return this.piqGet(findPIQMethods, findUserPIQMethods);
  }

  makePayment(paymentRequestObject) {
    const paymentrequestUrl = endpointConfigurations.makePayment_url
      .replace(":provider", paymentRequestObject.provider)
      .replace(":type", paymentRequestObject.paymentMethod)
      .replace(":method", paymentRequestObject.methodType);
    delete paymentRequestObject.provider;
    delete paymentRequestObject.paymentMethod;
    delete paymentRequestObject.methodType;
    return this.piqPost(paymentrequestUrl, paymentRequestObject);
  }

  getPIQTransactionStatus(requestObj) {
    const transactionUrl = endpointConfigurations.gettransactionStatus_url
      .replace(":merchantId", requestObj["merchantId"])
      .replace(":userId", requestObj["userId"])
      .replace(":txRefId", requestObj.txRefId);
    delete requestObj["merchantId"];
    delete requestObj["userId"];
    delete requestObj["txRefId"];
    return this.piqGet(transactionUrl, requestObj);
  }

  getCountryBlockData() {
    return this.psGet(endpointConfigurations.isCountryBlock_url).toPromise();
  }

  getLobbyGameGroups(): Observable<any> {
    return this.psGet(endpointConfigurations.getLobbyGameGroups_url);
  }

  getGames(): Observable<any> {
    return this.psGet(endpointConfigurations.getGames_url);
  }

  getGameGroupGames(): Observable<any> {
    return this.psGet(endpointConfigurations.getGameGroupGames_url);
  }

  getFavoriteGames(): Observable<any> {
    return this.psPost(endpointConfigurations.getFavoriteGame_url);
  }

  getLastedPlayedGames(): Observable<any> {
    return this.psGet(endpointConfigurations.getLastPlayedGames_url);
  }
  getPromotions(requestObject): Observable<any> {
    return this.psGet(endpointConfigurations.getPromotions_url, requestObject);
  }
  getTransactionHistory(requestobj) {
    let txnReq = { criteria: JSON.stringify(requestobj) };
    return this.psPost(endpointConfigurations.getTransactionhistory_url, txnReq);
  }

  getCashierTransactionHistory(requestObj) {
    let txnReq = { criteria: JSON.stringify(requestObj) };
    return this.psPost(endpointConfigurations.getCashierTransactionHistory_url, txnReq);
  }
  sendResetPasswordLink(requestObj) {
    return this.psPost(endpointConfigurations.forgot_password_url, requestObj);
  }

  resetPassword(requestObj) {
    return this.psPost(endpointConfigurations.reset_password_url, requestObj);
  }
  getBetTransactions(requestobj) {
    let txnReq = { criteria: JSON.stringify(requestobj) };
    return this.psPost(endpointConfigurations.getBetTransactions_url, txnReq);
  }
  getSubscriptionPreference() {
    return this.psPost(endpointConfigurations.getSubscriptionpreferences_url);
  }

  updateSubscriptionPreference(requestObj) {
    return this.psPost(
      endpointConfigurations.updatesubscriptionpreferenes_url,
      requestObj
    );
  }
  changePasswordHanlder(requestObj) {
    return this.psPost(endpointConfigurations.change_password_url, requestObj);
  }
  getActiveBonusDetails() {
    return this.psGet(endpointConfigurations.getActiveBonus_url);
  }

  getUnclaimedLevels(): Observable<any> {
    return this.psGet(endpointConfigurations.getUnclaimedLevels_url);
  }

  claimLevelupBonus(requestObj): Observable<any> {
    return this.psPost(endpointConfigurations.claimLevelupBonus_url, requestObj);
  }

  dropBonus(requestobj) {
    return this.psGet(endpointConfigurations.dropBonus_url, requestobj);
  }
  getUserData(): Observable<any> {
    return this.psGet(endpointConfigurations.getUserData_url);
  }

  doProfileUpdate(requestObj): Observable<any> {
    return this.psPost(endpointConfigurations.doprofile_update_url, requestObj);
  }

  getProfileCountryDetails(): Observable<any> {
    return this.psGet(endpointConfigurations.getProfileCountryDetails_url);
  }
  upLoadDocuments(requestObj) {
    let formData: FormData = new FormData();
    let type = requestObj.type;
    delete requestObj.type;
    formData.append("file", requestObj.file);
    const url = endpointConfigurations.uploadDocuments + "?file=" + type + "&accountId=";
    return this.psUploadPost(url, formData);
  }

  sendDocuments(requestData?) {
    if (!requestData) {
      return this.psPost(endpointConfigurations.documentSend_url);
    } else {
      return this.psPost(endpointConfigurations.documentSend_url, requestData);
    }
  }

  deletePayamenMethod(piqObject) {
    const findPIQAccountUrl = endpointConfigurations.deletePaymentMethod_url
      .replace(":merchantId", piqObject.merchantId)
      .replace(":userId", piqObject.userId)
      .replace(":accountId", piqObject.accountId);
    delete piqObject.merchantId;
    delete piqObject.userId;
    delete piqObject.accountId;
    return this.piqDelete(findPIQAccountUrl, piqObject);
  }

  getAccountVerficationStatus() {
    return this.psGet(endpointConfigurations.accountVerficationstatus_url);
  }

  setResponsibleGamingLimits(requestObj): Observable<any> {
    return this.psPost(endpointConfigurations.setResponsibleGamingLimits_url, requestObj);
  }

  getResponsibleGamingLimits(type): Observable<any> {
    let endpointPath: string;
    if (type === "deposit") {
      endpointPath = endpointConfigurations.getDepositLimit_url;
    } else if (type === "loss") {
      endpointPath = endpointConfigurations.getLossLimits_url;
    } else if (type === "wager") {
      endpointPath = endpointConfigurations.getWagerLimit_url;
    } else if (type === "session") {
      endpointPath = endpointConfigurations.getSessionLimit_url;
    }
    return this.psGet(endpointPath);
  }

  suspendAccount(requestObj): Observable<any> {
    return this.psPost(endpointConfigurations.suspendAccount_url, requestObj);
  }

  cancelDepositLimits(requestObj): Observable<any> {
    return this.psPost(endpointConfigurations.cancelDepositLimits, requestObj);
  }

  cancelLimits(requestObj): Observable<any> {
    return this.psPost(endpointConfigurations.cancelRgLimits, requestObj);
  }

  getPendingWithdrawal(requestPiqObj): Observable<any> {
    const pendigWithdrawUrl = endpointConfigurations.getPendingWithdrawal_url
      .replace(":merchantId", requestPiqObj.merchantId)
      .replace(":userId", requestPiqObj.userId);
    delete requestPiqObj.merchantId;
    delete requestPiqObj.userId;
    return this.piqGet(pendigWithdrawUrl, requestPiqObj);
  }

  cancelTransaction(requestPiqObj): Observable<any> {
    const cancelTransactionUrl = endpointConfigurations.cancelTransaction_url
      .replace(":merchantId", requestPiqObj.merchantId)
      .replace(":userId", requestPiqObj.userId)
      .replace(":transactionId", requestPiqObj.transactionId);
    delete requestPiqObj.merchantId;
    delete requestPiqObj.userId;
    delete requestPiqObj.transactionId;
    return this.piqDelete(cancelTransactionUrl, requestPiqObj);
  }

  getUserKycLevelDetails(): Observable<any> {
    return this.psGet(endpointConfigurations.getUserKycLevelDetails_url);
  }

  getFooterContent(requestObj): Observable<any> {
    return this.psGet(endpointConfigurations.getStaticPage_url, requestObj);
  }

  getFaqQuestionAndCategories(): Observable<any> {
    return this.psGet(endpointConfigurations.getFaqQuestionAndCategories_url);
  }
  getUserLevelupDetails(): Observable<any> {
    return this.psGet(endpointConfigurations.getUserLevelupDetails_url);
  }

  getZendeskToken(): Observable<any> {
    return this.psGet(endpointConfigurations.zendeskToken);
  }

  getLoginStatus() {
    return this.psGet(endpointConfigurations.loginStatus_url).toPromise();
  }

  getAllPromotions() {
    return this.psGet(endpointConfigurations.getPromotions_url);
  }

  /***********promise call ********** */

  getLobbyGameGroupsp() {
    return this.psGet(endpointConfigurations.getLobbyGameGroups_url).toPromise();
  }

  getGameGroupGamesp() {
    return this.psGet(endpointConfigurations.getGameGroupGames_url).toPromise();
  }

  getLastedPlayedGamesp() {
    return this.psGet(endpointConfigurations.getLastPlayedGames_url).toPromise();
  }

  getBannersp(bannerReqData) {
    return this.psGet(endpointConfigurations.getbanner_url, bannerReqData).toPromise();
  }

  getAllPromotionsp() {
    return this.psGet(endpointConfigurations.getPromotions_url).toPromise();
  }

  getStaticPagep(requestObject) {
    return this.psGet(endpointConfigurations.getStaticPage_url, requestObject).toPromise();
  }

  getProfileBalanceCurrency() {
    return this.psGet(endpointConfigurations.getprofilebalance_url).toPromise();
  }

  getToken(tokenObject) {
    return this.psGet(endpointConfigurations.getToken, tokenObject).toPromise();
  }
  getPNPMinMaxTxnLimits(requestData): Observable<any> {
    return this.psGet(endpointConfigurations.getPNPMinMaxTxnLimits, requestData);
  }

  getCashoutTxnLimits(requestData) {
    return this.psGet(endpointConfigurations.getCashOutDetails, requestData);
  }
  zimplerPayAndPlayInit(requestData): Observable<any> {
    return this.psPost(endpointConfigurations.zimplerPayAndPlayInit, requestData);
  }
  getPNPUserFromTransaction(requestData): Observable<any> {
    return this.psGet(endpointConfigurations.getPNPUserFromTransaction, requestData);
  }
  quickRegisterZimpler(requestData): Observable<any> {
    return this.psPost(endpointConfigurations.quickRegisterZimpler, requestData);
  }
  getPNPThirdPartyLogin(requestData): Observable<any> {
    return this.psGet(endpointConfigurations.getPNPThirdPartyLogin, requestData);
  }
  updateUserCredentails(requestData): Observable<any> {
    return this.psPost(endpointConfigurations.updateUserCredentials, requestData);
  }

  zimplerWithdrawInit(requestData): Observable<any> {
    return this.psPost(endpointConfigurations.zimplerWithdrawInit, requestData);
  }

  getUserPSPTranstionStatus(requestData): Observable<any> {
    return this.psPost(endpointConfigurations.getUserPSPTransactionStatus, requestData);
  }

  getPendingCashOuts(pendingWithdrawalObject): Observable<any> {
    return this.psGet(
      endpointConfigurations.getPendingCashOuts_url,
      this.getUrlParams(pendingWithdrawalObject)
    );
  }

  revertWithdrawal(requestData): Observable<any> {
    return this.psPost(endpointConfigurations.revertWithdraw_url, requestData);
  }

  getZendeskRubikoDetails(userName): Observable<any> {
    let url =
      "https://zdapi.rubiko.tech/tracking?user=" +
      encodeURIComponent(userName) +
      "&page=" +
      encodeURIComponent(window.location.href);
    return this.httpClient.get(url);
  }

  getUserBalanceByPockets() {
    return this.psGet(endpointConfigurations.getUserBalanceByPocket_url).toPromise();
  }

  getUserCampaigns(): Observable<any> {
    return this.psGet(endpointConfigurations.getUserCampaigns);
  }

  getPromoConfigDetails(requestObject): Observable<any> {
    return this.psGet(endpointConfigurations.getPromoConfigDetails, requestObject);
  }

  getOptIn_url(requestObject): Observable<any> {
    return this.psGet(endpointConfigurations.getOptIn_url, requestObject);
  }

  getPlayerPromoProgress(requestObject): Observable<any> {
    return this.psGet(endpointConfigurations.getPlayerPromoProgress, requestObject);
  }
}
