import { FormGroup } from "@angular/forms";
import { Component } from "@angular/core";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-form-validation",
  templateUrl: "./form-validation.component.html",
  styleUrls: ["./form-validation.component.scss"],
})
export class FormValidationComponent {
  // Element
  focusedElement: string;

  constructor(private utilityService: UtilityService) {}

  // -----------------------------------------------------------------
  // Get Methods
  getFormFieldClass(
    formControlName: string,
    formGroup: FormGroup
  ): "" | "has-error" | "has-success" {
    if (this.focusedElement && this.focusedElement == formControlName) {
      return "";
    } else {
      return this.getShouldShowErrorsInverted(formControlName, formGroup)
        ? "has-error"
        : this.isControlValid(formControlName, formGroup)
        ? "has-success"
        : "";
    }
  }

  isControlValid(formControlName: string, formGroup: FormGroup): boolean {
    return this.utilityService.isControlValid(formControlName, formGroup);
  }

  getShouldShowErrors(formControlName: string, formGroup: FormGroup): boolean {
    if (this.focusedElement && this.focusedElement == formControlName) {
      return false;
    } else {
      return this.utilityService.getShouldShowErrors(
        formControlName,
        formGroup
      );
    }
  }

  getShouldShowErrorsInverted(
    formControlName: string,
    formGroup: FormGroup
  ): boolean {
    if (this.focusedElement && this.focusedElement == formControlName) {
      return false;
    } else {
      return this.utilityService.getShouldShowErrors(
        formControlName,
        formGroup
      );
    }
  }

  getErrorMessage(formControlName: string, formGroup: FormGroup): string {
    return this.utilityService.getErrorMessage(formControlName, formGroup);
  }

  isButtonDisabled(formControl: FormGroup): boolean {
    return this.utilityService.isButtonDisabled(formControl);
  }

  // -----------------------------------------------------------------
  // Set Methods
  onFocusForElement(focusedElement: string): void {
    if (this.focusedElement != focusedElement) {
      this.focusedElement = focusedElement;
    }
  }

  onFocusOutForElement(): void {
    this.focusedElement = undefined;
  }

  onKeyPress(): void {
    this.focusedElement = undefined;
  }
}
