import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";

@Component({
  selector: "app-redirect",
  templateUrl: "./../../../../../redirection.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent implements OnInit {
  langCode: string;

  constructor(
    private router: Router,
    private translationService: TranslationService
  ) {
    this.translationService.langCode$.subscribe((langcode) => {
      this.langCode = langcode;
    });
  }

  ngOnInit() {
    if (this.isInsideIframe()) {
      let postMessageData = {
        message: "withdraw-transactionStatus",
      };
      window.parent.postMessage(postMessageData, "*");
    } else {
      let value = "withdraw-transactionStatus";
      window.location.href = window.location.origin + "?redirect=" + value;
    }
  }

  isInsideIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
}
