import { Observable, throwError, of, Subject, BehaviorSubject } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";

// Libraries
import * as _ from "underscore";

// Services
import { CommonService } from "src/app/services/common-service/common.service";
import { MainService } from "src/app/services/main-service/main.service";

@Injectable({
  providedIn: "root",
})
export class GameGroupsService {
  allGamesData = [];
  lobbyGameGroups: any;
  gameGroupGames: any;

  lobbyWithGameGroupsRespData: any = [];
  allAvialableGamesRespData: any = [];
  allFavoriteGamesRespData: any = [];
  isLoggedIn: any;

  constructor(
    private mainService: MainService,
    private commonService: CommonService
  ) {
    this.commonService.loginComplete$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
    this.commonService.logOutComplete$.subscribe((data) => {
      this.isLoggedIn = false;
    });
  }

  // gameLobbyRequestQueue = [];
  // getLobbyListWithGameGroups(isForce?: boolean): Observable<any> {
  //   if (!_.isEmpty(this.lobbyWithGameGroupsRespData) && !isForce) {
  //     return of(this.lobbyWithGameGroupsRespData);
  //   } else {
  //     return new Observable(Observer => {
  //       if (this.gameLobbyRequestQueue.length > 0) {
  //         this.gameLobbyRequestQueue.push(Observer);
  //       } else {
  //         this.gameLobbyRequestQueue.push(Observer);
  //         this.mainService.getLobbyGameGroups().subscribe(resp => {
  //           if (resp && resp.length > 0) {
  //             this.lobbyWithGameGroupsRespData = resp;
  //           }
  //           for (let i = 0; i < this.gameLobbyRequestQueue.length; i++) {
  //             this.gameLobbyRequestQueue[i].next(resp);
  //           }
  //           this.gameLobbyRequestQueue = [];
  //         }), catchError(error => {
  //           return throwError(error);
  //         });
  //       }
  //     })
  //     // return this.mainService.getLobbyGameGroups().pipe(map(resp => {
  //     //   if (resp && resp.length > 0) {
  //     //     this.lobbyWithGameGroupsRespData = resp;
  //     //   }
  //     //   return resp;
  //     // }), catchError(error => {
  //     //   return throwError(error);
  //     // })
  //     // );
  //   }
  // }

  getLobbyListWithGameGroups(isForce?: boolean): Observable<any> {
    if (!_.isEmpty(this.lobbyWithGameGroupsRespData) && !isForce) {
      return of(this.lobbyWithGameGroupsRespData);
    } else {
      return this.mainService.getLobbyGameGroups().pipe(
        map((resp) => {
          if (resp && resp.length > 0) {
            this.lobbyWithGameGroupsRespData = resp;
          }
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  getGameGroupGames(isForce?: boolean): Observable<any> {
    if (!_.isEmpty(this.allAvialableGamesRespData) && !isForce) {
      return of(this.allAvialableGamesRespData);
    } else {
      return this.mainService.getGameGroupGames().pipe(
        map((resp) => {
          if (resp && resp.length > 0) {
            this.allAvialableGamesRespData = resp;
          }
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  /**Mostly after we call toggleFavorite api call...we need to pass true flag
   * to get updated data from backoffice...
   */
  allFavoriteGamesData = [];
  getFavoriteGames(isForce: boolean = false): Observable<any> {
    if (!_.isEmpty(this.allFavoriteGamesRespData) && !isForce) {
      return of(this.allFavoriteGamesRespData);
    } else {
      return this.mainService.getFavoriteGames().pipe(
        map((resp) => {
          if (
            resp["favorite"] &&
            resp["favorite"]["favoriteGamesList"].length > 0
          ) {
            this.allFavoriteGamesRespData = resp;
          }
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    }
  }

  getLastPlayedGames() {
    return this.mainService.getLastedPlayedGames().pipe(
      map((resp) => {
        if (
          resp &&
          resp["status"] === "SUCCESS" &&
          resp["gameDetails"].length > 0
        ) {
          return resp["gameDetails"];
        } else {
          return [];
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getFreeGame(gameId): Observable<any> {
    return this.mainService
      .psGet(endpointConfigurations.lanuchFreeGames_url, { gameSymbol: gameId })
      .pipe(
        map((freegameData) => {
          return freegameData;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  gameRequestQueue = [];
  getGames(isForce: boolean = false): Observable<any> {
    if (isForce) {
      this.allGamesData = [];
    }
    if (this.allGamesData && this.allGamesData.length > 0) {
      return of(this.allGamesData);
    } else {
      return new Observable((observer) => {
        if (this.gameRequestQueue.length > 0) {
          this.gameRequestQueue.push(observer);
        } else {
          this.gameRequestQueue.push(observer);
          this.mainService.psGet(endpointConfigurations.getGames_url).subscribe((data) => {
            if (data && data.length > 0) {
              this.allGamesData = data;
            }
            for (let i = 0; i < this.gameRequestQueue.length; i++) {
              this.gameRequestQueue[i].next(data);
            }
            this.gameRequestQueue = [];
          });
        }
      });
    }
  }

  lobbyCallQueue = [];
  getLobbyListWithGameGroupsp(isForce: boolean = false) {
    if (!_.isEmpty(this.lobbyWithGameGroupsRespData) && !isForce) {
      return this.lobbyWithGameGroupsRespData;
    } else {
      return new Promise((resolve, reject) => {
        if (this.lobbyCallQueue.length > 0) {
          this.lobbyCallQueue.push(resolve);
        } else {
          this.lobbyCallQueue.push(resolve);
          Promise.resolve(this.mainService.getLobbyGameGroupsp())
            .then((response) => {
              if (response && response.length > 0) {
                this.lobbyWithGameGroupsRespData = response;
              }
              for (let callback in this.lobbyCallQueue) {
                this.lobbyCallQueue[callback](response);
              }
              this.lobbyCallQueue = [];
            })
            .catch((error) => {
              for (let callback in this.lobbyCallQueue) {
                this.lobbyCallQueue[callback]([]);
              }
              this.lobbyCallQueue = [];
            });
        }
      });
    }
  }

  gameCallQueue = [];
  getGameGroupGamesp(isForce: boolean = false) {
    if (!_.isEmpty(this.allAvialableGamesRespData) && !isForce) {
      return this.allAvialableGamesRespData;
    } else {
      return new Promise((resolve, reject) => {
        if (this.gameCallQueue.length > 0) {
          this.gameCallQueue.push(resolve);
        } else {
          this.gameCallQueue.push(resolve);
          Promise.resolve(this.mainService.getGameGroupGamesp())
            .then((response) => {
              if (response && response.length > 0) {
                this.allAvialableGamesRespData = response;
              }
              for (let callback in this.gameCallQueue) {
                this.gameCallQueue[callback](response);
              }
              this.gameCallQueue = [];
            })
            .catch((error) => {
              for (let callback in this.gameCallQueue) {
                this.gameCallQueue[callback]([]);
              }
              this.gameCallQueue = [];
            });
        }
      });
    }
  }

  lastPlayedGamesData;
  lastPlayedGamesCallQueue = [];
  getLastedPlayedGamesp(isForce: boolean = false) {
    if (isForce) {
      this.lastPlayedGamesData = undefined;
    }
    if (!_.isEmpty(this.lastPlayedGamesData) && !isForce) {
      return this.lastPlayedGamesData;
    } else {
      return new Promise((resolve, reject) => {
        if (this.lastPlayedGamesCallQueue.length > 0) {
          this.lastPlayedGamesCallQueue.push(resolve);
        } else {
          this.lastPlayedGamesCallQueue.push(resolve);
          Promise.resolve(this.mainService.getLastedPlayedGamesp())
            .then((response) => {
              if (
                response &&
                response["status"] === "SUCCESS" &&
                response["gameDetails"].length > 0
              ) {
                this.lastPlayedGamesData = response["gameDetails"];
              } else {
                this.lastPlayedGamesData = [];
              }
              for (let callback in this.lastPlayedGamesCallQueue) {
                this.lastPlayedGamesCallQueue[callback](
                  this.lastPlayedGamesData
                );
              }
              this.lastPlayedGamesCallQueue = [];
            })
            .catch((error) => {
              for (let callback in this.lastPlayedGamesCallQueue) {
                this.lastPlayedGamesCallQueue[callback]([]);
              }
              this.lastPlayedGamesCallQueue = [];
            });
        }
      });
    }
  }

  /**
   * Below Function Iternates through gameGroups vs games Data & filter each and every gameGroup related
   * games & push into it related game group object
   *
   * So when we run this below code final output will be all the game groupes and it's related games as
   * array with the group object will be returned.
   */

  processGameGroupGames(
    GroupsData,
    games,
    lastPlayedGames = [],
    allFavoriteGames = []
  ) {
    if (GroupsData && games) {
      _.each(GroupsData, (gameGroup, lindex) => {
        if (gameGroup["group_type"] === "manual") {
          _.each(games, (game, gindex) => {
            if (game["gameGroupList"] && game["gameGroupList"].length > 0) {
              _.each(game["gameGroupList"], (group, glindex) => {
                if (group["id"] === gameGroup["id"]) {
                  let fgame = _.clone(game);
                  fgame["gameGroupList"] = game["gameGroupList"][glindex];
                  if (!GroupsData[lindex]["games"]) {
                    GroupsData[lindex]["games"] = [];
                  }
                  GroupsData[lindex]["games"].push(fgame);
                }
              });
            }
          });
        } else if (
          gameGroup["group_type"] === "automatic" &&
          gameGroup["group_sub_type"] === "continue_playing"
        ) {
          if (
            this.isLoggedIn &&
            lastPlayedGames &&
            lastPlayedGames.length > 0
          ) {
            _.each(lastPlayedGames, (typeId) => {
              let game = _.findWhere(games, { beGameTypeId: typeId });
              if (!GroupsData[lindex]["games"]) {
                GroupsData[lindex]["games"] = [];
              }
              if (game) {
                GroupsData[lindex]["games"].push(game);
              }
            });
          } else {
            GroupsData[lindex]["games"] = [];
          }
        } else if (
          gameGroup["group_type"] === "automatic" &&
          gameGroup["group_sub_type"] === "favourite"
        ) {
          if (
            this.isLoggedIn &&
            allFavoriteGames &&
            allFavoriteGames.length > 0
          ) {
            _.each(allFavoriteGames, (favoriteGame) => {
              let game = _.findWhere(games, {
                beGameTypeId: favoriteGame["gameTypeId"],
              });
              if (!GroupsData[lindex]["games"]) {
                GroupsData[lindex]["games"] = [];
              }
              if (game) {
                GroupsData[lindex]["games"].push(game);
              }
            });
          } else {
            GroupsData[lindex]["games"] = [];
          }
        }
      });
      return GroupsData;
    }
  }

  /**This Methods Give each provider level Live , Non-Live & All games List */
  providerLevelGames = {};
  getProviderLevelGames() {
    if (
      this.providerLevelGames &&
      Object.keys(this.providerLevelGames).length > 0
    ) {
      return this.providerLevelGames;
    } else {
      return Promise.resolve(this.getGameGroupGamesp()).then((games) => {
        if (games && games.length > 0) {
          _.each(games, (game) => {
            if (!this.providerLevelGames.hasOwnProperty(game["vendorCode"])) {
              this.providerLevelGames[game["vendorCode"]] = {
                vendorName: game["vendorName"],
                "non-live-games": [],
                "live-games": [],
                all: [],
              };
            }
            this.providerLevelGames[game["vendorCode"]]["all"].push(game);
            if (
              game["gameType"] === "live_dealer" ||
              game["gameType"] === "live_casino"
            ) {
              this.providerLevelGames[game["vendorCode"]]["live-games"].push(
                game
              );
            } else {
              this.providerLevelGames[game["vendorCode"]][
                "non-live-games"
              ].push(game);
            }
          });
        }
        return this.providerLevelGames;
      });
    }
  }

  private isPlayAginClicked = new Subject<boolean>();
  public isPlayAginClicked$ = this.isPlayAginClicked.asObservable();

  private isLastPlayedLiveGamesExistSb = new BehaviorSubject(false);
  public isLastPlayedLiveGamesExistSb$ = this.isLastPlayedLiveGamesExistSb.asObservable();

  broadCastIsLastPlayedLiveGamesExist(flag: boolean) {
    this.isLastPlayedLiveGamesExistSb.next(flag);
  }
}
