import {
  SimpleChange,
  ElementRef,
  OnChanges,
  Component,
  ViewChild,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Services
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-reward-bonus-terms-condtions",
  templateUrl: "./reward-bonus-terms-condtions.component.html",
  styleUrls: ["./reward-bonus-terms-condtions.component.scss"],
})
export class RewardBonusTermsCondtionsComponent implements OnChanges {
  @Input() urlpath: string;
  @ViewChild("dynamicContent", { static: true }) el: ElementRef;
  isLoading: boolean = true;
  isPageAvailable: any;

  constructor(
    private staticPageService: StaticPageService,
    private utility: UtilityService
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["urlpath"]) {
      this.urlpath = changes["urlpath"].currentValue;
      if (this.urlpath) {
        this.loadStaticPage(this.urlpath);
      }
    }
  }

  loadStaticPage(urlpath) {
    /**re-initializing the properties before
     * every request...
     */
    Promise.resolve(this.staticPageService.getStaticPagep(urlpath, false)).then(
      (staticPageData) => {
        /**block to handle if no content is configured for this
         * specific path
         * */
        this.isLoading = false;
        if (
          !Object.keys(staticPageData).length ||
          staticPageData["errorCode"]
        ) {
          this.isPageAvailable = false;
        } else {
          /**block to handle successfull content from cms.. */
          this.isPageAvailable = true;

          const staticContent = this.utility.replaceAll(
            staticPageData.content,
            {
              "{{site_url}}": environment.siteUrl,
              "{{media_url}}": environment.mediaUrl,
            }
          );
          setTimeout(() => {
            if (this.el && this.el.nativeElement) {
              this.el.nativeElement.innerHTML = staticContent;
            }
          });
        }
      }
    );
  }
}
