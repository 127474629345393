import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, Injector, APP_INITIALIZER } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ServiceWorkerModule } from "@angular/service-worker";
import { BrowserModule } from "@angular/platform-browser";
import { LOCATION_INITIALIZED } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import {
  TranslateService,
  TranslateModule,
  TranslateLoader,
} from "@ngx-translate/core";

// Components
import { PromotionContentPageComponent } from "src/app/modules/promotions/components/promotion-content-page/promotion-content-page.component";
import { TransactionHistoryComponent } from "src/app/modules/transactions/components/transaction-history/transaction-history.component";
import { RecentSearchGamesComponent } from "src/app/modules/game-groups/components/recent-search-games/recent-search-games.component";
import { ProvideGamesDisplay } from "src/app/modules/game-groups/components/provider-games-display/provider-games-display.component";
import { SharedTransactionComponent } from "src/app/modules/transactions/components/shared-transaction/shared-transaction.component";
import { PendingWithdrawalComponent } from "src/app/modules/account/components/pending-withdrawal/pending-withdrawal.component";
import { BottomNavigationComponent } from "src/app/modules/navigation/components/bottom-navigation/bottom-navigation.component";
import { PromoRaceCounterComponent } from "src/app/modules/rewards/components/promo-race-counter/promo-race-counter.component";
import { GamePlayWindowComponent } from "src/app/modules/game-groups/components/game-play-window/game-play-window.component";
import { BetRangeFilterComponent } from "src/app/modules/game-groups/components/bet-range-filter/bet-range-filter.component";
import { RewardsIndicatorComponent } from "src/app/modules/rewards/components/rewards-indicator/rewards-indicator.component";
import { LimitsRegulatoryComponent } from "src/app/modules/limits/components/limits-regulatory/limits-regulatory.component";
import { ProviderFilterComponent } from "src/app/modules/game-groups/components/provider-filter/provider-filter.component";
import { LeftNavigationComponent } from "src/app/modules/navigation/components/left-navigation/left-navigation.component";
import { CookieMessageComponent } from "src/app/modules/country-block/components/cookie-message/cookie-message.component";
import { ProofOfPaymentComponent } from "src/app/pages/confirmidentity-kyc/proof-of-payment/proof-of-payment.component";
import { AllPromotionsComponent } from "src/app/modules/promotions/components/all-promotions/all-promotions.component";
import { PromotionCardComponent } from "src/app/modules/promotions/components/promotion-card/promotion-card.component";
import { ForgotPasswordComponent } from "src/app/modules/auth/components/forgot-password/forgot-password.component";
import { IdentityProofComponent } from "src/app/pages/confirmidentity-kyc/identity-proof/identity-proof.component";
import { SessionLimitsComponent } from "src/app/modules/limits/components/session-limits/session-limits.component";
import { SelfExclusionComponent } from "src/app/modules/limits/components/self-exclusion/self-exclusion.component";
import { SourceOfFundComponent } from "src/app/pages/confirmidentity-kyc/source-of-fund/source-of-fund.component";
import { SessionTimerComponent } from "src/app/modules/shared/components/session-timer/session-timer.component";
import { AddressProofComponent } from "src/app/pages/confirmidentity-kyc/address-proof/address-proof.component";
import { BetHistoryComponent } from "src/app/modules/transactions/components/bet-history/bet-history.component";
import { LiveCasinoComponent } from "src/app/modules/game-groups/components/live-casino/live-casino.component";
import { AccountMenuComponent } from "src/app/modules/account/components/account-menu/account-menu.component";
import { ConfirmidentityKycComponent } from "src/app/pages/confirmidentity-kyc/confirmidentity-kyc.component";
import { GameLobbyComponent } from "src/app/modules/game-groups/components/game-lobby/game-lobby.component";
import { PreferencesComponent } from "src/app/modules/shared/components/preferences/preferences.component";
import { AllRewardsComponent } from "src/app/modules/rewards/components/all-rewards/all-rewards.component";
import { NewsTickerComponent } from "src/app/modules/news/components/news-sticker/news-sticker.component";
import { TagGameComponent } from "src/app/modules/game-groups/components/tag-game/tag-game.component";
import { LoginFormComponent } from "src/app/modules/auth/components/login-form/login-form.component";
import { RgLimitsComponent } from "src/app/modules/limits/components/rg-limits/rg-limits.component";
import { FooterComponent } from "src/app/modules/static-pages/components/footer/footer.component";
import { RedirectComponent } from "src/app/modules/shared/components/redirect/redirect.component";
import { HeaderComponent } from "src/app/modules/shared/components/header/header.component";
import { LimitsComponent } from "src/app/modules/limits/components/limits/limits.component";
import { HomeComponent } from "src/app/modules/shared/components/home-page/home.component";
import { BonusComponent } from "src/app/modules/rewards/components/bonus/bonus.component";
import { TimeComponent } from "src/app/modules/shared/components/time/time.component";
import { AppComponent } from "src/app/app.component";

// Configurations
import { localToCmsLanguageConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Interceptors
import { AuthenticationInterceptor } from "src/app/modules/auth/interceptors/authentication.interceptor";

// Modules
import { MbscModule } from "src/app/modules/shared/libraries/mobiscroll/js/mobiscroll.custom-4.1.0.min";
import { DynamicFormModule } from "src/app/modules/dynamic-form/dynamic-form.module";
import { GameGroupsModule } from "src/app/modules/game-groups/game-groups.module";
import { SharedModule } from "src/app/modules/shared/shared.module";
import { AppRoutingModule } from "src/app/app-routing.module";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { PromotionsService } from "src/app/modules/promotions/services/promotions.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { RewardsService } from "src/app/pages/rewards/service/reward.service";
import { LoginService } from "src/app/modules/auth/services/login.service";
import { MainService } from "src/app/services/main-service/main.service";
import { EmitterService } from "src/app/services/emitter.service";

@NgModule({
  declarations: [
    PromotionContentPageComponent,
    TransactionHistoryComponent,
    ConfirmidentityKycComponent,
    RecentSearchGamesComponent,
    SharedTransactionComponent,
    PendingWithdrawalComponent,
    BottomNavigationComponent,
    RewardsIndicatorComponent,
    LimitsRegulatoryComponent,
    PromoRaceCounterComponent,
    GamePlayWindowComponent,
    LeftNavigationComponent,
    ForgotPasswordComponent,
    ProofOfPaymentComponent,
    ProviderFilterComponent,
    BetRangeFilterComponent,
    SelfExclusionComponent,
    SessionLimitsComponent,
    IdentityProofComponent,
    PromotionCardComponent,
    AllPromotionsComponent,
    CookieMessageComponent,
    AddressProofComponent,
    SourceOfFundComponent,
    SessionTimerComponent,
    PreferencesComponent,
    AccountMenuComponent,
    PreferencesComponent,
    BetHistoryComponent,
    BetHistoryComponent,
    ProvideGamesDisplay,
    AllRewardsComponent,
    NewsTickerComponent,
    LiveCasinoComponent,
    GameLobbyComponent,
    LoginFormComponent,
    RgLimitsComponent,
    RedirectComponent,
    TagGameComponent,
    HeaderComponent,
    FooterComponent,
    LimitsComponent,
    BonusComponent,
    HomeComponent,
    TimeComponent,
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    DynamicFormModule,
    AppRoutingModule,
    HttpClientModule,
    GameGroupsModule,
    BrowserModule,
    SharedModule,
    FormsModule,
    MbscModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [
    TranslationService,
    UserDetailsService,
    GameGroupsService,
    StaticPageService,
    PromotionsService,
    GamePlayService,
    AccountService,
    RewardsService,
    EmitterService,
    CommonService,
    LoginService,
    MainService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `${environment.apiUrl}${environment.langpath}`,
    `.json?cb=${new Date().getTime()}`
  );
}

/*
  This AppInitializer Factory is used for making API call 
  during app initialization itself

  Basically Here we apss default language of our application & also 
  store them in local storage for futher use.. 
*/
export function appInitializerFactory(
  translate: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );

      locationInitialized.then(() => {
        const defaultLang = environment.defaultLang;

        let langToSet = localStorage.getItem("langCode")
          ? localStorage.getItem("langCode")
          : defaultLang;

        let data = localToCmsLanguageConfigurations[langToSet];

        translate.setDefaultLang(data);

        translate.use(data).subscribe(
          () => {},
          (err) => {},
          () => {
            resolve(null);
          }
        );
      });
    });
}
