import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  SimpleChange,
  HostListener,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Input,
} from "@angular/core";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import * as _ from "underscore";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-showreel",
  templateUrl: "./showreel.component.html",
  styleUrls: ["./showreel.component.scss"],
})
export class ShowreelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() gameGroupData;
  @Input() callingFromlobby;
  isLoading: boolean = false;
  games: any;
  langCodeSubscription: Subscription;
  langCode: string;
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  swiperLazyConfig: {
    totalNumberOfGamesExist: number;
    indexNumberOfGameTilesToshow: number;
    numberOfExtraGamesToLoadForSlide: number;
    forwardslideIndex: number;
    backwardslideIndex: number;
  } = {
    totalNumberOfGamesExist: 0,
    indexNumberOfGameTilesToshow: 8,
    numberOfExtraGamesToLoadForSlide: 4,
    forwardslideIndex: 0,
    backwardslideIndex: 0,
  };
  /*Here we are stopping tab default behaviour because of swiper arrow moving to middle*/
  @HostListener("keydown", ["$event"]) onKeydown(e) {
    if (e.which == 9 && document.getElementsByClassName("swiper-container")) {
      e.preventDefault();
    }
  }
  constructor(
    private utility: UtilityService,
    private router: Router,
    private commonService: CommonService,
    private gamePlayService: GamePlayService,
    private translationService: TranslationService,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
  }

  swiperVidoeShow: SwiperConfigInterface = {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      2000: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 16,
      },
      1599: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 16,
      },
      767: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 8,
        freeMode: false,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 8,
        freeMode: false,
      },
    },
  };

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["gameGroupData"]) {
      this.gameGroupData = changes["gameGroupData"].currentValue;
      if (this.gameGroupData["group_type"] === "manual") {
        this.games = this.utility.sortGameByCountryOrder(
          this.gameGroupData["games"]
        );
      } else {
        this.games = this.gameGroupData["games"];
      }
      if (this.games && this.games.length > 0) {
        this.swiperLazyConfig["totalNumberOfGamesExist"] = this.games.length;
      }
    }
    if (changes["callingFromlobby"]) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  navigateAllGamesPage() {
    this.utility.navigateAllGamesPage(this.gameGroupData);
  }

  gameLaunch(gameName, hasDemo) {
    gameName = this.utility.convertGameNameToUrl(gameName);
    this.gamePlayService.clearGameWindowData();
    setTimeout(() => {
      if (gameName && (this.isLoggedIn || (!this.isLoggedIn && hasDemo))) {
        this.router.navigate([
          this.langCode +
            "/" +
            this.translationService.instant("url.game") +
            "/" +
            gameName,
        ]);
      } else {
        this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
      }
    });
  }

  triggerGameLaunch(data) {
    this.gamePlayService.setGameCalledfrom(data["callingFrom"]);
    this.gameLaunch(data["name"], data["hasDemo"]);
  }

  /**Function for Lazy loading of games goes here
   * on every next click of silde below function will be trigerred
   * & calculated how many game have to load amount that total games
   * present
   * This works on bases of swiperLazyConfig defined above...
   */
  onSwiperNextEvent() {
    if (this.swiperLazyConfig["backwardslideIndex"]) {
      this.swiperLazyConfig["backwardslideIndex"] =
        this.swiperLazyConfig["backwardslideIndex"] - 1;
      return;
    }
    if (
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] <
      this.swiperLazyConfig["totalNumberOfGamesExist"]
    ) {
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] =
        this.swiperLazyConfig["indexNumberOfGameTilesToshow"] +
        this.swiperLazyConfig["numberOfExtraGamesToLoadForSlide"];
      this.swiperLazyConfig["forwardslideIndex"] =
        this.swiperLazyConfig["forwardslideIndex"] + 1;
    }
  }

  onSwiperPrevEvent() {
    this.swiperLazyConfig["backwardslideIndex"] =
      this.swiperLazyConfig["backwardslideIndex"] + 1;
  }

  ngOnDestroy() {
    this.langCodeSubscription.unsubscribe();
    this.logOutCompleteSubscription.unsubscribe();
    this.loginCompleteSubscription.unsubscribe();
  }
}
