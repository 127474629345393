import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-preferences",
  templateUrl: "./preferences.component.html",
  styleUrls: ["./preferences.component.scss"],
})
export class PreferencesComponent implements OnInit, OnDestroy {
  subscriptionPreferenceForm = this.formBuilder.group({
    emailSubscribed: [false],
    mobileSubscribed: [false],
  });
  errorMessageText: string = "";
  emailId: string = "";
  mobileNumber: string = "";
  profileDetails;
  userDataSub: Subscription;
  updateSubscriptionPreSub: Subscription;
  getSubscriptionPreferenceSub: Subscription;
  loader: any = {
    emailSubscription: { disable: false, sub: false },
    mobileSubcription: { disable: false, sub: false },
  };
  constructor(
    private utility: UtilityService,
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private userDetailsService: UserDetailsService,
    private commonService: CommonService,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    this.getUserProfileData();
    this.getSubscriptionPreference("");
  }

  getUserProfileData() {
    if (!this.userDetailsService.getUserProfileDetails()) {
      this.profileDetails = this.userDetailsService.getUserProfileDetails();
      this.setUserDetails(this.profileDetails);
    } else {
      this.userDataSub = this.commonService.getUserData().subscribe((data) => {
        this.profileDetails = data;
        this.setUserDetails(this.profileDetails);
      });
    }
  }

  setUserDetails(profileData) {
    if (profileData) {
      this.emailId = profileData["email"];
      this.mobileNumber =
        "+" + profileData["cellPhoneAreaCode"] + profileData["cellPhone"];
    }
  }

  updateSubscriptionPreference(callingId) {
    this.togglePrferenceLoader(callingId, true);
    let data = this.utility.formControlToParams(
      this.subscriptionPreferenceForm,
      {}
    );
    this.updateSubscriptionPreSub = this.accountService
      .updateSubscriptionPreference(data)
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS") {
          this.getSubscriptionPreference(callingId);
        } else {
          if (callingId === "emailSubscription") {
            this.updateSubscriptionForm(
              !data["emailSubscribed"],
              data["mobileSubscribed"],
              callingId
            );
          } else {
            this.updateSubscriptionForm(
              data["emailSubscribed"],
              !data["mobileSubscribed"],
              callingId
            );
          }
          this.errorMessageText = this.translationService.instant(
            "preference.error_handle_msg"
          );
          setTimeout(() => {
            this.errorMessageText = undefined;
          }, 5000);
        }
      });
  }

  getSubscriptionPreference(callingId?) {
    this.togglePrferenceLoader(callingId, true);
    this.getSubscriptionPreferenceSub = this.accountService
      .getSubscriptionPreference()
      .subscribe((data) => {
        if (data && data["status"] == "SUCCESS") {
          this.updateSubscriptionForm(
            data["emailSubscribed"],
            data["mobileSubscribed"],
            callingId
          );
        } else {
          this.errorMessageText = this.translationService.instant(
            "preference.error_handle_msg"
          );
          setTimeout(() => {
            this.errorMessageText = undefined;
          }, 5000);
        }
      });
  }

  updateSubscriptionForm(emailStatus, mobileStatus, callingId?) {
    this.subscriptionPreferenceForm.controls["emailSubscribed"].setValue(
      emailStatus
    );
    this.subscriptionPreferenceForm.controls["mobileSubscribed"].setValue(
      mobileStatus
    );
    if (callingId) {
      this.togglePrferenceLoader(callingId, false);
    }
  }

  togglePrferenceLoader(callingId, isEnable) {
    if (isEnable && callingId) {
      this.loader[callingId]["sub"] = true;
      this.loader[callingId]["disable"] = true;
    } else if (callingId) {
      this.loader[callingId]["sub"] = false;
      this.loader[callingId]["disable"] = false;
    }
  }

  ngOnDestroy() {
    if (this.userDataSub) {
      this.userDataSub.unsubscribe();
    }
    if (this.updateSubscriptionPreSub) {
      this.updateSubscriptionPreSub.unsubscribe();
    }
    if (this.getSubscriptionPreferenceSub) {
      this.getSubscriptionPreferenceSub.unsubscribe();
    }
  }
}
