import {
  SimpleChange,
  Component,
  OnChanges,
  OnInit,
  Input,
} from "@angular/core";

// Libraries
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-jackpot-games",
  templateUrl: "./jackpot-games.component.html",
  styleUrls: ["./jackpot-games.component.scss"],
})
export class JackpotGamesComponent implements OnInit, OnChanges {
  @Input() gameGroupData;
  @Input() callingFromlobby;
  isLoading: boolean;
  filteredGames: any;
  games: any;
  swiperLazyConfig: {
    totalNumberOfGamesExist: number;
    indexNumberOfGameTilesToshow: number;
    numberOfExtraGamesToLoadForSlide: number;
    forwardslideIndex: number;
    backwardslideIndex: number;
  } = {
    totalNumberOfGamesExist: 0,
    indexNumberOfGameTilesToshow: 14,
    numberOfExtraGamesToLoadForSlide: 7,
    forwardslideIndex: 0,
    backwardslideIndex: 0,
  };

  constructor(
    private utility: UtilityService,
    private gameGroupsService: GameGroupsService
  ) {}

  swiperJackpotCasino: SwiperConfigInterface = {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
    freeMode: true,
    navigation: true,
    watchSlidesVisibility: true,
    observer: true,
    observeParents: true,
    resistanceRatio: 0,
    breakpoints: {
      // breakpoints works like max-width css
      2000: {
        slidesPerView: 7,
        slidesPerGroup: 7,
        spaceBetween: 16,
      },
      1599: {
        slidesPerView: 6,
        slidesPerGroup: 6,
        spaceBetween: 16,
      },
      1365: {
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 16,
      },
      767: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 8,
      },
      480: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 8,
      },
    },
  };

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["gameGroupData"]) {
      this.gameGroupData = changes["gameGroupData"].currentValue;
      this.gameGroupData = changes["gameGroupData"].currentValue;
      if (this.gameGroupData["group_type"] === "manual") {
        this.games = this.utility.sortGameByCountryOrder(
          this.gameGroupData["games"]
        );
      } else {
        this.games = this.gameGroupData["games"];
      }
      if (this.games && this.games.length > 0) {
        this.swiperLazyConfig["totalNumberOfGamesExist"] = this.games.length;
      }
    }
    if (changes["callingFromlobby"]) {
      this.callingFromlobby = changes["callingFromlobby"].currentValue;
    }
  }

  /**Function for Lazy loading of games goes here
   * on every next click of silde below function will be trigerred
   * & calculated how many game have to load amount that total games
   * present
   * This works on bases of swiperLazyConfig defined above...
   */
  onSwiperNextEvent() {
    if (this.swiperLazyConfig["backwardslideIndex"]) {
      this.swiperLazyConfig["backwardslideIndex"] =
        this.swiperLazyConfig["backwardslideIndex"] - 1;
      return;
    }
    if (
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] <
      this.swiperLazyConfig["totalNumberOfGamesExist"]
    ) {
      this.swiperLazyConfig["indexNumberOfGameTilesToshow"] =
        this.swiperLazyConfig["indexNumberOfGameTilesToshow"] +
        this.swiperLazyConfig["numberOfExtraGamesToLoadForSlide"];
      this.swiperLazyConfig["forwardslideIndex"] =
        this.swiperLazyConfig["forwardslideIndex"] + 1;
    }
  }

  onSwiperPrevEvent() {
    this.swiperLazyConfig["backwardslideIndex"] =
      this.swiperLazyConfig["backwardslideIndex"] + 1;
  }
  navigateAllGamesPage() {
    this.utility.navigateAllGamesPage(this.gameGroupData);
  }
}
