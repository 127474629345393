import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.scss"],
})
export class PageNotFoundComponent implements OnInit {
  langCode: string;
  langCodeSubscription: Subscription;
  constructor(
    private utility: UtilityService,
    private router: Router,
    public translationService: TranslationService
  ) {
    this.langCode = this.utility.getLangCode();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
  }

  ngOnInit() {}

  gotoHome() {
    this.router.navigate([this.langCode + "/casino"]);
  }
}
