import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  AfterViewInit,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
  OnInit,
} from "@angular/core";

// Configurations
import {
  excludeLanguageOptionsConfigurations,
  cmsToLocalLanguageConfigurations,
  localToCmsLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { StaticPageService } from "src/app/modules/static-pages/services/static-page.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit, AfterViewInit {
  @ViewChild("footerConatiner", { static: false }) footerConatiner: ElementRef;
  staticContent = "";
  isLangOpen: boolean;
  availableLanguages: string[];
  langCode: string;
  cmsToLocalLang: any;
  countryCode: string = "";
  acceptedLanguages: string[];
  excludedLangauges: any;
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  @HostListener("document:click", ["$event"])
  @HostListener("document:touchend", ["$event"])
  onClick(event) {
    if (event.target.classList.contains("overlay-backdrop--footer")) {
      this.isLangOpen = false;
    }
  }

  @HostListener("window:popstate", ["$event"])
  onBrowserBackBtnClose(event: Event) {
    let languageFromUrl = window.location.pathname.split("/")[1];
    if (event.type === "popstate" && languageFromUrl !== this.langCode) {
      this.changeLanguageHandler(languageFromUrl);
    }
  }

  localeDisplayName = {
    // 'en-row': 'English',
    // 'de-de': 'German',
    // 'en-ca': 'Canada',
    // 'fi-fi': 'Finland',
    // 'nb-no': 'Norwegian',
    // 'en-nz': 'New Zealand',
    // 'en-eu': 'NetherLand',
    // 'ja-jp':'Japanese',
    "fi-fi": "Finland",
    "et-ee": "Estonia",
  };
  constructor(
    private staticPageService: StaticPageService,
    private translationService: TranslationService,
    private router: Router,
    private utility: UtilityService,
    private commonService: CommonService
  ) {
    this.excludedLangauges = excludeLanguageOptionsConfigurations;
    this.filterPreferredLanguages();
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.loginCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
  }

  ngOnInit() {
    this.cmsToLocalLang = cmsToLocalLanguageConfigurations;
    this.langCode = this.utility.getLangCode();
    this.translationService.langCode$.subscribe((lang) => {
      if (this.langCode !== lang) {
        this.loadFooterContent(true);
      }
      this.langCode = lang;
    });
    this.availableLanguages = this.translationService.getLangs();
    this.loadFooterContent();
  }
  ngAfterViewInit() {
    if (this.footerConatiner) {
      this.utility.footerConatiner = this.footerConatiner.nativeElement.offsetHeight;
    }
  }

  /**
   * Here we remove Netherland & german option for locale drowdrop
   * as per client requirment,but user can use it from url directly
   * just we don't display in locale downdrop.
   */

  filterPreferredLanguages() {
    this.acceptedLanguages = supportedLanguagesList();
    console.log("**accepted languages", this.acceptedLanguages);
    this.excludedLangauges.forEach((languageCode) => {
      this.acceptedLanguages.splice(
        this.acceptedLanguages.indexOf(languageCode),
        1
      );
    });
  }

  loadFooterContent(isForce?) {
    let url = "footer";
    Promise.resolve(this.staticPageService.getStaticPagep(url, isForce)).then(
      (footerData) => {
        if (
          footerData &&
          Object.keys(footerData).length > 0 &&
          !footerData["errorCode"] &&
          footerData["content"]
        ) {
          this.staticContent = this.utility.replaceAll(footerData.content, {
            "{{site_url}}": environment.siteUrl,
            "{{media_url}}": environment.mediaUrl,
          });
        }
      }
    );
  }

  dynamicFooterContent(event) {
    const routerLink = event.target.getAttribute("routerLink");
    if (routerLink && routerLink.length > 0) {
      this.utility.cmsRoutingHandler(routerLink);
    }
  }

  changeLanguageHandler(lang: string) {
    this.langCode = lang;
    this.translationService.use(localToCmsLanguageConfigurations[this.langCode]);
    localStorage.setItem("langCode", lang);
    this.reloadPage();
  }

  reloadPage() {
    const params = this.utility.getQueryParams();
    this.navigateToLandingPage(params);
    setTimeout((_) => {
      window.location.reload();
    });
  }

  navigateToLandingPage(params = {}) {
    this.router.navigate([this.langCode + "/casino"], { queryParams: params });
  }
  navigateAfterSetLang(path) {
    if (window.location.search.length > 0) {
      const params = this.utility.getQueryParams();
      this.router.navigate([path], { queryParams: params });
    } else {
      this.router.navigate([path]);
    }
  }

  ngOnDestroy() {
    if (this.utility.footerConatiner) {
      this.utility.footerConatiner = 0;
    }
  }
}
