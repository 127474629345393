import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  SimpleChange,
  EventEmitter,
  Component,
  OnDestroy,
  OnChanges,
  Output,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { JackpotService } from "src/app/modules/game-groups/services/jackpot.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-game-card",
  templateUrl: "./game-card.component.html",
  styleUrls: ["./game-card.component.scss"],
})
export class GameCardComponent implements OnChanges, OnDestroy {
  @Input() gameData;
  @Input() isJackpot;
  @Input() game_asset_style;
  @Input() callingFrom;
  @Output() callBackAction: EventEmitter<any> = new EventEmitter();
  gamesUrlPath: string = environment.gamesUrlPath;
  isLoggedIn: boolean;
  langCode: string;
  loginCompleteSubscription: Subscription;
  langCodeSubscription: Subscription;
  mircoGamingJackpotDataSubscription: Subscription;

  /**
   * Below all are the basic assest type's which decide How the game card
   * should be displayed (mostly in terms of size) & image size both
   *
   * Basically use this constant values to attach at end of the game image url
   * to render proper image from CMS
   */
  ImageEndTag = {
    poster: "_poster",
    "default-casino": "_casino",
    "default-live-casino": "_default_live_casino",
    "poster-jackpot": "_poster",
    "poster-live-casino": "_poster",
  };

  logOutCompleteSubscription: Subscription;
  imageStypeTag: any;
  jackpotData: any;
  imgixParams: string;

  constructor(
    private utility: UtilityService,
    private router: Router,
    private gamePlayService: GamePlayService,
    private commonService: CommonService,
    private translationService: TranslationService,
    private jackpotService: JackpotService,
    private userDetailsService: UserDetailsService,
    private currencyPipe: CurrencyFormatPipe,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );

    this.translationService.langCodeSb$.subscribe((langcode) => {
      this.langCode = langcode;
    });
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["gameData"]) {
      this.gameData = changes["gameData"].currentValue;
    }
    if (changes["isJackpot"]) {
      this.isJackpot = changes["isJackpot"].currentValue;
    }
    if (changes["game_asset_style"]) {
      let styleTag = changes["game_asset_style"].currentValue;
      this.imageStypeTag = this.ImageEndTag[styleTag];
    }
    if (changes["callingFrom"]) {
      this.callingFrom = changes["callingFrom"].currentValue;
    }
  }

  ngOnInit() {
    if (this.isJackpot) {
      this.mircoGamingJackpotDataSubscription = this.jackpotService.mircoGamingJackpotData$.subscribe(
        (data) => {
          this.jackpotData = data;
          setTimeout(() => this.jackpotNumCount());
        }
      );
    }
  }

  ngAfterViewInit() {
    window["prerenderReady"] = true;
  }

  gameLauncher(event, gameName, hasDemo, callingFrom): void {
    gameName = this.utility.convertGameNameToUrl(gameName);
    let currentGameSlug = this.utility.getDecodedCurrentPath().split("/");
    if (currentGameSlug[currentGameSlug.length - 1] != gameName) {
      this.gamePlayService.clearGameWindowData();
      if (callingFrom === "freegame") {
        event.stopPropagation();
      }
      this.gamePlayService.setGameCalledfrom(callingFrom);
      setTimeout(() => {
        if (
          (gameName && callingFrom === "realgame" && this.isLoggedIn) ||
          (gameName && callingFrom === "freegame" && hasDemo)
        ) {
          this.router.navigate([
            this.langCode +
              "/" +
              this.translationService.instant("url.game") +
              "/" +
              gameName,
          ]);
        } else if (gameName) {
          /**We use below function to store clicked game related data,
           * so after user login we can navigate him to that exactly place
           *
           * This case occurs when user try to live casino or game
           * which don't have demo option with out login
           */
          this.commonService.setNavigateAfterLogin({
            type: "url",
            data: { navigateTo: "gameWindow", path: gameName },
          });
          this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
        }
      });
    } else if (
      currentGameSlug[currentGameSlug.length - 1] === gameName &&
      callingFrom != this.gamePlayService.getGameCalledfrom()
    ) {
      if (callingFrom === "freegame") {
        event.stopPropagation();
      }
      // user to set value like realgame or freegame/demogame
      this.gamePlayService.setGameCalledfrom(callingFrom);

      /**Below Event is used to reload or switch the game from demo to real & viva versa
       * we need this event because when user is in game play window &
       * playing 7 piggies(demo game) & want to play realgame by click on top btn or from tag managment
       * angular will not understand to reload route as URL in both cases will be same
       * so we use this event method to force relaod game window iframe
       */
      this.gamePlayService.broadCastRelanuchGame(true);
    }
    /**
     * Below code help you to close the search window after user
     * launch any game from search area.
     */
    if (
      this.callingFrom === "recent-search" ||
      this.callingFrom === "game-window-tag"
    ) {
      this.callBackAction.emit();
    }
  }

  getGameIdfromGameName(gameName, gameVendor) {}

  jackpotNumCount() {
    let self = this;
    $(".jamount").each(function () {
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 5000,
            easing: "swing",
            step: function (now) {
              if (!isNaN(now))
                $(this).text(
                  self.currencyPipe.transform(
                    now.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,"),
                    false
                  )
                );
            },
          }
        );
    });
  }

  ngOnDestroy() {
    this.loginCompleteSubscription.unsubscribe();
    this.logOutCompleteSubscription.unsubscribe();
    if (this.mircoGamingJackpotDataSubscription) {
      this.mircoGamingJackpotDataSubscription.unsubscribe();
    }
  }
}
