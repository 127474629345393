import { Component, ViewEncapsulation, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { LivespinsService } from "src/app/modules/livespins/services/livespins.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnDestroy {
  activeLobby: string = "casino";
  routerSub: Subscription;
  allGamesBanner = true;
  showSortByProviders = true;
  showSortByReleaseDate = false;
  filterByProvider = false;
  langCodeSubscription: Subscription;

  constructor(
    private translationService: TranslationService,
    private livespinsService: LivespinsService,
    private commonService: CommonService,
    private utility: UtilityService,
  ) {
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langCode) => {
        this.updateSEOContent();
      }
    );
    
    this.livespinsService.onInitialise();
  }

  onSelectedLobby(name) {
    this.activeLobby = name;
  }

  ngAfterViewInit() {
    this.updateSEOContent();
  }

  updateSEOContent() {
    this.utility.setSEO({
      metaTitle: this.translationService.instant(
        "SEO.casinoHomePage_metaTitle"
      ),
      metaDescription: this.translationService.instant(
        "SEO.casinoHomePage_metaDescription"
      ),
      metaTags: this.translationService.instant("SEO.casinoHomePage_metaTag"),
      title: this.translationService.instant("SEO.casinoHomePage_title"),
    });
    this.commonService.setCanonicalURL("homepage");
  }

  ngOnDestroy() {
    this.langCodeSubscription.unsubscribe();
  }
}
