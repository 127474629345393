import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import {
  zimplerCountryCodeConfigurations,
  languageToPnpConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as $ from "jquery";

// Models
import { Banks } from "src/app/modules/account/models/banks.model";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { CustomValidatorService } from "src/app/modules/shared/services/custom-validator.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { GtmService } from "src/app/services/gtm-service/gtm.service";
import { EmitterService } from "src/app/services/emitter.service";

@Component({
  selector: "app-pay-n-play-cashier",
  templateUrl: "./pay-n-play-cashier.component.html",
  styleUrls: ["./pay-n-play-cashier.component.scss"],
})
export class PayNPlayCashierComponent extends FormValidationComponent
  implements OnInit, OnDestroy {
  
  // Objects
  banks: Banks = {
    fi: {
      nordea: true,
      oppohjola: true,
      aktia: true,
      alandsbanken: true,
      saastopankki: true,
      spankki: true,
      omasp: true,
      danskebank: true,
      poppankki: true,
      handelsbanken: true,
    },
    ee: {
      swedBank: true,
      sebBank: true,
      luminorBank: true,
      citadele: false,
      coopBank: false,
    },
  };

  minDWLimitValue;
  maxDWLimitValue;
  minDPLimitValue: number;
  maxDPLimitValue: number;
  depositAmount: number = 0;
  windowType: string;
  paymentProcessStep: string = "step1";
  transactionType;
  isInitiateDepositTriggred: boolean = false;
  timerInstance: NodeJS.Timer;
  callingFrom: string;
  calledFromComponent: string;
  isButtonLoader: boolean = false;
  langCode: string;
  currencySymbol: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isFocus: boolean = false;
  isVisible: boolean = false;
  isLoggedIn: any;
  errorMessage;
  avialableDepositBonusDetails: any;
  selectedBonus: any;
  isBonusData: boolean = true;

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }

  @HostListener("window:message", ["$event"]) onMessage(e) {
    if (e && e.data && e.data.message === "deposit-transactionStatus") {
      this.paymentProcessStep = "step4";
      if (!this.isInitiateDepositTriggred) {
        this.isInitiateDepositTriggred = true;
      }
      this.getPNPUserFromTransaction();
    }

    if (e && e.data && e.data.message === "withdraw-transactionStatus") {
      this.paymentProcessStep = "step4";
      if (!this.isInitiateDepositTriggred) {
        this.isInitiateDepositTriggred = true;
      }
      this.getWithdrawTransactionStatus();
    }
  }

  @HostListener("keydown", ["$event"]) onKeydown(e) {
    if (document.getElementById("formInputValue")) {
      var inputValue =
        (document.getElementById("formInputValue") as HTMLInputElement).value
          .length - 1;
      if (e.which === 8 && inputValue <= 0) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private customValidators: CustomValidatorService,
    private userDetailsService: UserDetailsService,
    private paynplayCashierService: PayNPlayCashierService,
    private emitterService: EmitterService,
    public utils: UtilityService,
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private router: Router,
    private gtmService: GtmService
  ) {
    super(utils);
    this.userDetailsService.currencySymbolSb$
      .pipe(takeUntil(this.destroy$))
      .subscribe((currencySymbol) => {
        this.currencySymbol = currencySymbol;
      });
    this.isLoggedIn = this.utils.isUserLoggedIn();
    this.commonService.loginComplete$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        if (this.isLoggedIn) {
          this.getCashoutTxnLimits();
        }
      });
    this.commonService.logOutComplete$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.isLoggedIn = false;
      });
    /**This call is need on refresh to check emial Id updated by user or not
     * If not we open a popup & ask user to update it..
     */
    this.checkLoginStatus();
    this.paynplayCashierService.initiateLogin$
      .pipe(takeUntil(this.destroy$))
      .subscribe((txnType) => {
        this.updateLimits();
        this.zimplerPayAndPlayInit(txnType);
      });

    this.paynplayCashierService.initiateDespoit$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.callingFrom = "deposit";
        this.calledFromComponent = data["callingFrom"];
        if (
          data &&
          [
            "accountMenu",
            "bottomNavigation",
            "leftMenu",
            "deepLink",
            "payment-rewards",
            "all-rewards",
          ].indexOf(data["callingFrom"]) > -1
        ) {
          this.clearIframeData();
          this.paymentProcessStep = "step1";
          this.updateLimits();
          this.isInitiateDepositTriggred = true;
          this.addOverFlowHideen();
          if (this.isLoggedIn) {
            this.checkForActiveBonus(true);
          }
        } else if (data["callingFrom"] === "customBanner") {
          this.callingFrom = "customBanner";
          this.paymentProcessStep = "step4";
          this.updateLimits();
          this.zimplerForm.controls["amount"].setValue(data["amount"]);
          this.isInitiateDepositTriggred = true;
          if (this.isLoggedIn) {
            this.showBonusCards();
          } else {
            this.zimplerPayAndPlayInit();
          }
        }
      });

    this.paynplayCashierService.initiateWithdraw$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.callingFrom = "withdraw";
        this.paymentProcessStep = "step1";
        this.updateLimits();
        this.addOverFlowHideen();
        this.isInitiateDepositTriggred = true;
      });
    /**@@
     * This condition/blocked code is for only mobile device
     * as zimpler thrid party url we open in new window instead of iframe
     */
    this.paynplayCashierService.processUserTransaction$
      .pipe(takeUntil(this.destroy$))
      .subscribe((message) => {
        if (message && message === "deposit-transactionStatus") {
          this.paymentProcessStep = "step4";
          this.isInitiateDepositTriggred = true;
          this.getPNPUserFromTransaction();
        }

        if (message && message === "withdraw-transactionStatus") {
          this.paymentProcessStep = "step4";
          this.isInitiateDepositTriggred = true;
          this.getWithdrawTransactionStatus();
        }
      });
    this.langCode = this.utils.getLangCode();
    this.translationService.langCodeSb$
      .pipe(takeUntil(this.destroy$))
      .subscribe((langCode) => {
        this.langCode = langCode;
      });
    this.emitterService.closeCashierPopup$
      .pipe(takeUntil(this.destroy$))
      .subscribe((flag) => {
        if (flag) {
          this.backToCashier();
        }
      });
  }
  zimplerForm = this.formBuilder.group({
    amount: ["", [this.customValidators.required]],
  });

  decideDepositFlow() {
    if (this.callingFrom === "deposit" || this.callingFrom === "customBanner") {
      this.zimplerPayAndPlayInit();
    } else if (this.callingFrom === "withdraw") {
      this.zimplerWithdrawInit();
    }
  }

  updateLimits() {
    if (this.callingFrom === "deposit" || this.callingFrom === "customBanner") {
      this.updateDepositLimits();
    }
    if (this.callingFrom === "withdraw") {
      this.updateWithdrawLimits();
    }
  }

  updateDepositLimits() {
    let depositLimits = this.paynplayCashierService.depositLimits;
    if (depositLimits["minLimit"] && depositLimits["maxLimit"]) {
      this.applyMinMaxFromValidation(
        depositLimits["minLimit"],
        depositLimits["maxLimit"],
        "deposit"
      );
    } else {
      this.getPNPMinMaxTxnLimits();
    }
  }

  updateWithdrawLimits() {
    let withdrawLimits = this.paynplayCashierService.cashOutLimits;
    if (withdrawLimits["minLimit"] && withdrawLimits["maxLimit"]) {
      this.applyMinMaxFromValidation(
        withdrawLimits["minLimit"],
        withdrawLimits["maxLimit"],
        "withdraw"
      );
    } else {
      this.getCashoutTxnLimits(true);
    }
  }

  applyMinMaxFromValidation(minLimit, maxLimit, callingFrom) {
    if (callingFrom === "withdraw") {
      let withdrawableBalance: number = this.userDetailsService.getWithdrawableBalance();
      this.zimplerForm.controls["amount"].setValidators(
        this.customValidators.minValueNumber(
          minLimit,
          maxLimit,
          callingFrom,
          withdrawableBalance
        )
      );
    } else {
      this.zimplerForm.controls["amount"].setValidators(
        this.customValidators.minValueNumber(minLimit, maxLimit, callingFrom)
      );
    }
  }

  checkLoginStatus() {
    Promise.resolve(this.commonService.getLoginStatus()).then((data) => {
      if (data && data["status"]) {
        this.checkIsEmailUpdated();
      }
    });
  }

  ngOnInit() {
    this.getPNPMinMaxTxnLimits();
    if (this.isLoggedIn) {
      this.getCashoutTxnLimits();
    }
    this.getWindowType();
  }
  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  getPNPMinMaxTxnLimits() {
    let minMaxRequestData = {
      option: "ZIMPLER_DIRECT",
      currency: this.userDetailsService.getCurrencyCode(),
    };
    this.paynplayCashierService
      .getPNPMinMaxTxnLimits(minMaxRequestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data && data["minLimit"] && data["maxLimit"]) {
          this.minDPLimitValue = data["minLimit"];
          this.maxDPLimitValue = data["maxLimit"];
          this.applyMinMaxFromValidation(
            data["minLimit"],
            data["maxLimit"],
            "deposit"
          );
        }
      });
  }

  getCashoutTxnLimits(isapplyValidation = false) {
    let cashoutTxnLimitsReq = {
      txnType: "cashout",
    };
    this.paynplayCashierService
      .getCashoutTxnLimits(cashoutTxnLimitsReq)
      .pipe(takeUntil(this.destroy$))
      .subscribe((cashOutData) => {
        if (cashOutData && cashOutData["minLimit"] && cashOutData["maxLimit"]) {
          this.minDWLimitValue = cashOutData["minLimit"];
          this.maxDWLimitValue = cashOutData["maxLimit"];
          if (isapplyValidation) {
            this.applyMinMaxFromValidation(
              cashOutData["minLimit"],
              cashOutData["maxLimit"],
              "withdraw"
            );
          }
        }
      });
  }

  showBonusCards() {
    this.paymentProcessStep = "step2";
    this.addOverFlowHideen();
  }

  zimplerPayAndPlayInit(txnType = "DEPOSIT") {
    this.isButtonLoader = true;
    this.clearIframeData();
    if (
      (this.zimplerForm.valid && txnType === "DEPOSIT") ||
      txnType === "LOGIN"
    ) {
      let initRequestData = this.getPayNPlayInitRequestData(txnType);
      this.paynplayCashierService
        .zimplerPayAndPlayInit(initRequestData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.isButtonLoader = false;
          if (data["status"] === "SUCCESS" && data["redirectURL"]) {
            sessionStorage.setItem("txnId", data["txnId"]);
            if (this.windowType === "mobile") {
              window.open(data["redirectURL"], "_self");
            } else {
              this.paymentProcessStep = "step3";
              this.isInitiateDepositTriggred = true;
              setTimeout(() => {
                let $iframe = $(
                  '<iframe src="' + data["redirectURL"] + '" ></iframe>'
                );
                $(".zimplerIframeContainer").append($iframe);
              });
              this.addOverFlowHideen();
            }
            if (txnType === "LOGIN") {
              this.gtmService.trackLoginGTMEvent("open");
            } else if (txnType === "DEPOSIT") {
              this.gtmService.trackDepositGTMEvent("open");
            }
          } else {
            this.isInitiateDepositTriggred = true;
            const message = this.translationService.instant(
              "zimpler.something_went_wrong_redirection"
            );
            this.switchToNextStep("failure", "step5", message);
          }
        });
    }
  }

  getPayNPlayInitRequestData(txnType) {
    /**If we have bonuscode we can pass below in the request object 
   * & property name is bonusCode
   * please add below line for redirection url before you deploy
   *   successURL:environment.siteUrl+'/redirection.html',
       failureURL:environment.siteUrl+'/redirection.html',
       redirectURL:environment.siteUrl+'/redirection.html'
   * */
    let initRequestData = {
      amount:
        txnType === "DEPOSIT" ? this.zimplerForm.controls["amount"].value : 0,
      countryCode: zimplerCountryCodeConfigurations[this.langCode],
      language: languageToPnpConfigurations[this.langCode],
      currencyCode: this.userDetailsService.getCurrencyCode(),
      site: environment.websiteName,
      siteDisplayName: environment.website,
      txnType: txnType,
      successURL:
        window.location.origin + "/redirection.html?transactionType=deposit",
      failureURL:
        window.location.origin + "/redirection.html?transactionType=deposit",
      redirectURL:
        window.location.origin + "/redirection.html?transactionType=deposit",
    };
    if (this.getActiveBonus()) {
      initRequestData["bonusCode"] = this.getActiveBonus();
    }
    return initRequestData;
  }

  getActiveBonus() {
    const bonusDetails = this.paynplayCashierService.getActiveDepositBonus();
    if (bonusDetails && Object.keys(bonusDetails).length > 0) {
      return bonusDetails["bonusCode"];
    }
    return "";
  }

  clearIframeData() {
    if ($(".zimplerIframeContainer")) {
      $(".zimplerIframeContainer").empty();
    }
    if (this.transactionErrorMessage) {
      this.transactionErrorMessage = "";
    }
    if (this.transactionSuccessMessage) {
      this.transactionSuccessMessage = "";
    }
    if (this.timerInstance) {
      clearInterval(this.timerInstance);
    }
  }

  requestLoopCount = 0;
  getPNPUserFromTransaction() {
    this.addOverFlowHideen();
    let transactionData = {
      txnId: sessionStorage.getItem("txnId"),
    };
    this.paynplayCashierService
      .getPNPUserFromTransaction(transactionData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((transactionStatuData) => {
        this.handleRequestLooping(transactionStatuData);
      });
  }

  handleRequestLooping(transactionStatuData) {
    if (
      this.requestLoopCount < 5 &&
      transactionStatuData &&
      (transactionStatuData["txnStatus"] === "KYC_VERIFIED" ||
        transactionStatuData["txnStatus"] === "INIT")
    ) {
      setTimeout(() => {
        this.requestLoopCount++;
        this.getPNPUserFromTransaction();
      }, 3000);
    } else {
      this.requestLoopCount = 0;
      if (transactionStatuData) {
        this.navigateBasedOnTxStatus(transactionStatuData);
      }
    }
  }

  transactionErrorMessage: string;
  transactionSuccessMessage: string;
  navigateBasedOnTxStatus(transactionStatuData) {
    const {
      status,
      txnStatus,
      externalEcrId,
      errorCode,
    } = transactionStatuData;
    this.verifyAndPushGTMEvent(status, txnStatus, transactionStatuData);
    if (status === "SUCCESS" && externalEcrId) {
      this.successTranctionHanlder(txnStatus, transactionStatuData);
    }
    if (status === "FAILURE" && errorCode === 100159) {
      this.failureTransactionHanlder(txnStatus, transactionStatuData);
    }
  }
  tnxDataBeforeQuickRegister;
  verifyAndPushGTMEvent(status, txnStatus, transactionData) {
    /**
     * This IF case occur only for user deposit for first on our production
     * At this time user is not yet registered
     * so in this case we have persist data & push end of 3rd step
     * step1:successful quick registration,
     * step 2:successful login then after we can have to push data..
     */
    if (
      status === "FAILURE" &&
      txnStatus === "DEPOSIT_SUCCESS" &&
      !this.utils.isUserLoggedIn()
    ) {
      this.tnxDataBeforeQuickRegister = {
        txnStatus: txnStatus,
        txnId: transactionData["txnId"],
        amount: transactionData["amount"],
      };
    } else if (
      txnStatus !== "LOGIN_WITHOUT_DEPOSIT" &&
      txnStatus !== "INIT" &&
      !(
        this.isLoggedIn &&
        status === "FAILURE" &&
        txnStatus === "DEPOSIT_SUCCESS"
      )
    ) {
      const userProfileData = this.userDetailsService.getUserProfileDetails();
      if (userProfileData) {
        transactionData = {
          ...transactionData,
          ...{
            externalEcrId: userProfileData["playerID"],
          },
        };
      }
      this.gtmService.trackDepositGTMEvent(status, transactionData);
    }
  }
  successTranctionHanlder(txnStatus, transactionStatuData) {
    switch (txnStatus) {
      case "DEPOSIT_SUCCESS":
      case "CASHIER_TRANSFER_SUCCESS":
        if (!this.utils.isUserLoggedIn()) {
          this.getPNPThirdPartyLogin(transactionStatuData["txnId"], "deposit");
        } else {
          const message = this.translationService.instant(
            "zimpler.deposit_success_msg"
          );
          this.switchToNextStep("successful", "step5", message);
        }
        break;
      case "CASHIER_INIT_DEPOSIT_SUCCESS":
        if (!this.utils.isUserLoggedIn()) {
          this.getPNPThirdPartyLogin(transactionStatuData["txnId"], "deposit");
        } else {
          const message = this.translationService.instant(
            "zimpler.deposit_success_msg"
          );
          this.switchToNextStep("successful", "step5", message);
        }
        break;
      case "KYC_VERIFIED":
        this.backToCashier();
        break;
      case "CASHIER_INIT_DEPOSIT_FAILED":
        const message = this.translationService.instant(
          "zimpler.cashier_init_deposit_failed"
        );
        this.switchToNextStep("failure", "step5", message);
        break;
      case "LOGIN_WITHOUT_DEPOSIT":
        /**
         * Else condition encounter when user is already logged in with
         * DEMOUSER2 & try to deposit with DEMOUSER4 user
         * In general world on Production we don't encounter this case.
         */
        if (!this.utils.isUserLoggedIn()) {
          this.getPNPThirdPartyLogin(transactionStatuData["txnId"], "login");
        } else {
          const message = this.translationService.instant(
            "zimpler.something_went_wrong"
          );
          this.switchToNextStep("failure", "step5", message);
        }
        break;
      case "CATEGORY_LIMITS_EXCEEDED":
        {
          const message = this.translationService.instant(
            "zimpler.category_limits_excceed"
          );
          this.switchToNextStep("failure", "step5", message);
        }
        break;
      case "DEPOSIT_NOT_ALLOWED":
        {
          const message = this.translationService.instant(
            "zimpler.deposit_not_allowed"
          );
          this.switchToNextStep("failure", "step5", message);
        }
        break;
      case "RG_COOL_OFF":
        {
          const message = this.translationService.instant(
            "zimpler.rg_cool_off"
          );
          this.switchToNextStep("failure", "step5", message);
        }
        break;
    }
  }

  failureTransactionHanlder(txnStatus, transactionStatuData) {
    let message = "";
    switch (txnStatus) {
      case "LOGIN_WITHOUT_DEPOSIT":
        {
          message = this.translationService.instant(
            "zimpler.login_without_depositFailureMsg"
          );
          this.switchToNextStep("failure", "step5", message);
        }
        break;
      case "DEPOSIT_SUCCESS":
        if (!this.utils.isUserLoggedIn()) {
          this.quickRegisterZimpler();
        } else {
          message = this.translationService.instant(
            "zimpler.something_went_wrong"
          );
          this.switchToNextStep("failure", "step5", message);
        }
        break;
      case "INIT":
      case "KYC_VERIFIED":
        this.backToCashier();
        break;
    }
  }

  quickRegisterZimpler() {
    let reqRequestData = {
      txnId: sessionStorage.getItem("txnId"),
      emailId: sessionStorage.getItem("txnId") + "@kanuuna.com",
      password: Math.random().toString(36).slice(-8),
    };
    reqRequestData = this.getAffiliateData(reqRequestData);
    this.paynplayCashierService
      .quickRegisterZimpler(reqRequestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        let message;
        if (data["status"] === "SUCCESS") {
          this.utils.deleteAffCookies();
          this.getPNPThirdPartyLogin(
            sessionStorage.getItem("txnId"),
            "quick-registration"
          );
        } else {
          message = this.translationService.instant(
            "zimpler.quickRegisterFailureMsg"
          );
          this.switchToNextStep("failure", "step5", message);
        }
      });
  }

  getAffiliateData(reqRequestData) {
    if (this.utils.getAffCookies("bannerId")) {
      reqRequestData["bannerId"] = this.utils.getAffCookies("bannerId");
    }
    if (this.utils.getAffCookies("affId")) {
      reqRequestData["affiliateId"] = this.utils.getAffCookies("affId");
    }
    if (this.utils.getAffCookies("trackerId")) {
      reqRequestData["trackerId"] = this.utils.getAffCookies("trackerId");
    }
    return reqRequestData;
  }

  getPNPThirdPartyLogin(userTxnId, callingFrom) {
    if (userTxnId) {
      let loginData = {
        txnId: userTxnId,
      };
      this.paynplayCashierService
        .getPNPThirdPartyLogin(loginData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((loginResponse) => {
          if (loginResponse && loginResponse["status"] === "SUCCESS") {
            this.pnpThirdPartyLoginSuccesHanlder(loginResponse, callingFrom);
            /*Below error code is used for RgCoolOffuser */
          } else if (
            loginResponse["errorCode"] === 100173 &&
            loginResponse["errorDescription"]
          ) {
            let msg = loginResponse["errorDescription"];
            /**Below logic/work around is required to remove crossbrowser issue in safari, firefox & windows */
            let accountLockedDate = new Date(
              Date.parse(
                msg
                  .substr(msg.indexOf(":") + 1)
                  .trim()
                  .replace(" ", "T") + "+02:00"
              )
            );
            const errorDescMsg = this.translationService.instant(
              "zimpler.error_100173_rg_cool_off"
            );
            this.errorMessage = errorDescMsg + accountLockedDate;
            this.switchToNextStep("failure", "step5", this.errorMessage);
          } else {
            this.tnxDataBeforeQuickRegister = undefined;
            this.pnpThirdPartyLoginFailHanlder(loginResponse, callingFrom);
          }
        });
    }
  }

  /**
   * Below complete functioanlity deals with deep links before login
   * basically when user try to access few part of product without login
   * we useful ask user to force login before we ask for force login
   * we store it's related data in service data layer & reuse it after successful
   * login so that user lands on exact place where we preferred to be before login
   */

  navigateAfterLogin() {
    const navigateAfterLogin = this.commonService.getNavigateAfterLogin();
    if (navigateAfterLogin && navigateAfterLogin["type"] === "url") {
      this.navigateByUrlData(navigateAfterLogin);
    } else if (navigateAfterLogin && navigateAfterLogin["type"] === "view") {
      this.navigateByViewData(navigateAfterLogin);
    } else if (this.utils.getDecodedCurrentPath()) {
      this.naviagateOnCurrentPath();
    }
  }

  navigateByUrlData(navigateAfterLogin) {
    if (navigateAfterLogin["data"]["navigateTo"] === "lobby") {
      this.commonService.broadCastUpdateActiveLobby(navigateAfterLogin["data"]);
      if (navigateAfterLogin["data"]["group"]) {
        this.router.navigate([
          this.langCode + "/casino/" + navigateAfterLogin["data"]["group"],
        ]);
      }
    }

    if (navigateAfterLogin["data"]["navigateTo"] === "gameWindow") {
      this.gamePlayService.setGameCalledfrom("realgame");
      this.router.navigate([
        this.langCode +
          "/" +
          this.translationService.instant("url.game") +
          "/" +
          navigateAfterLogin["data"]["path"],
      ]);
    }
  }

  navigateByViewData(navigateAfterLogin) {
    if (navigateAfterLogin["path"] === "confirmidentity") {
      this.checkKycLevelDetails(navigateAfterLogin);
    } else {
      if (navigateAfterLogin["path"] === "rewards") {
        this.utils.updateActiveLeftMenu("rewards");
      }
      this.utils.openAccountComponent(navigateAfterLogin["path"]);
    }
  }

  checkKycLevelDetails(navigateAfterLogin) {
    this.commonService
      .getUserKycLevelDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (!data["enableKyc"]) {
          this.commonService.broadCastActiveAcountMenu("menuOptions");
        } else {
          this.utils.openAccountComponent(navigateAfterLogin["path"]);
        }
      });
  }

  naviagateOnCurrentPath() {
    const pathName = this.utils.getDecodedCurrentPath();
    if (pathName.length <= 7) {
      this.router.navigate([this.langCode + "/casino"]);
    } else if (pathName.includes(this.translationService.instant("url.game"))) {
      let data = this.gamePlayService.getCurrentGameData();
      if (data && data["gameType"]) {
        data["gameType"] = "realgame";
      }
      this.gamePlayService.setGameCalledfrom("realgame");
      this.gamePlayService.setCurrentGameData(data);
      this.gamePlayService.broadCastRelanuchGame(true);
    }
  }

  pnpThirdPartyLoginSuccesHanlder(response, callingFrom) {
    let messgae;
    if (callingFrom === "quick-registration") {
      this.switchToNextStep("", "step6", "", "quick-registration");
      this.emitterService.broadCastLimitsRegulatoryPopup(true);
    } else {
      let message = "";
      switch (callingFrom) {
        case "login":
          message = this.translationService.instant("zimpler.loginSuccessMsg");
          break;
        case "deposit":
          message = this.translationService.instant(
            "zimpler.depositSuccessMsg"
          );
          break;
        default:
          message = this.translationService.instant(
            "zimpler.loginSuccessDefaultMsg"
          );
      }
      this.switchToNextStep("successful", "step5", message, callingFrom);
      this.navigateAfterLogin();
    }
  }

  onEmailSubscriptionUpdate() {
    /**This will triggered only when user doing is first deposit */
    if (this.tnxDataBeforeQuickRegister) {
      const userProfileData = this.userDetailsService.getUserProfileDetails();
      if (userProfileData) {
        this.tnxDataBeforeQuickRegister["externalEcrId"] =
          userProfileData["playerID"];
      }
      this.gtmService.trackDepositGTMEvent(
        "SUCCESS",
        this.tnxDataBeforeQuickRegister,
        true
      );
      this.tnxDataBeforeQuickRegister = undefined;
    }
    this.backToCashier();
  }

  pnpThirdPartyLoginFailHanlder(response, callingFrom) {
    let message = "";
    switch (callingFrom) {
      case "login":
        message = this.translationService.instant("zimpler.loginFailureMsg");
        break;
      case "deposit":
        message = this.translationService.instant(
          "zimpler.deposit_LoginFailureMsg"
        );
        break;
      case "quick-registration":
        message = this.translationService.instant(
          "zimpler.quick_registration_LoginFailureMsg"
        );
        break;
      default:
        message = this.translationService.instant(
          "zimpler.something_went_wrong"
        );
    }
    this.switchToNextStep("failure", "step5", message);
  }

  switchToNextStep(status, stepNumber, message, callingFrom?) {
    this.paymentProcessStep = stepNumber;
    if (status === "failure" && message) {
      this.transactionErrorMessage = message;
    }
    if (status === "successful" && message) {
      this.transactionSuccessMessage = message;
    }
    if (callingFrom !== "quick-registration") {
      this.timerInstance = setTimeout(() => {
        this.backToCashier();
      }, 3000);
    }
  }

  setTransactionAmount(event) {
    if (event) {
      this.zimplerForm.controls["amount"].setValue(event.target.value);
      this.isVisible = true;
    }
  }

  uncheckQuickPicks() {
    const inputRadioElement = document.querySelectorAll(
      '.quickPick__wrap input[type="radio"]:checked'
    ) as NodeListOf<Element>;
    if (inputRadioElement && inputRadioElement.length > 0) {
      (inputRadioElement[0] as HTMLInputElement).checked = false;
    }
  }

  isButtonDisabled(formName) {
    return this.utils.isButtonDisabled(formName);
  }

  backToCashier() {
    this.isButtonLoader = false;
    this.isVisible = false;
    this.paymentProcessStep = "step1";
    localStorage.removeItem("txnId");
    this.removeOverFlowHidden();
    this.isInitiateDepositTriggred = false;
    this.paymentProcessStep = "step1";
    this.zimplerForm.controls["amount"].setValue("");
    this.zimplerForm.reset();
    this.paynplayCashierService.broadCastZimplerProcessCompleted();
    this.selectedBonus = undefined;
    if (this.utils.isUserLoggedIn()) {
      this.checkIsEmailUpdated("loginSucces");
    }
    if (this.paynplayCashierService.getActiveDepositBonus()) {
      this.paynplayCashierService.setActiveDepositBonus({});
    }
  }

  addOverFlowHideen() {
    this.utils.addClassToAppBody("overflow-hidden zimplerModalOpen");
  }

  removeOverFlowHidden() {
    this.utils.removeClassFromAppBody("overflow-hidden zimplerModalOpen");
  }

  checkIsEmailUpdated(callingFrom?: string) {
    const userProfileDetails = this.userDetailsService.userBalanceDetails;
    if (userProfileDetails && userProfileDetails["email"]) {
      this.doEmailMatch(userProfileDetails["email"], callingFrom);
    } else {
      Promise.resolve(this.commonService.getProfileBalanceCurrency()).then(
        (data) => {
          if (data && data["profile"] && data["profile"]["email"]) {
            this.doEmailMatch(data["profile"]["email"], callingFrom);
          }
        }
      );
    }
  }

  doEmailMatch(emailId, callingFrom) {
    let numberRegex = new RegExp("^[0-9]+$");
    if (emailId) {
      let emailSegments = emailId.split("@");
      if (
        numberRegex.test(emailSegments[0]) &&
        emailSegments[1].startsWith("kanuuna.com")
      ) {
        this.paymentProcessStep = "step6";
        this.isInitiateDepositTriggred = true;
        this.addOverFlowHideen();
      }
    }
  }

  /**This functional will be called from payment rewards component
   * after user clicks add bonus or No thanks button then we proccess
   * zimpler payment init flow to complete deposit
   */
  navigateToDeposit() {
    this.zimplerPayAndPlayInit();
  }

  /**********************withdraw flow start from here**************************/

  zimplerWithdrawInit(txnType = "WITHDRAW") {
    this.isButtonLoader = true;
    this.clearIframeData();
    if (this.zimplerForm.valid && txnType === "WITHDRAW") {
      let withdrawRequestData = {
        depositAmount: this.zimplerForm.controls["amount"].value,
        paymentMethod: "ZIMPLER_DIRECT",
        comments: "system",
        /**for deposit redirection urls are coming from CMS,
         * where as for withdraw redirection url is from php code level which
         * is hard coded on php side
         * just adding here for reference
         * whether success or failure use will be naviagted to below url
         * which we are handing from app router only widthdraw due some
         * dependance from php side
         *cashier/callback/ZIMPLER_DIRECT/ctctrvctrctrtvctrctrctctctc?paymentType=cashout
         */
      };
      this.paynplayCashierService
        .zimplerWithdrawInit(withdrawRequestData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          if (data["status"] === "SUCCESS" && data["redirectURL"]) {
            sessionStorage.setItem("txnId", data["txnId"]);
            if (this.windowType === "mobile") {
              window.open(data["redirectURL"], "_self");
            } else {
              this.paymentProcessStep = "step3";
              this.isInitiateDepositTriggred = true;
              setTimeout(() => {
                let $iframe = $(
                  '<iframe src="' + data["redirectURL"] + '" ></iframe>'
                );
                $(".zimplerIframeContainer").append($iframe);
              });
              this.addOverFlowHideen();
            }
          } else if (data["status"] === "SUCCESS") {
            this.switchToNextStep(
              "successful",
              "step5",
              this.translationService.instant(
                "zimpler.withdrawSuccessWaitingApproval"
              )
            );
          } else {
            const message = this.translationService.instant(
              "zimpler.something_went_wrong_redirection"
            );
            this.switchToNextStep("failure", "step5", message);
          }
          this.isButtonLoader = false;
        });
    }
  }

  getWithdrawTransactionStatus() {
    if (sessionStorage.getItem("txnId")) {
      const data = {
        txnId: sessionStorage.getItem("txnId"),
        type: "CASHOUT",
      };
      this.paynplayCashierService
        .getUserPSPTranstionStatus(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if (response && response["status"] === "SUCCESS") {
            this.withdrawTnxSuccessHandler(response);
          } else {
            this.withdrawTnxFailureHandler(response);
          }
        });
    }
  }

  withdrawTnxSuccessHandler(data) {
    let message = "";
    if (data["actualStatusInDB"] === "co_initiated") {
      message = this.translationService.instant(
        "zimpler.withdrawSuccessWaitingApproval"
      );
    } else {
      message = this.translationService.instant("zimpler.withdrawSuccess");
    }
    this.switchToNextStep("successful", "step5", message);
  }

  withdrawTnxFailureHandler(data) {
    let message = this.translationService.instant(
      "zimpler.withdrawRequestfailed"
    );
    this.switchToNextStep("failure", "step5", message);
  }

  /**
   * Below code complete deal with passing of bonus code
   * in make transaction
   * Below we check is there any active bonus already been selected &
   * user navigated to cashier like we have reward area where user can click on
   * reward card & land on cashier page
   * so we store bonus selected in rewards component into service
   * & fetch back here to apply & passed it on pass in payment flow
   */

  checkForActiveBonus(eligibleBonusdata?) {
    let activeDepositBonus = this.paynplayCashierService.getActiveDepositBonus();
    if (activeDepositBonus && activeDepositBonus["bonusCode"]) {
      this.selectedBonus = activeDepositBonus;
    }
    if (!eligibleBonusdata) {
      this.isBonusData = false;
    } else {
      this.isBonusData = true;
    }
  }

  selectedBonusHandler(data) {
    this.selectedBonus = data;
  }

  ngOnDestroy() {
    /**
     * We have to clear saved active bonus if their is any active bonus saved in our local,
     * basically when user navigaye from all -rewards deposit card we store it their
     * & activate that bonus by default once he lands on cashier page
     */
    if (this.paynplayCashierService.getActiveDepositBonus()) {
      this.paynplayCashierService.setActiveDepositBonus(undefined);
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
