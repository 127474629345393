import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  SimpleChange,
  HostListener,
  Component,
  OnChanges,
  OnDestroy,
  Input
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-game-groups-display",
  templateUrl: "./game-groups-display.component.html",
  styleUrls: ["./game-groups-display.component.scss"],
})
export class GameGoupsDisplayComponent implements OnChanges, OnDestroy {
  @Input() lobby;
  gameGroups: any;
  games;
  lobbyGroupDataWithGames: any;
  windowType: string;
  providerList: any;
  allFavoriteGames;
  lastPlayedGames;
  langCodeSubscription: Subscription;
  langCode: string;
  isLoading: boolean = true;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  isLoggedIn: any;
  activeGameGroupLobby: any;

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  @HostListener("window:scroll") onWindowScroll() {
    if (
      this.lobbyGroupDataWithGames &&
      this.lobbyGroupDataWithGames.length > 0 &&
      this.filteredGameGroupsWithGames &&
      this.filteredGameGroupsWithGames.length <
        this.lobbyGroupDataWithGames.length
    ) {
      this.onScroll();
    }
  }

  constructor(
    public utility: UtilityService,
    private router: Router,
    private gameGroupsService: GameGroupsService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private gamesFilterPipe: GamesFilterPipe
  ) {
    this.getWindowType();
    this.langCode = this.utility.getLangCode();
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        this.getLobbyGroupsAndGames(true);
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
        this.getLobbyGroupsAndGames(true);
      }
    );
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes["lobby"]) {
      this.gameGroups = undefined;
      this.games = undefined;
      this.lobbyGroupDataWithGames = undefined;
      this.lobby = changes["lobby"].currentValue;
      this.getLobbyGroupsAndGames();
    }
  }

  getLobbyGroupsAndGames(isForce?: boolean) {
    this.isLoading = true;
    let apiURL = [
      this.gameGroupsService.getLobbyListWithGameGroupsp(isForce),
      this.gameGroupsService.getGameGroupGamesp(isForce),
    ];
    if (this.isLoggedIn) {
      apiURL.push(this.gameGroupsService.getLastedPlayedGamesp(isForce));
    }

    Promise.all(apiURL).then(([lobbyGameGroups, games, lastPlayed]) => {
      this.lastPlayedGames = _.clone(lastPlayed);
      lobbyGameGroups = JSON.parse(JSON.stringify(lobbyGameGroups));
      this.updateMetaTagBasedOnLobby(lobbyGameGroups, this.lobby);
      if (lobbyGameGroups && lobbyGameGroups.length > 0) {
        this.gameGroups = this.utility.getGameGroupsByLobby(
          _.clone(lobbyGameGroups),
          this.lobby
        );
      }
      if (
        !_.isEmpty(this.gameGroups) &&
        this.lobby &&
        games &&
        games.length > 0
      ) {
        this.games = JSON.parse(JSON.stringify(games));
        this.lobbyGroupDataWithGames = this.processGameGroupGames(
          this.gameGroups,
          this.games
        );
        this.initializeLazyLoading();
      } else {
        this.lobbyGroupDataWithGames = [];
        this.filteredGameGroupsWithGames = [];
      }
      this.isLoading = false;
    });
  }

  processGameGroupGames(GroupsData, games) {
    if (GroupsData && games) {
      _.each(GroupsData, (gameGroup, lindex) => {
        if (gameGroup["group_type"] === "manual") {
          _.each(games, (game, gindex) => {
            if (game["gameGroupList"] && game["gameGroupList"].length > 0) {
              _.each(game["gameGroupList"], (group, glindex) => {
                if (group["id"] === gameGroup["id"]) {
                  if (GroupsData[lindex]["games"]) {
                    let fgame = _.clone(game);
                    fgame["gameGroupList"] = game["gameGroupList"][glindex];
                    GroupsData[lindex]["games"].push(fgame);
                  } else {
                    let fgame = _.clone(game);
                    fgame["gameGroupList"] = game["gameGroupList"][glindex];
                    GroupsData[lindex]["games"] = [];
                    GroupsData[lindex]["games"].push(fgame);
                  }
                }
              });
            }
          });
        } else if (
          gameGroup["group_type"] === "automatic" &&
          gameGroup["group_sub_type"] === "continue_playing"
        ) {
          if (
            this.isLoggedIn &&
            this.lastPlayedGames &&
            this.lastPlayedGames.length > 0
          ) {
            _.each(this.lastPlayedGames, (typeId) => {
              let game = _.findWhere(games, { beGameTypeId: typeId });
              if (game && GroupsData[lindex]["games"]) {
                GroupsData[lindex]["games"].push(game);
              } else if (game) {
                GroupsData[lindex]["games"] = [];
                GroupsData[lindex]["games"].push(game);
              }
            });
            GroupsData[lindex][
              "games"
            ] = this.gamesFilterPipe.transform(GroupsData[lindex]["games"], {
              typeOfGames: "non-live-game",
            });
          } else {
            GroupsData[lindex]["games"] = [];
          }
        } else if (
          gameGroup["group_type"] === "automatic" &&
          gameGroup["group_sub_type"] === "favourite"
        ) {
          if (
            this.isLoggedIn &&
            this.allFavoriteGames &&
            this.allFavoriteGames.length > 0
          ) {
            _.each(this.allFavoriteGames, (favoriteGame) => {
              let game = _.findWhere(games, {
                beGameTypeId: favoriteGame["gameTypeId"],
              });
              if (game && GroupsData[lindex]["games"]) {
                GroupsData[lindex]["games"].push(game);
              } else if (game) {
                GroupsData[lindex]["games"] = [];
                GroupsData[lindex]["games"].push(game);
              }
            });
          } else {
            GroupsData[lindex]["games"] = [];
          }
        }
      });
      return GroupsData;
    }
  }

  getLastPlayedGames() {
    return this.gameGroupsService.getLastPlayedGames().subscribe((data) => {
      this.lastPlayedGames = data;
    });
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww <= 767) {
      this.windowType = "mobile";
    } else {
      this.windowType = "device";
    }
  }

  getAllproviders(games) {
    this.providerList = this.utility.getProvidesList(games);
  }

  navigateToAllGames(providerName) {
    this.router.navigate([
      this.langCode +
        "/" +
        this.translationService.instant("url." + this.lobby) +
        "/" +
        providerName,
    ]);
  }

  updateMetaTagBasedOnLobby(lobbyGameGroup, activeLobby) {
    this.activeGameGroupLobby = lobbyGameGroup.filter((group) => {
      return group.name.toLowerCase() === activeLobby.toLowerCase();
    });
    if (this.activeGameGroupLobby && this.activeGameGroupLobby.length) {
      this.activeGameGroupLobby = this.activeGameGroupLobby[0];
    }
  }

  /**
   * Below Logic is used to lazy load game group's under a selected lobby
   * Flow will be like this we load only three game groups first on lading casino page
   * & based on user scroll
   *
   * we calculate window scroll height & inner height If this both exceeds
   * scroll height-footer height we next 3 game group on to Dom, In this way we
   * achive lazy loading.
   */

  filteredGameGroupsWithGames;
  gameGroupSize: number = 3;
  initializeLazyLoading() {
    this.gameGroupSize = 3;
    if (
      this.lobbyGroupDataWithGames &&
      this.lobbyGroupDataWithGames.length > 0
    ) {
      this.filteredGameGroupsWithGames = this.lobbyGroupDataWithGames.slice(
        0,
        this.gameGroupSize
      );
    }
  }

  onScroll() {
    let metaContentHeight = 0;
    let footerHeight = this.utility.getFooterHeight();
    const metaContentDom: any = document.getElementsByClassName("meta-content");
    if (metaContentDom && metaContentDom.length > 0) {
      metaContentHeight = metaContentDom[0].offsetHeight;
    }
    if (
      window.scrollY + window.innerHeight >=
      document.body.scrollHeight - (footerHeight + metaContentHeight + 250)
    ) {
      this.gameGroupSize = this.gameGroupSize + 3;
      this.filteredGameGroupsWithGames = this.lobbyGroupDataWithGames.slice(
        0,
        this.gameGroupSize
      );
    }
  }

  ngOnDestroy() {
    this.langCodeSubscription.add(this.loginCompleteSubscription);
    this.langCodeSubscription.add(this.logOutCompleteSubscription);
    this.langCodeSubscription.unsubscribe();
  }
}
