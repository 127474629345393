import { Observable, Subscription, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Libraries
import * as _ from "underscore";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { MainService } from "src/app/services/main-service/main.service";

@Injectable({
  providedIn: "root",
})
export class JackpotService {
  microGamingJackpotFeed?;
  mircroGameJackpots?;
  userProfileSubscription: Subscription;
  constructor(
    private mainService: MainService,
    private gameGroupsService: GameGroupsService,
    private userDetailsService: UserDetailsService
  ) {
    this.getMgsJackpots();

    this.userProfileSubscription = this.userDetailsService.userProfileUpdated$.subscribe(
      (data) => this.getMgsJackpots()
    );
  }

  getMgsJackpots() {
    let self = this;

    this.getJackpotFeed().subscribe((jackpotfeed) => {
      this.microGamingJackpotFeed = jackpotfeed;
      self.mircroGameJackpots = {};
      if (jackpotfeed.length > 0) {
        this.gameGroupsService.getGameGroupGames().subscribe((data) => {
          let microgamingGames = _.where(data, {
            gameType: "progressive_slots",
          });

          jackpotfeed.filter((jackpotObj, i) => {
            let gameName = jackpotObj.friendlyName
              .replace(/[\W_]+/g, " ")
              .trim();
            microgamingGames.filter((mcgGamesObj) => {
              if (
                mcgGamesObj.name.toUpperCase().indexOf(gameName.toUpperCase()) >
                  -1 &&
                jackpotObj.gamePayId == 0
              ) {
                self.mircroGameJackpots[mcgGamesObj.gameCode] =
                  jackpotObj.startAtValue;
              }
            });
          });
          this.broadCastMicroGamingJackpotSuccess();
        });
      }
    });
  }

  public mircoGamingJackpotData = new BehaviorSubject<any>(
    this.mircroGameJackpots
  );
  public mircoGamingJackpotData$: Observable<
    any
  > = this.mircoGamingJackpotData.asObservable();

  broadCastMicroGamingJackpotSuccess() {
    this.mircoGamingJackpotData.next(this.mircroGameJackpots);
  }

  getJackpotFeed(): Observable<any> {
    return this.mainService
      .microGamingJackpotFeed(this.userDetailsService.getCurrencyCode())
      .pipe(
        map((jackpotFeed) => {
          return jackpotFeed;
        })
      );
  }
}
//   let jackpotfeed = [{
//   "progressiveId": 47,
//   "moduleId": 47,
//   "gamePayId": 0,
//   "startAtValue": 5421.55,
//   "endAtValue": 5445.55,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 4,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Cash Splash™",
//   "jackpotNumber": 6714,
//   "secondsSinceLastWin": 14696
// }, {
//   "progressiveId": 61,
//   "moduleId": 61,
//   "gamePayId": 0,
//   "startAtValue": 251280.25,
//   "endAtValue": 251316.25,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 6,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Major Millions™",
//   "jackpotNumber": 217,
//   "secondsSinceLastWin": 131643
// }, {
//   "progressiveId": 105,
//   "moduleId": 105,
//   "gamePayId": 0,
//   "startAtValue": 248423.05,
//   "endAtValue": 248429.05,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Poker Ride™",
//   "jackpotNumber": 404,
//   "secondsSinceLastWin": 7921704
// }, {
//   "progressiveId": 105,
//   "moduleId": 105,
//   "gamePayId": 1,
//   "startAtValue": 248423.05,
//   "endAtValue": 248429.05,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Poker Ride™",
//   "jackpotNumber": 404,
//   "secondsSinceLastWin": 7921704
// }, {
//   "progressiveId": 10011,
//   "moduleId": 10011,
//   "gamePayId": 0,
//   "startAtValue": 26204.65,
//   "endAtValue": 26210.65,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Lotsaloot™",
//   "jackpotNumber": 2159,
//   "secondsSinceLastWin": 2373121
// }, {
//   "progressiveId": 10016,
//   "moduleId": 10016,
//   "gamePayId": 0,
//   "startAtValue": -6.00,
//   "endAtValue": 0.00,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "WowPot™",
//   "jackpotNumber": 1226,
//   "secondsSinceLastWin": 24140319
// }, {
//   "progressiveId": 110,
//   "moduleId": 10438,
//   "gamePayId": 0,
//   "startAtValue": 118390.07,
//   "endAtValue": 118396.07,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Golden Money Frog™",
//   "jackpotNumber": 6,
//   "secondsSinceLastWin": 23138104
// }, {
//   "progressiveId": 15000,
//   "moduleId": 15000,
//   "gamePayId": 0,
//   "startAtValue": 18964.08,
//   "endAtValue": 18970.08,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Fruit Fiesta™",
//   "jackpotNumber": 847,
//   "secondsSinceLastWin": 7899074
// }, {
//   "progressiveId": 15001,
//   "moduleId": 15001,
//   "gamePayId": 0,
//   "startAtValue": 105793.66,
//   "endAtValue": 105799.66,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Treasure Nile™",
//   "jackpotNumber": 1429,
//   "secondsSinceLastWin": 129301
// }, {
//   "progressiveId": 15003,
//   "moduleId": 15003,
//   "gamePayId": 0,
//   "startAtValue": 38266.78,
//   "endAtValue": 38267.78,
//   "numberOfSeconds": 100.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Tunzamunni™",
//   "jackpotNumber": 143,
//   "secondsSinceLastWin": 14147072
// }, {
//   "progressiveId": 47,
//   "moduleId": 15004,
//   "gamePayId": 0,
//   "startAtValue": 5421.55,
//   "endAtValue": 5445.55,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 4,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Cash Splash™",
//   "jackpotNumber": 6714,
//   "secondsSinceLastWin": 14696
// }, {
//   "progressiveId": 61,
//   "moduleId": 15005,
//   "gamePayId": 0,
//   "startAtValue": 251280.25,
//   "endAtValue": 251316.25,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 6,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Major Millions™",
//   "jackpotNumber": 217,
//   "secondsSinceLastWin": 131643
// }, {
//   "progressiveId": 10016,
//   "moduleId": 15006,
//   "gamePayId": 0,
//   "startAtValue": -6.00,
//   "endAtValue": 0.00,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "WowPot™",
//   "jackpotNumber": 1226,
//   "secondsSinceLastWin": 24140319
// }, {
//   "progressiveId": 10011,
//   "moduleId": 15007,
//   "gamePayId": 0,
//   "startAtValue": 26204.65,
//   "endAtValue": 26210.65,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Lotsaloot™",
//   "jackpotNumber": 2159,
//   "secondsSinceLastWin": 2373121
// }, {
//   "progressiveId": 15000,
//   "moduleId": 15008,
//   "gamePayId": 0,
//   "startAtValue": 18964.08,
//   "endAtValue": 18970.08,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Fruit Fiesta™",
//   "jackpotNumber": 847,
//   "secondsSinceLastWin": 7899074
// }, {
//   "progressiveId": 61,
//   "moduleId": 15009,
//   "gamePayId": 0,
//   "startAtValue": 251292.25,
//   "endAtValue": 251316.25,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 4,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Major Millions™",
//   "jackpotNumber": 217,
//   "secondsSinceLastWin": 131643
// }, {
//   "progressiveId": 101,
//   "moduleId": 15010,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "The Dark Knight™",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 15010,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "The Dark Knight™",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 15010,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "The Dark Knight™",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 15010,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "The Dark Knight™",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 124,
//   "moduleId": 15017,
//   "gamePayId": 0,
//   "startAtValue": 2283.77,
//   "endAtValue": 2289.77,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Hansel & Gretel Treasure Trail™",
//   "jackpotNumber": 225,
//   "secondsSinceLastWin": 7346123
// }, {
//   "progressiveId": 125,
//   "moduleId": 15017,
//   "gamePayId": 1,
//   "startAtValue": 784.85,
//   "endAtValue": 790.85,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Hansel & Gretel Treasure Trail™",
//   "jackpotNumber": 666,
//   "secondsSinceLastWin": 544291
// }, {
//   "progressiveId": 126,
//   "moduleId": 15017,
//   "gamePayId": 2,
//   "startAtValue": 225.23,
//   "endAtValue": 231.23,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Hansel & Gretel Treasure Trail™",
//   "jackpotNumber": 7873,
//   "secondsSinceLastWin": 75639
// }, {
//   "progressiveId": 127,
//   "moduleId": 15017,
//   "gamePayId": 3,
//   "startAtValue": 159.42,
//   "endAtValue": 165.42,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Hansel & Gretel Treasure Trail™",
//   "jackpotNumber": 17355,
//   "secondsSinceLastWin": 185596
// }, {
//   "progressiveId": 128,
//   "moduleId": 15041,
//   "gamePayId": 0,
//   "startAtValue": 4851694.21,
//   "endAtValue": 4851700.21,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Wheel of Wishes",
//   "jackpotNumber": 0,
//   "secondsSinceLastWin": 0
// }, {
//   "progressiveId": 129,
//   "moduleId": 15041,
//   "gamePayId": 1,
//   "startAtValue": 185028.53,
//   "endAtValue": 185034.53,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Wheel of Wishes",
//   "jackpotNumber": 17,
//   "secondsSinceLastWin": 574150
// }, {
//   "progressiveId": 130,
//   "moduleId": 15041,
//   "gamePayId": 2,
//   "startAtValue": 95.29,
//   "endAtValue": 101.29,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Wheel of Wishes",
//   "jackpotNumber": 86404,
//   "secondsSinceLastWin": 644
// }, {
//   "progressiveId": 131,
//   "moduleId": 15041,
//   "gamePayId": 3,
//   "startAtValue": 4.15,
//   "endAtValue": 10.15,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Wheel of Wishes",
//   "jackpotNumber": 297408,
//   "secondsSinceLastWin": 73
// }, {
//   "progressiveId": 128,
//   "moduleId": 15041,
//   "gamePayId": 4,
//   "startAtValue": 4851694.21,
//   "endAtValue": 4851700.21,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Wheel of Wishes",
//   "jackpotNumber": 0,
//   "secondsSinceLastWin": 0
// }, {
//   "progressiveId": 129,
//   "moduleId": 15041,
//   "gamePayId": 5,
//   "startAtValue": 185028.53,
//   "endAtValue": 185034.53,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Wheel of Wishes",
//   "jackpotNumber": 17,
//   "secondsSinceLastWin": 574150
// }, {
//   "progressiveId": 130,
//   "moduleId": 15041,
//   "gamePayId": 6,
//   "startAtValue": 95.29,
//   "endAtValue": 101.29,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Wheel of Wishes",
//   "jackpotNumber": 86404,
//   "secondsSinceLastWin": 644
// }, {
//   "progressiveId": 131,
//   "moduleId": 15041,
//   "gamePayId": 7,
//   "startAtValue": 4.15,
//   "endAtValue": 10.15,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Wheel of Wishes",
//   "jackpotNumber": 297408,
//   "secondsSinceLastWin": 73
// }, {
//   "progressiveId": 101,
//   "moduleId": 15042,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Atlantean Treasures",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 15042,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Atlantean Treasures",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 15042,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Atlantean Treasures",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 15042,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Atlantean Treasures",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 101,
//   "moduleId": 15043,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Vault Millionaire™",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 15043,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Vault Millionaire™",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 15043,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Vault Millionaire™",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 15043,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Vault Millionaire™",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 101,
//   "moduleId": 15044,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Absolootly Mad",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 15044,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Absolootly Mad",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 15044,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Absolootly Mad",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 15044,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Absolootly Mad",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 128,
//   "moduleId": 15048,
//   "gamePayId": 0,
//   "startAtValue": 4851694.21,
//   "endAtValue": 4851700.21,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Book of Atem WOWPOT!",
//   "jackpotNumber": 0,
//   "secondsSinceLastWin": 0
// }, {
//   "progressiveId": 129,
//   "moduleId": 15048,
//   "gamePayId": 1,
//   "startAtValue": 185028.53,
//   "endAtValue": 185034.53,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Book of Atem WOWPOT!",
//   "jackpotNumber": 17,
//   "secondsSinceLastWin": 574150
// }, {
//   "progressiveId": 130,
//   "moduleId": 15048,
//   "gamePayId": 2,
//   "startAtValue": 95.29,
//   "endAtValue": 101.29,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Book of Atem WOWPOT!",
//   "jackpotNumber": 86404,
//   "secondsSinceLastWin": 644
// }, {
//   "progressiveId": 131,
//   "moduleId": 15048,
//   "gamePayId": 3,
//   "startAtValue": 4.15,
//   "endAtValue": 10.15,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Book of Atem WOWPOT!",
//   "jackpotNumber": 297408,
//   "secondsSinceLastWin": 73
// }, {
//   "progressiveId": 101,
//   "moduleId": 15049,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Atlantean Treasures: Mega Moolah",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 15049,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Atlantean Treasures: Mega Moolah",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 15049,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Atlantean Treasures: Mega Moolah",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 15049,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Atlantean Treasures: Mega Moolah",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 101,
//   "moduleId": 15050,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Absolootly Mad",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 15050,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Absolootly Mad",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 15050,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Absolootly Mad",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 15050,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Absolootly Mad",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 132,
//   "moduleId": 15052,
//   "gamePayId": 0,
//   "startAtValue": 9994.00,
//   "endAtValue": 10000.00,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Galactic Gold",
//   "jackpotNumber": 0,
//   "secondsSinceLastWin": 0
// }, {
//   "progressiveId": 133,
//   "moduleId": 15052,
//   "gamePayId": 1,
//   "startAtValue": 994.00,
//   "endAtValue": 1000.00,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Galactic Gold",
//   "jackpotNumber": 0,
//   "secondsSinceLastWin": 0
// }, {
//   "progressiveId": 134,
//   "moduleId": 15052,
//   "gamePayId": 2,
//   "startAtValue": 94.00,
//   "endAtValue": 100.00,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Galactic Gold",
//   "jackpotNumber": 0,
//   "secondsSinceLastWin": 0
// }, {
//   "progressiveId": 135,
//   "moduleId": 15052,
//   "gamePayId": 3,
//   "startAtValue": 4.00,
//   "endAtValue": 10.00,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Galactic Gold",
//   "jackpotNumber": 0,
//   "secondsSinceLastWin": 0
// }, {
//   "progressiveId": 17500,
//   "moduleId": 17500,
//   "gamePayId": 0,
//   "startAtValue": 1109509.41,
//   "endAtValue": 1109510.41,
//   "numberOfSeconds": 100.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "King Cashalot™",
//   "jackpotNumber": 35,
//   "secondsSinceLastWin": 92598073
// }, {
//   "progressiveId": 101,
//   "moduleId": 17501,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 17501,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 17501,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 17501,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 101,
//   "moduleId": 17502,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – 5 Reel Drive",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 17502,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – 5 Reel Drive",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 17502,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – 5 Reel Drive",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 17502,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – 5 Reel Drive",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 101,
//   "moduleId": 17503,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – Summertime",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 17503,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – Summertime",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 17503,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – Summertime",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 17503,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – Summertime",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 101,
//   "moduleId": 17504,
//   "gamePayId": 0,
//   "startAtValue": 10955104.03,
//   "endAtValue": 10955110.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – Isis",
//   "jackpotNumber": 80,
//   "secondsSinceLastWin": 4958783
// }, {
//   "progressiveId": 102,
//   "moduleId": 17504,
//   "gamePayId": 1,
//   "startAtValue": 10017.94,
//   "endAtValue": 10023.94,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – Isis",
//   "jackpotNumber": 3871,
//   "secondsSinceLastWin": 11978
// }, {
//   "progressiveId": 103,
//   "moduleId": 17504,
//   "gamePayId": 2,
//   "startAtValue": 94.03,
//   "endAtValue": 100.03,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – Isis",
//   "jackpotNumber": 3911173,
//   "secondsSinceLastWin": 11
// }, {
//   "progressiveId": 104,
//   "moduleId": 17504,
//   "gamePayId": 3,
//   "startAtValue": 4.01,
//   "endAtValue": 10.01,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Mega Moolah™ – Isis",
//   "jackpotNumber": 13486577,
//   "secondsSinceLastWin": 4
// }, {
//   "progressiveId": 110,
//   "moduleId": 18600,
//   "gamePayId": 0,
//   "startAtValue": 118390.07,
//   "endAtValue": 118396.07,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Hi Lo Roller™",
//   "jackpotNumber": 6,
//   "secondsSinceLastWin": 23138104
// }, {
//   "progressiveId": 110,
//   "moduleId": 18600,
//   "gamePayId": 0,
//   "startAtValue": 118390.07,
//   "endAtValue": 118396.07,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Lucky Fairy™",
//   "jackpotNumber": 6,
//   "secondsSinceLastWin": 23138104
// }, {
//   "progressiveId": 110,
//   "moduleId": 18602,
//   "gamePayId": 0,
//   "startAtValue": 118390.07,
//   "endAtValue": 118396.07,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Hi Lo Gambler™",
//   "jackpotNumber": 6,
//   "secondsSinceLastWin": 23138104
// }, {
//   "progressiveId": 110,
//   "moduleId": 18602,
//   "gamePayId": 0,
//   "startAtValue": 118390.07,
//   "endAtValue": 118396.07,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Lucky Fairy™",
//   "jackpotNumber": 6,
//   "secondsSinceLastWin": 23138104
// }, {
//   "progressiveId": 25001,
//   "moduleId": 25001,
//   "gamePayId": 0,
//   "startAtValue": 58270.67,
//   "endAtValue": 58276.67,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "SupaJax™",
//   "jackpotNumber": 421,
//   "secondsSinceLastWin": 5232221
// }, {
//   "progressiveId": 25002,
//   "moduleId": 25002,
//   "gamePayId": 0,
//   "startAtValue": 6890.15,
//   "endAtValue": 6896.15,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Jackpot Deuces™",
//   "jackpotNumber": 742,
//   "secondsSinceLastWin": 189690
// }, {
//   "progressiveId": 30001,
//   "moduleId": 30001,
//   "gamePayId": 0,
//   "startAtValue": 383895.10,
//   "endAtValue": 383901.10,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Roulette Royale™",
//   "jackpotNumber": 11,
//   "secondsSinceLastWin": 161288343
// }, {
//   "progressiveId": 30002,
//   "moduleId": 30002,
//   "gamePayId": 0,
//   "startAtValue": 33299.11,
//   "endAtValue": 33305.11,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Triple 7s™",
//   "jackpotNumber": 121,
//   "secondsSinceLastWin": 11016340
// }, {
//   "progressiveId": 30003,
//   "moduleId": 30003,
//   "gamePayId": 0,
//   "startAtValue": 257646.64,
//   "endAtValue": 257652.64,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Cyberstud™",
//   "jackpotNumber": 56,
//   "secondsSinceLastWin": 73043104
// }, {
//   "progressiveId": 109,
//   "moduleId": 30004,
//   "gamePayId": 0,
//   "startAtValue": 347190.52,
//   "endAtValue": 347196.52,
//   "numberOfSeconds": 600.0,
//   "centsPerSecond": 1,
//   "currencyIsoCode": "USD",
//   "friendlyName": "Caribbean Draw Poker",
//   "jackpotNumber": 7,
//   "secondsSinceLastWin": 74369247
// }]
