import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AfterViewInit,
  SimpleChange,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  Input,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-showcase",
  templateUrl: "./showcase.component.html",
  styleUrls: ["./showcase.component.scss"],
})
export class ShowCaseComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() gameGroupData;
  gameURl: any;
  gameId: string = "booming_5c9547cf2dd5bd000b45103b";
  loginCompleteSubscription: Subscription;
  isLoggedIn: boolean;
  logOutCompleteSubscription: Subscription;
  langCodeSubscription: Subscription;
  langCode: string;
  game: any;
  isVisible = false;
  mediaUrlPath: string = environment.mediaUrlPath;
  imgixParams: string;
  gamesUrlPath: string = environment.gamesUrlPath;

  constructor(
    private commonService: CommonService,
    private gameGroupsService: GameGroupsService,
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private utility: UtilityService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private paynplayCashierService: PayNPlayCashierService
  ) {
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
    this.imgixParams = this.utility.getImgixParams();
  }

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes["gameGroupData"]) {
      this.gameGroupData = changes["gameGroupData"].currentValue;
      this.game = this.gameGroupData["games"][0];
      if (this.game && this.game["hasDemo"]) {
        this.getFreeGame(this.game["gameCode"]);
      }
    }
  }

  /**we always show user a demo game only here...
    *But user can click on the btn & navigate to game play window 
     to for real money 
    */
  getFreeGame(gameId) {
    this.gameGroupsService.getFreeGame(gameId).subscribe((data) => {
      if (data["gameDetails"] && data["gameDetails"]["url"]) {
        this.gameURl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          data["gameDetails"]["url"]
        );
      }
    });
  }

  gameLaunch(gameName, hasDemo) {
    gameName = gameName.toLowerCase().split(" ").join("-");
    this.gamePlayService.clearGameWindowData();
    setTimeout(() => {
      if (gameName && this.isLoggedIn) {
        this.router.navigate([
          this.langCode +
            "/" +
            this.translationService.instant("url.game") +
            "/" +
            gameName,
        ]);
      } else {
        this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
        /**We use below function to store clicked game related data,
         * so after user login we can navigate him to that exactly place
         *
         * This case occurs when user try to live casino or game
         * which don't have demo option with out login
         */
        this.commonService.setNavigateAfterLogin({
          type: "url",
          data: { navigateTo: "gameWindow", path: gameName },
        });
      }
    });
  }

  ngAfterViewInit() {
    this.isVisible = true;
  }

  ngOnDestroy() {
    this.langCodeSubscription.add(this.logOutCompleteSubscription);
    this.langCodeSubscription.add(this.loginCompleteSubscription);
    this.langCodeSubscription.unsubscribe();
  }
}
