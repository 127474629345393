import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  HostListener,
  Component,
  OnInit,
} from "@angular/core";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { PromotionsService } from "src/app/modules/promotions/services/promotions.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { MainService } from "src/app/services/main-service/main.service";
import { EmitterService } from "src/app/services/emitter.service";

@Component({
  selector: "app-all-promotions",
  templateUrl: "./all-promotions.component.html",
  styleUrls: ["./all-promotions.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AllPromotionsComponent implements OnInit {
  langCode: string;
  langCodeSubscription: Subscription;
  logOutCompleteBsSubscription: Subscription;
  loginCompleteBsSubscription: Subscription;
  promotions;
  isLoading: boolean;
  promotionSnippetSubscription: Subscription;
  isLoggedIn: boolean;
  reloadBannersSub: any;
  pageHeaderBg: boolean;

  @HostListener("window:resize") onResize() {
    this.utility.getFixedHeaderHeight();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.utility.getFixedHeaderHeight();
  }
  @HostListener("window:scroll") onScroll() {
    this.pageHeaderBg = this.utility.scrollAddClass();
  }
  constructor(
    private router: Router,
    private utility: UtilityService,
    private activatedRoute: ActivatedRoute,
    public translationService: TranslationService,
    private promotionsService: PromotionsService,
    private commonService: CommonService,
    private mainService: MainService,
    private emitterService: EmitterService
  ) {
    this.langCode = this.utility.getLangCode();
    this.langCodeSubscription = this.translationService.langCode$.subscribe(
      (langCode) => {
        this.langCode = langCode;
        this.updateSEOContent();
        if (this.langCode) {
          this.allPromotionCards(true);
        }
      }
    );
    /**allpromotionCards call is not required bacause on logout , because
     * we navigate user back to home page
     * */
    this.loginCompleteBsSubscription = this.commonService.loginComplete.subscribe(
      (isloggedIn) => {
        this.isLoggedIn = true;
        this.allPromotionCards(true);
      }
    );
    this.logOutCompleteBsSubscription = this.commonService.logOutComplete.subscribe(
      (isloggedOut) => {
        this.isLoggedIn = false;
        this.allPromotionCards(true);
      }
    );
    this.reloadBannersSub = this.emitterService.reloadBanners$.subscribe(
      (flag) => {
        this.allPromotionCards();
      }
    );
    this.utility.getFixedHeaderHeight();
  }

  ngOnInit() {
    window["prerenderReady"] = false;
    this.activatedRoute.params.subscribe((params) => {
      this.allPromotionCards();
    });
    this.updateSEOContent();
  }

  updateSEOContent() {
    this.utility.setSEO({
      metaDescription: this.translationService.instant(
        "SEO.promotions_metaDescription"
      ),
      metaTags: this.translationService.instant("SEO.promotions_metaTag"),
      metaTitle: this.translationService.instant("SEO.promotions_metaTitle"),
      title: this.translationService.instant("SEO.promotions_title"),
    });
    this.commonService.setCanonicalURL("promotionspage");
  }

  allPromotionCards(isForce?) {
    this.isLoading = true;
    const { replaceAll } = this.utility;
    Promise.resolve(this.promotionsService.getAllPromotionsp(isForce)).then(
      (promoData) => {
        this.isLoading = false;
        if (promoData && promoData.length > 0) {
          this.promotions = promoData;
          _.each(this.promotions, function (promoData) {
            promoData.snippet = replaceAll(promoData.snippet, {
              "{{site_url}}": environment.siteUrl,
              "{{media_url}}": environment.mediaUrl,
            });
          });
        } else {
          this.promotions = [];
        }
        if (this.isLoggedIn) {
          this.checkNavigateData();
        }
        this.utility.getFixedHeaderHeight();
        window["prerenderReady"] = true;
      }
    );
  }

  checkNavigateData() {
    const navigateData = this.commonService.getNavigateAfterLogin();
    if (
      this.promotions &&
      this.promotions.length > 0 &&
      navigateData &&
      navigateData["data"]
    ) {
      let filterdData = _.filter(this.promotions, (promotion) => {
        let promotionUrl;
        /**need to remove this replace function after CMS change(Their an issues from CMS),It's a hack */
        if (
          this.translationService.instant("url.promotions") !== "promotions"
        ) {
          promotionUrl = promotion["url"];
          promotionUrl = promotionUrl.replace("promotions", "");
        } else {
          promotionUrl = promotion["url"];
        }
        return navigateData["data"]["path"].endsWith(promotionUrl);
      });
      if (filterdData.length > 0) {
        this.router.navigate([navigateData["data"]["path"]]);
      }
    }
  }

  gotoPromotionPage(url) {
    this.utility.closeAccountComponent("");
    if (url && url.length > 0) {
      /**need to remove this replace function after CMS change,It's a hack */
      if (this.translationService.instant("url.promotions") !== "promotions") {
        url = url.replace("promotions", "");
      }
      this.router.navigate([this.langCode + "/" + url]);
    }
  }

  backToHome() {
    this.utility.closeAccountComponent("");
    this.router.navigate([this.langCode + "/casino"]);
  }

  ngOnDestroy() {
    // this.utility.removeClassFromAppBody('overflow-hidden');
    this.loginCompleteBsSubscription.unsubscribe();
    if (this.promotionSnippetSubscription) {
      this.promotionSnippetSubscription.unsubscribe();
    }
    if (this.langCodeSubscription) {
      this.langCodeSubscription.unsubscribe();
    }
  }
}
