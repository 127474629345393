import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  ViewEncapsulation,
  HostListener,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";

// Configurations
import { localToCmsLanguageConfigurations } from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";

// Pipes
import { GamesFilterPipe } from "src/app/modules/shared/pipes/games-filter.pipe";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-view-all-game",
  templateUrl: "./view-all-games.component.html",
  styleUrls: ["./view-all-games.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ViewAllGamesComponent implements OnInit, OnDestroy {
  routerSub: any;
  activeLobby: string;
  activeGroupName: string;
  isLoading: boolean = false;
  isGameAvailable: any;
  filteredGames;
  isSortBy: string;
  langCodeSubscription: Subscription;
  langCode: string;
  isSortedBy: any = "";
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  lastPlayedGames: any;
  allFavoriteGames: any;
  providerList: unknown[];
  /**variables for provider level mapping.. */
  viewType = "games";
  activeProviderName;
  beforeSortFilteredGame;
  liveTableData: any = {};
  liveGameEvent: Subscription;
  providerName;
  windowType;
  isnameLoading: boolean = false;
  mediaUrlPath: string = environment.mediaUrlPath;

  gameGroupDetails: any;
  pageTitle: string;
  imgixParams: string;
  mediaUrl: string = environment.mediaUrl;
  websiteCode: string = environment.websiteCode;
  userBalanceSubscription: Subscription;
  userTotalBalance: number = 0;
  isShowBanner: boolean = true;
  deviceType;
  pageHeaderBg: boolean;

  @HostListener("window:resize") onResize() {
    this.utility.getFixedHeaderHeight();
    this.checkDeviceType();
    this.setOverflowHiddenClass();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.utility.getFixedHeaderHeight();
    this.checkDeviceType();
    this.utility.removeClassFromAppBody("overflow-hidden");
  }
  @HostListener("window:scroll") onScroll() {
    this.pageHeaderBg = this.utility.scrollAddClass();
  }

  constructor(
    private router: Router,
    private utility: UtilityService,
    private commonService: CommonService,
    private gameGroupsService: GameGroupsService,
    private translationService: TranslationService,
    private gamePlayService: GamePlayService,
    private gamesFilterPipe: GamesFilterPipe,
    private paynplayCashierService: PayNPlayCashierService,
    private userDetailsService: UserDetailsService
  ) {
    this.getActiveLobbyOrProviderName();
    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
      }
    );
    this.logOutCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
      }
    );
    if (this.userDetailsService.getUserTotalBalance()) {
      this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
    }
    this.userBalanceSubscription = this.userDetailsService.userBalanceUpdated$.subscribe(
      (balanceUpdateMessage) => {
        if (balanceUpdateMessage == "UserBalanceUpdated") {
          this.userTotalBalance = this.userDetailsService.getUserTotalBalance();
          this.utility.toggleBannerDepositBtn(this.userTotalBalance);
        }
      }
    );

    this.imgixParams = this.utility.getImgixParams();
  }

  currentPageGGroupUrl: string;
  getActiveLobbyOrProviderName() {
    const segments = this.utility.getDecodedCurrentPath().split("/");
    if (segments[3]) {
      this.currentPageGGroupUrl = segments[2] + "/" + segments[3];
    }
    var lobbyName = this.commonService.getActiveLobby();
    if (lobbyName) {
      this.activeLobby = this.utility.getActiveLobby(lobbyName);
    } else {
      this.getLobbyNamebyGGroupMutilingualUrl(this.currentPageGGroupUrl);
    }
    this.commonService.broadCastActiveLeftMenuLobby(this.activeLobby);
  }

  ngOnInit() {
    this.getLobbyGroupsAndGames();

    this.checkDeviceType();
  }

  ngAfterViewInit() {
    this.checkDeviceType();
    this.setOverflowHiddenClass();
  }

  goToFullScreen(targetEle) {
    this.utility.fullScreenInit(targetEle);
  }
  goOutFullScreen() {
    this.utility.exitFullScreen();
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  getLobbyGroupsAndGames(isForce?: boolean) {
    this.isLoading = true;
    let apiURL = [
      this.gameGroupsService.getLobbyListWithGameGroupsp(isForce),
      this.gameGroupsService.getGameGroupGamesp(isForce),
    ];
    if (this.isLoggedIn) {
      apiURL.push(this.gameGroupsService.getLastedPlayedGamesp(false));
    }
    Promise.all(apiURL).then(([lobbyGameGroups, games, lastPlayed]) => {
      window["prerenderReady"] = true;
      this.lastPlayedGames = _.clone(lastPlayed);
      // if (favoriteGames && favoriteGames['favorite'] && favoriteGames['favorite']['favoriteGamesList'].length > 0) {
      //   this.allFavoriteGames = JSON.parse(JSON.stringify(favoriteGames['favorite']['favoriteGamesList']));
      // }
      /**Funationality Get Active lobby Group details based on
       * the active group name  */
      lobbyGameGroups = JSON.parse(JSON.stringify(lobbyGameGroups));
      if (lobbyGameGroups && lobbyGameGroups.length > 0) {
        this.getGroupDetailsbyGGmultilingualUrl(
          _.clone(lobbyGameGroups),
          this.currentPageGGroupUrl
        );
      }
      // if (this.gameGroupDetails) {
      //   this.updateMetaTagBasedOnGameGroup(this.gameGroupDetails)
      // }
      /**Funationality Get all the games mapped with this
       * active group name  */
      if (this.gameGroupDetails && games) {
        games = JSON.parse(JSON.stringify(games));
        this.gameGroupDetails["games"] = this.getGamesbyGroupId(
          games,
          this.gameGroupDetails
        );

        if (this.gameGroupDetails["content"]) {
          this.gameGroupDetails["content"] = this.utility.replaceAll(
            this.gameGroupDetails["content"],
            {
              "{{site_url}}": environment.siteUrl,
              "{{mediaUrl}}": environment.mediaUrl,
              "{{websiteCode}}": environment.websiteCode,
            }
          );
          setTimeout(() => {
            this.utility.toggleBannerDepositBtn(this.userTotalBalance);
            let gameGroupBanner = document.querySelectorAll(".gameGroupBanner");
            if (gameGroupBanner && gameGroupBanner.length <= 0) {
              this.isShowBanner = false;
            }
          });
        }
      }

      /**
       * Functionality to check whether games are available ot not...
       * To show Message as "No games configured under {{}}"
       */
      if (this.gameGroupDetails && this.gameGroupDetails["games"]) {
        if (this.gameGroupDetails["game_asset_style"] === "quicklink") {
          if (this.providerName) {
            this.showGamesInProviders(this.providerName);
          } else {
            this.viewType = "providers";
            this.providerList = this.utility.getSortedUniqProviderList(
              this.gameGroupDetails["games"]
            );
            this.isGameAvailable = true;
          }
        } else if (
          (this.gameGroupDetails["group_type"] === "automatic" &&
            this.gameGroupDetails["group_sub_type"] === "continue_playing") ||
          (this.gameGroupDetails["group_type"] === "automatic" &&
            this.gameGroupDetails["group_sub_type"] === "favourite")
        ) {
          this.filteredGames = this.gameGroupDetails["games"];
          this.isGameAvailable = true;
        } else {
          this.filteredGames = this.utility.sortGameByCountryOrder(
            this.gameGroupDetails["games"]
          );
          this.isGameAvailable = true;
        }
      } else {
        this.filteredGames = [];
        this.isGameAvailable = false;
      }
      this.isLoading = false;
    });
  }

  /**Functioanlity to get Game Group details based on active group name */
  getGroupDetailsbyGGmultilingualUrl(lobbys, currentPageGGroupUrl) {
    lobbys.map((data, index) => {
      if (
        data["name"].toLowerCase() === this.activeLobby &&
        data["gameGroupList"].length
      ) {
        data["gameGroupList"].map((group, gindex) => {
          if (
            group["multilingualUrlGameGroup"] &&
            group["multilingualUrlGameGroup"][
              localToCmsLanguageConfigurations[this.langCode]
            ] &&
            currentPageGGroupUrl
          ) {
            let multilingualUrlGameGroupName =
              group["multilingualUrlGameGroup"][
                localToCmsLanguageConfigurations[this.langCode]
              ];
            if (
              multilingualUrlGameGroupName.toLowerCase().trim() ===
              currentPageGGroupUrl.toLowerCase().trim()
            ) {
              this.gameGroupDetails = group;
              this.utility.setSEO(this.gameGroupDetails, true);
              this.commonService.setCanonicalURL(
                this.gameGroupDetails["multilingualUrlGameGroup"]
              );
              this.pageTitle = group["name"];
              this.isnameLoading = false;
              return;
            }
          }
        });
      }
    });
  }

  /**Functaionality to get all the based configired for this active
   * game group by game group ID */
  getGamesbyGroupIdTemp(games, gameGroupDetails) {
    let filteredGames = [];
    _.each(games, (game, gindex) => {
      if (game["gameGroupList"]) {
        _.each(game["gameGroupList"], (list, glindex) => {
          if (list["id"] === gameGroupDetails["id"]) {
            let fgame = _.clone(game);
            fgame["gameGroupList"] = game["gameGroupList"][glindex];
            filteredGames.push(fgame);
            return;
          }
        });
      }
    });
    if (this.gameGroupDetails["group_type"] === "manual") {
      return this.utility.sortGameByCountryOrder(filteredGames);
    } else {
      return filteredGames;
    }
  }

  getGamesbyGroupId(games, gameGroupDetails) {
    let filteredGames = [];
    if (gameGroupDetails["group_type"] === "manual") {
      _.each(games, (game) => {
        if (game["gameGroupList"]) {
          _.each(game["gameGroupList"], (list, glindex) => {
            if (list["id"] === gameGroupDetails["id"]) {
              if (game) {
                let fgame = _.clone(game);
                fgame["gameGroupList"] = game["gameGroupList"][glindex];
                filteredGames.push(fgame);
              }
              return;
            }
          });
        }
      });
    } else if (
      gameGroupDetails["group_type"] === "automatic" &&
      gameGroupDetails["group_sub_type"] === "continue_playing"
    ) {
      if (
        this.isLoggedIn &&
        this.lastPlayedGames &&
        this.lastPlayedGames.length > 0
      ) {
        _.each(this.lastPlayedGames, (typeId) => {
          let game = _.findWhere(games, { beGameTypeId: typeId });
          if (game) {
            filteredGames.push(game);
          }
        });
        filteredGames = this.gamesFilterPipe.transform(filteredGames, {
          typeOfGames: "non-live-game",
        });
      } else {
        this.filteredGames = [];
      }
    } else if (
      gameGroupDetails["group_type"] === "automatic" &&
      gameGroupDetails["group_sub_type"] === "favourite"
    ) {
      if (
        this.isLoggedIn &&
        this.allFavoriteGames &&
        this.allFavoriteGames.length > 0
      ) {
        _.each(this.allFavoriteGames, (favoriteGame) => {
          let game = _.findWhere(games, {
            beGameTypeId: favoriteGame["gameTypeId"],
          });
          if (game) {
            filteredGames.push(game);
          }
        });
      } else {
        this.filteredGames = [];
      }
    }
    return filteredGames;
  }

  /**Sort game name by alphabet(A-Z)  */
  providerBeforeSort;
  sortByGameName(calledFrom) {
    if (calledFrom === "games") {
      if (this.isSortedBy !== "a-z") {
        this.isSortedBy = "a-z";
        this.filteredGames = this.utility.sortByGameName(
          this.gameGroupDetails["games"],
          "name"
        );
      } else {
        this.isSortedBy = "";
        this.filteredGames = this.utility.sortGameByCountryOrder(
          this.gameGroupDetails["games"]
        );
      }
    } else if (calledFrom === "providers") {
      if (this.isSortedBy !== "a-z") {
        this.isSortedBy = "a-z";
        this.providerBeforeSort = this.providerList;
        this.providerList = _.sortBy(this.providerList, "vendorDisplayName");
      } else {
        this.isSortedBy = "";
        this.providerList = this.providerBeforeSort;
      }
    } else {
      if (this.isSortedBy !== "a-z") {
        this.isSortedBy = "a-z";
        this.filteredGames = this.utility.sortByGameName(
          this.filteredGames,
          "name"
        );
      } else {
        this.isSortedBy = "";
        this.filteredGames = this.beforeSortFilteredGame;
      }
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  /**Sort game by Release Date */
  orderByReleaseDate() {
    if (this.isSortedBy !== "release-date") {
      this.isSortedBy = "release-date";
      this.filteredGames = this.utility.sortByReleaseDate(
        this.gameGroupDetails["games"],
        "date"
      );
    } else {
      this.isSortedBy = "";
      this.filteredGames = this.gameGroupDetails["games"];
    }
  }

  showGamesInProviders(providerName) {
    providerName = this.utility.convertGameNameToUrl(providerName);
    this.router.navigate([this.langCode + "/studio/" + providerName]);
  }

  /**Functaionality Go back to the respective lobby... */
  goBack() {
    this.router.navigate([
      this.langCode + "/" + this.translationService.instant("url." + "casino"),
    ]);
    this.goOutFullScreen();
  }

  gameLaunch(gameName, hasDemo) {
    gameName = this.utility.convertGameNameToUrl(gameName);
    this.gamePlayService.clearGameWindowData();
    setTimeout(() => {
      if (gameName && (this.isLoggedIn || (!this.isLoggedIn && hasDemo))) {
        this.router.navigate([
          this.langCode +
            "/" +
            this.translationService.instant("url.game") +
            "/" +
            gameName,
        ]);
      } else {
        this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
      }
    });
  }

  triggerGameLaunch(data) {
    this.gamePlayService.setGameCalledfrom(data["callingFrom"]);
    this.gameLaunch(data["name"], data["hasDemo"]);
  }

  ngOnDestroy() {
    this.langCodeSubscription.add(this.logOutCompleteSubscription);
    this.langCodeSubscription.add(this.loginCompleteSubscription);
    this.langCodeSubscription.unsubscribe();
    if (this.liveGameEvent) {
      this.liveGameEvent.unsubscribe();
    }
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  getLobbyNamebyGGroupMutilingualUrl(currentPageGGroupUrl) {
    Promise.resolve(this.gameGroupsService.getLobbyListWithGameGroupsp()).then(
      (response) => {
        if (response && response.length > 0) {
          let lobbyWithGameGroup = response;
          _.filter(lobbyWithGameGroup, (lobby) => {
            if (lobby["gameGroupList"]) {
              lobby["gameGroupList"].filter((data) => {
                if (
                  data &&
                  this.langCode &&
                  data["multilingualUrlGameGroup"] &&
                  data["multilingualUrlGameGroup"][
                    localToCmsLanguageConfigurations[this.langCode]
                  ]
                ) {
                  let multilingualUrlGameGroupName =
                    data["multilingualUrlGameGroup"][
                      localToCmsLanguageConfigurations[this.langCode]
                    ];
                  if (
                    multilingualUrlGameGroupName.toLowerCase().trim() ===
                    currentPageGGroupUrl.toLowerCase().trim()
                  ) {
                    this.activeLobby = lobby["name"];
                    if (this.activeLobby) {
                      this.activeLobby = this.utility.getActiveLobby(
                        this.activeLobby
                      );
                    }
                    return;
                  }
                }
              });
            }
            if (this.activeLobby) {
              return;
            }
          });
        }
        if (!this.activeLobby) {
          this.router.navigate([this.langCode + "/casino"]);
        }
      }
    );
  }

  checkDeviceType() {
    let ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "desktop";
      this.deviceType = "desktop";
    } else {
      this.windowType = "mobile";
      this.deviceType = "mobile";
    }
    if (
      (ww >= 500 && window.matchMedia("(orientation: landscape)").matches) ||
      (ww >= 768 && window.matchMedia("(orientation: portrait)").matches)
    ) {
      this.windowType = "desktop";
    }
  }

  joinBanners(event) {
    const routerLink = event.target.getAttribute("routerLink");
    if (routerLink) {
      this.utility.cmsRoutingHandler(routerLink);
    }
  }

  setOverflowHiddenClass() {
    setTimeout(() => {
      if (
        document.querySelector(".page-body") &&
        this.windowType === "mobile"
      ) {
        this.goToFullScreen(document.documentElement);
        this.utility.addClassToAppBody("overflow-hidden");
      } else {
        this.utility.removeClassFromAppBody("overflow-hidden");
      }
    });
  }
}
