// Enums
import { WorldCurrencyCode } from "src/app/models/configurations/enums/localization/world-currencies.enum";
import { CurrencyPosition } from "src/app/models/configurations/enums/currency-position.enum";
import { CryptoCurrency } from "src/app/models/configurations/enums/crypto-currency.enum";
import { CurrencySymbol } from "src/app/models/configurations/enums/currency-symbol.enum";
import { LanguageCodes } from "src/app/models/configurations/enums/language-codes.enum";
import { CountryCodes } from "src/app/models/configurations/enums/country-codes.enum";
import { AccountView } from "src/app/models/configurations/enums/account-view.enum";

// Models
import { SeoLanguageCodeUpdateConfigurations } from "src/app/models/configurations/general-configurations/seo-language-code-update-configuration.model";
import { LanguageToGameLaunchConfigurations } from "src/app/models/configurations/general-configurations/language-to-game-launch-configuration.model";
import { LocalRegilyLanguageConfigurations } from "src/app/models/configurations/general-configurations/local-regily-language-configuration.model";
import { LanguageToRegisterConfigurations } from "src/app/models/configurations/general-configurations/language-to-register-configuration.model";
import { ZimplerCountryCodeConfigurations } from "src/app/models/configurations/general-configurations/zimpler-country-code-configuration.model";
import { CmsLocalLanguageConfigurations } from "src/app/models/configurations/general-configurations/cms-local-language-configuration.model";
import { LocalCMSLanguageConfigurations } from "src/app/models/configurations/general-configurations/local-cms-language-configuration.model";
import { CountryLanguageConfigurations } from "src/app/models/configurations/general-configurations/country-language-configuration.model";
import { LanguageToPIQConfigurations } from "src/app/models/configurations/general-configurations/language-to-piq-configuration.model";
import { LanguageToPnpConfigurations } from "src/app/models/configurations/general-configurations/language-to-pnp-configuration.model";
import { HrefLanguageConfigurations } from "src/app/models/configurations/general-configurations/href-language-configuration.model";
import { IncomeSourceConfigurations } from "src/app/models/configurations/general-configurations/income-source-configuration.model";
import { CurrencyConfigurations } from "src/app/models/configurations/general-configurations/currency-configuration.model";

/*
  Here we are using different code for indian english
  & canada as per our configuration in CMS iirespective of their 
  respective code
  For Indian English is "ei"
  For Canada English is 'cd'
  rw stands for rest of world English 

  Regily Language to CMS
*/
export const cmsToLocalLanguageConfigurations: CmsLocalLanguageConfigurations = {
  fi: LanguageCodes["fi-fi"],
  et: LanguageCodes["et-ee"],
};

/*
  CMS to Language Codes for Regily
*/
export const localToCmsLanguageConfigurations: LocalCMSLanguageConfigurations = {
  "fi-fi": LanguageCodes.fi,
  "et-ee": LanguageCodes.et,
};

/*
  Special cofiguration for regily registration flow.
*/
export const localToRegilyLanguageConfigurations: LocalRegilyLanguageConfigurations = {
  "fi-fi": LanguageCodes.fi,
  "et-ee": LanguageCodes.et,
};

export const time24FormatConfigurations: string[] = [
  "en-row",
  "fi-fi",
  "et-ee",
];

/*
  Special configuration for country ip based to our CMS support.

  Reference: https://www.science.co.il/language/Codes.php
  https://docs.oracle.com/cd/E13214_01/wli/docs92/xref/xqisocodes.html
*/
export const countryLanguageConfigurations: CountryLanguageConfigurations = {
  FI: LanguageCodes.fi,
  EE: LanguageCodes.et,
};

/*
  Special Configuartion hreflang based on regions
*/
export const hrefLanguageConfigurations: HrefLanguageConfigurations = {
  "fi-fi": LanguageCodes["fi-fi"],
  "et-ee": LanguageCodes["et-ee"],
};

export const languageToRegisterConfigurations: LanguageToRegisterConfigurations = {
  "fi-fi": "fi_FI",
  "et-ee": "et_EE",
};

/*
  Language To PIQ -> Payment IQ
*/
export const languageToPIQConfigurations: LanguageToPIQConfigurations = {
  "fi-fi": "FI",
  "et-ee": "ET",
};

/*
  Language To Game Launch
*/
export const languageToGameLaunchConfigurations: LanguageToGameLaunchConfigurations = {
  "fi-fi": LanguageCodes.fi,
  "et-ee": LanguageCodes.et,
};

/*
  Account View List
*/
export const accountViewConfigurations: string[] = [
  AccountView.my_transacts_history,
  AccountView.confirm_identity,
  AccountView.change_password,
  AccountView.update_profile,
  AccountView.bet_history,
  AccountView.preferences,
  AccountView.withdrawal,
  AccountView.time_out,
  AccountView.limits,
  AccountView.bonus,
];

/*
  Max Supported File Size
*/
export const maxSupportedFileSizeInBytesConfigurations: number = 3145728;

/*
  Income Source
*/
export const incomeSourceListConfigurations: IncomeSourceConfigurations[] = [
  {
    value: "savingsFromSalary",
    value_text: "sourceoffundDropDownText.salary_wage",
    description: "sourceoffundDropDownText.salary_wage_des",
  },
  {
    value: "companyProfits",
    value_text: "sourceoffundDropDownText.business_profits",
    description: "sourceoffundDropDownText.business_profits_des",
  },
  {
    value: "salesOfSharesOrOtherInvestments",
    value_text: "sourceoffundDropDownText.sale_of_shares",
    description: "sourceoffundDropDownText.sale_of_shares_des",
  },
  {
    value: "saleOfProperty",
    value_text: "sourceoffundDropDownText.sale_of_property",
    description: "sourceoffundDropDownText.sale_of_property_des",
  },
  {
    value: "companySale",
    value_text: "sourceoffundDropDownText.sale_assets_company_sale",
    description: "sourceoffundDropDownText.sale_assets_company_sale_des",
  },
  {
    value: "dividentPayment",
    value_text: "sourceoffundDropDownText.dividentPayment",
    description: "sourceoffundDropDownText.dividentPayment_des",
  },
  {
    value: "fixedDepositSavings",
    value_text: "sourceoffundDropDownText.fixedDepositSavings",
    description: "sourceoffundDropDownText.fixedDepositSavings_des",
  },
  {
    value: "inheritance",
    value_text: "sourceoffundDropDownText.Inheritance_Gift",
    description: "sourceoffundDropDownText.Inheritance_Gift_des",
  },
  {
    value: "loan",
    value_text: "sourceoffundDropDownText.loan",
    description: "sourceoffundDropDownText.loan_des",
  },
  {
    value: "gift",
    value_text: "sourceoffundDropDownText.gift",
    description: "sourceoffundDropDownText.gift_des",
  },
  {
    value: "other",
    value_text: "sourceoffundDropDownText.other",
    description: "sourceoffundDropDownText.other_des",
  },
];

/*
  Crypto Currencies that are currently Supported

  https://coinmarketcap.com/all/views/all/

  https://finance.yahoo.com/cryptocurrencies/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAANkjVq8ZFLyEltZ72_UW2pr9xW75GFH4x29iDvDozbrbFz4lIQHMeglwyoRA7SRHlwi4Xe3af9-uGgUoG3WSETfzaHabBzARhDeGOSj70am2vIf6ptaJtsgywf7CKgwR-TnqzL-FTyS_WuAO3LOXHOejKE7uP7ofLV1C5yOjbeza
*/
export const cryptoCurrencyListConfigurations: string[] = [
  CryptoCurrency.BCH,
  CryptoCurrency.BTC,
  CryptoCurrency.ETH,
  CryptoCurrency.LTC,
];

/*
  This mapping is required for logout user's to show currency as per
  there country Locality

  NOTE: Used only for logout Users.
*/
export const currencyMappingConfigurations: {
  [key: string]: CurrencyConfigurations;
} = {
  CA: {
    currencyCode: WorldCurrencyCode.CAD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  FI: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  EE: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  NO: {
    currencyCode: WorldCurrencyCode.NOK,
    currencySymbol: CurrencySymbol.kr,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  NZ: {
    currencyCode: WorldCurrencyCode.NZD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  NL: {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
};

/*
  Available Countries
*/
export const avialableCountriesConfigurations: string[] = [
  CountryCodes.FI,
  CountryCodes.EE,
];

/*
 this config used to display deposit button on Banner if user has lessar than config balance else visible Play button
*/
export const bannerCTAConfigurations: {
  [key: string]: number;
} = {
  EUR: 5,
  CAD: 5,
  NZD: 5,
  USD: 5,
  NOK: 50,
  INR: 400,
  JPY: 500,
};

export const userMinimumRGLimitCheckConfigurations: {
  [key: string]: number;
} = {
  kr: 100,
  $: 10,
  C$: 10,
  "€": 10,
};

/*
  SEO Language Code Update
*/
export const seoLanguageCodeUpdateConfigurations: SeoLanguageCodeUpdateConfigurations = {
  "fi-fi": "fi",
  "et-ee": "et",
};

export const liveProviderCheckListPreferredOrderConfigurations: string[] = [
  "hub88_evolutiongaming",
  "pragmaticplay",
  "hub88_ezugi",
  "netent",
  "vivo",
];

export const zimplerCountryCodeConfigurations: ZimplerCountryCodeConfigurations = {
  "fi-fi": CountryCodes.FI,
  "et-ee": CountryCodes.EE,
};

export const excludeLanguageOptionsConfigurations: string[] = [];

export const marketLocaleCurrencyMappingConfigurations: {
  [key: string]: CurrencyConfigurations;
} = {
  "en-ca": {
    currencyCode: WorldCurrencyCode.CAD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "fi-fi": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "et-ee": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "nb-no": {
    currencyCode: WorldCurrencyCode.NOK,
    currencySymbol: CurrencySymbol.kr,
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
  "en-nz": {
    currencyCode: WorldCurrencyCode.NZD,
    currencySymbol: CurrencySymbol.$,
    position: CurrencyPosition.before,
    isSpaceRequired: false,
  },
  "en-eu": {
    currencyCode: WorldCurrencyCode.EUR,
    currencySymbol: CurrencySymbol["€"],
    position: CurrencyPosition.after,
    isSpaceRequired: true,
  },
};

export const languageToPnpConfigurations: LanguageToPnpConfigurations = {
  "fi-fi": LanguageCodes.fi,
  "et-ee": LanguageCodes.et,
};
