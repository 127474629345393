import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { Injectable } from "@angular/core";

// Services
import { MainService } from "src/app/services/main-service/main.service";

@Injectable({
  providedIn: "root"
})
export class ForgotPasswordService {
  constructor(private mainService: MainService) {}
  
  sendResetPasswordLink(requestObj): Observable<any> {
    return this.mainService.sendResetPasswordLink(requestObj).pipe(
      map((data) => {
        return data;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
