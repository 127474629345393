import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { Meta, Title } from "@angular/platform-browser";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// Configurations
import { menuConfigurations } from "src/app/configurations/menu.configurations";
import {
  localToCmsLanguageConfigurations,
  accountViewConfigurations,
  bannerCTAConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Models
import { MenuConfigurations } from "src/app/models/configurations/menu/menu-configuration.model";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { EmitterService } from "src/app/services/emitter.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  langCode: string;
  isLoggedIn: any;
  loginCompleteSubscription: Subscription;
  logOutCompleteSubscription: Subscription;
  affliateIdKey = environment.websiteCode + "_affId";
  bannerIdKey = environment.websiteCode + "_bannerId";
  trackerIdKey = environment.websiteCode + "_trackerId";
  referrerKey = environment.websiteCode + "_referrer";
  previousContent: any;
  fragment: any;
  activeLeftMenuOption: string;
  acceptedLanguages: string[];
  public footerConatiner: number = 0;
  currencyCodeSubscription: Subscription;
  currencyCode: string;
  userTotalBalance: number = 0;

  constructor(
    private router: Router,
    private metaService: Meta,
    private commonService: CommonService,
    private translationService: TranslationService,
    private metaTagService: Meta,
    private titleService: Title,
    private emitterService: EmitterService,
    private paynplayCashierService: PayNPlayCashierService,
    private userDetailsService: UserDetailsService
  ) {
    this.acceptedLanguages = supportedLanguagesList();
    this.translationService.langCodeSb$.subscribe((langCode) => {
      this.langCode = langCode;
    });
    this.isLoggedIn = this.isUserLoggedIn();
    this.commonService.loginComplete$.subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });
    this.commonService.logOutComplete$.subscribe((data) => {
      this.isLoggedIn = false;
    });
    this.currencyCodeSubscription = this.userDetailsService.currencyCodeSb$.subscribe(
      (curCode) => {
        this.currencyCode = curCode;
      }
    );

    // this.getWindowType();
  }

  getQueryParams() {
    if (window.location.search) {
      return JSON.parse(
        '{"' +
          decodeURI(
            window.location.search
              .substring(1)
              .replace(/&/g, '","')
              .replace(/=/g, '":"')
          ) +
          '"}'
      );
    } else {
      return {};
    }
  }

  creditCardValidation(ccNum): boolean {
    const arr = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    let len = ccNum.length,
      bit = 1,
      sum = 0,
      val;
    if (len >= 13 && len <= 19) {
      while (len) {
        val = parseInt(ccNum.charAt(--len), 10);
        sum += (bit ^= 1) ? arr[val] : val;
      }
    }
    return sum && sum % 10 === 0;
  }

  getBrandBasedConfig(key) {
    const menu: MenuConfigurations = menuConfigurations();
    return _.clone(menu[key]);
  }

  cmsRouting(routerLink) {
    if (routerLink) {
      this.router.navigate([this.langCode + "/" + routerLink]);
      window.scrollTo(0, 0);
    }
  }

  replaceAll(str, mapObj) {
    return str.replace(
      new RegExp(Object.keys(mapObj).join("|"), "gi"),
      (matched) => mapObj[matched]
    );
  }

  addClassToAppBody(className: string) {
    const appbody = $("body");
    if (appbody && !appbody.hasClass(className)) {
      appbody.addClass(className);
    }
  }

  removeClassFromAppBody(className: string) {
    const appbody = $("body");
    if (appbody && appbody.hasClass(className)) {
      appbody.removeClass(className);
    }
  }

  getFooterHeight() {
    const footerContainerDom: any = document.getElementsByClassName(
      "footer-conatiner"
    );
    if (footerContainerDom && footerContainerDom.length > 0) {
      return footerContainerDom[0].offsetHeight;
    } else {
      return 0;
    }
  }

  /**All the navigation related to cms banner will be handler here Itself
   * irrespective of where it loads on website...
   */
  cmsRoutingHandler(routerPath: string, data?) {
    const accountRoutingConfig = accountViewConfigurations;
    if (routerPath && routerPath === "login") {
      if (!this.isLoggedIn) {
        this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
      }
    } else if (
      routerPath &&
      accountRoutingConfig &&
      accountRoutingConfig.indexOf(routerPath) > -1
    ) {
      if (this.isLoggedIn) {
        this.openAccountComponent(routerPath);
      } else {
        this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
        this.commonService.setNavigateAfterLogin(
          data
            ? { type: "view", path: routerPath, data: data }
            : { type: "view", path: routerPath }
        );
      }
    } else if (
      routerPath &&
      this.getActiveLobby(routerPath.split("/")[1]) === "game"
    ) {
      if (this.isLoggedIn) {
        this.router.navigate([this.langCode + "/" + routerPath]);
      } else {
        this.commonService.setNavigateAfterLogin({
          type: "url",
          data: { navigateTo: "gameWindow", path: routerPath.split("/")[2] },
        });
        this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
      }
    } else if (routerPath && routerPath === "deposit") {
      this.paynplayCashierService.broadCastInitiateDeposit({
        callingFrom: "deepLink",
      });
    } else if (routerPath) {
      this.router.navigate([this.langCode + "/" + routerPath]);
    } else {
      this.router.navigate([this.langCode + "/casino"]);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  toggleCMSButtons() {
    const loggedIn = document.querySelectorAll(".logged-in-user");
    const loggedOut = document.querySelectorAll(".logged-out-user");
    if (this.isLoggedIn) {
      loggedIn.forEach((val) => val.classList.remove("hide"));
      loggedOut.forEach((val) => val.classList.add("hide"));
    } else {
      loggedIn.forEach((val) => val.classList.add("hide"));
      loggedOut.forEach((val) => val.classList.remove("hide"));
    }
  }

  isUserLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("kan_user"));
    if (user) {
      return true;
    }
    return false;
  }

  getGameGroupsByLobby(data, lobbyName: string) {
    let filterredData = data.filter(function (item) {
      return item.name.toLowerCase() === lobbyName.toLowerCase();
    });
    return filterredData.length > 0
      ? this.sortGameGroupsByCountryOrder(filterredData[0]["gameGroupList"])
      : [];
  }

  navigateAllGamesPage(gameGroupData, gameProvider?) {
    if (
      gameGroupData &&
      this.langCode &&
      gameGroupData["multilingualUrlGameGroup"] &&
      gameGroupData["multilingualUrlGameGroup"][
        localToCmsLanguageConfigurations[this.langCode]
      ]
    ) {
      window.scrollTo(0, 0);
      let multilingualUrlGameGroupName =
        gameGroupData["multilingualUrlGameGroup"][
          localToCmsLanguageConfigurations[this.langCode]
        ];
      let allgamesPath = multilingualUrlGameGroupName.toLowerCase().trim();
      setTimeout(() => {
        this.getFixedHeaderHeight();
      }, 100);
      if (gameProvider) {
        this.router.navigate([
          this.langCode +
            "/" +
            this.translationService.instant("url." + "casino") +
            "/" +
            allgamesPath +
            "/" +
            this.convertGameNameToUrl(gameProvider),
        ]);
      } else {
        this.router.navigate([this.langCode + "/" + allgamesPath]);
      }
    }
  }

  getProvidesList(gamesData) {
    if (gamesData) {
      let providesList = [...new Set(gamesData.map((game) => game.vendorCode))];
      return providesList;
    } else {
      return [];
    }
  }

  getSortedUniqProviderList(games) {
    /**
     * This condition " game['gameGroupList'] ? game['gameGroupList']['provider_order'] : 0" is not needed
     * here...because gameGroupList is already filter to only single object & it exist all time...
     * But for double check we have added it.
     */
    if (games && games.length > 0) {
      let data = _.map(games, (game, index) => {
        return {
          vendorCode: game["vendorCode"],
          vendorDisplayName: game["vendorDisplayName"],
          vendorName: game["vendorName"],
          provider_order: game["gameGroupList"]
            ? game["gameGroupList"]["provider_order"]
            : 0,
        };
      });
      if (data) {
        let providerList = _.uniq(data, false, (provider) => {
          return provider.vendorCode;
        });
        providerList = _.sortBy(providerList, "provider_order").reverse();
        return providerList;
      } else {
        return [];
      }
    }
  }

  getProviderList(games) {
    if (games && games.length > 0) {
      let data = _.map(games, (game, index) => {
        return {
          vendorCode: game["vendorCode"],
          vendorDisplayName: game["vendorDisplayName"],
          vendorName: game["vendorName"],
          provider_order: game["gameGroupList"]
            ? game["gameGroupList"]["provider_order"]
            : 0,
          isChecked: true,
        };
      });
      if (data) {
        let providerList = _.uniq(data, false, (provider) => {
          return provider.vendorCode;
        });
        return providerList;
      } else {
        return [];
      }
    }
  }

  getGamesByProviderName(games, providerName) {
    if (games) {
      return _.filter(games, { vendorCode: providerName });
    } else {
      return [];
    }
  }
  /**Sort game name by alphabet(A-Z)  */
  sortByGameName(games, key) {
    let sortedGames = _.sortBy(games, key);
    return sortedGames;
  }

  /**Sort game by Release Date */
  sortByReleaseDate(games, key) {
    let orderGames = _.sortBy(games, (game) => {
      return game[key];
    });
    return orderGames;
  }

  sortGameGroupsByCountryOrder(gameGrops) {
    let orderedData = _.sortBy(gameGrops, (group) => {
      return group["game_group_order"];
    });
    return orderedData.reverse();
  }

  sortGameByCountryOrder(games) {
    let orderGames = _.sortBy(games, (game) => {
      return game["gameGroupList"]["game_order"];
    });
    return orderGames.reverse();
  }

  openAccountComponent(viewname) {
    if (viewname) {
      this.commonService.broadCastActiveAcountMenu(viewname);
    } else {
      this.closeAccountComponent();
    }
  }

  closeAccountComponent(navigateTo?) {
    this.removeUrlQueryParams();
    if (navigateTo === "menuOptions") {
      this.commonService.broadCastActiveAcountMenu("menuOptions");
    } else {
      this.commonService.broadCastActiveAcountMenu("");
    }
  }

  isControlValid(controlFormNField, formName): boolean {
    let control = formName.controls[controlFormNField];
    return control && !control.errors && (control.dirty || control.touched);
  }

  getShouldShowErrors(formControlName: string, formGroup: FormGroup): boolean {
    let control: AbstractControl = formGroup.controls[formControlName];

    return control && control.errors && (control.dirty || control.touched);
  }

  getErrorMessage(controlFormNField, formName): string {
    let control;

    control = formName.controls[controlFormNField];

    return control.errors.message;
  }

  isButtonDisabled(formName): boolean {
    return formName.invalid || formName.pending;
  }
  formControlToParams(values, data) {
    Object.keys(values.controls).forEach((field) => {
      const control = values.get(field);
      if (control instanceof FormControl) {
        data[field] = control.value;
      } else if (control instanceof FormGroup) {
        this.formControlToParams(control, data);
      }
    });
    return data;
  }

  resetFormFields(formGroup: FormGroup, isForceReset?: boolean) {
    if (isForceReset) {
      formGroup.reset();
    } else {
      Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.setValue(null);
        } else if (control instanceof FormGroup) {
          this.resetFormFields(control);
        }
      });
    }
  }

  removeAllFormFieldsvalidation(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsUntouched({ onlySelf: true });
        control.markAsPristine({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.removeAllFormFieldsvalidation(control);
      }
    });
  }

  setAffCookies(affid, trackerid, bannerid) {
    const date = new Date();
    const newdate = new Date(date);
    date.setDate(date.getDate() + 30);
    const expires = "expires=" + date.toUTCString();

    if (affid) {
      document.cookie =
        this.affliateIdKey + "=" + affid + ";" + expires + ";path=/";
    }
    if (trackerid) {
      document.cookie =
        this.trackerIdKey + "=" + bannerid + ";" + expires + ";path=/";
    }
    if (bannerid) {
      document.cookie =
        this.bannerIdKey + "=" + trackerid + ";" + expires + ";path=/";
    }

    if (
      document.referrer &&
      !document.referrer.includes(window.location.origin)
    ) {
      document.cookie =
        this.referrerKey + "=" + document.referrer + ";" + expires + ";path=/";
    }
  }

  getAffCookies(cookiekey) {
    const name = environment.websiteCode + "_" + cookiekey + "=";

    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  deleteAffCookies() {
    document.cookie =
      this.affliateIdKey +
      "=" +
      "; expires=Thu, 01 Jan 1970 00:00:01 GMT ;path=/";
    document.cookie =
      this.bannerIdKey +
      "=" +
      "; expires=Thu, 01 Jan 1970 00:00:01 GMT ;path=/";
    document.cookie =
      this.trackerIdKey +
      "=" +
      "; expires=Thu, 01 Jan 1970 00:00:01 GMT ;path=/";
    document.cookie =
      this.referrerKey +
      "=" +
      "; expires=Thu, 01 Jan 1970 00:00:01 GMT ;path=/";
  }
  getSessionTimeText(minutes) {
    let sessionTimeText;
    if (minutes <= 60) {
      sessionTimeText =
        minutes + " " + this.translationService.instant("limits.minutes");
    } else {
      var hours: number = Math.floor(minutes / 60);
      var mins: number | string = minutes % 60;
      if (hours && mins && mins !== 0) {
        mins = mins < 10 ? "0" + mins : mins;
        sessionTimeText =
          hours +
          " " +
          this.translationService.instant("limits.hours") +
          " " +
          mins +
          " " +
          this.translationService.instant("limits.minutes");
      } else {
        sessionTimeText =
          hours + " " + this.translationService.instant("limits.hours");
      }
    }
    return sessionTimeText;
  }
  // addMetaTag(metaTag, metaDescription) {
  //   if (metaDescription && metaDescription !== this.previousContent) {
  //     this.removeMetaTag();
  //     this.previousContent = metaDescription;
  //     this.metaService.addTag({ name: 'description', content: metaDescription });
  //   }
  // }

  removeMetaTag() {
    this.metaService.removeTag("name='description'");
  }

  getFixedHeaderHeight() {
    setTimeout(() => {
      const fixedHeaderHeight = $("#pageHeader").outerHeight();
      $("#popoutOverlay").css("margin-top", fixedHeaderHeight + "px");
      let width = $(window).width();
      if (1920 < width) {
        $("#popoutOverlay").css("margin-top", fixedHeaderHeight + 40 + "px");
      }
    }, 1000);
  }

  scrollAddClass() {
    const doc = document.documentElement;
    if (doc) {
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      if (top > 25) {
        return true;
      } else {
        return false;
      }
    }
  }

  getActiveLobby(path) {
    let activeLobby = "";
    switch (path) {
      case "spielautomaten":
      case "slots":
      case "spilleautomater":
      case "kolikkopelit":
      case "slotid":
        activeLobby = "slots";
        break;
      case "table-games":
      case "tischspiele":
      case "bordspill":
      case "kolikkopelit":
      case "maengud-laudades":
        activeLobby = "table-games";
        break;
      case "all":
      case "alle":
      case "kaikki":
      case "koeik":
        activeLobby = "all";
        break;
      case "game":
      case "spiel":
      case "spill":
      case "peli":
      case "maeng":
        activeLobby = "game";
        break;
      case "promotions":
      case "aktionen":
      case "kampanjer":
      case "kampanjat":
      case "pakkumised":
        activeLobby = "promotions";
        break;
      case "rewards":
      case "boni":
      case "premier":
      case "palkinnot":
      case "preemiad":
        activeLobby = "rewards";
        break;
      default:
        activeLobby = path;
        break;
    }
    return activeLobby;
  }

  convertGameNameToUrl(gameName) {
    return gameName
      .replace(/[\W_]+/g, " ")
      .trim()
      .toLowerCase()
      .split(" ")
      .join("-");
  }

  convertSpecialCharactersWithSpace(gameName) {
    return gameName
      .toUpperCase()
      .replace(/[\W_]+/g, " ")
      .trim();
  }

  getLangCode() {
    if (localStorage.getItem("langCode")) {
      return localStorage.getItem("langCode");
    } else {
      const urlSegments = this.getDecodedCurrentPath().split("/");
      var langCode =
        urlSegments && urlSegments.length >= 2 ? urlSegments[1] : "";
      if (langCode && _.contains(this.acceptedLanguages, langCode)) {
        langCode = urlSegments[1];
      } else {
        langCode = environment.defaultLang;
      }
      return langCode;
    }
  }

  backNavigationURlHandler() {
    if (!this.commonService.previousComponentURL) {
      this.router.navigate([this.langCode + "/casino"]);
    } else {
      this.router.navigate([this.commonService.previousComponentURL]);
    }
  }

  scrollToFragment(location) {
    const element = document.querySelector("#" + location);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }, 1000);
    }
  }

  updateActiveLeftMenu(name?) {
    var segment;
    if (["rewards"].indexOf(name) > -1) {
      segment = name;
    } else if (!this.commonService.getActiveAccountMenu()) {
      var urlSegments: string[] = this.getDecodedCurrentPath().split("/");
      if (urlSegments && urlSegments.length >= 3) {
        segment = this.getActiveLobby(urlSegments[2]);
      }
    } else if (this.commonService.getActiveAccountMenu() === "rewards") {
      segment = "rewards";
    }
    this.commonService.broadCastActiveLeftMenu(segment);
  }

  /**open terms & conditions page in new tab */
  openTermsAndCondtionPage() {
    const url = this.translationService.instant("url.terms_conditions");
    if (url) {
      this.commonService.broadCastActiveAcountMenu("");
      window.open(this.langCode + "/" + url);
    }
  }

  fullScreenInit(targetEle) {
    let fullscreenPromise;
    if (targetEle) {
      if (targetEle["requestFullscreen"]) {
        fullscreenPromise = targetEle["requestFullscreen"]();
      } else if (targetEle["webkitRequestFullscreen"]) {
        fullscreenPromise = targetEle["webkitRequestFullscreen"]();
      } else if (targetEle["mozRequestFullScreen"]) {
        fullscreenPromise = targetEle["mozRequestFullScreen"]();
      } else if (targetEle["msRequestFullscreen"]) {
        fullscreenPromise = targetEle["msRequestFullscreen"]();
      }
    }

    if (fullscreenPromise) {
      fullscreenPromise
        .catch(() => {})
        .then(() => {
          fullscreenPromise.done = true;
        });
    }
  }

  exitFullScreen() {
    let exitPromise;
    if (document && document["exitFullscreen"]) {
      exitPromise = document["exitFullscreen"]();
    } else if (document && document["mozCancelFullScreen"]) {
      exitPromise = document["mozCancelFullScreen"]();
    } else if (document && document["webkitExitFullscreen"]) {
      exitPromise = document["webkitExitFullscreen"]();
    } else if (document && document["msExitFullscreen"]) {
      exitPromise = document["msExitFullscreen"]();
    }
    if (exitPromise) {
      exitPromise
        .catch(() => {})
        .then(() => {
          exitPromise.done = true;
        });
    }
  }

  getTranslatedText(value, langcode) {
    switch (langcode) {
      case "en-row":
        if (value === "promotions") {
          return "promotions";
        }
        break;
      case "fi-fi":
        if (value === "promotions") {
          return "kampanjat";
        }
        break;
      case "et-ee":
        if (value === "promotions") {
          return "pakkumised";
        }
        break;
      default:
        if (value === "promotions") {
          return "kampanjat";
        }
    }
  }
  /**Code to remove query params from url if we have any on closing of component
   * because we have chance of opening account component from query params,
   * which we need to remove before close.
   */
  removeUrlQueryParams() {
    if (window.location.search.length > 0) {
      this.router.navigate([window.location.pathname]);
    }
  }

  setSEO(contents, isGame = false) {
    if (contents["metaTitle"] || contents["metatitle"]) {
      this.metaTagService.updateTag({
        name: "title",
        content: contents["metaTitle"]
          ? contents["metaTitle"]
          : contents["metatitle"],
      });
      this.metaTagService.updateTag({
        property: "og:title",
        content: contents["metaTitle"]
          ? contents["metaTitle"]
          : contents["metatitle"],
      });
    } else {
      this.metaTagService.updateTag({ name: "title", content: "Kanuuna" });
      this.metaTagService.updateTag({
        property: "og:title",
        content: "Kanuuna",
      });
    }
    if (contents["metaDescription"] || contents["metadescription"]) {
      this.metaTagService.updateTag({
        name: "description",
        content: contents["metaDescription"]
          ? contents["metaDescription"]
          : contents["metadescription"],
      });
      this.metaTagService.updateTag({
        property: "og:description",
        content: contents["metaDescription"]
          ? contents["metaDescription"]
          : contents["metadescription"],
      });
    } else {
      this.metaTagService.updateTag({
        name: "description",
        content:
          "Kanuuna.com on värikäs uusi pikakasino kaikille suomalaisille Trustlyn, Eutellerin, Zimplerin ystäville. 200 ilmaiskierrosta uusille asiakkaille.",
      });
      this.metaTagService.updateTag({
        property: "og:description",
        content:
          "Kanuuna.com on värikäs uusi pikakasino kaikille suomalaisille Trustlyn, Eutellerin, Zimplerin ystäville. 200 ilmaiskierrosta uusille asiakkaille.",
      });
    }
    if (contents["metaTags"] || contents["metatags"]) {
      if (document.querySelectorAll("meta[name=keywords]").length > 0) {
        this.metaService.updateTag({
          name: "keywords",
          content: contents["metaTags"]
            ? contents["metaTags"]
            : contents["metatags"],
        });
      } else {
        this.metaService.addTag({
          name: "keywords",
          content: contents["metaTags"]
            ? contents["metaTags"]
            : contents["metatags"],
        });
      }
    } else if (document.querySelectorAll("meta[name=keywords]").length > 0) {
      this.metaService.updateTag({ name: "keywords", content: "" });
    } else {
      this.metaService.addTag({ name: "keywords", content: "" });
    }
    if (contents["title"]) {
      this.titleService.setTitle(contents["title"]);
    } else {
      this.titleService.setTitle("Kanuuna");
    }
    if (isGame && contents["name"]) {
      this.titleService.setTitle(contents["name"]);
    }
  }

  capitalizeFirstLetter(providerName) {
    return (
      providerName.charAt(0).toUpperCase() +
      providerName
        .slice(1)
        .replace(/[\W_]+/g, " ")
        .trim()
    );
  }

  getLobbyDataByLobbyName(lobbys, lobbyName) {
    let filterredData = [];
    if (lobbys && lobbys.length > 0) {
      filterredData = lobbys.filter(function (item) {
        return item.name.toLowerCase() === lobbyName.toLowerCase();
      });
    }
    return filterredData.length > 0 ? filterredData[0] : [];
  }

  /**open terms & conditions page in new tab */
  toggleRewardTCPopUp(flag) {
    if (flag) {
      document.body.classList.add("overflow-hidden");
    }
    // else{
    //   document.body.classList.remove('overflow-hidden');
    // }
    this.emitterService.broadCastRewardTC(flag);
  }

  getImgixParams(opts?: { [key: string]: string }) {
    // https://docs.imgix.com/apis/rendering
    const defaults = {
      auto: "format,compress",
    };
    const options = opts ? { ...defaults, ...opts } : defaults;
    const params = `?${Object.keys(options)
      .map((k) => k + "=" + options[k])
      .join("&")}`;

    return params;
  }
  getDecodedCurrentPath() {
    return decodeURIComponent(window.location.pathname);
  }

  /**
   * Functionality to hide & show "Despoit & Game play" (If they configured on banner)
   * Based on banner CTA Config vs user total balance
   */
  toggleBannerDepositBtn(userTotalBalance?) {
    const isDepositBtnAvailable = document.querySelectorAll(
      ".show-banner-cta-deposit"
    );
    const isGamePlayBtnAvailable = document.querySelectorAll(
      ".show-banner-cta-play"
    );
    if (userTotalBalance) {
      this.userTotalBalance = userTotalBalance;
    }
    if (isDepositBtnAvailable.length > 0 && isGamePlayBtnAvailable.length > 0) {
      if (this.isLoggedIn) {
        if (
          this.currencyCode &&
          this.userTotalBalance < bannerCTAConfigurations[this.currencyCode]
        ) {
          this.removeClassFromDomElements(isDepositBtnAvailable, "hide");
          this.addHideClassToDomElements(isGamePlayBtnAvailable, "hide");
        } else {
          this.addHideClassToDomElements(isDepositBtnAvailable, "hide");
          this.removeClassFromDomElements(isGamePlayBtnAvailable, "hide");
        }
      } else {
        this.addHideClassToDomElements(isDepositBtnAvailable, "hide");
        this.removeClassFromDomElements(isGamePlayBtnAvailable, "hide");
      }
    }
  }

  addHideClassToDomElements(domElements, className) {
    domElements.forEach((val) => val.classList.add(className));
  }

  removeClassFromDomElements(domElements, className) {
    domElements.forEach((val) => val.classList.remove(className));
  }

  getTimeFormat(expiryDate) {
    var translated_days: string = this.translationService.instant(
      "rewards.days"
    );
    var translated_day: string = this.translationService.instant("rewards.day");
    let date_future: any,
      date_now: any,
      seconds: any,
      minutes: any,
      hours: any,
      days: any;
    let durationTime;
    date_future = new Date(expiryDate);
    date_now = new Date();
    if (date_future > date_now) {
      seconds = Math.floor((date_future - date_now) / 1000);
      minutes = Math.floor(seconds / 60);
      hours = Math.floor(minutes / 60);
      days = Math.floor(hours / 24);
      hours = hours - days * 24;
      minutes = minutes - days * 24 * 60 - hours * 60;
      seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
      if (days > 30) {
        durationTime = ">30 " + translated_days;
      } else if (days > 0) {
        if (days > 0 && (hours || minutes || seconds)) {
          days = days + 1;
        }
        durationTime =
          days + " " + (days === 1 ? translated_day : translated_days);
      } else if (hours > 0) {
        durationTime =
          hours +
          this.translationService.instant("common.hours") +
          minutes +
          this.translationService.instant("common.minutes");
      } else if (minutes > 0) {
        durationTime =
          minutes +
          this.translationService.instant("common.minutes") +
          seconds +
          this.translationService.instant("common.seconds");
      } else if (seconds > 0) {
        durationTime =
          seconds + this.translationService.instant("common.seconds");
      } else {
        durationTime =
          seconds + this.translationService.instant("common.seconds");
      }
    }
    return durationTime;
  }
}
