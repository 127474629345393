import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GtmService {
  constructor() {}

  pushGTMDataEvent(event) {
    let dataLayer = (window["dataLayer"] = window["dataLayer"] || []);
    dataLayer.push(event);
  }

  trackGTMPageViewEvents(event) {
    let pathname = event["url"];
    let router_name = "/casino";
    if (pathname && pathname.length > 6) {
      router_name = pathname.substring(6);
    }
    if (router_name) {
      this.pushGTMDataEvent({
        event: "customPage",
        pageName: router_name,
      });
    }
  }

  trackLoginGTMEvent(type, data?) {
    if (type === "open") {
      this.pushGTMDataEvent({
        event: "customPage",
        pageName: "/open-login",
      });
    }
    if (type === "login-success") {
      this.pushGTMDataEvent({
        event: "logged-In",
        externalEcrId: data["ecrExternalId"],
      });
    }
  }

  /**we basically push three events here
   * 1.open deposit
   * 2.success depost &
   * 3.failure deposit
   */

  trackDepositGTMEvent(
    depositType: string,
    transactionData?,
    isFirstDeposit = false
  ) {
    let gtmEventPlayload;
    if (depositType === "open") {
      gtmEventPlayload = {
        event: "customPage",
        pageName: "/deposits/open",
      };
    }
    if (depositType === "FAILURE" && transactionData) {
      gtmEventPlayload = this.getDepositFailure(transactionData);
    }
    if (depositType === "SUCCESS" && transactionData) {
      gtmEventPlayload = this.getDepositSuccessBassedTxnStatus(
        transactionData,
        isFirstDeposit
      );
    }
    this.pushGTMDataEvent(gtmEventPlayload);
  }

  getDepositFailure(transactionData) {
    let payload = {
      event: "customPage",
      pageName: "/deposits/failure",
      transactionId: transactionData["txnId"],
      amount: transactionData["amount"],
    };
    if (transactionData["errorCode"]) {
      payload["errorCode"] = transactionData["errorCode"];
      payload["errorDescription"] = transactionData["errorDescription"];
    }
    if (transactionData["externalEcrId"]) {
      payload["externalEcrId"] = transactionData["externalEcrId"];
    }
    return payload;
  }

  getDepositSuccessBassedTxnStatus(transactionData, isFirstDeposit) {
    let payload = {
      event: "customPage",
      amount: transactionData["amount"],
      transactionId: transactionData["txnId"],
    };
    if (transactionData["externalEcrId"]) {
      payload["externalEcrId"] = transactionData["externalEcrId"];
    }
    switch (transactionData["txnStatus"]) {
      case "DEPOSIT_SUCCESS":
      case "CASHIER_TRANSFER_SUCCESS":
      case "CASHIER_INIT_DEPOSIT_SUCCESS":
        payload["pageName"] = "/deposits/success";
        payload["depositType"] = isFirstDeposit ? "first" : "subsequent";
        break;
      case "KYC_VERIFIED":
      case "CASHIER_INIT_DEPOSIT_FAILED":
      case "CATEGORY_LIMITS_EXCEEDED":
      case "DEPOSIT_NOT_ALLOWED":
        {
          payload["pageName"] = "/deposits/failure";
          if (
            transactionData["errorCode"] &&
            transactionData["errorDescription"]
          ) {
            payload["errorCode"] = transactionData["errorCode"];
            payload["errorDescription"] = transactionData["errorDescription"];
          }
        }
        break;
    }
    return payload;
  }
}
