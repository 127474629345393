import { ViewEncapsulation, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";

// Models
import { LivespinsBetBehind } from "src/app/modules/livespins/models/livespins-bet-behind.model";

// Plugins
import { LivespinsSDK } from "livespins/@livespins";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { LivespinsService } from "src/app/modules/livespins/services/livespins.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { MainService } from "src/app/services/main-service/main.service";

@Component({
  selector: "app-livespins-live-casino",
  templateUrl: "./livespins-live-casino.component.html",
  styleUrls: ["./livespins-live-casino.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LivespinsLiveCasinoComponent implements OnInit {
  private params: Params;

  // Booleans
  isLoggedIn: boolean = false;
  isLoading: boolean = false;

  // Strings
  originalThemeColor: string = "";
  noGameUrlError: string = "";
  languageCode: string = "";
  authToken: string = "";
  sessionId: string = "";
  iframeURL: string = "";
  shareURL: string = "";
  language: string = "";
  backURL: string = "";

  // Subscriptions
  livespinsSDKSubscription: Subscription;
  livespinsBetSubscription: Subscription;
  subscriptions: Subscription[] = [];

  constructor(
    private translationService: TranslationService,
    private livespinsService: LivespinsService,
    private activatedRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private commonService: CommonService,
    private mainService: MainService
  ) {}

  // -----------------------------------------------------------------
  // Lifecycle Hooks
  ngOnInit(): void {
    this.livespinsService.onInitialise();

    this.isLoggedIn = this.utilityService.isUserLoggedIn();

    this.subscriptions = [
      this.activatedRoute.params.subscribe((params: Params) => {
        this.params = params;
      }),
      this.commonService.loginComplete$.subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      }),
      this.commonService.logOutComplete$.subscribe(() => {
        this.isLoggedIn = false;
      }),
    ];

    this.languageCode = this.utilityService.getLangCode();

    this.originalThemeColor = document
      .querySelector('meta[name="theme-color"]')
      .getAttribute("content");

    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", "#0D0D10");
    document.querySelector("body").classList.add("livespins");

    // @ts-ignore
    window.prerenderReady = false;

    if (this.isLoggedIn) {
      this.onInitializeLoggedInArea();
    } else {
      this.commonService.broadCastActiveAcountMenu("login");
    }
  }

  // -----------------------------------------------------------------
  // Set Methods
  onInitializeLoggedInArea(): void {
    this.livespinsBetSubscription = this.mainService
      .psGet(endpointConfigurations.getRealgame_url, {
        gameSymbol: "livespinsBetBehind",
      })
      .subscribe((realGameResponse: LivespinsBetBehind) => {
        if (!realGameResponse.gameDetails.url) {
          this.noGameUrlError = this.translationService.instant(
            "gameplay.gamenot_found"
          );
          return;
        }

        this.language =
          LivespinsService.langCodeToLivespinsLanguageCode[this.languageCode] ||
          "en";

        this.backURL = `${window.location.origin}/game-redirect.html?to=${this.languageCode}/casino`;

        this.shareURL = `${window.location.origin}/${this.languageCode}/livespins/:sessionId`;

        this.authToken = realGameResponse.gameDetails.url
          .split("&")[2]
          .replace("token=", "");

        this.sessionId = this.params.id;

        this.isLoading = false;

        this.onInvokeLivespinsSDK();
      });
  }

  onInvokeLivespinsSDK(): void {
    this.livespinsSDKSubscription = this.livespinsService.sdkSubject
      .pipe(filter((x: LivespinsSDK | null) => x !== null))
      .subscribe((value: LivespinsSDK) => {
        this.iframeURL = value.convert.toLobbyURL({
          authToken: this.authToken,
          backURL: this.backURL,
          shareURL: this.shareURL,
          openStream: `ifCount:${1}`,
          streamEndRedirect: true,
        });
      });
  }

  // -----------------------------------------------------------------
  // On Destroy
  ngOnDestroy(): void {
    if (this.livespinsBetSubscription)
      this.livespinsBetSubscription.unsubscribe();
    if (this.livespinsSDKSubscription)
      this.livespinsSDKSubscription.unsubscribe();

    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );

    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", this.originalThemeColor);

    document.querySelector("body").classList.remove("livespins");
  }
}
