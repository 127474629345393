import { Component, OnInit, HostListener, OnDestroy } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  NavigationStart,
  UrlSegmentGroup,
  ActivatedRoute,
  PRIMARY_OUTLET,
  NavigationEnd,
  UrlSegment,
  UrlTree,
  Router,
} from "@angular/router";

// Configurations
import {
  cmsToLocalLanguageConfigurations,
  localToCmsLanguageConfigurations,
  countryLanguageConfigurations,
} from "src/app/configurations/main.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Services
import { PayNPlayCashierService } from "src/app/modules/account/services/pay-n-play-cashier.service";
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { CashbackPromoService } from "src/app/pages/rewards/service/cashback-promo.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { LoginService } from "src/app/modules/auth/services/login.service";
import { GtmService } from "src/app/services/gtm-service/gtm.service";
import { EmitterService } from "src/app/services/emitter.service";

// Utilities
import { supportedLanguagesList } from "src/app/modules/shared/utilities/languages.utilities";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "kanuuna";
  langCode: string;
  showBanner: boolean = false;
  activePath: string;
  gameData: any;
  isGameWindowMinimized: boolean = false;
  isNavpushopen: boolean = false;
  windowType: string;
  routerSub: Subscription;
  isGameWindowLanuchSub: Subscription;
  isGameWindowMinimizedSub: Subscription;
  isLoggedIn: boolean;
  loginCompleteSubscription: Subscription;
  sessionTimeText: any;
  activePage: string;
  sessionTimeUpdateSub: Subscription;
  isMaintanceOrCountryBlockEnabled: boolean = false;
  activePageSub: Subscription;
  isHeaderHide: boolean = false;
  unsubscribeEmail;
  doLogOutSub: Subscription;
  activeBonusSub: any;
  dropBonusSub: any;
  termsConditionSub: Subscription;
  openRewardPopup: any;
  sessionExpiryPopup: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  cashBackDepositOfferPopup: boolean = false;
  cashBackReward;
  cashBackStartedPopup: boolean = false;
  cashBackUserSlabInfo;
  currencyCodeSub: Subscription;
  currencyCode: string;
  promoConfigInfo;
  cbPromoStartDate;
  cbPromoEndDate;
  cbStartTime;
  cbEndTime;

  @HostListener("window:resize") onResize() {
    this.getWindowType();
  }

  @HostListener("window:orientationchange") onRotate() {
    this.getWindowType();
  }

  @HostListener("window:unload", ["$event"]) unloadHandler(event: Event) {
    if (this.paynplayCashierService.getUserLoggedTime()) {
      localStorage.setItem(
        "loggedInTime",
        this.paynplayCashierService.getUserLoggedTime()
      );
    }
  }

  @HostListener("window:load", ["$event"]) domContentLoadHandler(event: Event) {
    if (localStorage.getItem("loggedInTime")) {
      this.paynplayCashierService.setUserLoggedTime(
        new Date(Date.parse(localStorage.getItem("loggedInTime")))
      );
    }
  }

  constructor(
    private loginService: LoginService,
    private gamePlayService: GamePlayService,
    private translationService: TranslationService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utility: UtilityService,
    private emitterService: EmitterService,
    private accountService: AccountService,
    private gtmService: GtmService,
    private userDetailsService: UserDetailsService,
    private paynplayCashierService: PayNPlayCashierService,
    private liveChatService: LiveChatService,
    private gameGroupsService: GameGroupsService,
    private cashBackPromoService: CashbackPromoService
  ) {
    this.gameIframeRedirection();
    // this.addPiqJsEncrypterSrcipt();

    /**
     * please refer comment 1 in app component
     * this.liveGames.connect(environment.liveGameUrl, environment.liveGameCasioID);
     *  this.liveGames.getNetentLiveGamesFeed();
     *
     **/

    this.getWindowType();
    this.translationService.addLangs(environment.langCode);
    this.translationService.langCodeSb$.subscribe((langcode) => {
      this.langCode = langcode;
    });
    this.userDetailsService.currencyCodeSb$
      .pipe(takeUntil(this.destroy$))
      .subscribe((curreny) => {
        this.currencyCode = curreny;
      });
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      if (
        queryParams.redirect &&
        (queryParams.redirect.includes("deposit") ||
          queryParams.redirect.includes("withdraw"))
      ) {
        setTimeout(() => {
          this.paynplayCashierService.broadCastProcessUserTranaction(
            queryParams.redirect
          );
        }, 500);
        this.clearQueryParams();
      }
    });
    this.routerSub = this.router.events.subscribe((event) => {
      /**we clear game play data here when user navigate out from gamepaly window
       to some other route path with click on minimize options  */
      if (event instanceof NavigationStart) {
        if (
          event.url &&
          !event.url.includes(
            "/" + this.translationService.instant("url.game")
          ) &&
          !this.gamePlayService.isGameMinimized
        ) {
          if (!_.isEmpty(this.gamePlayService.getCurrentGameData())) {
            this.gamePlayService.broadCastIsGameLanuch({});
            this.gamePlayService.setGameCalledfrom(undefined);
          }
        }
        if (
          event.url &&
          (event.url.includes("/maintenance") ||
            event.url.includes("/countryblock"))
        ) {
          this.isMaintanceOrCountryBlockEnabled = true;
        } else {
          this.isMaintanceOrCountryBlockEnabled = false;
        }
        if (
          (event.url == "/" + this.langCode + "/unsubscribe" ||
            event.url == "/" + this.langCode + "/casino/login") &&
          this.isLoggedIn
        ) {
          this.router.navigate([this.langCode + "/casino"]);
        }
      }

      /**re-intainize all the required properties
       * after every navigation...
       */
      if (event instanceof NavigationEnd) {
        let currentUrlPath = this.utility.getDecodedCurrentPath();
        const tree: UrlTree = router.parseUrl(event.url);
        const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
        const s: UrlSegment[] = g ? g.segments : [];
        if (s && s[1] && s[1].path) {
          this.activePage = this.utility.getActiveLobby(s[1].path);
        } else {
          this.activePage = "";
        }
        if (
          currentUrlPath === "" ||
          currentUrlPath === "/" ||
          currentUrlPath.endsWith(this.langCode)
        ) {
          this.showBanner = true;
        } else {
          this.showBanner = false;
        }
        this.utility.updateActiveLeftMenu();
        this.onRouteChange(event);
      }
    });

    this.isGameWindowLanuchSub = this.gamePlayService.isGamewindowLanuched$.subscribe(
      (gameData) => {
        this.gameData = Object.keys(gameData).length > 0 ? gameData : undefined;
      }
    );
    this.isGameWindowMinimizedSub = this.gamePlayService.isGameWindowMinimized$.subscribe(
      (flag) => {
        this.isGameWindowMinimized = flag;
      }
    );
    this.termsConditionSub = this.emitterService.openRewardTermsConditions$.subscribe(
      (flag) => {
        this.openRewardPopup = flag;
      }
    );
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.loginCompleteSubscription = this.commonService.loginComplete$.subscribe(
      (isLoggedIn) => {
        this.isLoggedIn = isLoggedIn;
        this.setSessionTimeText();
      }
    );
    this.loginCompleteSubscription = this.commonService.logOutComplete$.subscribe(
      (data) => {
        this.isLoggedIn = false;
        this.clearServiceDataonLogOut();
        this.liveChatService.updateSessionData();
        this.paynplayCashierService.setUserLoggedTime("");
      }
    );
    this.sessionTimeUpdateSub = this.commonService.sessionTimeUpdated$.subscribe(
      (data) => {
        this.setSessionTimeText();
      }
    );

    this.activePageSub = this.commonService.updateActivePage$.subscribe(
      (pagename) => {
        if (pagename === "gamepage") {
          this.isHeaderHide = true;
        } else {
          this.isHeaderHide = false;
        }
      }
    );
    this.doLogOutSub = this.emitterService.doLogOut$.subscribe((flag) => {
      if (flag) {
        this.commonService.doLogout();
      }
    });
    this.cashBackPromoService.cashBackOfferAmount$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cashBackAmount) => {
        if (cashBackAmount) {
          this.cashBackReward = cashBackAmount;
          this.cashBackDepositOfferPopup = true;
          this.utility.addClassToAppBody("overflow-hidden");
        }
      });

    this.cashBackPromoService.cashBackstartedPromoPopup$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.getCBPromoConfigDetails(data);
        }
      });

    this.findMyOS();
  }

  getCBPromoConfigDetails(data) {
    let requestData = {
      promoRaceId: data["promoRaceId"],
      promoParentId: data["promoParentId"],
    };
    this.cashBackPromoService
      .getPromoConfigDetails(requestData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data && data["status"] === "SUCCESS" && data["promoConfig"]) {
          this.promoConfigInfo = data["promoConfig"];
          if (
            this.promoConfigInfo["campaignStartDate"] &&
            this.promoConfigInfo["campaignEndDate"]
          ) {
            this.cbPromoStartDate = new Date(
              this.promoConfigInfo["campaignStartDate"]
            ).toDateString();
            this.cbPromoEndDate = new Date(
              this.promoConfigInfo["campaignEndDate"]
            ).toDateString();
            this.cbStartTime = new Date(
              this.promoConfigInfo["campaignStartDate"]
            ).toLocaleTimeString();
            this.cbEndTime = new Date(
              this.promoConfigInfo["campaignEndDate"]
            ).toLocaleTimeString();
          }
          let slabGroupInfo = this.promoConfigInfo["slabGroupInfo"][
            Object.keys(this.promoConfigInfo["slabGroupInfo"])[0]
          ];
          this.cashBackUserSlabInfo = _.filter(slabGroupInfo["slabInfo"], {
            slabCurrency: this.currencyCode,
          })[0];
          this.cashBackStartedPopup = true;
          this.utility.addClassToAppBody("overflow-hidden");
        }
      });
  }

  receiveMessage(event) {
    alert("Get transaction status" + JSON.parse(event));
  }

  ngOnInit() {
    this.isCountryBlocked();
    console.log(`
      ▄ •▄  ▄▄▄·  ▐ ▄ ▄• ▄▌▄• ▄▌ ▐ ▄  ▄▄▄· 
      █▌▄▌▪▐█ ▀█ •█▌▐██▪██▌█▪██▌•█▌▐█▐█ ▀█ 
      ▐▀▀▄·▄█▀▀█ ▐█▐▐▌█▌▐█▌█▌▐█▌▐█▐▐▌▄█▀▀█ 
      ▐█.█▌▐█ ▪▐▌██▐█▌▐█▄█▌▐█▄█▌██▐█▌▐█ ▪▐▌
      ·▀  ▀ ▀  ▀ ▀▀ █▪ ▀▀▀  ▀▀▀ ▀▀ █▪ ▀  ▀ 
    `);
  }

  ipBasedLangCode: string;
  isCountryBlocked() {
    Promise.resolve(this.commonService.getCountryBlockData()).then((data) => {
      if (data && data["countryCode"]) {
        this.commonService.setCountryCode(data["countryCode"]);
        if (!this.isLoggedIn) {
          this.commonService.setCurrencyByLocality();
        }
        /**Here 'IN' language code is already occupied by indonesia country
         * in our CMS system..so to avoid conflict we are using ei for indain english
         * As configured in CMS.
         * so we are overriding India(IN code) to ei,To manage language translations.
         * & we also overriding Canada(CA code) to cd,To manage language translations.
         */
        this.ipBasedLangCode =
          cmsToLocalLanguageConfigurations[
            countryLanguageConfigurations[data["countryCode"]]
          ];
        this.setLang(this.ipBasedLangCode);
      }
    });
  }
  doLogout() {
    this.commonService.doLogout();
  }
  navigateTo(navigateTo) {
    switch (navigateTo) {
      case "login":
        this.router.navigate([this.langCode + "/login"]);
        break;
      case "/logout":
        this.doLogout();
        break;
      case "home":
        this.router.navigate([this.langCode + "/casino"]);
      default:
        this.router.navigate([this.langCode + "/casino"]);
    }
  }

  closePopup() {
    this.cashBackStartedPopup = false;
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  setLang(ipbasedLang) {
    let regfirst = /^[a-z]{2}-[a-z]{2}$/;
    let regSecond = /^[a-z]{2}-[a-z]{3}$/;
    let locationPath = this.utility.getDecodedCurrentPath();
    let acceptedLanguages: string[] = supportedLanguagesList();
    const localLangcode = localStorage.getItem("langCode");
    if (locationPath && locationPath.length > 1) {
      let languageCodeFromURL = this.utility
        .getDecodedCurrentPath()
        .split("/")[1];
      if (
        languageCodeFromURL &&
        (regfirst.test(languageCodeFromURL) ||
          regSecond.test(languageCodeFromURL))
      ) {
        if (
          languageCodeFromURL &&
          _.contains(acceptedLanguages, languageCodeFromURL)
        ) {
          if (this.isLoggedIn && localLangcode) {
            this.userProfileLangNavigation(regfirst, languageCodeFromURL);
          } else {
            this.updateLanguageCode(languageCodeFromURL);
            localStorage.setItem("langCode", languageCodeFromURL);
            if (locationPath.split("/").length < 3) {
              this.router.navigate([locationPath + "/" + "casino"]);
            }
          }
        } else {
          let pathwithoutlangCode;
          if (regfirst.test(languageCodeFromURL)) {
            pathwithoutlangCode = this.utility
              .getDecodedCurrentPath()
              .substring(6);
          } else {
            pathwithoutlangCode = this.utility
              .getDecodedCurrentPath()
              .substring(7);
          }
          if (pathwithoutlangCode === "") {
            pathwithoutlangCode = "casino";
          }
          if (localLangcode && _.contains(acceptedLanguages, localLangcode)) {
            this.navigateAfterSetLang(
              localLangcode + "/" + pathwithoutlangCode
            );
            this.langCode = localLangcode;
          } else if (
            ipbasedLang &&
            _.contains(acceptedLanguages, ipbasedLang)
          ) {
            this.updateLanguageCode(ipbasedLang);
            this.navigateAfterSetLang(ipbasedLang + "/" + pathwithoutlangCode);
          } else {
            this.updateLanguageCode(environment.defaultLang);
            this.navigateAfterSetLang(
              environment.defaultLang + "/" + pathwithoutlangCode
            );
          }
        }
      } else {
        if (localLangcode && _.contains(acceptedLanguages, localLangcode)) {
          this.navigateAfterSetLang(localLangcode + "/" + locationPath);
          this.langCode = localLangcode;
        } else if (ipbasedLang && _.contains(acceptedLanguages, ipbasedLang)) {
          this.updateLanguageCode(ipbasedLang);
          this.navigateAfterSetLang(ipbasedLang + "/" + locationPath);
        } else {
          this.updateLanguageCode(environment.defaultLang);
          this.navigateAfterSetLang(
            environment.defaultLang + "/" + locationPath
          );
        }
      }
    } else {
      if (localLangcode && _.contains(acceptedLanguages, localLangcode)) {
        this.navigateAfterSetLang(localLangcode + "/" + "casino");
        this.langCode = localLangcode;
      } else if (ipbasedLang && _.contains(acceptedLanguages, ipbasedLang)) {
        this.updateLanguageCode(ipbasedLang);
        this.navigateAfterSetLang(ipbasedLang + "/" + "casino");
      } else {
        this.updateLanguageCode(environment.defaultLang);
        this.navigateAfterSetLang(environment.defaultLang + "/" + "casino");
      }
    }
    const queryParams = this.utility.getQueryParams();
    if (!_.isEmpty(queryParams)) {
      if (!this.isLoggedIn) {
        Promise.resolve(this.commonService.getLoginStatus()).then((data) => {
          this.handleQueryParams(queryParams);
        });
      } else {
        this.handleQueryParams(queryParams);
      }
    }
  }

  clearQueryParams() {
    var snapshot = this.activatedRoute.snapshot;
    const params = { ...snapshot.queryParams };
    delete params.redirect;
    this.router.navigate([], { queryParams: params });
  }
  updateLanguageCode(languageCode) {
    this.translationService.use(localToCmsLanguageConfigurations[languageCode]);
  }

  navigateAfterSetLang(path) {
    if (window.location.search.length > 0) {
      const params = this.utility.getQueryParams();
      this.router.navigate([path], { queryParams: params });
    } else {
      this.router.navigate([path]);
    }
  }

  userProfileLangNavigation(regfirst, languageCodeFromURL) {
    let pathwithoutlangCode;
    if (regfirst.test(languageCodeFromURL)) {
      pathwithoutlangCode = this.utility.getDecodedCurrentPath().substring(6);
    } else {
      pathwithoutlangCode = this.utility.getDecodedCurrentPath().substring(7);
    }
    this.navigateAfterSetLang(this.langCode + "/" + pathwithoutlangCode);
  }

  getWindowType() {
    var ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  setSessionTimeText() {
    if (sessionStorage.getItem("session")) {
      let minutes = JSON.parse(sessionStorage.getItem("session"))[
        "sessionLimit"
      ];
      this.sessionTimeText = this.utility.getSessionTimeText(minutes);
    }
  }
  // disabled part of ICS-636
  // sessionContinue() {
  //   this.commonService.sessionContinue();
  //   $('#sessionPopup').css('display', 'none');
  //   $('body').removeClass('scroll-lock');
  // }

  // doLogOut() {
  //   $('#sessionPopup').css('display', 'none');
  //   $('body').removeClass('scroll-lock');
  //   this.commonService.doLogout();
  // }

  sesssionExpiryLogin() {
    this.sesssionExpiryBackdropClick();
    this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
  }

  sesssionExpiryBackdropClick() {
    this.sessionExpiryPopup = false;
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  gameIframeRedirection() {
    const ww = document.body.clientWidth;
    //if (ww <= 1024) {
    let windowParentOrigin = window.parent.location.origin;
    let doc = window.parent.document;
    let iframeId = doc.getElementById("netentgame")
      ? "netentgame"
      : "gamePlayIframe";
    const element: HTMLIFrameElement = doc.getElementById(
      iframeId
    ) as HTMLIFrameElement;
    if (element) {
      const iframe = element.contentDocument || element.contentWindow.document;
      const iframeOrigin = iframe.location.origin;
      if (iframeOrigin === windowParentOrigin) {
        let clsoeEle = window.parent.document.getElementById("closeGame");
        $(clsoeEle).trigger("click");
      }
    }
  }

  /**
   * please refer comment 1 in app component
   addPiqJsEncrypterSrcipt(){
    let piqScript = document.createElement('script');
    piqScript.src = environment.paymentIqUrl+'/api/viq/jscardencrypter/' + environment.paymentIqMID
    document.body.appendChild(piqScript)
  }
  */
  /* If user withdraws any money from wallet, we delete all the active bonus from his account 
      because of some regularization relues
      @below 2 mothods
    */

  onRouteChange(event) {
    this.gtmService.trackGTMPageViewEvents(event);
  }

  /**
   * DeepLinks Navigation URL:
   * 1. Deposit Deeplink URL
   *   link:{{SiteURL}}/en-row/casino?tab=deposit
   * 2. Deposit Deeplink with active bonus URL:
   *   link:{{SiteURL}}/en-row/casino?tab=deposit&bonusCode=test3reload
   * 3.Rewards Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?tab=rewards
   * 4.KYC Verification Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?tab=confirmidentity
   * 5.Live Chat Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?tab=live_chat
   * 6.Preferences Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?tab=preferences
   * 7.login Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?login=true
   * 8.Registration Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?register=true
   * 9.Game window Deeplink URL:
   *   link:{{SiteURL}}/en-eu/game/7-piggies?login=true
   * 10.Lobby Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?lobby=slots
   * 11.Game Group Deeplink URL:
   *   link:{{SiteURL}}/en-row/casino?lobby=slots&group=new-games
   * 12.Promotions Deeplink URL:
   *  a) For all login promotions
   *  link:{{SiteURL}}/en-row/promotions?login=true
   *  b) For all logout Promotions URL:
   *  link:{{SiteURL}}/en-row/promotions
   * 13.Promotion Content Deeplink URL:
   *  a) Promotion Content for loggedIn
   *  link:{{SiteURL}}/en-row/promotions/search-anything-anywhere?login=true
   *  b) Promotion Content for logged out ()
   *  link:{{SiteURL}}/en-row/promotions/search-anything-anywhere
   */

  handleQueryParams(queryParams) {
    if (!this.isLoggedIn && queryParams["register"] === "true") {
      this.paynplayCashierService.broadCastInitiateDeposit({
        callingFrom: "deepLink",
      });
    } else if (!this.isLoggedIn && queryParams["login"] === "true") {
      let path = this.utility.getDecodedCurrentPath();
      if (
        path &&
        path.includes(this.translationService.instant("url.promotions")) &&
        path.split("/").length > 3
      ) {
        let navigationData = {
          type: "url",
          data: {
            navigateTo: "promotions",
            path: path,
          },
        };
        this.commonService.setNavigateAfterLogin(navigationData);
        this.router.navigate([
          this.langCode +
            "/" +
            this.translationService.instant("url.promotions"),
        ]);
      }
      if (path && path.includes(this.translationService.instant("url.game"))) {
        let navigationData = {
          type: "url",
          data: {
            navigateTo: "gameWindow",
            path: path.split("/")[3],
          },
        };
        this.commonService.setNavigateAfterLogin(navigationData);
        this.router.navigate([this.langCode + "/casino"]);
      }
      this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
    } else if (queryParams["tab"] === "deposit") {
      this.paynplayCashierService.broadCastInitiateDeposit({
        callingFrom: "deepLink",
      });
    } else if (queryParams["tab"] === "live_chat") {
      this.liveChatService.initChat();
    } else if (queryParams["tab"] === "confirmidentity" && this.isLoggedIn) {
      this.commonService.getUserKycLevelDetails().subscribe((data) => {
        if (!data["enableKyc"]) {
          this.commonService.broadCastActiveAcountMenu("menuOptions");
        } else {
          this.utility.openAccountComponent(queryParams["tab"]);
        }
      });
    } else if (this.isLoggedIn && queryParams.hasOwnProperty("tab")) {
      this.utility.openAccountComponent(queryParams["tab"]);
    } else if (!this.isLoggedIn && queryParams.hasOwnProperty("tab")) {
      this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
      let navigationData = {
        type: "view",
        path: queryParams["tab"],
      };
      this.commonService.setNavigateAfterLogin(navigationData);
    } else if (queryParams && queryParams.hasOwnProperty("lobby")) {
      let navigationData = {
        type: "url",
        data: {
          navigateTo: "lobby",
        },
      };
      navigationData["data"]["lobby"] = queryParams["lobby"];
      if (queryParams.hasOwnProperty("group")) {
        navigationData["data"]["group"] = queryParams["group"];
      }
      if (!this.isLoggedIn) {
        this.paynplayCashierService.broadCastInitiateLogin("LOGIN");
        this.commonService.setNavigateAfterLogin(navigationData);
      } else {
        this.commonService.broadCastUpdateActiveLobby(navigationData["data"]);
        if (navigationData["data"]["group"]) {
          this.router.navigate([
            this.langCode + "/casino/" + queryParams["group"],
          ]);
        }
      }
    }
    setTimeout(() => {
      this.utility.removeUrlQueryParams();
    });
  }

  findMyOS() {
    if (
      navigator.appVersion.indexOf("Win") !== -1 ||
      navigator.appVersion.indexOf("X11") !== -1 ||
      navigator.appVersion.indexOf("Linux") !== -1
    ) {
      document.body.classList.add("scrollBarJerk");
    } else {
      document.body.classList.remove("scrollBarJerk");
    }
  }

  rewardTCBackdropClick() {
    this.utility.toggleRewardTCPopUp(false);
  }

  clearServiceDataonLogOut() {
    if (!_.isEmpty(this.gamePlayService.getCurrentGameData())) {
      this.gamePlayService.clearGameWindowData();
    }
    this.commonService.broadCastUpdateActiveLobby("");
    this.gameGroupsService.broadCastIsLastPlayedLiveGamesExist(false);
  }

  closeConfirmDialogue() {
    this.cashBackDepositOfferPopup = false;
    this.utility.removeClassFromAppBody("overflow-hidden");
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
    this.isGameWindowLanuchSub.unsubscribe();
    this.isGameWindowMinimizedSub.unsubscribe();
    this.sessionTimeUpdateSub.unsubscribe();
    this.activePageSub.unsubscribe();
    this.doLogOutSub.unsubscribe();
    this.termsConditionSub.unsubscribe();
    if (this.activeBonusSub) {
      this.activeBonusSub.unsubscribe();
    }
    if (this.dropBonusSub) {
      this.dropBonusSub.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

/**
 * 1 . This is clone from other brand(casinofriday) & we are not using this
 *    we may use later.
 * 2. All the commponents are clone from casinofriday & we are not using them
 *    so commented please don't remove we amy use it later
 * 3. we may enable this link based required as off now not using
 * 4. This code is useful to display feed live data like number of tables open
 *     number of seats left etc...
 *     which are not using we may enable it later please don't remove it
 *
 */
