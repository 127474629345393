import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Subscription } from "rxjs";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as $ from "jquery";

// Services
import { CustomBannerService } from "src/app/modules/banner/services/custom-banner.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Component({
  selector: "app-news-ticker",
  templateUrl: "./news-sticker.component.html",
  styleUrls: ["./news-sticker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NewsTickerComponent implements OnInit {
  isLoading: boolean;
  zoneId: string = "stickers";
  bannerContent: any;
  logOutCompleteBsSubscription: Subscription;
  loginCompleteBsSubscription: Subscription;
  langCodeSubscription: Subscription;
  isLoggedIn: any;

  constructor(
    private customBannerService: CustomBannerService,
    private commonService: CommonService,
    private utility: UtilityService
  ) {
    this.loginCompleteBsSubscription = this.commonService.loginComplete.subscribe(
      (isloggedIn) => {
        this.getBannersByZoneId({ zoneId: this.zoneId }, true);
      }
    );
    this.logOutCompleteBsSubscription = this.commonService.logOutComplete.subscribe(
      (isloggedOut) => {
        this.getBannersByZoneId({ zoneId: this.zoneId }, true);
      }
    );
  }

  ngOnInit() {
    this.getBannersByZoneId({ zoneId: this.zoneId }, true);
  }

  getBannersByZoneId(bannerReqData, isforce?) {
    this.isLoading = true;
    Promise.resolve(
      this.customBannerService.getStickerBanners(bannerReqData, isforce)
    ).then((banners) => {
      this.isLoading = false;
      if (banners && banners.length > 0) {
        this.bannerContent = this.utility.replaceAll(banners[0]["content"], {
          "{{site_url}}": environment.siteUrl,
          "{{media_url}}": environment.mediaUrl,
        });

        setTimeout(() => {
          if (document.getElementById("news-ticker")) {
            document.getElementById(
              "news-ticker"
            ).innerHTML = this.bannerContent;

            var newsTickerList = $(".ticker-list p").length;

            for (var i = 0; i < newsTickerList; i++) {
              $(".ticker-list").clone().prependTo(".newsTicker--list");
            }
          }
        });
      }
    });
  }
}
