import { Component, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Subscription, forkJoin } from "rxjs";

// Libraries
import * as _ from "underscore";

// Services
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { CashbackPromoService } from "src/app/pages/rewards/service/cashback-promo.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { RewardsService } from "src/app/pages/rewards/service/reward.service";

@Component({
  selector: "app-all-rewards",
  templateUrl: "./all-rewards.component.html",
  styleUrls: ["./all-rewards.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AllRewardsComponent implements OnDestroy {
  isLoading: boolean;
  bonusDetails: any;
  gameGroupGameSub: Subscription;
  games: any;
  unClaimedLevels: any = [];
  finalFilteredBonusData = [];
  depositBonus: any;
  currencyCode: string;
  promoRaceInfoList: any;

  constructor(
    private utility: UtilityService,
    private userDetailsService: UserDetailsService,
    private rewardsService: RewardsService,
    private gameGroupsService: GameGroupsService,
    private cashBackPromoService: CashbackPromoService
  ) {
    this.userDetailsService.currencyCodeSb$.subscribe((curreny) => {
      this.currencyCode = curreny;
    });
    this.getAllAvailableBonus();
  }

  activeBonusDetails = [];
  getAllAvailableBonus(isRefreshRequired = true) {
    if (isRefreshRequired) {
      this.isLoading = true;
      this.activeBonusDetails = [];
      this.unClaimedLevels = [];
    }
    forkJoin([
      this.cashBackPromoService.getUserCampaigns(),
      this.rewardsService.getActiveBonusDetails(),
      this.rewardsService.getEligibleBonus({ criteriaType: "DEPOSIT" }),
      this.rewardsService.getUnclaimedLevels(),
    ]).subscribe(
      ([
        promoRaceInfoList,
        activeBonusResp,
        eligibleDespositBonusResp,
        unClaimedLevelsResp,
      ]) => {
        if (
          promoRaceInfoList &&
          promoRaceInfoList["campaignDetails"] &&
          promoRaceInfoList["status"] === "SUCCESS"
        ) {
          this.promoRaceInfoList = promoRaceInfoList["campaignDetails"];
          this.promoRaceInfoList = this.promoRaceInfoList.filter((data) => {
            let currentTime = new Date();
            let promoStartTime = new Date(data["campaignStartDate"]);
            let promoOfferExpiryDate = new Date(data["campaignEndDate"]);
            if (
              data &&
              data["campaignType"] === "CASHBACK_PROMO" &&
              data["optInRequired"] &&
              !data["playerOptinTime"] &&
              currentTime >= promoStartTime &&
              currentTime < promoOfferExpiryDate
            ) {
              data["bonusTypeFD"] = "cashback-promotion";
              return data;
            }
          });
          if (this.promoRaceInfoList && this.promoRaceInfoList.length > 0) {
            this.finalFilteredBonusData.push(this.promoRaceInfoList);
            this.finalFilteredBonusData = _.flatten(
              this.finalFilteredBonusData
            );
          }
        }
        if (activeBonusResp && activeBonusResp.length > 0) {
          this.bonusDetails = activeBonusResp;
          this.bonusDetails = this.bonusDetails.filter((bonus) => {
            if (bonus.isFreeSpinBonus && !bonus.isFreeSpinsCompleted) {
              bonus["bonusTypeFD"] = "freeSpin-bonus";
              return bonus;
            }
          });
          if (this.bonusDetails && this.bonusDetails.length > 0) {
            this.finalFilteredBonusData.push(this.bonusDetails);
            this.finalFilteredBonusData = _.flatten(
              this.finalFilteredBonusData
            );
          }
        }
        if (
          this.finalFilteredBonusData &&
          this.finalFilteredBonusData.length > 0
        ) {
          this.getGameGroupGames(false);
        }
        if (
          eligibleDespositBonusResp &&
          eligibleDespositBonusResp["status"] === "SUCCESS" &&
          eligibleDespositBonusResp["eligibleBonusList"]
        ) {
          this.depositBonus = eligibleDespositBonusResp["eligibleBonusList"];
          this.depositBonus = this.rewardsService.filterEligibleBonusForPnp(
            this.depositBonus,
            this.currencyCode
          );
          if (this.depositBonus && this.depositBonus.length > 0) {
            this.finalFilteredBonusData.push(this.depositBonus);
            this.finalFilteredBonusData = _.flatten(
              this.finalFilteredBonusData
            );
          }
        }
        if (
          this.finalFilteredBonusData &&
          this.finalFilteredBonusData.length > 0
        ) {
          this.getGameGroupGames(false);
        }
        if (
          unClaimedLevelsResp &&
          unClaimedLevelsResp["status"] === "SUCCESS" &&
          unClaimedLevelsResp["unclaimedLevels"].length > 0
        ) {
          this.unClaimedLevels = unClaimedLevelsResp["unclaimedLevels"];
          this.unClaimedLevels = _.map(
            this.unClaimedLevels,
            (unclaimlevelId) => {
              return {
                bonusTypeFD: "unclaimedFreeSpin-bonus",
                levelId: unclaimlevelId,
              };
            }
          );
          if (this.unClaimedLevels && this.unClaimedLevels.length > 0) {
            this.finalFilteredBonusData.push(this.unClaimedLevels);
            this.finalFilteredBonusData = _.flatten(
              this.finalFilteredBonusData
            );
          }
        }
        if (isRefreshRequired) {
          this.isLoading = false;
        }
      }
    );
  }

  getGameGroupGames(isForce) {
    this.gameGroupGameSub = this.gameGroupsService
      .getGameGroupGames(isForce)
      .subscribe((games) => {
        if (games && games.length > 0) {
          this.games = games;
          this.finalFilteredBonusData = this.finalFilteredBonusData.filter(
            (data, bindex) => {
              if (
                data["freeSpinGameIds"] &&
                data["freeSpinGameIds"].length > 0
              ) {
                let filteredGame = _.findWhere(games, {
                  gameCode: data["freeSpinGameIds"][0],
                });
                if (filteredGame && Object.keys(filteredGame).length > 0) {
                  data["gameData"] = filteredGame;
                }
              } else if (data["gameIds"] && data["gameIds"].length > 0) {
                let filteredGame = _.findWhere(games, {
                  gameCode: data["gameIds"][0],
                });
                if (filteredGame && Object.keys(filteredGame).length > 0) {
                  data["gameData"] = filteredGame;
                }
              }
              return data;
            }
          );
        }
      });
  }

  reloadActiveBonus() {
    this.finalFilteredBonusData = [];
    this.getAllAvailableBonus(false);
  }

  openRewardTCPopUp(event) {
    if (event) {
      event.stopPropagation();
    }
    this.utility.toggleRewardTCPopUp(true);
  }

  ngOnDestroy() {
    if (this.gameGroupGameSub) {
      this.gameGroupGameSub.unsubscribe();
    }
  }
}
