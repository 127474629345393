import { takeUntil, map } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import {
  ActivatedRoute,
  NavigationEnd,
  ParamMap,
  Router,
} from "@angular/router";
import {
  ViewEncapsulation,
  HostListener,
  ElementRef,
  Component,
  ViewChild,
  OnDestroy,
  OnInit,
} from "@angular/core";

// Libraries
import * as _ from "underscore";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { GameGroupsService } from "src/app/modules/game-groups/services/game-groups.service";
import { GamePlayService } from "src/app/modules/game-groups/services/game-play.service";
import { LiveChatService } from "src/app/modules/chat/services/live-chat.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { EmitterService } from "src/app/services/emitter.service";

@Component({
  selector: "app-game-play-window",
  templateUrl: "./game-play-window.component.html",
  styleUrls: ["game-play-window.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class GamePlayWindowComponent implements OnDestroy, OnInit {
  aspectRatio: any;
  windowHeight: number;
  tagFilterHeight;
  topHeaderRibbonHeight;
  gameIframeHeight: number;
  gameScreenFooterHeight: number = 64;
  isLoggedIn: boolean;
  isFavoriteGame: boolean = false;
  langCode: string;
  isGameMinimized: boolean = false;
  gameplayedFrom: any;
  langCodeSubscription: Subscription;
  loginCompleteSub: Subscription;
  logoutCompleteSub: Subscription;
  makeFavoriteSub: Subscription;
  routerSub: Subscription;
  gamePlayserviceSub: Subscription;
  favoriteStatusSub: Subscription;
  isGamewindowMinimizedSub: Subscription;
  activeGameName;
  activeGameData;
  windowType;
  isGamesToasterOpen: boolean = false;
  activatedRouteParamMapPipe$ = this.activatedRoute.paramMap.pipe(
    map((params: ParamMap) => params.get("gameId"))
  );
  previousURL: any;

  @ViewChild("gameWindowScreen", { static: false })
  gameWindowScreen: ElementRef;
  @ViewChild("gameWindowIframe", { static: false })
  gameWindowIframe: ElementRef;

  gameCalledFrom: string;
  relanchSub: Subscription;
  maxBetPopup: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @HostListener("window:orientationchange") onRotate() {
    this.getScreenHeight();
    this.getWindowType();
  }

  @HostListener("window:resize") onResize() {
    this.getScreenHeight();
    this.getWindowType();
  }

  @HostListener("window:message", ["$event"])
  onMessage(e) {
    if (e["data"] === "pplive_enter_fs") {
      const ele = document.querySelector("#gameIframeWrapper iframe");
      this.goToFullScreen(ele);
    } else if (e["data"] === "pplive_exit_fs" || e["data"] === "exit_fs_pp") {
      this.goOutFullScreen();
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translationService: TranslationService,
    private commonService: CommonService,
    private gamePlayService: GamePlayService,
    private gameGroupsService: GameGroupsService,
    private emitterService: EmitterService,
    private utility: UtilityService,
    private currencyPipe: CurrencyFormatPipe,
    public liveChatService: LiveChatService
  ) {
    let self = this;
    self.gameCalledFrom = self.gamePlayService.getGameCalledfrom();
    self.langCodeSubscription = self.translationService.langCodeSb$.subscribe(
      (langCode) => {
        self.langCode = langCode;
      }
    );

    self.favoriteStatusSub = self.gamePlayService.updateCurrentGameFavoriteStatus$.subscribe(
      (flag) => {
        self.isFavoriteGame = flag;
      }
    );
    self.isGamewindowMinimizedSub = self.gamePlayService.isGameWindowMinimized$.subscribe(
      (flag) => {
        self.isGameMinimized = flag;
      }
    );

    self.activatedRoute.params.subscribe((params) => {
      let data = self.gamePlayService.getCurrentGameData();
      if (data && Object.keys(data).length > 0) {
        self.activeGameData = data;
        self.activeGameName = data["name"];
        // this.updateMetaTagBasedOnGame(self.activeGameData);
      }
    });

    self.isLoggedIn = self.utility.isUserLoggedIn();

    self.loginCompleteSub = self.commonService.loginComplete$.subscribe(
      (flag) => {
        self.isLoggedIn = true;
      }
    );
    self.logoutCompleteSub = self.commonService.logOutComplete$.subscribe(
      (flag) => {
        self.isLoggedIn = false;
      }
    );
    this.isGamewindowMinimizedSub = this.gamePlayService.isGameWindowMinimized$.subscribe(
      (flag) => {
        this.isGameMinimized = flag;
      }
    );

    /**
     * Below Navigation handler Logic will read game name from URL & filter the game data from all games
     * present in the system by name and the use this data to create iframe & load it on page.
     *
     * creating game window iframe is handled in game service.ts file
     */
    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeGameData = this.gamePlayService.getCurrentGameData();
        if (
          this.activeGameData &&
          Object.keys(this.activeGameData).length > 0
        ) {
          this.activeGameName = this.activeGameData["name"];
        }
        this.activatedRouteParamMapPipe$
          .pipe(takeUntil(this.destroy$))
          .subscribe((gameId) => {
            const queryParams = this.utility.getQueryParams();
            const gameName = this.activeGameName
              ? this.utility.convertGameNameToUrl(this.activeGameName)
              : undefined;
            if (queryParams && queryParams.hasOwnProperty("login")) {
              this.activeGameName = gameId;
              this.checkSessionAndLoad();
            } else if (
              gameId &&
              gameName !== gameId &&
              this.utility
                .getDecodedCurrentPath()
                .includes("/" + this.translationService.instant("url.game"))
            ) {
              this.activeGameName = gameId;
              this.getGameDataById(this.activeGameName);
            }
          });
      }
    });

    /**
     * Use case : when user is playing 7 piggies of demo game & trying to switch real game In this angular will
     * not reload page/game because in both cases URL will be same so framework will not recognize change
     *
     * To Handle this case we have written a logic which emit event to force reload of game window/iframe
     * Below function is handling this scenario when ever below function hits we relaod the iframe instead of page.
     * & Mostly below event is boradcasted by game card component by comparing current game page url vs incoming game name
     * and then broadcast relaod event.(This scenario occurs when user in game page & relaunch same game from search
     * or tag or ribben)
     */
    this.relanchSub = this.gamePlayService.relanuchGame$.subscribe((flag) => {
      this.activeGameData = this.gamePlayService.getCurrentGameData();
      if (this.activeGameData && Object.keys(this.activeGameData).length > 0) {
        this.activeGameName = this.activeGameData["name"];
        this.getGameDataById(this.activeGameName);
      }
    });
    this.getScreenHeight();
    this.getWindowType();
    this.emitterService.isMaxBetWarningTriggerred$.subscribe((data) => {
      if (data) {
        this.openMaxBetWarningPopup(data);
      }
    });
  }

  ngOnInit() {
    this.gamePlayService.isgameplaywindowlaunched = true;
    window["prerenderReady"] = false;
    this.getScreenHeight();
    this.getWindowType();
  }

  checkSessionAndLoad() {
    if (this.utility.isUserLoggedIn()) {
      this.getGameDataById(this.activeGameName);
    } else {
      Promise.resolve(this.commonService.getLoginStatus()).then((data) => {
        if (data && data["status"]) {
          this.getGameDataById(this.activeGameName);
        }
      });
    }
  }

  goToFullScreen(targetEle) {
    this.utility.fullScreenInit(targetEle);
  }

  goOutFullScreen() {
    document.exitFullscreen();
  }

  getGameDataById(gameName) {
    this.isFavoriteGame = false;
    this.gamePlayService.broadCastCurrentGameFavoriteStatus(
      this.isFavoriteGame
    );
    Promise.resolve(this.gameGroupsService.getGameGroupGamesp()).then(
      (data) => {
        let filterGameData = data.find(({ name }) => {
          if (name && gameName) {
            return (
              this.utility.convertSpecialCharactersWithSpace(name) ==
              this.utility.convertSpecialCharactersWithSpace(gameName)
            );
          }
        });
        if (!_.isEmpty(filterGameData)) {
          this.gamePlayService.broadCastIsGameLanuch(filterGameData);
          /**In mobile we will not have play & try for fun btn..so based on login/logout state
           * we decide realgame & freegame..But if we don't have demo version a particular game,we
           * are asking user to froce login(which is handled in the component level itself).
           *
           * whether as in desktop It will not depend on only login state...It will depends from where it's call(
           * on clicking 'play btn' or 'fun btn')..because we have "try for fun" for logged In users also..
           * Same here to if we don't
           */
          gameName = filterGameData["name"];
          this.gameCalledFrom = this.gamePlayService.gameCalledFrom;
          if (!this.gameCalledFrom) {
            this.gameCalledFrom = this.isLoggedIn ? "realgame" : "freegame";
          }
          setTimeout(() => {
            this.gamePlayService.loadGame({
              gameId: filterGameData["gameCode"],
              gameType: this.gameCalledFrom,
              hasDemo: filterGameData["hasDemo"],
            });
          }, 300);
          /**If user is in login state we call get Favovite game API
           * & to check whether active game is favorite or not...
           */
          // if (this.isLoggedIn) {
          //   this.getFavoriteGame(true);
          // }
          this.utility.setSEO(filterGameData, true);
          this.commonService.setCanonicalURL(filterGameData["multilingualUrl"]);
        } else {
          let data = {
            gameId: "",
            gameType: this.gameCalledFrom === "play" ? "realgame" : "freegame",
            hasDemo: "",
          };
          this.gamePlayService.broadCastIsGameLanuch(data);
          // setTimeout(() => {
          //   this.gamePlayService.loadIframe('gamedata-not-found');
          // }, 300)
        }
        window["prerenderReady"] = true;
      }
    );
  }

  ngAfterViewInit() {
    this.getScreenHeight();
    this.getWindowType();
    setTimeout(() => {
      if (
        document
          .querySelector(".main_container")
          .classList.contains("atGamePlay") &&
        this.windowType === "mobile"
      ) {
        this.goToFullScreen(document.documentElement);
      }
    });
  }

  /**
   * This functionality to handle Game window minimize & maximize
   * Feature
   */
  makeMinimizedGame() {
    this.isGameMinimized = true;
    this.gamePlayService.broadCastGameWindowMinimized(true);
    setTimeout(() => {
      this.utility.backNavigationURlHandler();
    }, 200);
  }

  makeMaximizeGame() {
    if (this.windowType === "mobile") {
      this.goToFullScreen(document.documentElement);
    }
    this.isGameMinimized = false;
    this.router.navigate([
      this.langCode +
        "/" +
        this.translationService.instant("url.game") +
        "/" +
        this.utility.convertGameNameToUrl(this.activeGameName),
    ]);
    this.gamePlayService.broadCastGameWindowMinimized(false);
    this.getWindowType();
  }

  /**
   *
   * @param gameId
   * game Id for which game we have to make favorite.
   * @param gameState
   *  we have pass isFavorite state of current game in this flag
   *  suppose if isFavorite flag in game is true..we have to pass false to toggle it
   *  & if it's false,we have to pass true
   */

  getFavoriteGame(isForce) {
    let activebeGameTypeId: any;
    if (this.gamePlayService.getCurrentGameData()) {
      activebeGameTypeId = this.gamePlayService.getCurrentGameData()[
        "beGameTypeId"
      ];
    }
    if (activebeGameTypeId) {
      this.gameGroupsService.getFavoriteGames(isForce).subscribe((data) => {
        if (
          data["favorite"] &&
          data["favorite"]["favoriteGamesList"].length > 0
        ) {
          let filterreddata = _.filter(data["favorite"]["favoriteGamesList"], {
            gameTypeId: activebeGameTypeId,
          });
          if (!_.isEmpty(filterreddata[0])) {
            this.isFavoriteGame = filterreddata[0]["isFavourite"];
            this.gamePlayService.broadCastCurrentGameFavoriteStatus(
              this.isFavoriteGame
            );
          } else {
            this.isFavoriteGame = false;
            this.gamePlayService.broadCastCurrentGameFavoriteStatus(
              this.isFavoriteGame
            );
          }
        }
      });
    }
  }
  toggleFavoriteGame(currentState) {
    let gameId = this.gamePlayService.getCurrentGameData()["gameCode"];
    let nextState = !currentState;
    if (gameId) {
      this.makeFavoriteSub = this.gamePlayService
        .toggleFavoriteGame(gameId, nextState)
        .subscribe((data) => {
          if (data && data["status"] === "SUCCESS") {
            this.isFavoriteGame = data["favoriteAddFlag"] ? true : false;
            this.gameGroupsService.getFavoriteGames(true).subscribe();
          }
        });
    }
  }

  clearGamePlayData() {
    this.gamePlayService.broadCastIsGameLanuch({});
    this.gamePlayService.broadCastGameWindowMinimized(false);
  }

  closePopout() {
    this.clearGamePlayData();
  }

  closeGame() {
    this.isGameMinimized = true;
    this.clearGamePlayData();
    setTimeout(() => {
      this.utility.backNavigationURlHandler();
    });
  }

  // updateMetaTagBasedOnGame(gameData) {
  //   if (gameData['metaDescription']) {
  //     this.utility.addMetaTag(gameData['metaTags'], gameData['metaDescription']);
  //   }
  // }

  ngOnDestroy() {
    if (this.isLoggedIn) {
      this.updateLastPlayedGames();
    }

    this.loginCompleteSub.add(this.langCodeSubscription);
    this.loginCompleteSub.add(this.logoutCompleteSub);
    this.loginCompleteSub.add(this.makeFavoriteSub);
    this.loginCompleteSub.add(this.routerSub);
    this.loginCompleteSub.add(this.gamePlayserviceSub);
    this.loginCompleteSub.add(this.favoriteStatusSub);
    this.loginCompleteSub.add(this.isGamewindowMinimizedSub);
    this.loginCompleteSub.unsubscribe();
    this.relanchSub.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getScreenHeight() {
    this.tagFilterHeight = 64;
    this.topHeaderRibbonHeight = 48;
    this.windowHeight =
      document.body.clientHeight -
      (this.tagFilterHeight + this.topHeaderRibbonHeight);
  }

  getWindowType() {
    const ww = document.body.clientWidth;
    if (ww >= 1024) {
      this.windowType = "device";
    } else {
      this.windowType = "mobile";
    }
  }

  playRealGame() {
    this.gamePlayService.setGameCalledfrom("realgame");
    this.gameCalledFrom = this.gamePlayService.getGameCalledfrom();
    this.getGameDataById(this.activeGameName);
  }

  makeFullScreen() {
    let iframe = document.querySelector("#makeFullscreenFrame");
    this.utility.fullScreenInit(iframe);
  }

  exitFullscreen() {
    this.utility.exitFullScreen();
  }

  maxBetWarningMessage: string;
  openMaxBetWarningPopup(data) {
    this.maxBetWarningMessage = this.translationService.instant(
      "gameplay.max_bet_text",
      {
        bonusBetLimitPerBet: data
          ? this.currencyPipe.transform(data["bonusBetLimitPerBet"])
          : 0,
      }
    );
    this.maxBetPopup = true;
  }

  closeMaxBetWarningPopup() {
    this.maxBetWarningMessage = undefined;
    this.maxBetPopup = false;
  }

  updateLastPlayedGames() {
    Promise.resolve(this.gameGroupsService.getLastedPlayedGamesp(true)).then();
  }

  openAccountMenuList() {
    this.commonService.broadCastActiveAcountMenu("menuOptions");
  }
}
