import { Injectable } from "@angular/core";

// Libraries
import * as socketIo from "socket.io-client";

// Services
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { RewardsService } from "src/app/pages/rewards/service/reward.service";
import { MainService } from "src/app/services/main-service/main.service";
import { EmitterService } from "src/app/services/emitter.service";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  socket;
  url: string;
  sessionId: string;
  constructor(
    private emitterService: EmitterService,
    private userDetailsService: UserDetailsService,
    private rewardsService: RewardsService,
    private mainService: MainService
  ) {}

  connectToScoket(url, sessionId, force?) {
    if (sessionId) {
      this.url = url;
      this.sessionId = sessionId;
      if (!this.socket || (this.socket && !this.socket.connected) || force) {
        this.socket = socketIo(url);
        this.socket.on("connect", (esocket) => {
          this.socket.emit("setAuthCode", sessionId);
          this.socket.on("message", (data) => {
            /*To keep session active when user playing games */
            if (
              data &&
              data.messageName &&
              data.messageName.includes("PushRealBalance")
            ) {
              /*commenting below line because mutliple status Api calls are hitting
             when user playing games */
              // Promise.resolve(this.mainService.getLoginStatus()).then();
              setTimeout(() => {
                const socketBalanceData = {
                  realCashBalance: Number(data["cashBalance"]),
                  crpBalance: data["crpBalance"] / 100,
                  wrpBalance: data["wrpBalance"] / 100,
                  drpBalance: data["drpBalance"] / 100,
                  rrpBalance: data["rrpBalance"] / 100,
                };
                this.userDetailsService.setUserBalanceDetails(
                  socketBalanceData
                );
              });
            } else if (
              data &&
              data.messageName &&
              data.messageName.includes("LEVELUP_LEVEL_UPGRADE")
            ) {
              data["dataFrom"] = "socket";
              this.rewardsService.broadLevelUpDetails(data);
            } else if (
              data &&
              data.messageName &&
              data.messageName.includes("LEVELUP_POINTS_UPDATE")
            ) {
              this.rewardsService.broadLevelUpPointDetails(data);
            } else if (
              data &&
              data.messageName.includes("BET_FROM_BONUS_EXCEEDED_LIMIT")
            ) {
              this.emitterService.broadCastMaxBetWarningTriggerred(data);
            } else if (
              data &&
              data.messageName &&
              data.messageName.includes("PushLogoutEvent")
            ) {
              this.emitterService.broadCastDoLogout(true);
            }
          });
        });
      }
    }
  }
}
