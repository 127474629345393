import { Observable, Subject, of, Subscription } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";

// Configurations
import { endpointConfigurations } from "src/app/configurations/endpoint.configurations";

// Environments
import { environment } from "src/environments/environment";

// Libraries
import * as _ from "underscore";
import * as $ from "jquery";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";
import { MainService } from "src/app/services/main-service/main.service";

@Injectable()
export class GamePlayService implements OnDestroy {
  isLoggedIn: boolean;
  gamePauseData = {};
  langCode: string = environment.defaultLang;

  public logOutComplete = new Subject<boolean>();
  public logOutComplete$: Observable<
    boolean
  > = this.logOutComplete.asObservable();
  isGameMinimized: any;
  gameData: any;
  langCodeSubscription: Subscription;
  RegilyPopUpTimerUpdateSubscription: Subscription;
  isCurrentGameFavoriteStatus: boolean = false;
  isgameplaywindowlaunched;
  dummy: string;
  registrationPopUpTimePeriodInSeconds = 90;
  registrationTimerInstance;
  isGameLoading: boolean;
  gameDetails: any;

  broadCastIsLoggedOut(islogin: boolean) {
    this.logOutComplete.next(islogin);
  }

  private updateGameIframeData = new Subject<any>();
  public updateGameIframeData$: Observable<
    any
  > = this.updateGameIframeData.asObservable();

  broadCastGameIframeData(gameData) {
    this.updateGameIframeData.next(gameData);
  }

  private isGamewindowLanuched = new Subject<any>();
  public isGamewindowLanuched$ = this.isGamewindowLanuched.asObservable();

  broadCastIsGameLanuch(data) {
    this.setCurrentGameData(data);
    this.isGamewindowLanuched.next(data);
  }

  setCurrentGameData(data) {
    this.gameData = data;
  }

  getCurrentGameData() {
    return this.gameData;
  }

  private isGameWindowMinimized = new Subject<any>();
  public isGameWindowMinimized$ = this.isGameWindowMinimized.asObservable();

  broadCastGameWindowMinimized(flag: boolean) {
    this.setIsGameWindowMinimized(flag);
    this.isGameWindowMinimized.next(flag);
  }

  setIsGameWindowMinimized(flag) {
    this.isGameMinimized = flag;
  }
  getIsGameWindowMinimized() {
    return this.isGameMinimized;
  }

  updateCurrentGameFavoriteStatus = new Subject<any>();
  updateCurrentGameFavoriteStatus$ = this.updateCurrentGameFavoriteStatus.asObservable();

  broadCastCurrentGameFavoriteStatus(flag) {
    this.setCurrentGameFavoriteStatus(flag);
    this.updateCurrentGameFavoriteStatus.next(flag);
  }

  setCurrentGameFavoriteStatus(flag) {
    this.isCurrentGameFavoriteStatus = flag;
  }

  getCuurentGameFavoriteStatus() {
    return this.isCurrentGameFavoriteStatus;
  }

  gameCalledFrom: string;
  setGameCalledfrom(type) {
    this.gameCalledFrom = type;
  }

  getGameCalledfrom() {
    return this.gameCalledFrom;
  }

  private relaunchGame = new Subject<any>();
  public relanuchGame$ = this.relaunchGame.asObservable();

  broadCastRelanuchGame(flag) {
    this.relaunchGame.next(flag);
  }

  constructor(
    private utility: UtilityService,
    private router: Router,
    private translationService: TranslationService,
    private mainService: MainService,
    private commonService: CommonService
  ) {
    this.isLoggedIn = this.utility.isUserLoggedIn();
    this.commonService.loginComplete$.subscribe((data) => {
      this.isLoggedIn = true;
    });
    this.commonService.logOutComplete$.subscribe((data) => {
      this.isLoggedIn = false;
    });

    this.langCodeSubscription = this.translationService.langCodeSb$.subscribe(
      (langCode) => {
        this.langCode = langCode;
      }
    );
  }

  loadGame(gameDetails) {
    this.isEventListenerAdded = false;
    this.gameDetails = gameDetails;
    $(".game-is-loading").css("display", "flex");
    const urlpath =
      gameDetails.gameType === "realgame"
        ? endpointConfigurations.getRealgame_url
        : endpointConfigurations.getFreeGame_url;
    this.mainService
      .psGet(urlpath, { gameSymbol: gameDetails.gameId })
      .subscribe((gameResponse) => {
        gameResponse = gameResponse["gameDetails"];
        this.loadIframe(gameResponse);
      });
  }

  loadIframe(resp) {
    /**
     * we remove gameplay loading progress indicator here
     */
    // $(".loaderPlaceHolder").removeClass('loaderShow');
    $(".game-is-loading").hide();
    let $iframe;
    $("#gameIframeWrapper").html("");
    if (resp && resp.netentDetails) {
      $("#gameIframeWrapper").append('<div id="netentgame"></div>');
      this.netentScript(resp.netentDetails, this.getDeviceType());
    } else if (resp && resp["url"]) {
      resp = this.checkIsPlayNGo(resp);
      $iframe = $(
        '<iframe title="Game playground" name="gamePlayIframe" class="iframe" id="gamePlayIframe" src="' +
          resp["url"] +
          '"></iframe>'
      );
    } else if (resp === "gamedata-not-found") {
      this.getErrorMessageiframe(resp);
    } else {
      this.getErrorMessageiframe(resp["error"]);
    }
    $("#gameIframeWrapper").append($iframe);
    this.isGameLoading = false;
    setTimeout(() => {
      $("#gamePlayIframe").on("load", () => {
        /* Blueprint vendor's postMessage listeners:
         * CloseGame - On fatal Error redirecting it to Home page
         * requestSize - Sending width and height of the iframe container
         */
        window.parent.addEventListener("message", (e) => {
          if (e && e.data) {
            if (e.data["type"] == "requestSize") {
              this.blueprintPostMessage();
            }
          }
        });
        if (resp && resp.url.includes("playngonetwork")) {
          this.establishCrossCommunication(resp["url"]);
        }
      });

      window.onresize = () => {
        if (
          $("#gamePlayIframe").length > 0 &&
          resp &&
          resp["game_code"].includes("hub88bpg")
        ) {
          this.blueprintPostMessage();
        }
      };
    });
  }

  isEventListenerAdded = false;
  checkIsPlayNGo(response) {
    if (response && response.url.includes("playngonetwork")) {
      let responseUrl = response.url;
      responseUrl = responseUrl
        .replace("lobby", "origin")
        .concat("&embedmode=iframe");
      if (!responseUrl.includes("origin")) {
        responseUrl = responseUrl.concat("&origin=" + window.location.origin);
      }
      response["url"] = responseUrl;
      return response;
    } else {
      return response;
    }
  }

  establishCrossCommunication(gameUrl) {
    let self = this;
    let GameCommunicator = {
      source: undefined,
      targetOrigin: undefined,
      init: function (element) {
        self.addWindowEventListenersForGameCommunication();
        this.source = element.contentWindow;
        this.targetOrigin = gameUrl.split("?")[0];
      },
      postMessage: function (data) {
        this.source.postMessage(data, this.targetOrigin);
      },
    };

    GameCommunicator.init(document.getElementById("gamePlayIframe"));
    GameCommunicator.postMessage({
      messageType: "addEventListener",
      eventType: "backToLobby",
    });
    GameCommunicator.postMessage({
      messageType: "addEventListener",
      eventType: "reloadGame",
    });
  }

  addWindowEventListenersForGameCommunication() {
    if (!this.isEventListenerAdded) {
      this.isEventListenerAdded = true;
      window.addEventListener("message", (event) => {
        this.eventListenerProcessMessage(event);
      });
    }
  }

  eventListenerProcessMessage(event) {
    if (event && event.data && event.data["type"] == "backToLobby") {
      this.router.navigate([this.langCode + "/casino"]);
    }
    if (
      event &&
      event.data &&
      event.data["type"] == "reloadGame" &&
      this.isGameLoading === false
    ) {
      this.isGameLoading = true;
      this.loadGame(this.gameDetails);
    }
  }

  /**
   * Blue print providers don't handle client device resolution automatically
   * so we have send clients device width & height as window post message when "requestResize" event is
   * send by them
   */
  blueprintPostMessage() {
    let ele = document.getElementById("gamePlayIframe") as HTMLIFrameElement;
    let deviceResolution = this.getDeviceWidthHeight();
    let data = { type: "resize", ...deviceResolution };
    ele.contentWindow.postMessage(data, "*");
  }

  getDeviceWidthHeight() {
    let ww = document.body.clientWidth;
    let contWidth,
      footerNaviagtionHeight = 0,
      contHeight;
    if (document.getElementById("bottomNavigation")) {
      footerNaviagtionHeight = document.getElementById("bottomNavigation")
        .offsetHeight;
    }
    if (ww <= 1024 && window.matchMedia("(orientation: portrait)").matches) {
      contWidth = window.innerWidth;
      contHeight = window.innerHeight - footerNaviagtionHeight;
    } else if (
      ww <= 1024 &&
      window.matchMedia("(orientation: landscape)").matches &&
      this.isLoggedIn
    ) {
      contWidth = window.innerWidth - footerNaviagtionHeight;
      contHeight = window.innerHeight;
    } else {
      contWidth = $("#gameIframeWrapper").outerWidth();
      contHeight = $("#gameIframeWrapper").outerHeight();
    }
    return { width: contWidth, height: contHeight };
  }

  /**
   * All the Erros which we encounters when we're trying fetch game url or  loading on iframe
   * will be handle by below function handler.
   */

  getErrorMessageiframe(resp) {
    let $iframe;
    if (resp && resp.length > 20) {
      $iframe = $(
        '<iframe title="Game playground"  name="gamePlayIframe" class="iframe" id="gamePlayIframe" src=' +
          resp +
          "></iframe>"
      );
    } else if (resp == "session expired") {
      $iframe =
        "<div class='loading-failed'>" +
        resp +
        this.translationService.instant("gameplay.please_login") +
        "</div>";
    } else if (
      resp &&
      (resp["problem_loading"] ||
        resp["unable_to_find_game"] ||
        resp["no_response_url"])
    ) {
      $iframe =
        "<div class='loading-failed'>" +
        resp["problem_loading"] +
        " " +
        resp["unable_to_find_game"] +
        " " +
        resp["no_response_url"] +
        "</div>";
    } else if (resp && resp["errorCode"] && resp["errorCode"] === 100431) {
      $iframe =
        "<div class='loading-failed'>" +
        this.translationService.instant("gameplay.game_code_missing") +
        "</div>";
    } else if (resp === "gamedata-not-found") {
      $iframe =
        "<div class='loading-failed'>" +
        this.translationService.instant("gameplay.gamenot_config") +
        "</div>";
    } else {
      $iframe =
        "<div class='loading-failed'>" +
        this.translationService.instant("gameplay.gamenot_found") +
        "</div>";
    }
    $("#gameIframeWrapper").append($iframe);
  }

  navigateToGame(gameId, gameType, hasDemo) {
    this.router.navigate([
      this.langCode +
        "/" +
        this.translationService.instant("url.game") +
        "/" +
        gameId,
    ]);
  }

  toggleFavoriteGame(gameId, gameState): Observable<any> {
    return this.mainService.psPost(endpointConfigurations.toggleFavoriteGame_url, {
      gameID: gameId,
      newState: gameState,
    });
  }

  gameRequestQueue = [];
  allGamesData = [];
  getGames(isForce: boolean = false): Observable<any> {
    if (isForce) {
      this.allGamesData = [];
    }
    if (this.allGamesData && this.allGamesData.length > 0) {
      return of(this.allGamesData);
    } else {
      return new Observable((observer) => {
        if (this.gameRequestQueue.length > 0) {
          this.gameRequestQueue.push(observer);
        } else {
          this.gameRequestQueue.push(observer);
          this.mainService.psGet(endpointConfigurations.getGames_url).subscribe((data) => {
            if (data && data.length > 0) {
              this.allGamesData = data;
            }
            for (let i = 0; i < this.gameRequestQueue.length; i++) {
              this.gameRequestQueue[i].next(data);
            }
            this.gameRequestQueue = [];
          });
        }
      });
    }
  }

  netentScript(resp, device) {
    if (!window["netent"]) {
      let gameServerUrl = resp.staticServer;
      let netentScript = document.createElement("script");
      netentScript.onload = () => {
        this.loadNetentGames(resp, device);
      };
      netentScript.setAttribute(
        "src",
        gameServerUrl + "/gameinclusion/library/gameinclusion.js"
      );
      document.head.appendChild(netentScript);
    } else {
      this.loadNetentGames(resp, device);
    }
  }

  loadNetentGames(resp, device) {
    if (device === "mobile") {
      this.loadMobileNetentGames(resp);
    } else {
      this.loadDesktopNetentGames(resp);
    }
  }

  // Desktop Netent Game config
  loadDesktopNetentGames(resp) {
    let config = {
      gameId: resp.gameId,
      staticServer: resp.staticServer,
      gameServer: resp.gameServer,
      sessionId: resp.sessionId,
      casinoBrand: "oneupent",
      targetElement: "netentgame",
      walletMode: resp.walletMode,
      language: resp.language,
      lobbyURL: environment.siteUrl,
    };

    if (resp.liveCasinoHost) {
      config["liveCasinoHost"] = resp.liveCasinoHost;
    }

    // Game launch successful.
    let success = function (netEntExtend) {
      netEntExtend.resize(640, 480);
      netEntExtend.addEventListener("gameReady", function () {
        netEntExtend.get("volumeLevel", function (volumeLevel) {
          console.log(volumeLevel);
          netEntExtend.set("volumeLevel", 50);
        });
      });
    };

    // Error handling here.
    let error = function (e) {
      console.log(e);
    };

    window["netent"].launch(config, success, error);
  }

  // Mobile Netent Game config
  loadMobileNetentGames(resp) {
    var config = {
      gameId: resp.gameId,
      staticServer: resp.staticServer,
      gameServer: resp.gameServer,
      sessionId: resp.sessionId,
      walletMode: resp.walletMode,
      lobbyURL: resp.lobbyURL,
      keepAliveURL: "",
      keepAliveInterval: 15,
      casinoBrand: resp.casinoBrand,
      launchType: "iframe",
      applicationType: resp.applicationType,
      targetElement: "netentgame",
      iframeSandbox:
        "allow-scripts allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation allow-same-origin allow-forms allow-pointer-lock",
      allowHtmlEmbedFullScreen: true,
      width: "100%",
      height: "100%",
      enforceRatio: false,
      language: resp.language,
    };

    if (resp.liveCasinoHost) {
      config["liveCasinoHost"] = resp.liveCasinoHost;
    }

    var success = function (netEntExtend) {
      // gameReady Listener - callback is used for iFrame Touch games launch
      netEntExtend.addEventListener("gameReady", function () {
        // Game ready handler to setup Operator specific code
      });
    };

    // Error handling here.
    var error = function (e) {
      console.log(e);
    };

    window["netent"].launch(config, success, error);
  }

  getDeviceType() {
    if (document.body.clientWidth >= 1024) {
      return "desktop";
    } else {
      return "mobile";
    }
  }

  /**Below logic is to clean game window data which we in store game card componet
   * on user game launch hit on every new game launch
   */
  clearGameWindowData() {
    let data = this.getCurrentGameData();
    if (!_.isEmpty(data)) {
      this.broadCastIsGameLanuch({});
      this.broadCastGameWindowMinimized(false);
    }
  }

  ngOnDestroy() {
    this.RegilyPopUpTimerUpdateSubscription.unsubscribe();
  }
}
