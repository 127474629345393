import { ValidationErrors, AbstractControl, FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";

// Configurations
import { userMinimumRGLimitCheckConfigurations } from "src/app/configurations/main.configurations";

// Pipes
import { CurrencyFormatPipe } from "src/app/modules/shared/pipes/currency-format.pipe";

// Services
import { TranslationService } from "src/app/services/translation-service/translation.service";
import { UserDetailsService } from "src/app/services/user-details/user-details.service";
import { UtilityService } from "src/app/modules/shared/services/utility.service";
import { CommonService } from "src/app/services/common-service/common.service";

@Injectable({
  providedIn: "root"
})
export class CustomValidatorService {
  static CHAZ =
    "\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29";
  static JAAZ =
    "\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B";
  static ACCENTEDLETTERS = "A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff";
  static SPECIALCHAR =
    "ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ";
  static numbers = "0-9";
  static letters =
    CustomValidatorService.CHAZ +
    CustomValidatorService.JAAZ +
    CustomValidatorService.ACCENTEDLETTERS +
    CustomValidatorService.SPECIALCHAR;
  static nameRegex = new RegExp("^([" + CustomValidatorService.letters + "\\s-])*$");
  static address = CustomValidatorService.letters + CustomValidatorService.numbers;
  static alphaNum = new RegExp("^([" + CustomValidatorService.address + "\\s-])*$");
  static addressRegex = new RegExp(
    "^([" + CustomValidatorService.address + "#,'\\s-])*$"
  );
  static alphaNumericRegex = new RegExp(
    "^([" + CustomValidatorService.address + "#,'\\s-])*$"
  );
  static dobRegex = new RegExp("^([" + CustomValidatorService.numbers + "\\/])*$");
  static translationService: any;

  constructor(
    public translationServiceParam: TranslationService,
    public userDetailsService: UserDetailsService,
    public currencyFormatPipe: CurrencyFormatPipe
  ) {
    CustomValidatorService.translationService = translationServiceParam;
  }
  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    let message;

    if (!c.get("password").value) {
      c.get("password").setErrors({
        message: CustomValidatorService.translationService.instant("errors.error1"),
      });
      message = {
        message: CustomValidatorService.translationService.instant("errors.error1"),
      };
    }
    if (!c.get("confirmPassword").value) {
      c.get("confirmPassword").setErrors({
        message: CustomValidatorService.translationService.instant("errors.error1"),
      });
      message = {
        message: CustomValidatorService.translationService.instant("errors.error1"),
      };
    } else if (
      c.get("confirmPassword").value &&
      c.get("password").value &&
      c.get("password").value !== c.get("confirmPassword").value
    ) {
      c.get("confirmPassword").setErrors({
        message: CustomValidatorService.translationService.instant("errors.error2"),
      });
      message = {
        message: CustomValidatorService.translationService.instant("errors.error2"),
      };
    } else {
      c.get("confirmPassword").setErrors(null);
      message = {};
    }
    return message;
  }

  validName(minChar, maxChar) {
    return function (control: FormControl) {
      const name = control.value;
      let hasCat = /_/;
      let message;
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error3") +
            minChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else if (name && name.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error8") +
            maxChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else if (!CustomValidatorService.nameRegex.test(name) || hasCat.test(name)) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error5"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  maxMinCharacters(minChar, maxChar) {
    return function (control: FormControl) {
      const name = control.value;
      let message;
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error3") +
            minChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else if (name && name.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error8") +
            maxChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  validAlphaNumeric(minChar, maxChar) {
    return function (control: FormControl) {
      const name = control.value;
      let message;
      const regEx = /^[a-z0-9]+$/i;

      if (!name) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (!regEx.test(name)) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error6"),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error3") +
            minChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else if (name && maxChar && name.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error7") +
            maxChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }
  // getater dan this month and yr
  reqMinMaxNum(minNum, maxChar) {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      const regEx = /^[0-9]*$/;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error9"),
        };
      } else if (value.length < minNum) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error3") +
            minNum +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else if (value.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error8") +
            maxChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  validNumbers(minChar) {
    return function (control: FormControl) {
      const name = control.value;
      let message;
      const regEx = /^[0-9]*$/;

      if (!name) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (name && !regEx.test(name)) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error9"),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error3") +
            minChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  minValueNumber(minValue, maxValue, callingFrom, withdrawableBalance?) {
    return function (control: FormControl) {
      let value = control.value;
      let message;
      let regEx;
      minValue = Number(minValue);
      maxValue = Number(maxValue);
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (value) {
        if (callingFrom === "withdraw") {
          regEx = /^[0-9]*(\.\d{0,2})?/;
          value = Number(value);
        } else if (callingFrom === "deposit") {
          regEx = /^[0-9]*$/;
        }
        if (!regEx.test(value)) {
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error12"
            ),
          };
        } else if (!value) {
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error12"
            ),
          };
        } else if (
          value &&
          callingFrom === "withdraw" &&
          minValue &&
          value > withdrawableBalance &&
          value < minValue
        ) {
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error67",
              { withdrawalAmount: withdrawableBalance, minValue: minValue }
            ),
          };
        } else if (value && minValue && value < minValue) {
          message = {
            message:
              CustomValidatorService.translationService.instant("errors.error10") +
              minValue,
          };
        } else if (
          value &&
          callingFrom === "withdraw" &&
          maxValue &&
          value > withdrawableBalance &&
          value > maxValue
        ) {
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error68",
              { withdrawalAmount: withdrawableBalance, maxValue: maxValue }
            ),
          };
        } else if (
          value &&
          callingFrom === "withdraw" &&
          minValue &&
          maxValue &&
          value > withdrawableBalance &&
          value >= minValue &&
          value <= maxValue
        ) {
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error69",
              { withdrawalAmount: withdrawableBalance }
            ),
          };
        } else if (value && maxValue && value > maxValue) {
          message = {
            message:
              CustomValidatorService.translationService.instant("errors.error11") +
              maxValue,
          };
        }
      } else {
        message = null;
      }
      return message;
    };
  }

  maxLength(value) {
    return function (control: FormControl) {
      const name = control.value;
      let message;
      value = Number(value);
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (name && value && name.length > value) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error13") +
            value +
            CustomValidatorService.translationService.instant("errors.error14"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  addressValidator(isOptional) {
    return function (c: FormControl) {
      const address =
        typeof c.value === "string" && c.value ? c.value.trim() : c.value;
      const mindigit = 2;
      const maxdigit = 100;
      let message;
      if (!address && !isOptional) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (address && address.length < mindigit) {
        message = {
          message: CustomValidatorService.translationService.instant(
            "errors.error15"
          ),
        };
      } else if (address && address.length > maxdigit) {
        message = {
          message: CustomValidatorService.translationService.instant(
            "errors.error16"
          ),
        };
      } else if (!CustomValidatorService.addressRegex.test(address)) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error17") +
            "  /'#,-'",
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  phoneNumberValidator(c: FormControl): ValidationErrors {
    const phone = c.value;
    const regEx = /^[0-9]*$/;
    const mindigit = 5;
    const maxdigit = 10;
    let message;
    if (!phone) {
      message = {
        message: CustomValidatorService.translationService.instant("errors.error1"),
      };
    } else if (phone && !regEx.test(phone)) {
      message = {
        message: CustomValidatorService.translationService.instant("errors.error18"),
      };
    } else if (phone && phone.length < mindigit) {
      message = {
        message:
          CustomValidatorService.translationService.instant("errors.error19") +
          mindigit +
          CustomValidatorService.translationService.instant("errors.error21"),
      };
    } else if (phone && phone.length > maxdigit) {
      message = {
        message:
          CustomValidatorService.translationService.instant("errors.error20") +
          maxdigit +
          CustomValidatorService.translationService.instant("errors.error21"),
      };
    } else {
      message = null;
    }

    return message;
  }

  validateUniqueness(
    fieldToValidate,
    commonService: CommonService,
    validateUnique
  ) {
    return function (control: FormControl) {
      const name = control.value;
      const regEx = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
      // /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      const regEx_nickname = /^[\w&.-]+$/;
      let message;
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (name && fieldToValidate == "txtEmail" && !regEx.test(name)) {
        message = {
          message: CustomValidatorService.translationService.instant(
            "errors.error22"
          ),
        };
      } else if (
        name &&
        fieldToValidate == "txtEmail" &&
        (name.length < 5 || name.length > 50)
      ) {
        message = {
          message: CustomValidatorService.translationService.instant(
            "errors.error61"
          ),
        };
      } else if (
        name &&
        fieldToValidate == "txtNickname" &&
        name.length < 5 &&
        !regEx.test(name)
      ) {
        message = {
          message: CustomValidatorService.translationService.instant(
            "errors.error23"
          ),
        };
      } else if (validateUnique) {
        const validateValues = {
          [fieldToValidate]: name,
        };
        Promise.resolve(commonService.validateUniqueness(validateValues)).then(
          (uniqueData) => {
            if (uniqueData && uniqueData["response"] == "1") {
              message = null;
              control.setErrors(null);
            } else if (uniqueData && uniqueData["response"] == "-1") {
              message = {
                message:
                  CustomValidatorService.translationService.instant(
                    "errors.error26"
                  ) +
                  (fieldToValidate === "txtEmail"
                    ? CustomValidatorService.translationService.instant(
                        "errors.error28"
                      )
                    : CustomValidatorService.translationService.instant(
                        "errors.error25"
                      )),
              };
              control.setErrors({
                message:
                  CustomValidatorService.translationService.instant(
                    "errors.error26"
                  ) +
                  (fieldToValidate === "txtEmail"
                    ? CustomValidatorService.translationService.instant(
                        "errors.error28"
                      )
                    : CustomValidatorService.translationService.instant(
                        "errors.error25"
                      )),
              });
            } else {
              message = {
                message:
                  fieldToValidate == "txtEmail"
                    ? CustomValidatorService.translationService.instant(
                        "errors.error29"
                      )
                    : CustomValidatorService.translationService.instant(
                        "errors.error30"
                      ),
              };
              control.setErrors(message);
            }
          }
        );
      }

      return message;
    };
  }
  validatePassword(minChar, maxChar) {
    let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/;
    let self = this;
    return function (control: FormControl) {
      const password = control.value;

      let message;
      if (!password) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (password && password.length < minChar) {
        message = {
          message: CustomValidatorService.translationService.instant(
            "errors.error24"
          ),
        };
      } else if (password && password.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error57") +
            maxChar +
            CustomValidatorService.translationService.instant("errors.error58"),
        };
      }
      // else if (!regex.test(password)) {
      //   message = { 'message': CustomValidatorService.translationService.instant('errors.error24') };
      // }
      else {
        message = null;
      }
      return message;
    };
  }
  validEmail(c: FormControl): ValidationErrors {
    const email = c.value;
    const regEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let message;
    if (!email) {
      message = {
        message: CustomValidatorService.translationService.instant("errors.error1"),
      };
    } else if (email && !regEx.test(email)) {
      message = {
        message: CustomValidatorService.translationService.instant("errors.error22"),
      };
    } else {
      message = null;
    }

    return message;
  }

  required(c: FormControl): ValidationErrors {
    const value = c.value;
    let message;
    if (!value || value.length <= 0) {
      message = {
        message: CustomValidatorService.translationService.instant("errors.error1"),
      };
    } else {
      message = null;
    }

    return message;
  }

  reqMin(minNum) {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (value.length < minNum) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error3") +
            minNum +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
        control.setErrors(message);
      } else {
        message = null;
      }

      return message;
    };
  }

  creditCardValidator(utils: UtilityService) {
    return function (control: FormControl) {
      const ccNumber = control.get("cardNumber").value
        ? control.get("cardNumber").value.replace(/-/g, "")
        : null;

      // typeof control.get('cardNumber').value == 'string' &&

      const newCard = 0; // control.get("cardId").value;
      const regEx = /^[0-9]*$/;
      const mindigit = 2;
      let message;

      if (newCard === 0) {
        if (!ccNumber) {
          control.get("cardNumber").setErrors({
            message: CustomValidatorService.translationService.instant(
              "errors.error1"
            ),
          });
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error1"
            ),
          };
        } else if (ccNumber && !regEx.test(ccNumber)) {
          control.get("cardNumber").setErrors({
            message: CustomValidatorService.translationService.instant(
              "errors.error9"
            ),
          });
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error9"
            ),
          };
        } else if (ccNumber && !utils.creditCardValidation(ccNumber)) {
          control.get("cardNumber").setErrors({
            message: CustomValidatorService.translationService.instant(
              "errors.error31"
            ),
          });
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error31"
            ),
          };
        } else {
          message = null;
        }
      } else {
        message = null;
      }

      return message;
    };
  }

  expCardData() {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      const regEx = /^(((0)[0-9])|((1)[0-2]))(\/)\d{2}$/i;
      const date = new Date();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.instant(
            "errors.error64"
          ),
        };
      } else {
        const month = date.getMonth() + 1;
        // const year = parseInt(new Date().getFullYear().toString().substring(0,2));
        const year = date.getFullYear();
        const expiryMonth = parseInt(value.split("/")[0]);
        const expiryYear = parseInt("20" + value.split("/")[1]);
        if (expiryYear < year) {
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error64"
            ),
          };
        } else if (expiryYear === year && expiryMonth < month) {
          message = {
            message: CustomValidatorService.translationService.instant(
              "errors.error64"
            ),
          };
        }
      }
      return message;
    };
  }

  validateRealityCheckValue() {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      const regEx = /^[0-9]+$/;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error9"),
        };
      } else if (value > 60) {
        message = {
          message: CustomValidatorService.translationService.instant(
            "errors.error37"
          ),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  validateNumericValue() {
    return function (control: FormControl) {
      const value = control.value;
      let message;
      const regEx = /^[0-9]+$/;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (!regEx.test(value)) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error9"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }
  validateRgLimits(currentLimits) {
    let userCurrency = this.userDetailsService.getCurrencySymbol();
    let userMinimumLimit = userMinimumRGLimitCheckConfigurations[userCurrency];
    var self = this;

    return function (control: FormControl) {
      const period = control.get("period").value;
      const value = control.get("limit").value;
      let message;
      if (!value) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
        control.get("limit").setErrors(message);
        return message;
      } else if (value < userMinimumLimit) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error38") +
            self.currencyFormatPipe.transform(userMinimumLimit),
        };
        control.get("limit").setErrors(message);
        return message;
      }
      if (currentLimits) {
        switch (period) {
          case "daily": {
            // tslint:disable-next-line:max-line-length
            if (
              (currentLimits["weekly"] &&
                currentLimits["weekly"].value &&
                value > currentLimits["weekly"].value) ||
              (currentLimits["monthly"] &&
                currentLimits["monthly"].value &&
                value > currentLimits["monthly"].value)
            ) {
              message = {
                message: CustomValidatorService.translationService.instant(
                  "errors.error39"
                ),
              };
              control.get("limit").setErrors(message);
            } else {
              message = null;
            }
            return message;
          }
          case "weekly": {
            if (
              currentLimits["daily"] &&
              currentLimits["daily"].value &&
              value < currentLimits["daily"].value
            ) {
              message = {
                message: CustomValidatorService.translationService.instant(
                  "errors.error40"
                ),
              };
              control.get("limit").setErrors(message);
            } else if (
              currentLimits["monthly"] &&
              currentLimits["monthly"].value &&
              value > currentLimits["monthly"].value
            ) {
              message = {
                message: CustomValidatorService.translationService.instant(
                  "errors.error41"
                ),
              };
              control.get("limit").setErrors(message);
            } else if (
              currentLimits["daily"] &&
              currentLimits["daily"].pending &&
              value < currentLimits["daily"].pending
            ) {
              message = {
                message: CustomValidatorService.translationService.instant(
                  "errors.error59"
                ),
              };
              control.get("limit").setErrors(message);
            } else {
              message = null;
            }
            return message;
          }
          case "monthly": {
            if (
              (currentLimits["daily"] &&
                currentLimits["daily"].value &&
                value < currentLimits["daily"].value) ||
              (currentLimits["weekly"] &&
                currentLimits["weekly"].value &&
                value < currentLimits["weekly"].value)
            ) {
              message = {
                message: CustomValidatorService.translationService.instant(
                  "errors.error42"
                ),
              };
              control.get("limit").setErrors(message);
            } else if (
              (currentLimits["daily"] &&
                currentLimits["daily"].pending &&
                value < currentLimits["daily"].pending) ||
              (currentLimits["weekly"] &&
                currentLimits["weekly"].pending &&
                value < currentLimits["weekly"].pending)
            ) {
              message = {
                message: CustomValidatorService.translationService.instant(
                  "errors.error60"
                ),
              };
              control.get("limit").setErrors(message);
            } else {
              message = null;
            }
            return message;
          }
        }
      }
    };
  }

  startEndDateCheck(c: AbstractControl): { invalid: boolean } {
    let message;
    const startDate = c.get("startDate").value;
    const endDate = c.get("endDate").value;
    if (!startDate) {
      c.get("startDate").setErrors({ invalid: true });
      message = {
        message: CustomValidatorService.translationService.instant("errors.error10"),
      };
    }
    if (!endDate) {
      c.get("endDate").setErrors({ invalid: true });
      message = {
        message: CustomValidatorService.translationService.instant("errors.error10"),
      };
    }

    if (startDate && endDate && Date.parse(endDate) < Date.parse(startDate)) {
      c.get("endDate").setErrors({
        message: CustomValidatorService.translationService.instant("errors.error25"),
      });
      message = {
        message: CustomValidatorService.translationService.instant("errors.error25"),
      };
    }
    return message;
  }

  validAlphaNumericName(minChar, maxChar) {
    return function (control: FormControl) {
      const name = control.value;
      let hasCat = /_/;
      let message;
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (name && name.length < minChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error3") +
            minChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else if (name && name.length > maxChar) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error8") +
            maxChar +
            CustomValidatorService.translationService.instant("errors.error4"),
        };
      } else if (
        !CustomValidatorService.alphaNumericRegex.test(name) ||
        hasCat.test(name)
      ) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error5"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }

  exactNumberMatch(value, removeWhiteSpaces?) {
    return function (control: FormControl) {
      let name =
        typeof control.value == "string" && control.value
          ? control.value.trim()
          : control.value;
      if (name && removeWhiteSpaces) {
        name = name.replace(/ /g, "");
      }
      let message;
      const length = name && name.toString().length;
      var regEx = /^[0-9]*$/;
      value = Number(value);
      if (!name) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error1"),
        };
      } else if (name && !regEx.test(name)) {
        message = {
          message: CustomValidatorService.translationService.instant("errors.error9"),
        };
      } else if (name && value && length !== value) {
        message = {
          message:
            CustomValidatorService.translationService.instant("errors.error65") +
            value +
            CustomValidatorService.translationService.instant("errors.error21"),
        };
      } else {
        message = null;
      }

      return message;
    };
  }
}
