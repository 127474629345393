import { Component, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";

// Components
import { FormValidationComponent } from "src/app/modules/shared/components/form-validation/form-validation.component";

// Configurations
import { ccConfigurations } from "src/app/configurations/cashier-config/cc.configurations";

// Environments
import { environment } from "src/environments/environment";

// Services
import { UtilityService } from "src/app/modules/shared/services/utility.service";

@Component({
  selector: "app-form-input",
  templateUrl: "./form-input.component.html",
  styleUrls: ["./form-input.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FormInputComponent extends FormValidationComponent {
  mediaUrlPath: string = environment.mediaUrlPath;
  config: any;
  group: FormGroup;
  selectedMethodData: any;
  callingFrom: any;
  focusedElement;
  usedAccount: boolean;
  selectedCardType: string;
  selectedCardTypeBlocks: any;
  ccReExp: { mastercard: RegExp; visa: RegExp };
  ccBlockSep: {
    uatp: number[];
    amex: number[];
    diners: number[];
    discover: number[];
    mastercard: number[];
    dankort: number[];
    instapayment: number[];
    jcb: number[];
    maestro: number[];
    visa: number[];
    mir: number[];
    general: number[];
    unionPay: number[];
    generalStrict: number[];
  };
  imgixParams: string;

  constructor(private utils: UtilityService) {
    super(utils);
    this.setPaymentLogoWidth();
    this.imgixParams = this.utils.getImgixParams();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setPaymentLogoWidth();
    }, 500);
  }

  formatInputValue(config) {
    if (config["key"] === "creditcardNumber") {
      this.formatCreditCard(config);
    } else if (config["key"] === "expiryMonthYear") {
      this.formatExpiryDate(config);
    }
  }
  formatCreditCard(config) {
    this.ccReExp = ccConfigurations.re;
    this.ccBlockSep = ccConfigurations.blocks;
    let reg = /^[0-9\s]+$/;
    if (reg.test(this.group.controls["creditcardNumber"].value) === false) {
      this.group.controls["creditcardNumber"].setValue(
        this.group.controls["creditcardNumber"].value.substring(
          0,
          this.group.controls["creditcardNumber"].value.length - 1
        )
      );
    }
    let ccValue = this.group.controls["creditcardNumber"].value;
    if (ccValue) {
      this.ccReExp = ccConfigurations.re;
      this.ccBlockSep = ccConfigurations.blocks;
      for (let key in this.ccReExp) {
        if (this.ccReExp[key].test(ccValue)) {
          this.selectedCardType = key;
          this.selectedCardTypeBlocks = this.ccBlockSep[key];
        }
      }
      let v = ccValue
        ? JSON.stringify(ccValue)
            .replace(/\s+/g, "")
            .replace(/[^0-9]/gi, "")
        : "";
      let matches = v.match(ccConfigurations.minMaxlines[this.selectedCardType]);
      let match = (matches && matches[0]) || "";
      let parts = [];
      for (
        let i = 0, j = 0, len = match.length;
        i < len && match.length > 4;
        i += this.selectedCardTypeBlocks[j++]
      ) {
        parts.push(v.substring(i, i + this.selectedCardTypeBlocks[j]));
      }
      if (parts.length) {
        this.group.controls["creditcardNumber"].setValue(parts.join(" "));
      } else {
        this.group.controls["creditcardNumber"].setValue(ccValue);
      }
    }
  }

  previousValue: any;
  formatExpiryDate(config) {
    var reg = /^[0-9\/]+$/;
    if (
      reg.test(this.group.controls["expiryMonthYear"].value) === false ||
      (this.group.controls["expiryMonthYear"].value &&
        this.group.controls["expiryMonthYear"].value.length > 5)
    ) {
      this.group.controls["expiryMonthYear"].setValue(
        this.group.controls["expiryMonthYear"].value.substring(
          0,
          this.group.controls["expiryMonthYear"].value.length - 1
        )
      );
    }
    let expiryValue = this.group.controls["expiryMonthYear"].value;
    if (
      expiryValue &&
      expiryValue.length === 2 &&
      expiryValue.length > this.previousValue.length
    ) {
      this.group.controls["expiryMonthYear"].setValue(expiryValue + "/");
    }
    this.previousValue = this.group.controls["expiryMonthYear"].value;
  }

  setPaymentLogoWidth() {
    // const paymentLogoWidth = $('.payementKindLogo').outerWidth();
    // $('.payementKindInput').css('padding-left', paymentLogoWidth  + 'px');
  }
}
