import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class EmitterService {
  private isMaxBetWarningTriggerred = new Subject<any>();
  public isMaxBetWarningTriggerred$ = this.isMaxBetWarningTriggerred.asObservable();

  broadCastMaxBetWarningTriggerred(data) {
    this.isMaxBetWarningTriggerred.next(data);
  }

  private doLogout = new Subject<any>();
  public doLogOut$ = this.doLogout.asObservable();

  broadCastDoLogout(flag) {
    this.doLogout.next(flag);
  }
  constructor() {}

  private reloadBanners = new Subject<any>();
  public reloadBanners$ = this.reloadBanners.asObservable();

  broadCastReloadBanner(flag) {
    this.reloadBanners.next(flag);
  }

  private openRewardTermsConditions = new Subject<any>();
  public openRewardTermsConditions$ = this.openRewardTermsConditions.asObservable();

  broadCastRewardTC(flag) {
    this.openRewardTermsConditions.next(flag);
  }

  private openLimitsRegulatoryPopup = new Subject<any>();
  public openLimitsRegulatoryPopup$ = this.openLimitsRegulatoryPopup.asObservable();

  broadCastLimitsRegulatoryPopup(flag) {
    this.openLimitsRegulatoryPopup.next(flag);
  }

  private closeCashierPopup = new Subject<any>();
  public closeCashierPopup$ = this.closeCashierPopup.asObservable();

  broadCastCloseCashierPopup(flag) {
    this.closeCashierPopup.next(flag);
  }
}
